import { useState } from 'react';
import { Grid } from '@mui/material';
import Modal from 'src/view/components/modal/Modal';
import Header from 'src/view/components/header/Header';
import { ETBContainer } from 'src/view/components/container/container';
import SupplierTableFeature from 'src/app/components/features/supplier/SupplierTableFeature';
import { SupplierDetailsFormFeature } from 'src/app/components/features/supplier/SupplierDetailsFormFeature';
import Button from 'src/view/components/button/Button';

export default function SuppliersOverview() {
    const [tableInvalidatedAt, setTableInvalidatedAt] = useState<number | undefined>();
    const [createModalOpen, setCreateModalOpen] = useState(false);

    return (
        <>
            <Header GridProps={{ justifyContent: 'flex-end' }}>
                <Grid item>
                    <Button color="primary" onClick={() => setCreateModalOpen(true)}>
                        Add New Supplier
                    </Button>
                </Grid>
            </Header>

            <ETBContainer>
                <SupplierTableFeature tableInvalidatedAt={tableInvalidatedAt} />
            </ETBContainer>
            <Modal
                title="Add New Supplier"
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
            >
                <SupplierDetailsFormFeature
                    onSuccess={() => {
                        setTableInvalidatedAt(Date.now());
                        setCreateModalOpen(false);
                    }}
                />
            </Modal>
        </>
    );
}
