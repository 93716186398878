import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Skeleton } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
    skeletonContainer: {
        display: 'flex',
        padding: theme.spacing(0.5, 0),
        minWidth: '640px',
        width: '100%',
    },
    skeletonTitle: {
        width: '25%',
        height: 35,
        borderRadius: theme.layout.borderRadius.regular,
        marginRight: '5%',
    },
    skeletonDescription: {
        width: '70%',
        height: 35,
        borderRadius: theme.layout.borderRadius.regular,
    },
}));

export interface DictionarySkeletonProps {
    rows?: number;
    width?: number;
}

export default function DictionarySkeleton({
    rows = 1,
    width,
}: DictionarySkeletonProps): JSX.Element {
    const classes = useStyles();

    return <>
        {[...Array(rows)].map((_, index: number) => (
            <div
                key={`skeleton-container-${index}`}
                className={classes.skeletonContainer}
                style={{ width: width }}
            >
                <Skeleton variant="rectangular" className={classes.skeletonTitle} />
                <Skeleton variant="rectangular" className={classes.skeletonDescription} />
            </div>
        ))}
    </>;
}
