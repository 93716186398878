import Grid from '@material-ui/core/Grid';
import { Theme } from '@material-ui/core/styles';
import { Alert, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Controller, type UseFormReturn } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import SupplierSelectDataWrapper from 'src/app/components/data-wrappers/inputs/SupplierSelectDataWrapper';
import purchaseUrlService from 'src/app/pages/purchases/purchaseUrlService';
import { isCreditCardMethod } from 'src/app/utilities/helpers/paymentMethod';
import UserPermissions from 'src/app/utilities/helpers/userPermissions';
import currencyOptions from 'src/app/utilities/mappers/mapCurrenciesToOptions';
import { paymentMethodOptions } from 'src/app/utilities/mappers/mapPaymentMethodsToOptions';
import ErrorDetail from 'src/data/api/responses/ErrorDetail';
import { PURCHASE_STATUS, PURCHASE_TYPES, PURCHASE_TYPE_ITEMS } from 'src/data/enums/purchase';
import AutoComplete from 'src/view/components/auto-complete/AutoComplete';
import { AutoCompleteOption } from 'src/view/components/auto-complete/interfaces';
import Checkbox from 'src/view/components/checkbox/Checkbox';
import DatePicker from 'src/view/components/date-picker/DatePicker';
import DictionaryGrid from 'src/view/components/dictionary-grid/DictionaryGrid';
import { Divider } from 'src/view/components/divider/Divider';
import { FormActionButtons } from 'src/view/components/form-action-buttons/FormActionButtons';
import FormButtons from 'src/view/components/form/FormButtons';
import FormFieldError from 'src/view/components/form/FormFieldError';
import { FormLabel } from 'src/view/components/form/FormLabel';
import Input from 'src/view/components/input/Input';

export interface PurchaseInformationData {
    numTickets?: number;
    numSplits?: number;
    status?: PURCHASE_STATUS | string;
}

const useStyles = makeStyles((theme: Theme) => ({
    spacingLeft: {
        paddingLeft: theme.spacing(1),
    },
    spacingRight: {
        paddingRight: theme.spacing(1),
    },
    noMarginTop: {
        marginTop: 0,
    },
}));

export interface PurchaseDetailsFormValues {
    supplier?: AutoCompleteOption;
    supplierUserAccount?: string;
    purchaseDate: Date;
    currency: AutoCompleteOption;
    price?: string;
    externalReference?: string;
    paymentMethod?: AutoCompleteOption | null;
    creditcardNumber?: string;
    internalNotes?: string;
    finalized?: boolean;
    purchaseType?: AutoCompleteOption;
    paymentMethodPresent?: boolean;
}

export interface CreatePurchaseDetailsFormProps {
    loading?: boolean;
    cancelIsLoading?: boolean;
    completeIsLoading?: boolean;
    editMode?: boolean;
    formSubmitText: string;
    onSubmit: (values: PurchaseDetailsFormValues) => void;
    onComplete?: () => void;
    onCancel?: () => void;
    informationDictionaryValues: PurchaseInformationData;
    showActionButtons?: boolean;
    completeErrors?: ErrorDetail[];
    cancelErrors?: ErrorDetail[];
    purchaseId: string;
    isPurchaseStatusCompleted?: boolean;
    isPurchaseStatusCancelled?: boolean;
    isContractTicket?: boolean;
    form: UseFormReturn<PurchaseDetailsFormValues, unknown, undefined>;
}

export default function CreatePurchaseDetailsForm({
    loading,
    cancelIsLoading,
    completeIsLoading,
    editMode,
    formSubmitText,
    onSubmit,
    onComplete,
    onCancel,
    informationDictionaryValues,
    showActionButtons = true,
    completeErrors,
    cancelErrors,
    purchaseId,
    isPurchaseStatusCompleted,
    isPurchaseStatusCancelled,
    isContractTicket,
    form,
}: CreatePurchaseDetailsFormProps): JSX.Element {
    const classes = useStyles();
    const theme = useTheme();
    const navigate = useNavigate();

    const { control, handleSubmit, watch } = form;

    const paymentMethod = watch('paymentMethod');

    const isFormLocked = isPurchaseStatusCompleted || isPurchaseStatusCancelled;

    return (
        <>
            {editMode && (
                <>
                    {isFormLocked && (
                        <Grid item>
                            <Alert severity="warning" sx={{ marginBottom: 1 }}>
                                No actions are allowed for purchases with the status of{' '}
                                <strong>
                                    {isPurchaseStatusCompleted ? 'Completed' : 'Cancelled'}
                                </strong>
                            </Alert>
                        </Grid>
                    )}
                    <h3 className={classes.noMarginTop}>Additional Purchase Information</h3>
                    <DictionaryGrid>
                        <Grid item xs={4}>
                            Number of tickets
                        </Grid>
                        <Grid item xs={8}>
                            {informationDictionaryValues.numTickets}
                        </Grid>
                        <Grid item xs={4}>
                            Number of splits
                        </Grid>
                        <Grid item xs={8}>
                            {informationDictionaryValues.numSplits}
                        </Grid>
                        <Grid item xs={4}>
                            Status
                        </Grid>
                        <Grid item xs={8}>
                            {informationDictionaryValues.status}
                        </Grid>
                    </DictionaryGrid>
                    <Divider />
                    <h3 className={classes.noMarginTop}>Edit Purchase Information</h3>
                </>
            )}

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormLabel>Supplier*</FormLabel>
                    <Controller
                        name="supplier"
                        control={control}
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <>
                                <SupplierSelectDataWrapper
                                    name={name}
                                    onChange={onChange}
                                    value={value}
                                    onSuccessfullyCreate={(option) => onChange(option)}
                                    disabled={editMode}
                                />
                                <FormFieldError message={error?.message} />
                            </>
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormLabel>Supplier Account Username</FormLabel>
                    <Controller
                        name="supplierUserAccount"
                        control={control}
                        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                            <Input
                                name={name}
                                value={value}
                                onChange={onChange}
                                placeholder="Supplier Account Username"
                                inputHelperText={error?.message}
                                disabled={editMode}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormLabel>Purchase Date*</FormLabel>
                    <Controller
                        name="purchaseDate"
                        control={control}
                        render={({ field: { value, onChange }, fieldState: { error } }) => (
                            <>
                                <DatePicker value={value} onChange={onChange} disabled={editMode} />
                                <FormFieldError message={error?.message} />
                            </>
                        )}
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormLabel>Currency*</FormLabel>
                    <Controller
                        name="currency"
                        control={control}
                        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                            <>
                                <AutoComplete
                                    onChange={onChange}
                                    name={name}
                                    value={value}
                                    disabled={editMode}
                                    options={currencyOptions}
                                />
                                <FormFieldError message={error?.message} />
                            </>
                        )}
                    />
                </Grid>
                <Grid item xs={8}>
                    <FormLabel>Purchase Price*</FormLabel>
                    <Controller
                        name="price"
                        control={control}
                        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                            <>
                                <Input
                                    name={name}
                                    value={value}
                                    onChange={onChange}
                                    placeholder="Price"
                                    type="number"
                                    disabled={editMode}
                                />
                                <FormFieldError message={error?.message} />
                            </>
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormLabel>External Reference</FormLabel>
                    <Controller
                        name="externalReference"
                        control={control}
                        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                            <>
                                <Input
                                    name={name}
                                    value={value}
                                    onChange={onChange}
                                    placeholder="External reference"
                                    disabled={isFormLocked}
                                />
                                <FormFieldError message={error?.message} />
                            </>
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormLabel>Payment Method</FormLabel>
                    <Controller
                        name="paymentMethod"
                        control={control}
                        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                            <>
                                <AutoComplete
                                    onChange={onChange}
                                    name={name}
                                    value={value}
                                    options={paymentMethodOptions}
                                    disabled={isFormLocked}
                                />
                                <FormFieldError message={error?.message} />
                            </>
                        )}
                    />
                </Grid>
                {paymentMethod && isCreditCardMethod(paymentMethod.value) && (
                    <Grid item xs={12}>
                        <FormLabel>Creditcard Number (last 4 digits)*</FormLabel>
                        <Controller
                            name="creditcardNumber"
                            control={control}
                            render={({
                                field: { name, value, onChange },
                                fieldState: { error },
                            }) => (
                                <>
                                    <Input
                                        name={name}
                                        value={value}
                                        onChange={onChange}
                                        placeholder="Enter last 4 digits of creditcard"
                                        disabled={isFormLocked}
                                    />
                                    <FormFieldError message={error?.message} />
                                </>
                            )}
                        />
                    </Grid>
                )}
                <Grid item xs={12} className={classes.spacingRight}>
                    <FormLabel>Internal Notes</FormLabel>
                    <Controller
                        name="internalNotes"
                        control={control}
                        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                            <Input
                                name={name}
                                value={value}
                                onChange={onChange}
                                multiline
                                rows={4}
                                placeholder="Write your internal notes here"
                                inputHelperText={error?.message}
                                disabled={isFormLocked}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormLabel>Purchase Type</FormLabel>
                    <Controller
                        name="purchaseType"
                        control={control}
                        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                            <>
                                <AutoComplete
                                    name={name}
                                    placeholder="Select a purchase type"
                                    value={value || null}
                                    onChange={onChange}
                                    options={PURCHASE_TYPES.map((pt) => ({
                                        label: PURCHASE_TYPE_ITEMS[pt],
                                        value: pt,
                                    }))}
                                    disabled={isFormLocked}
                                />
                                <FormFieldError message={error?.message} />
                            </>
                        )}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Controller
                        name="finalized"
                        control={control}
                        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                            <>
                                <FormLabel>
                                    Mark as finalized
                                    <Checkbox
                                        color="primary"
                                        name={name}
                                        checked={value || false}
                                        onChange={onChange}
                                        disabled={isFormLocked}
                                    />
                                </FormLabel>

                                <FormFieldError message={error?.message} />
                            </>
                        )}
                    />
                </Grid>
                {isContractTicket && (
                    <Grid item xs={12}>
                        <Alert severity="info">
                            Advanced editing is disabled for contract tickets.
                        </Alert>
                    </Grid>
                )}
                <FormButtons
                    buttons={[
                        {
                            children: <span>Advanced edit</span>,
                            disabled:
                                loading ||
                                !UserPermissions.canChangePurchasePrices ||
                                isFormLocked ||
                                isContractTicket,
                            startIcon: loading && (
                                <CircularProgress size={theme.layout.loader.sizes.small} />
                            ),
                            onClick: () => {
                                navigate(purchaseUrlService.advancedEdit(purchaseId));
                            },
                        },
                        {
                            children: (
                                <>
                                    <span>{formSubmitText}</span>
                                </>
                            ),
                            disabled: loading || isFormLocked,
                            startIcon: loading && (
                                <CircularProgress size={theme.layout.loader.sizes.small} />
                            ),
                            onClick: () => handleSubmit(onSubmit)(),
                        },
                    ]}
                />
                <Grid item xs={12}>
                    {!UserPermissions.canChangePurchasePrices && (
                        <Alert severity="info">
                            Only The <strong>Product Lead & Product Senior</strong> can access Advanced Editing.
                        </Alert>
                    )}
                </Grid>
                {editMode && showActionButtons && (
                    <>
                        <Divider />
                        <FormActionButtons
                            buttons={[
                                {
                                    children: 'Complete',
                                    onClick: () => onComplete?.(),
                                    text: "Complete the purchase, it can't be change afterwards",
                                    color: 'success',
                                    disabled: completeIsLoading || isFormLocked,
                                    startIcon: completeIsLoading && (
                                        <CircularProgress size={theme.layout.loader.sizes.small} />
                                    ),
                                    error: 'Could not complete the purchase',
                                    errors: completeErrors,
                                },
                                {
                                    children: 'Cancel',
                                    onClick: () => onCancel?.(),
                                    text: 'Cancel the purchase, all tickets will be cancelled',
                                    color: 'error',
                                    disabled: cancelIsLoading || isFormLocked,
                                    startIcon: cancelIsLoading && (
                                        <CircularProgress size={theme.layout.loader.sizes.small} />
                                    ),
                                    error: 'Could not cancel the purchase',
                                    errors: cancelErrors,
                                },
                            ]}
                        />
                    </>
                )}
            </Grid>
        </>
    );
}
