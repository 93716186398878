import ErrorsList from 'src/view/components/errors-list/ErrorsList';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';
import FormButtons from 'src/view/components/form/FormButtons';
import useApiFetch from 'src/app/hooks/useApiFetch';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import { EmptyBody } from 'src/data/models/common/emptyBody';
import orderlineService from 'src/data/services/orderlineService';

interface Props {
    orderId: string;
    orderlineId: string;
    ticketIds: string[];
    onSucceed: () => void;
}

export default function AssignTicketsActionDataWrapper({
    orderId,
    orderlineId,
    ticketIds,
    onSucceed,
}: Props): JSX.Element {
    const [{ loading, errors }, handleAssign] = useApiFetch<ApiResponseBody<EmptyBody>>();

    const handleAssignTickets = () => {
        handleAssign(orderlineService.assignTickets(orderId, orderlineId, { ticketIds }), {
            onSuccess: () => onSucceed(),
            useDefaultErrorHandler: true,
        });
    };

    return (
        <>
            {loading && <LoadingOverlay />}
            {errors.length > 0 && <ErrorsList errors={errors} />}

            <FormButtons
                buttons={[
                    {
                        children: 'Assign ticket(s)',
                        disabled: errors.length > 0,
                        onClick: handleAssignTickets,
                    },
                ]}
            />
        </>
    );
}
