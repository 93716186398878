import { ENDPOINT_VERSIONS } from './_endpoint-versions';

export const SERIES_GET_ENDPOINT = `/${ENDPOINT_VERSIONS.V2}/series`;

export const SERIES_CREATE_ENDPOINT = `/${ENDPOINT_VERSIONS.V2}/series/create`;

export const SERIES_GET_BY_ID_ENDPOINT = (seriesId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/series/${seriesId}`;

export const SERIES_UPDATE_ENDPOINT = (seriesId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/series/${seriesId}/update`;

export const SERIES_DELETE_ENDPOINT = (seriesId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/series/${seriesId}/delete`;
