import { TicketDetailsFormValues } from 'src/app/components/forms/TicketDetailsForm';
import Ticket from 'src/data/models/tickets/ticket';

export default function mapTicketDataToViewModel(ticket: Ticket): TicketDetailsFormValues {
    const {
        seatingPlanCategoryName,
        seatingPlanCategoryId,
        bookingOptionName,
        bookingOptionId,
        blockId,
        rowId,
        seatId,
        blockNumber,
        rowNumber,
        seatNumber,
        available,
        files,
        type,
        purchasePrice,
    } = ticket;

    const block =
        blockId && blockNumber
            ? {
                  label: blockNumber,
                  value: blockId,
              }
            : undefined;

    const row =
        rowId && rowNumber
            ? {
                  label: rowNumber,
                  value: rowId,
              }
            : undefined;

    const seat =
        seatId && seatNumber
            ? {
                  label: seatNumber,
                  value: seatId,
              }
            : undefined;

    return {
        category: seatingPlanCategoryName,
        seatingPlanCategoryId,
        bookingOptionId,
        bookingOptionName,
        block,
        row,
        seat,
        available,
        type,
        ticketFile: files[0],
        purchasePrice: purchasePrice ?? undefined,
    };
}
