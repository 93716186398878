import { useEffect } from 'react';
import useApiFetch from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import PaginatedApiResponseBody from 'src/data/api/responses/PaginatedApiResponseBody';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import { SalesProfile } from 'src/data/models/sales-profile/SalesProfile';
import salesProfilesService from 'src/data/services/salesProfilesService';
import PaginationMeta from 'src/data/api/responses/PaginationMeta';

interface FetchSalesProfilesDataWrapperChildrenParams
    extends DataWrapperChildrenParams<PaginatedApiResponseBody<SalesProfile>> {
    fetchSalesProfiles: () => Promise<PaginatedApiResponseBody<SalesProfile, PaginationMeta>>;
}

type FetchSalesProfilesDataWrapperProps = DataWrapperProps<
    FetchSalesProfilesDataWrapperChildrenParams,
    PaginatedApiResponseBody<SalesProfile>
> &
    BaseFetchOptions;

export const useFetchSalesProfilesDataWrapper = ({
    onError,
    onSuccess,
    page,
    pageSize,
    filter,
}: Omit<FetchSalesProfilesDataWrapperProps, 'children'>) => {
    const [{ loading, data, errors, resetData }, handleFetchSalesProfiles] =
        useApiFetch<PaginatedApiResponseBody<SalesProfile>>();

    const fetchSalesProfilesCall = () => {
        return handleFetchSalesProfiles(
            salesProfilesService.getSalesProfiles({
                page,
                pageSize,
                filter,
            }),
            {
                useDefaultErrorHandler: true,
                onFail: onError,
                onSuccess,
            }
        );
    };

    useEffect(() => {
        fetchSalesProfilesCall();
    }, [page, pageSize, filter]);

    return {
        fetchSalesProfiles: fetchSalesProfilesCall,
        data,
        loading,
        errors,
        resetData,
    };
};

export const FetchSalesProfilesDataWrapper = (props: FetchSalesProfilesDataWrapperProps) => {
    const { children } = props;
    const hookData = useFetchSalesProfilesDataWrapper(props);

    if (!children) return <></>;

    return <>{children(hookData)}</>;
};
