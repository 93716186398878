import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Filters from 'src/app/components/forms/filters/Filters';
import formatValuesToParams from 'src/app/utilities/helpers/formatValuesToParams';
import FilterOption from 'src/data/api/common/FilterOption';
import { TicketFileStatus } from 'src/data/models/tickets/unprocessedTicketFile';
import { FilterAutoCompleteOption } from 'src/view/components/filters/AutoComplete/AutoComplete';

interface TtiFilterFormValues {
    processStatus?: FilterAutoCompleteOption | null;
}

interface Props {
    defaultValues?: TtiFilterFormValues;
    onFiltersChange?: (options: FilterOption[]) => void;
}

export const statusLabelMap = new Map<TicketFileStatus, string>([
    ['Linked', 'Linked'],
    ['NotLinked', 'Not Linked'],
    ['Failed', 'Failed'],
    ['Unprocessed', 'Unprocessed'],
]);

const TtiFilterForm = ({ onFiltersChange, defaultValues }: Props) => {
    const [urlInvalidatedAt, setUrlInvalidatedAt] = useState<number>();
    const { control, getValues, reset } = useForm<TtiFilterFormValues>({
        mode: 'onChange',
        defaultValues,
    });

    useEffect(() => {
        if (!urlInvalidatedAt) return;
        onFiltersChange?.(
            _.compact([
                formatValuesToParams.formatOptionToParam(
                    'processStatus',
                    getValues('processStatus')
                ),
            ])
        );
    }, [urlInvalidatedAt]);

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues]);

    return (
        <Filters
            control={control}
            filters={[
                {
                    type: 'autocomplete',
                    name: 'processStatus',
                    options: [...statusLabelMap].map(([value, label]) => ({ label, value })),
                    filterPlaceholderProps: {
                        selectedText: 'Status selected',
                        placeholder: 'Select status',
                    },
                    onChange: () => {
                        setUrlInvalidatedAt(Date.now());
                    },
                },
            ]}
        />
    );
};

export default TtiFilterForm;
