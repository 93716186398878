import { useQuery } from '@tanstack/react-query';
import Toaster from 'src/app/utilities/helpers/Toaster';
import { parseErrors } from 'src/app/utilities/helpers/errors';
import type BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import { fetchPurchases } from 'src/data/services/purchaseService';

export const PURCHASES_QUERY = 'PURCHASES_QUERY';

export const useFetchPurchases = (fetchOptions: BaseFetchOptions, eventId?: string) => {
    return useQuery({
        queryKey: [PURCHASES_QUERY, fetchOptions, eventId],
        queryFn: () => fetchPurchases(fetchOptions, eventId),
        onError: (errors) => Toaster.toastErrors(parseErrors(errors)),
    });
};
