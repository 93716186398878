import { Theme } from '@mui/material/styles';
import p1Theme from 'src/app/theme/p1';
import etbaasTheme from 'src/app/theme/etbaas';

export enum THEME_ENVS {
    p1 = 'etbaas.p1hospitality.io',
    etbaas = 'etbaas.eventstravel.io',
}

export const themeMap: { [key in THEME_ENVS]: Theme } = {
    [THEME_ENVS.p1]: p1Theme,
    [THEME_ENVS.etbaas]: etbaasTheme,
};

export const getThemeByName = (theme: THEME_ENVS = THEME_ENVS.etbaas) => {
    return themeMap[theme];
};
