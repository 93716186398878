import { useQuery } from '@tanstack/react-query';
import Toaster from 'src/app/utilities/helpers/Toaster';
import { parseErrors } from 'src/app/utilities/helpers/errors';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import { fetchPurchasesEvents } from 'src/data/services/purchaseService';

type Options = {
    enabled?: boolean;
};
export const USE_FETCH_PURCHASE_FILTERS = 'USE_FETCH_PURCHASE_FILTERS';

export const useFetchPurchasesEvents = ({
    fetchOptions,
    options,
}: {
    fetchOptions?: BaseFetchOptions;
    options?: Options;
}) => {
    return useQuery({
        queryKey: [USE_FETCH_PURCHASE_FILTERS, fetchOptions],
        queryFn: () => fetchPurchasesEvents(fetchOptions),
        onError: (e) => {
            Toaster.toastErrors(parseErrors(e));
        },
        ...options,
    });
};
