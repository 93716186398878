import { helpContent } from 'src/data/constants/helpContent';

const getHelpContentByName = (name: keyof typeof helpContent) => {
    const content = helpContent[name];

    if (!content) {
        return {
            title: name,
        };
    }

    return content;
};

export default {
    getHelpContentByName,
};
