import { useEffect } from 'react';
import useApiFetch from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import PaginatedApiResponseBody from 'src/data/api/responses/PaginatedApiResponseBody';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import { Tag } from 'src/data/models/tag/tag';
import tagService from 'src/data/services/tagService';
import { TAG_TYPES } from 'src/data/enums/tagType';

interface FetchTagsDataWrapperChildrenParams
    extends DataWrapperChildrenParams<PaginatedApiResponseBody<Tag>> {
    fetchTags: (
        type: TAG_TYPES,
        options?: BaseFetchOptions
    ) => Promise<PaginatedApiResponseBody<Tag>>;
}

interface FetchTagsDataWrapperProps
    extends DataWrapperProps<FetchTagsDataWrapperChildrenParams, PaginatedApiResponseBody<Tag>>,
        BaseFetchOptions {
    type: TAG_TYPES;
}

export const useFetchTagsDataWrapper = ({
    includes = [],
    onError,
    onSuccess,
    invalidatedAt,
    page,
    pageSize,
    filter,
    sorting,
    q,
    type,
}: Omit<FetchTagsDataWrapperProps, 'children'>) => {
    const [{ loading, data, errors, resetData }, handleFetchTags] =
        useApiFetch<PaginatedApiResponseBody<Tag>>();

    const fetchTags = (type: TAG_TYPES, options?: BaseFetchOptions) => {
        return handleFetchTags(
            tagService.getTags(type, {
                includes,
                page,
                pageSize,
                filter,
                sorting,
                q,
                ...options,
            }),
            {
                useDefaultErrorHandler: true,
                onFail: onError,
                onSuccess,
            }
        );
    };

    useEffect(() => {
        fetchTags(type);
    }, [page, pageSize, filter, sorting, q, invalidatedAt]);

    return {
        fetchTags,
        data,
        loading,
        errors,
        resetData,
    };
};

export const FetchTagsDataWrapper = (props: FetchTagsDataWrapperProps) => {
    const { children } = props;
    const hookData = useFetchTagsDataWrapper(props);

    if (!children) return <></>;

    return <>{children(hookData)}</>;
};
