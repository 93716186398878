import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import urlService from 'src/app/services/urlService';
import { UserRoleConfig, allowTo } from 'src/app/utilities/helpers/userRole';
import authService from 'src/data/services/authService';

const useAuthRouting = () => {
    const navigate = useNavigate();
    const isLoggedIn = authService.loggedIn();

    const [isAllowedTo, setIsAllowedTo] = useState<UserRoleConfig | undefined>();
    const [isConfiguringUser, setIsConfiguringUser] = useState<boolean>(false);

    useEffect(() => {
        if (isLoggedIn) {
            // Inits the authenticated state
            setIsAllowedTo(allowTo(authService.getProfile()['cognito:groups']));

            const redirectUrl = window.localStorage.getItem('loginIntendedUrl');

            if (redirectUrl && redirectUrl.length > 1) {
                navigate(redirectUrl);
                window.localStorage.removeItem('loginIntendedUrl');
            }
        } else {
            // Triggers the unauthenticated state
            const { pathname } = window.location;
            const loginUrl = urlService.getLogin();

            if (pathname !== loginUrl) {
                window.localStorage.setItem('loginIntendedUrl', pathname);
                navigate(loginUrl, { replace: true });
            }
        }

        setIsConfiguringUser(true);
    }, [isLoggedIn, navigate]);

    return {
        isAllowedTo,
        isConfiguringUser,
        isLoggedIn,
    };
};

export default useAuthRouting;
