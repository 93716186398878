import { Grid } from '@mui/material';
import Button from 'src/view/components/button/Button';
import { ETBContainer } from 'src/view/components/container/container';
import Header from 'src/view/components/header/Header';
import { OrderlinesOverviewFeature } from 'src/app/components/features/orderline/OrderlinesOverviewFeature';
import { useNavigate, useParams } from 'react-router-dom';
import eventUrlService from 'src/app/pages/events/eventUrlService';

interface OrderlinesPageProps {
    eventName: string;
}

const OrderlinesPage = ({ eventName }: OrderlinesPageProps) => {
    const { eventId } = useParams();
    const navigate = useNavigate();

    if (!eventId) return <></>;

    return (
        <>
            <Header>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <h1>{eventName}</h1>
                    </Grid>
                    <Grid item>
                        <Button
                            color="primary"
                            onClick={() => navigate(eventUrlService.autoAssign(eventId))}
                        >
                            Auto assign
                        </Button>
                    </Grid>
                </Grid>
            </Header>
            <ETBContainer>
                <OrderlinesOverviewFeature />
            </ETBContainer>
        </>
    );
};

export default OrderlinesPage;
