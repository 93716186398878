import useApiFetch from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import ticketService, { TicketDownloadLinkResponse } from 'src/data/services/ticketService';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';

interface CreateDownloadLinkDataWrapperChildrenParams
    extends DataWrapperChildrenParams<ApiResponseBody<TicketDownloadLinkResponse>> {
    createDownloadLink: (ticketId: string, fileId: string) => Promise<ApiResponseBody<TicketDownloadLinkResponse>>;
}

type FetchTicketsDataWrapperProps = DataWrapperProps<
    CreateDownloadLinkDataWrapperChildrenParams,
    ApiResponseBody<TicketDownloadLinkResponse>
> &
    BaseFetchOptions;

export const useCreateDownloadLinkDataWrapper = ({
    onError,
    onSuccess,
}: Omit<FetchTicketsDataWrapperProps, 'children'>) => {
    const [{ data, loading, errors, resetData }, handleCreateDownloadLink] =
        useApiFetch<ApiResponseBody<TicketDownloadLinkResponse>>();

    const createDownloadLinkCall = (ticketId: string, fileId: string) => {
        return handleCreateDownloadLink(ticketService.createTicketDownloadLink(ticketId, fileId), {
            useDefaultErrorHandler: true,
            onFail: onError,
            onSuccess,
        });
    };

    return {
        createDownloadLink: createDownloadLinkCall,
        data,
        loading,
        errors,
        resetData,
    };
};

export const CreateDownloadLinkDataWrapper = (props: FetchTicketsDataWrapperProps) => {
    const { children } = props;
    const hookData = useCreateDownloadLinkDataWrapper(props);

    if (!children) return <></>;

    return <>{children(hookData)}</>;
};
