import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { OrderlinesAutoAssignFormFeature } from 'src/app/components/features/orderline/OrderlinesAutoAssignFormFeature';
import { Grid } from '@mui/material';
import { OrderlinesSuccessfullAssignmentsTableFeature } from 'src/app/components/features/orderline/OrderlinesSuccessfullAssignmentsTableFeature';

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(2),
    },
    formContainer: {
        paddingRight: theme.spacing(3),
        borderRight: `1px solid ${theme.colors.mediumGrey}`,
    },
}));

interface OrderlinesAutoAssignFeatureProps {
    eventId: string;
}

export const OrderlinesAutoAssignFeature = ({ eventId }: OrderlinesAutoAssignFeatureProps) => {
    const classes = useStyles();
    const [assignmentResultInvalidated, setAssignmentResultInvalidated] = useState<number>();

    return (
        <Grid container className={classes.container} spacing={3}>
            <Grid item xs={12} md={3} className={classes.formContainer}>
                <OrderlinesAutoAssignFormFeature
                    onSuccess={() => setAssignmentResultInvalidated(Date.now())}
                    eventId={eventId}
                />
            </Grid>
            <Grid item xs={12} md={9}>
                <OrderlinesSuccessfullAssignmentsTableFeature
                    eventId={eventId}
                    invalidatedAt={assignmentResultInvalidated}
                />
            </Grid>
        </Grid>
    );
};
