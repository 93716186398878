import { getInternalAxiosInstance } from 'src/data/api/api';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import { getQueryParams } from 'src/data/api/endpointUrlBuilder';
import ApiResponse from 'src/data/api/responses/ApiResponse';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import { SEATS_CREATE_ENDPOINT } from 'src/data/constants/endpoints/seats-endpoints';
import { IdResponse } from 'src/data/models/common/idResponse';
import Seat from 'src/data/models/seat/seat';
import { EVENTS_GET_SEATS_ENDPOINT } from '../constants/endpoints/events-endpoints';

async function getSeatsByEventId(
    eventId: string,
    options?: BaseFetchOptions
): Promise<ApiResponse<ApiResponseBody<Seat[]>>> {
    return await getInternalAxiosInstance()
        .get<ApiResponseBody<Seat[]>>(EVENTS_GET_SEATS_ENDPOINT(eventId), {
            params: options && getQueryParams(options),
        })
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function createSeat(
    number: string,
    rowId: string
): Promise<ApiResponse<ApiResponseBody<IdResponse>>> {
    const dto = { number, rowId };

    return await getInternalAxiosInstance()
        .post<ApiResponseBody<IdResponse>>(SEATS_CREATE_ENDPOINT, dto)
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export default { getSeatsByEventId, createSeat };
