import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DatePicker as DatePickerMui, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';
import Input from 'src/view/components/input/Input';

export interface DatePickerProps {
    value: Date | Moment | null;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    onChange?: (date: Moment | null) => void;
    minDate?: Moment;
    maxDate?: Moment;
    disabled?: boolean;
    disableTyping?: boolean;
    placeholder?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    datePickerContainer: {
        width: '100%',
        backgroundColor: theme.colors.lightestGrey,
        margin: 0,
        '& .MuiFormHelperText-root': {
            backgroundColor: 'white',
            margin: 0,
            paddingTop: 0,
        },
    },
    input: {},
}));

export default function DatePicker({
    value,
    onChange,
    minDate,
    maxDate,
    disabled,
    placeholder,
}: DatePickerProps): JSX.Element {
    const classes = useStyles();
    const date = value ? moment(value) : null;

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePickerMui<Moment>
                className={classes.datePickerContainer}
                slots={{
                    textField: Input,
                }}
                slotProps={{
                    textField: {
                        placeholder,
                        className: classes.input,
                    },
                }}
                format="DD/MM/YYYY"
                minDate={minDate}
                maxDate={maxDate}
                disabled={disabled}
                value={date}
                onChange={(date) => {
                    onChange?.(date);
                }}
            />
        </LocalizationProvider>
    );
}
