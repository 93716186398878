import { useEffect } from 'react';
import useApiFetch from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import PaginatedApiResponseBody from 'src/data/api/responses/PaginatedApiResponseBody';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import Split from 'src/data/models/split/split';
import eventService from 'src/data/services/eventService';
import PaginationMeta from 'src/data/api/responses/PaginationMeta';

interface FetchSplitsDataWrapperChildrenParams
    extends DataWrapperChildrenParams<PaginatedApiResponseBody<Split>> {
    fetchSplits: () => Promise<PaginatedApiResponseBody<Split, PaginationMeta>>;
}

interface FetchSplitsDataWrapperProps
    extends DataWrapperProps<FetchSplitsDataWrapperChildrenParams, PaginatedApiResponseBody<Split>>,
        BaseFetchOptions {
    eventId: string;
}

export const useFetchSplitsDataWrapper = ({
    includes = [],
    onError,
    onSuccess,
    invalidatedAt,
    eventId,
    page,
    pageSize,
    filter,
    sorting,
    q,
}: Omit<FetchSplitsDataWrapperProps, 'children'>) => {
    const [{ loading, data, errors, resetData }, handleFetchSplits] =
        useApiFetch<PaginatedApiResponseBody<Split>>();

    const fetchSplitsCall = () => {
        return handleFetchSplits(
            eventService.fetchSplitsByEventId(eventId, {
                includes,
                page,
                pageSize,
                filter,
                sorting,
                q,
            }),
            {
                useDefaultErrorHandler: true,
                onFail: onError,
                onSuccess,
            }
        );
    };

    useEffect(() => {
        fetchSplitsCall();
    }, [page, pageSize, filter, sorting, q, invalidatedAt, eventId]);

    return {
        fetchSplits: fetchSplitsCall,
        data,
        loading,
        errors,
        resetData,
    };
};

export const FetchSplitsDataWrapper = (props: FetchSplitsDataWrapperProps) => {
    const { children } = props;
    const hookData = useFetchSplitsDataWrapper(props);

    if (!children) return <></>;

    return <>{children(hookData)}</>;
};
