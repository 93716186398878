import AuditLog from 'src/data/models/audit-log/AuditLog';
import ActivityItem from 'src/view/components/activity/Item/ActivityItem';
import moment from 'moment';
import makeStyles from '@mui/styles/makeStyles';
import { localDateFormat } from 'src/shared/date';
import mapToReadableAuditType from 'src/app/utilities/mappers/audit-log/mapToReadableAuditType';
import { Alert } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    heading: {
        padding: theme.spacing(1),
        background: theme.palette.primary.main,
        color: 'white',
        fontWeight: 'bold',
        borderTopLeftRadius: theme.layout.borderRadius.regular,
        borderTopRightRadius: theme.layout.borderRadius.regular,
    },
}));
interface ActivityLogOverviewProps {
    logs: AuditLog[];
    loading?: boolean;
}

export const AuditLogOverview = ({ logs, loading }: ActivityLogOverviewProps) => {
    const classes = useStyles();

    const renderEmptyLogsRow = () => {
        return <Alert severity="info">{loading ? 'Loading...' : 'No logs available'}</Alert>;
    };

    return (
        <>
            <div className={classes.heading}>Audit Logs</div>
            {logs.length === 0 && renderEmptyLogsRow()}
            {logs.map((l) => (
                <ActivityItem
                    key={l.auditDateTimeUtc}
                    date={moment(l.auditDateTimeUtc).local().format(localDateFormat)}
                    auditType={mapToReadableAuditType(l.auditType)}
                    message={l.message}
                    userFullName={l.auditUserFullName}
                    userEmail={l.auditUserEmail}
                />
            ))}
        </>
    );
};
