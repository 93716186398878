import { useMutation } from '@tanstack/react-query';
import seatingPlanCategoryService from 'src/data/services/seatingPlanCategoryService';
import Toaster from 'src/app/utilities/helpers/Toaster';
import { parseErrors } from 'src/app/utilities/helpers/errors';

type TData = Awaited<ReturnType<typeof seatingPlanCategoryService.uploadImage>>;

export default function useUploadSPCImage(seatingplanCategoryId: string, onSuccess?: (data: TData) => unknown) {
    return useMutation({
        mutationFn: (file: File) =>
        seatingPlanCategoryService.uploadImage(seatingplanCategoryId, file),
        onSuccess,
        onError: (err) => {
            Toaster.toastErrors(parseErrors(err))
        }
    });
}
