import HandshakeIcon from '@mui/icons-material/Handshake';
import { Tooltip } from '@mui/material';
import BigNumber from 'bignumber.js';
import eventUrlService from 'src/app/pages/events/eventUrlService';
import Split from 'src/data/models/split/split';
import { BlockRowSeatRange } from 'src/view/components/block-row-seat/BlockRowSeatRange';
import Table from 'src/view/components/table/table/Table';
import {
NewTableColumn,
TableColumnSorting,
TablePaginationData,
TABLE_CELL_WIDTH
} from 'src/view/components/table/table/Types';
import { WarningText } from 'src/view/components/warning-text/WarningText';

export interface SplitOverviewTableProps {
    eventId: string;
    splits: Split[];
    onChangeSorting?: (sortings: TableColumnSorting[]) => void;
    loading?: boolean;
    pagination?: TablePaginationData;
}

export default function SplitOverviewTable({
    eventId,
    onChangeSorting,
    loading,
    splits = [],
    pagination,
}: SplitOverviewTableProps): JSX.Element {
    const cols: NewTableColumn<Split>[] = [
        {
            title: 'Seatingplan category',
            key: 'tickets',
            cellRenderer: (rowData) => {
                const ticket = rowData.tickets[0];
                return (
                    <span>
                        {ticket?.seatingPlanCategoryName}{' '}
                        {ticket?.seatingplanCategoryIsArchived ? (
                            <WarningText text="archived" />
                        ) : (
                            ''
                        )}
                    </span>
                );
            },
            width: TABLE_CELL_WIDTH.MEDIUM,
        },
        {
            title: 'Block/Row/Seat',
            key: 'tickets',
            cellRenderer: (rowData) => {
                return <BlockRowSeatRange seatings={rowData.tickets} />;
            },
            width: TABLE_CELL_WIDTH.MEDIUM,
        },
        {
            title: 'Split Name',
            key: 'splitName',
            cellRenderer: (rowData) => {
                return <span>{rowData.splitName}</span>;
            },
            width: TABLE_CELL_WIDTH.SMALL,
        },
        {
            title: '# of tickets',
            key: 'tickets.length',
            cellRenderer: (rowData) => (
                <a
                    href={
                        eventId
                            ? eventUrlService.tickets(eventId, { splitId: rowData.id })
                            : undefined
                    }
                >
                    {rowData.tickets.length}
                </a>
            ),
            width: TABLE_CELL_WIDTH.SMALL,
        },
        {
            title: 'Available tickets',
            key: 'tickets.length',
            cellRenderer: (rowData) => {
                const availableTickets = rowData.tickets.filter((t) => t.available);

                return availableTickets.length;
            },
            width: TABLE_CELL_WIDTH.SMALL,
        },
        {
            title: 'purchase price',
            key: 'tickets',
            cellRenderer: (rowData) => {
                const sum = BigNumber.sum
                    .apply(
                        null,
                        rowData.tickets.map((t) => t?.purchasePrice?.value || 0)
                    )
                    .toString();

                return `${rowData.tickets[0]?.purchasePrice.currency} ${sum}`;
            },
            width: TABLE_CELL_WIDTH.MEDIUM,
        },
        {
            cellRenderer: (rowData) =>
                rowData.tickets[0]?.isContractTicket ? (
                    <Tooltip title="Contract ticket">
                        <HandshakeIcon fontSize="small" color="primary" />
                    </Tooltip>
                ) : (
                    <></>
                ),
            width: TABLE_CELL_WIDTH.TINY,
            align: 'center',
        },
    ];

    return (
        <Table<Split>
            data={splits}
            rowIdResolver={(rowData: Split) => ({ id: rowData.id || '' })}
            columns={cols}
            onSortingChange={onChangeSorting}
            loading={loading}
            minWidth={650}
            pagination={pagination}
        />
    );
}
