import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { FetchEventsDataWrapper } from 'src/app/components/data-wrappers/event/FetchEventsDataWrapper';
import { EditEventFeature } from 'src/app/components/features/event/EditEventFeature';
import EventsFilterFormFeature from 'src/app/components/features/event/EventsFilterFormFeature';
import { TableHeaderTotalFeature } from 'src/app/components/features/tables/TableHeaderTotalFeature';
import EventsOverviewTable from 'src/app/components/tables/EventOverviewTable';
import { config } from 'src/app/constants/config/config';
import { FETCH_EVENT_BY_ID_QUERY_KEY } from 'src/app/hooks/events/useFetchEventById';
import { useCloseEvent } from 'src/app/hooks/useCloseEvent';
import { useCustomConfirm } from 'src/app/hooks/useCustomConfirm';
import { useLockEvent } from 'src/app/hooks/useLockEvent';
import useQueryParams from 'src/app/hooks/useQueryParams';
import { useUnlockEvent } from 'src/app/hooks/useUnlockEvent';
import { eventOverviewDefaultFilters } from 'src/app/pages/events/eventsOverviewUrlService';
import Toaster from 'src/app/utilities/helpers/Toaster';
import { parseErrors } from 'src/app/utilities/helpers/errors';
import tableSortingToSortingOptionMapper from 'src/app/utilities/mappers/table/tableSortingToSortingOptionMapper';
import FilterOption from 'src/data/api/common/FilterOption';
import SortingOption from 'src/data/api/common/SortingOption';
import { Event } from 'src/data/models/events/event';
import CheckboxWithLabel from 'src/view/components/checkbox-with-label/CheckboxWithLabel';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';
import { TableColumnSorting } from 'src/view/components/table/table/Types';

interface Props {
    invalidatedAt?: number;
}

export default function EventTableFeature({ invalidatedAt }: Props): JSX.Element {
    const confirm = useCustomConfirm();
    const [eventsInvalidatedAt, setEventsInvalidatedAt] = useState<number | undefined>(
        invalidatedAt
    );
    const [eventId, setEventId] = useState<string>();
    const [detailsModalOpen, setDetailsModalOpen] = useState(false);
    const [sorting, setSorting] = useState<SortingOption[]>([]);
    const [includeArchivedSeatingplanCategories, setIncludeArchivedSeatingplanCategories] =
        useState(false);

    const queryClient = useQueryClient();

    useEffect(() => {
        setEventsInvalidatedAt(invalidatedAt);
    }, [invalidatedAt]);

    const { mutate: lockEvent } = useLockEvent({
        onSuccess: () => setEventsInvalidatedAt(Date.now()),
        onError: (e) => {
            Toaster.toastErrors(parseErrors(e));
        },
    });
    const { mutate: unlockEvent } = useUnlockEvent({
        onSuccess: () => setEventsInvalidatedAt(Date.now()),
        onError: (e) => {
            Toaster.toastErrors(parseErrors(e));
        },
    });
    const { mutate: closeEvent } = useCloseEvent({
        onSuccess: () => setEventsInvalidatedAt(Date.now()),
        onError: (e) => {
            Toaster.toastErrors(parseErrors(e));
        },
    });

    const {
        values: { filterOptions, customFields },
        setFilterOptions,
        setCustomFields,
    } = useQueryParams('events', {
        defaultFilters: eventOverviewDefaultFilters,
    });

    const onSuccess = (hideModal = true) => {
        setEventsInvalidatedAt(Date.now());
        queryClient.invalidateQueries({
            queryKey: [FETCH_EVENT_BY_ID_QUERY_KEY],
            exact: false,
        });
        if (hideModal) setDetailsModalOpen(false);
    };

    const seatingPlanValue = filterOptions?.find((f) => f.property === 'seatingPlan')?.value;
    const organizers = filterOptions.filter((f) => f.property === 'organizerId');

    const handleSortingChange = (tableSorting: TableColumnSorting[]) => {
        if (sorting.length === 0 && tableSorting.length === 0) return;

        setSorting(tableSorting.map(tableSortingToSortingOptionMapper));
    };

    const getIndexOfFilterProperty = (property: string) => {
        return filterOptions.findIndex((f) => f.property === property);
    };

    const handleOnChangeFilterOptions = (options: FilterOption[]) => {
        setIncludeArchivedSeatingplanCategories(false);
        setFilterOptions(options);
    };

    const containsArchivedSeatingPlanCategory = (data: Event[] | undefined) => {
        const hasArchived = data
            ?.flatMap(
                ({ seatingPlanCategoryResultsSummaries }) => seatingPlanCategoryResultsSummaries
            )
            .some((seatingPlan) => seatingPlan?.isArchived);

        return getIndexOfFilterProperty('seatingPlan') !== -1 && hasArchived;
    };

    const onCloseModal = async () => {
        setDetailsModalOpen(false);
        setEventId(undefined);
    };

    return (
        <>
            <EventsFilterFormFeature
                initialOptions={filterOptions}
                initialSearch={customFields['q'] || ''}
                onChangeFilterOptions={handleOnChangeFilterOptions}
                onChangeSearchTerm={(q: string) => {
                    setCustomFields({
                        ...customFields,
                        q: q?.length > 0 ? q : '',
                    });
                }}
            />

            <FetchEventsDataWrapper
                invalidatedAt={eventsInvalidatedAt}
                filter={filterOptions}
                sorting={sorting}
                q={customFields.q}
                page={Number(customFields['page'] || 1)}
                pageSize={config.ITEMS_PER_PAGE_STANDARD}
                includes={['ResultsSummary']}
                seatingPlan={seatingPlanValue}
            >
                {({ data, loading }) => (
                    <>
                        {loading && <LoadingOverlay />}

                        <TableHeaderTotalFeature
                            title="Events"
                            totalResults={data?.meta.total}
                            priceTotals={data?.meta.totalPriceSumPerCurrency}
                            rightSlot={
                                containsArchivedSeatingPlanCategory(data?.data) ? (
                                    <CheckboxWithLabel
                                        name="showArchivedSeatingplanCategories"
                                        variant="light"
                                        label="Show archived categories"
                                        checked={includeArchivedSeatingplanCategories}
                                        onChange={(isChecked) => {
                                            setIncludeArchivedSeatingplanCategories(isChecked);
                                        }}
                                    />
                                ) : (
                                    <></>
                                )
                            }
                        />
                        <EventsOverviewTable
                            events={data?.data || []}
                            loading={loading}
                            showSeatingPlanCategories={!!seatingPlanValue && organizers.length < 2}
                            seatingPlanCategories={
                                seatingPlanValue && data?.meta.seatingPlanCategories
                                    ? data?.meta.seatingPlanCategories
                                    : undefined
                            }
                            includeArchivedSeatingplanCategories={
                                includeArchivedSeatingplanCategories
                            }
                            onChangeSorting={handleSortingChange}
                            onClickDetails={(eventId: string) => {
                                setEventId(eventId);
                                setDetailsModalOpen(true);
                            }}
                            pagination={{
                                currentPage: data?.meta.currentPage || 1,
                                totalPages: data?.meta.totalPages || 1,
                                onPaginate: (page: number) =>
                                    setCustomFields({ ...customFields, page: page.toString() }),
                            }}
                            onEventActionDispatched={async (action, eventId) => {
                                let result = false;
                                if (action === 'lock' || action === 'close') {
                                    result = await confirm({
                                        title: `Are you sure you want to ${action} the event?`,
                                    });
                                }
                                if (action === 'lock') return result && lockEvent({ eventId });
                                if (action === 'close') return result && closeEvent({ eventId });
                                if (action === 'unlock') return unlockEvent({ eventId });
                            }}
                        />
                    </>
                )}
            </FetchEventsDataWrapper>

            {eventId && (
                <EditEventFeature
                    invalidatedAt={eventsInvalidatedAt}
                    eventId={eventId}
                    onUpdatedEvent={() => onSuccess(false)}
                    onDeleteEvent={() => onSuccess(true)}
                    onUpdatedName={() => onSuccess(false)}
                    onUpdatedOrganizer={() => onSuccess(false)}
                    onUpdatedSerie={() => onSuccess(false)}
                    open={detailsModalOpen}
                    onClose={onCloseModal}
                />
            )}
        </>
    );
}
