import makeStyles from '@mui/styles/makeStyles';

export const useTableStyles = makeStyles((theme) => ({
    totalText: {
        color: theme.colors.grey,
        marginLeft: theme.spacing(1),
    },
    tableRowIcon: {
        marginTop: 5,
        fontSize: '1rem',
    },
}));
