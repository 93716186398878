import { getInternalAxiosInstance } from 'src/data/api/api';
import ApiResponse from 'src/data/api/responses/ApiResponse';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import { EVENTS_EXPORT_TICKETS_ENDPOINT } from 'src/data/constants/endpoints/events-endpoints';
import EventTicketsExportLink from 'src/data/models/exports/eventTicketsExportLink';

async function exportEventTickets(
    eventId: string
): Promise<ApiResponse<ApiResponseBody<EventTicketsExportLink>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<EventTicketsExportLink>>(EVENTS_EXPORT_TICKETS_ENDPOINT(eventId))
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export default { exportEventTickets };
