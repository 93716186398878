import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';
import { PurchaseStatisticPriceType } from 'src/data/models/purchase/PurchaseStatistic';
import { formatPrice } from 'src/shared/helpers/formatPrice';
import Popover from 'src/view/components/popover/Popover';
import { Price } from 'src/view/components/price/Price';

const useStyles = makeStyles((theme) => ({
    pricesGrid: {
        fontSize: '.75rem',
    },
    pricesGridItem: {
        padding: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
    },
    spacingLeft: {
        marginLeft: theme.spacing(1),
    },
}));

interface PricesPopOverProps {
    text: string | number | JSX.Element;
    secondaryPrice?: PurchaseStatisticPriceType;
    prices: PurchaseStatisticPriceType[];
}

export const PricesPopOver = ({ prices, secondaryPrice, text }: PricesPopOverProps) => {
    const classes = useStyles();

    const hasDifferentCurrency = prices.some((p) => p?.currency !== 'EUR');

    return (
        <>
            <Popover text={text} isLink={false} boxPadding={0}>
                <Grid container className={classes.pricesGrid}>
                    {prices.map((p, index) => (
                        <Grid
                            item
                            xs={12}
                            className={classnames(classes.pricesGridItem)}
                            key={`${p?.currency}-${index}`}
                        >
                            <span className={classes.spacingLeft}>
                                {formatPrice({
                                    currency: p?.currency,
                                    amount: p?.value || 0,
                                })}
                            </span>
                        </Grid>
                    ))}
                </Grid>
            </Popover>
            {hasDifferentCurrency && secondaryPrice && (
                <Price price={secondaryPrice} inParenthesis grey />
            )}
        </>
    );
};
