import { AutoCompleteOption } from 'src/view/components/auto-complete/interfaces';
import z from 'zod';

export const zodOptionalDdropdownSchema = z
    .object<AutoCompleteOption>({
        label: z.string(),
        value: z.string(),
    })
    .nullable()
    .optional();
