import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    text: {
        color: theme.colors.grey,
        fontStyle: 'italic',
    },
}));
interface PreviewItemPlaceholderProps {
    text: string;
}

export const PreviewItemPlaceholder = ({ text }: PreviewItemPlaceholderProps) => {
    const classes = useStyles();

    return <span className={classes.text}>{text}</span>;
};
