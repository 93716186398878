import { useMutation, useQueryClient } from '@tanstack/react-query';
import Toaster from 'src/app/utilities/helpers/Toaster';
import { parseErrors } from 'src/app/utilities/helpers/errors';
import seatingPlanService, {
    CreateSeatingplanCategoryDto,
} from 'src/data/services/seatingPlanService';
import { FETCH_SEATINGPLAN_CATEOGIRES_BY_SEATINGPLAN_ID_QUERY } from './seating-plan-categories/useFetchSPCsBySeatingplanId';
import { SEATING_PLAN_CATEGORY_QUERY } from './useFetchSeatingPlanCategoryById';

type TData = Awaited<ReturnType<typeof seatingPlanService.createSeatingPlanCategory>>;
type TVariables = {
    seatingplanId: string;
    dto: CreateSeatingplanCategoryDto;
    seatingplanCategoryId?: string;
};

const useCreateOrUpdateSeatingPlanCategory = (onSuccess?: (data: TData) => unknown) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ seatingplanId, seatingplanCategoryId, dto }: TVariables) => {
            if (seatingplanCategoryId) {
                return seatingPlanService.updateSeatingplanCategory(
                    seatingplanId,
                    seatingplanCategoryId,
                    dto
                );
            }

            return seatingPlanService.createSeatingPlanCategory(seatingplanId, dto);
        },
        onSuccess: (data) => {
            queryClient.invalidateQueries({
                queryKey: [SEATING_PLAN_CATEGORY_QUERY],
            });
            queryClient.invalidateQueries({
                queryKey: [FETCH_SEATINGPLAN_CATEOGIRES_BY_SEATINGPLAN_ID_QUERY],
            });
            onSuccess?.(data);
        },
        onError: (errors) => Toaster.toastErrors(parseErrors(errors)),
    });
};

export default useCreateOrUpdateSeatingPlanCategory;
