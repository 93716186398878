import urlService, { defaultCustomFields, PathConfig } from 'src/app/services/urlService';
import { filterOptionsToQueryParams } from 'src/app/utilities/helpers/filter';

const BasicAdministrationUrlService = {
    venues(props?: PathConfig) {
        if (props?.rootOnly) return urlService.getBasicAdministration(`/venues`);

        return urlService.getBasicAdministration(
            `/venues${filterOptionsToQueryParams({
                customFields: defaultCustomFields,
                suffix: 'venues',
            })}`
        );
    },
    teams(props?: PathConfig) {
        if (props?.rootOnly) return urlService.getBasicAdministration(`/teams`);

        return urlService.getBasicAdministration(
            `/teams${filterOptionsToQueryParams({
                customFields: defaultCustomFields,
                suffix: 'teams',
            })}`
        );
    },
    suppliers(props?: PathConfig) {
        if (props?.rootOnly) return urlService.getBasicAdministration(`/suppliers`);

        return urlService.getBasicAdministration(
            `/suppliers${filterOptionsToQueryParams({
                customFields: defaultCustomFields,
                suffix: 'supplier',
            })}`
        );
    },

    eventCategories(props?: PathConfig) {
        if (props?.rootOnly) return urlService.getBasicAdministration(`/event-categories`);

        return urlService.getBasicAdministration(
            `/event-categories${filterOptionsToQueryParams({
                customFields: defaultCustomFields,
                suffix: 'event-categories',
            })}`
        );
    },

    series(props?: PathConfig) {
        if (props?.rootOnly) return urlService.getBasicAdministration(`/series`);

        return urlService.getBasicAdministration(
            `/series${filterOptionsToQueryParams({
                customFields: defaultCustomFields,
                suffix: 'series',
            })}`
        );
    },
    seriesGroups(props?: PathConfig) {
        if (props?.rootOnly) return urlService.getBasicAdministration(`/series-groups`);

        return urlService.getBasicAdministration(
            `/series-groups${filterOptionsToQueryParams({
                customFields: defaultCustomFields,
                suffix: 'seriesGroups',
            })}`
        );
    },
    organizers(props?: PathConfig) {
        if (props?.rootOnly) return urlService.getBasicAdministration(`/organizers`);

        return urlService.getBasicAdministration(
            `/organizers${filterOptionsToQueryParams({
                customFields: defaultCustomFields,
                suffix: 'organizer',
            })}`
        );
    },
    orderComparisonTool() {
        return urlService.getBasicAdministration('/order-comparison-tool');
    },
    seatingPlanCategoryProperties() {
        return urlService.getBasicAdministration('/seating-plang-category-properties');
    },
};

export default BasicAdministrationUrlService;
