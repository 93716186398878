import { useQueryClient } from '@tanstack/react-query';
import { useCallback, type Dispatch, type SetStateAction } from 'react';
import { FETCH_VENUES_QUERY_KEY } from 'src/app/hooks/venues/useFetchVenues';
import Toaster from 'src/app/utilities/helpers/Toaster';
import Modal from 'src/view/components/modal/Modal';
import CreateVenueFormDataWrapper from '../../data-wrappers/venues/CreateVenueFormDataWrapper';

export function CreateEditVenueModal({
    venueId,
    setModalOpenState,
}: {
    venueId?: string;
    setModalOpenState: Dispatch<SetStateAction<boolean>>;
}) {
    const queryClient = useQueryClient();

    const invalidateVenuesQuery = useCallback(() => {
        queryClient.invalidateQueries({
            queryKey: [FETCH_VENUES_QUERY_KEY],
        });
    }, [queryClient]);

    const onSuccess = useCallback(
        (action: 'updated' | 'created') => {
            invalidateVenuesQuery();
            Toaster.toast(`Venue successfully ${action}`, {
                variant: 'success',
            });

            setModalOpenState(false);
        },
        [invalidateVenuesQuery]
    );

    return (
        <Modal
            title={venueId ? `Edit venue` : 'Add new venue'}
            open
            onClose={() => setModalOpenState(false)}
        >
            <CreateVenueFormDataWrapper
                venueId={venueId}
                onSuccessfullyUpdateVenue={() => onSuccess('updated')}
                onSuccessfullyCreateVenue={() => onSuccess('created')}
            />
        </Modal>
    );
}
