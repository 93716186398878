import { useState, useEffect } from 'react';
import Modal from 'src/view/components/modal/Modal';
import useQueryParams from 'src/app/hooks/useQueryParams';
import { FetchSuppliersDataWrapper } from 'src/app/components/data-wrappers/supplier/FetchSuppliersDataWrapper';
import SupplierTable from 'src/app/components/tables/SupplierTable';
import { TableHeaderTotalFeature } from 'src/app/components/features/tables/TableHeaderTotalFeature';
import Filters, { FiltersArray } from 'src/app/components/forms/filters/Filters';
import { useForm } from 'react-hook-form';
import { SupplierDetailsFormFeature } from 'src/app/components/features/supplier/SupplierDetailsFormFeature';
import { config } from 'src/app/constants/config/config';

interface Props {
    tableInvalidatedAt?: number;
}

export default function SupplierTableFeature({ tableInvalidatedAt }: Props): JSX.Element {
    const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
    const [supplierId, setSupplierId] = useState<string | null>(null);
    const [invalidatedAt, setInvalidatedAt] = useState<number | undefined>();

    useEffect(() => {
        if (!tableInvalidatedAt) return;

        setInvalidatedAt(tableInvalidatedAt);
    }, [tableInvalidatedAt]);

    const {
        values: { customFields, filterOptions },
        setCustomFields,
        setFilterOptions,
    } = useQueryParams('supplier');

    const form = useForm({
        mode: 'onChange',
        defaultValues: customFields,
    });

    const { control } = form;

    const onEditClick = (supplierId: string) => {
        setEditModalOpen(true);
        setSupplierId(supplierId);
    };

    const page = Number(customFields['page'] || 0);
    const defaultQuery = filterOptions.find((f) => f.property === 'query');

    const arrayOfFilters: FiltersArray = [
        {
            name: 'searchTerm',
            onChange: (q) =>
                setFilterOptions([
                    ...filterOptions,
                    {
                        value: q,
                        property: 'query',
                    },
                ]),
            type: 'search',
            searchPlaceholder: 'Search (at least 3 characters)',
            searchDefaultValue: defaultQuery?.value,
            limit: 3,
            label: 'Search supplier name',
        },
    ];
    const invalidateAndCloseModal = () => {
        setInvalidatedAt(Date.now());
        setEditModalOpen(false);
    };

    return (
        <>
            <Filters control={control} filters={arrayOfFilters} />
            <FetchSuppliersDataWrapper
                page={page}
                filter={filterOptions}
                invalidatedAt={invalidatedAt}
                pageSize={config.ITEMS_PER_PAGE_STANDARD}
            >
                {({ loading, data }) => (
                    <>
                        <TableHeaderTotalFeature
                            title="Suppliers"
                            totalResults={data?.meta.total}
                        />
                        <SupplierTable
                            suppliers={data?.data}
                            loading={loading}
                            onEditClick={onEditClick}
                            pagination={{
                                currentPage: data?.meta.currentPage || 1,
                                totalPages: data?.meta.totalPages || 1,
                                onPaginate: (page: number) =>
                                    setCustomFields({ ...customFields, page: page.toString() }),
                            }}
                        />
                    </>
                )}
            </FetchSuppliersDataWrapper>
            <Modal
                open={editModalOpen}
                onClose={() => setEditModalOpen(false)}
                title="Supplier Details"
            >
                <SupplierDetailsFormFeature
                    supplierId={supplierId}
                    onSuccess={invalidateAndCloseModal}
                    onDisableSupplier={invalidateAndCloseModal}
                />
            </Modal>
        </>
    );
}
