import { useMutation, useQueryClient } from '@tanstack/react-query';
import Toaster from 'src/app/utilities/helpers/Toaster';
import { parseErrors } from 'src/app/utilities/helpers/errors';
import { deleteSeatingPlanCategory } from 'src/data/services/seatingPlanCategoryService';
import { FETCH_SEATINGPLAN_CATEOGIRES_BY_SEATINGPLAN_ID_QUERY } from './useFetchSPCsBySeatingplanId';

type TData = Awaited<ReturnType<typeof deleteSeatingPlanCategory>>;

export default function useDeleteSeatingplanCategory(onSuccess?: (data: TData) => unknown) {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: deleteSeatingPlanCategory,
        onSuccess: (data) => {
            queryClient.invalidateQueries({
                queryKey: [FETCH_SEATINGPLAN_CATEOGIRES_BY_SEATINGPLAN_ID_QUERY],
            });

            Toaster.toast('Seating plan category deleted successfully', {
                variant: 'success',
            });

            onSuccess?.(data);
        },
        onError: (errors) => Toaster.toastErrors(parseErrors(errors)),
    });
}
