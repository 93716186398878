import useApiFetch, { ErrorReturn } from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import { IdResponse } from 'src/data/models/common/idResponse';
import seriesGroupService, { CreateSeriesGroupDto } from 'src/data/services/seriesGroupService';

interface CreateSeriesGroupDataWrapperChildrenParams
    extends DataWrapperChildrenParams<ApiResponseBody<IdResponse>> {
    createSeriesGroup: (dto: CreateSeriesGroupDto) => Promise<void | ErrorReturn>;
}

type CreateSeriesGroupDataWrapperProps = DataWrapperProps<
    CreateSeriesGroupDataWrapperChildrenParams,
    ApiResponseBody<IdResponse>
> &
    BaseFetchOptions;

export const useCreateSeriesGroupDataWrapper = ({
    onError,
    onSuccess,
}: Omit<CreateSeriesGroupDataWrapperProps, 'children'>) => {
    const [{ data, loading, errors, resetData }, handleCreateSeriesGroup] =
        useApiFetch<ApiResponseBody<IdResponse>>();

    const createSeriesGroupCall = (dto: CreateSeriesGroupDto) => {
        return handleCreateSeriesGroup(seriesGroupService.createSeriesGroup(dto), {
            useDefaultErrorHandler: true,
            defaultSuccessMessage: 'Series Group has been created',
            onFail: onError,
            onSuccess,
        });
    };

    return {
        createSeriesGroup: createSeriesGroupCall,
        data,
        loading,
        errors,
        resetData,
    };
};
