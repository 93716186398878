import { ENDPOINT_VERSIONS } from './_endpoint-versions';

export const VENUES_GET_ENDPOINT = `/${ENDPOINT_VERSIONS.V2}/venues`;

export const VENUES_GET_BY_ID_ENDPOINT = (id: string) => `/${ENDPOINT_VERSIONS.V2}/venues/${id}`;

export const VENUES_CREATE_ENDPOINT = `/${ENDPOINT_VERSIONS.V2}/venues/create`;

export const VENUES_UPDATE_ENDPOINT = (id: string) =>
    `/${ENDPOINT_VERSIONS.V2}/venues/${id}/update`;

export const VENUES_DELETE_ENDPOINT = (id: string) =>
    `/${ENDPOINT_VERSIONS.V2}/venues/${id}/delete`;

export const VENUES_CREATE_SEATING_PLAN_ENDPOINT = (venueId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/venues/${venueId}/seating-plans/create`;

export const VENUES_GET_SEATING_PLANS_BY_ID_ENDPOINT = (venueId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/venues/${venueId}/seating-plans`;
