import { useMutation } from '@tanstack/react-query';
import supplierService from 'src/data/services/supplierService';

type TData = Awaited<ReturnType<typeof supplierService.deleteSupplier>>;

const useDeleteSupplier = (onSuccess?: (data: TData) => void) => {
    return useMutation({
        mutationFn: (id: string) => {
            return supplierService.deleteSupplier(id);
        },
        onSuccess,
    });
};

export default useDeleteSupplier;
