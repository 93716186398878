import useApiFetch, { ErrorReturn } from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import eventService from 'src/data/services/eventService';
import { EmptyBody } from 'src/data/models/common/emptyBody';

interface DeleteEventDataWrapperChildrenParams
    extends DataWrapperChildrenParams<ApiResponseBody<EmptyBody>> {
    deleteEvent: (eventId: string) => Promise<void | ErrorReturn>;
}

type DeleteEventDataWrapperProps = DataWrapperProps<
    DeleteEventDataWrapperChildrenParams,
    ApiResponseBody<EmptyBody>
> &
    BaseFetchOptions;

export const useDeleteEventDataWrapper = ({
    onError,
    onSuccess,
}: Omit<DeleteEventDataWrapperProps, 'children'>) => {
    const [{ data, loading, errors, resetData }, handleDeleteEvent] =
        useApiFetch<ApiResponseBody<EmptyBody>>();

    const deleteEventCall = (eventId: string) => {
        return handleDeleteEvent(eventService.deleteEvent(eventId), {
            useDefaultErrorHandler: true,
            defaultSuccessMessage: 'event has been deleted',
            onFail: onError,
            onSuccess,
        });
    };

    return {
        deleteEvent: deleteEventCall,
        data,
        loading,
        errors,
        resetData,
    };
};
