import * as Sentry from '@sentry/react';
import axios from 'axios';
import { ErrorReturn } from 'src/app/hooks/useApiFetch';
import Toaster from 'src/app/utilities/helpers/Toaster';
import type ErrorDetail from 'src/data/api/responses/ErrorDetail';

export const showMappedBackendErrors = (e: unknown) => {
    const errors = e as ErrorReturn;

    if (errors.parsedErrors?.length > 0) {
        Toaster.toastErrors(errors.parsedErrors);

        return;
    }

    Sentry.captureException(e);
};

export const parseErrors = (e: unknown): ErrorDetail[] => {
    const isAxiosError = axios.isAxiosError(e);

    if (!e) return [];

    if (!isAxiosError) {
        const message = e instanceof Error ? e.message : '';
        return [
            {
                code: 'FE_API_UNKNOWN_ERROR',
                title: `An unknown error occurred in the API communication layer.`,
                detail: `Message: ${message}`,
            },
        ];
    }

    if (!e.response) {
        return [
            {
                code: 'FE_API_NO_RESPONSE',
                title: 'The API returned no response.',
                detail: `Message: ${e.message}`,
            },
        ];
    }

    if (Array.isArray(e.response?.data?.errors)) {
        const errors = e.response?.data.errors as ErrorDetail[];
        return errors
            .filter((errorDetail) => errorDetail.code)
            .map((errorDetail) => ({
                ...errorDetail,
                code: errorDetail.code,
                title: errorDetail.title || 'Unknown',
                detail: errorDetail.detail,
                source: errorDetail.source,
            }));
    }

    if (!e.response?.data?.errors && e.response?.status) {
        return [
            {
                code: 'FE_API_ERROR_STATUS',
                title: `The API returned a ${e.response.status} status code.`,
                detail: e.response.data.detail ? `${e.response.data.detail}` : '',
            },
        ];
    }

    const message = e instanceof Error ? e.message : '';
    
    return [
        {
            code: 'FE_API_UNKNOWN_ERROR',
            title: `An unknown error occurred in the API communication layer.`,
            detail: `Message: ${message}`,
        },
    ];
};
