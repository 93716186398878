import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';
import { BaseComponent } from 'src/view/interfaces/BaseComponent';

const useStyles = makeStyles((theme) => ({
    container: {
        background: theme.colors.lightBlue,
        padding: theme.spacing(2),
        borderRadius: theme.layout.borderRadius.regular,
        overflowY: 'scroll',
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.colors.mediumGrey,
        },
    },
}));

export const Preview = ({ children, className }: BaseComponent) => {
    const classes = useStyles();

    return <div className={classnames(className, classes.container)}>{children}</div>;
};
