import { Box, FormLabel, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isArray } from 'lodash';
import { useCallback, useState } from 'react';
import { DropEvent, DropzoneProps, FileRejection } from 'react-dropzone';
import file from 'src/app/constants/constants/file-types/file';
import Toaster from 'src/app/utilities/helpers/Toaster';
import { parseErrors } from 'src/app/utilities/helpers/errors';
import ErrorDetail from 'src/data/api/responses/ErrorDetail';
import unprocessedTicketFileService from 'src/data/services/unprocessedTicketFileService';
import Button from 'src/view/components/button/Button';
import { Dropzone, DropzoneComponentProps } from 'src/view/components/dropzone-input/Dropzone';
import {
    FilterAutoComplete,
    FilterAutoCompleteValueOption,
} from 'src/view/components/filters/AutoComplete/AutoComplete';
import { ChildlessBaseComponent } from 'src/view/interfaces/BaseComponent';

export interface FileData {
    fileId: string | null;
    name: string;
    error?: ErrorDetail;
}

interface FileUploadFeatureProps extends ChildlessBaseComponent {
    onFileUploaded: (file: FileData) => void;
    onLoadingStateChanged?: (state: boolean) => void;
    onFileUploadFailed?: (file: Omit<FileData, 'fileId'>) => void;
    dropzoneComponentProps?: DropzoneComponentProps;
    dropzoneProps?: DropzoneProps;
    eventId: string;
}

export const UnprocessedTicketFileUploadFeature = ({
    onFileUploaded,
    onLoadingStateChanged,
    onFileUploadFailed,
    className,
    dropzoneComponentProps,
    dropzoneProps,
    eventId,
}: FileUploadFeatureProps) => {
    const maximumAllowedFilesAtOnce = 100;
    const queryClient = useQueryClient();
    const [language, setLanguage] = useState<SupportedTtiLanguagesType>(
        SUPPORTED_TTI_LANGUAGES.ENGLISH
    );
    const [files, setFiles] = useState<File[]>([]);

    const { mutateAsync: uploadFile, isLoading } = useMutation({
        mutationFn: (file: File) =>
            unprocessedTicketFileService.uploadUnprocessedTicketFile({ eventId, file, language }),
    });

    const handleFileUpload = async (file: File) => {
        try {
            const resp = await uploadFile(file);
            const uploadedFile = { fileId: resp.data.data.id, name: file.name };

            onFileUploaded(uploadedFile);
        } catch (error) {
            onFileUploadFailed?.({ name: file.name });
            const parsedError = parseErrors(error)[0];

            const failedUploadFile = {
                fileId: null,
                name: file.name,
                error: parsedError,
            };

            onFileUploaded(failedUploadFile);
        }
    };

    const handleDrop = async (
        acceptedFiles: File[],
        fileRejections: FileRejection[],
        event: DropEvent
    ) => {
        if (fileRejections.length) {
            const isMaxFilesAtOnceLimitExceeded = fileRejections.every(
                (r) => r.errors[0]?.code === 'too-many-files'
            );

            if (isMaxFilesAtOnceLimitExceeded) {
                Toaster.toast(
                    `Selection includes more than the allowed number of files at once, which is ${maximumAllowedFilesAtOnce}.`,
                    {
                        variant: 'error',
                    }
                );

                return;
            }
        }
        if (dropzoneProps?.onDrop) {
            dropzoneProps.onDrop(acceptedFiles, fileRejections, event);

            return;
        }

        setFiles(acceptedFiles);
    };

    const confirmUpload = useCallback(async () => {
        onLoadingStateChanged?.(true);

        await Promise.all(
            files.map(async (file) => {
                await handleFileUpload(file);
            })
        );

        onLoadingStateChanged?.(false);
        setFiles([]);
        queryClient.invalidateQueries({ queryKey: ['unprocessedTicketFiles'], exact: false });
    }, [files, queryClient]);

    const onChangeLanguage = useCallback(
        (selectedOption: FilterAutoCompleteValueOption | undefined) => {
            if (!isArray(selectedOption) && selectedOption?.value) {
                setLanguage(selectedOption.value.toString() as SupportedTtiLanguagesType);
            } else {
                setLanguage(SUPPORTED_TTI_LANGUAGES.ENGLISH);
            }
        },
        []
    );

    return (
        <>
            <FormLabel>Files*</FormLabel>
            <Dropzone
                className={className}
                loading={dropzoneComponentProps?.loading || isLoading}
                onDrop={handleDrop}
                accept={dropzoneProps?.accept || file.ticketFilesAcceptedMimeTypes}
                maxFiles={maximumAllowedFilesAtOnce}
                {...dropzoneProps}
                {...dropzoneComponentProps}
            />

            {files.length > 0 && (
                <Box marginTop={2}>
                    <Box
                        sx={{
                            marginBottom: 2,
                        }}
                    >
                        <FormLabel>Language</FormLabel>
                        <FilterAutoComplete
                            options={LANGUAGE_DROPDOWN_OPTIONS}
                            onChange={onChangeLanguage}
                            value={{
                                label: language,
                                value: language,
                            }}
                        />
                    </Box>
                    <Typography variant="h6">
                        {files.length} files are ready to be uploaded.
                    </Typography>
                    <Button disabled={isLoading} onClick={confirmUpload}>
                        Confirm upload
                    </Button>
                </Box>
            )}
        </>
    );
};

export type SupportedTtiLanguagesType =
    (typeof SUPPORTED_TTI_LANGUAGES)[keyof typeof SUPPORTED_TTI_LANGUAGES];

export const SUPPORTED_TTI_LANGUAGES = {
    ENGLISH: 'English',
    ITALIAN: 'Italian',
    SPANISH: 'Spanish',
} as const;

const LANGUAGE_DROPDOWN_OPTIONS = Object.values(SUPPORTED_TTI_LANGUAGES).map((language) => ({
    label: language,
    value: language,
}));
