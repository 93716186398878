import useApiFetch, { ErrorReturn } from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import eventService from 'src/data/services/eventService';
import { EmptyBody } from 'src/data/models/common/emptyBody';

interface DeactivateEventDataWrapperChildrenParams
    extends DataWrapperChildrenParams<ApiResponseBody<EmptyBody>> {
    deactivateEvent: (eventId: string) => Promise<void | ErrorReturn>;
}

type DeactivateEventDataWrapperProps = DataWrapperProps<
    DeactivateEventDataWrapperChildrenParams,
    ApiResponseBody<EmptyBody>
> &
    BaseFetchOptions;

export const useDeactivateEventDataWrapper = ({
    onError,
    onSuccess,
}: Omit<DeactivateEventDataWrapperProps, 'children'>) => {
    const [{ data, loading, errors, resetData }, handleDeactivateEvent] =
        useApiFetch<ApiResponseBody<EmptyBody>>();

    const deactivateEventCall = (eventId: string) => {
        return handleDeactivateEvent(eventService.deactivateEvent(eventId), {
            useDefaultErrorHandler: true,
            defaultSuccessMessage: 'event has been deactivated',
            onFail: onError,
            onSuccess,
        });
    };

    return {
        deactivateEvent: deactivateEventCall,
        data,
        loading,
        errors,
        resetData,
    };
};
