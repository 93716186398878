import { useState } from 'react';
import { Grid } from '@mui/material';
import Modal from 'src/view/components/modal/Modal';
import Header from 'src/view/components/header/Header';
import { ETBContainer } from 'src/view/components/container/container';
import OrganizerTableFeature from 'src/app/components/features/organizer/OrganizerTableFeature';
import Button from 'src/view/components/button/Button';
import CreateOrUpdateOrganizerFeature from 'src/app/components/features/organizer/CreateOrUpdateOrganizerFeature';

export default function OrganizersOverview() {
    const [createModalOpen, setCreateModalOpen] = useState(false);

    return (
        <>
            <Header GridProps={{ justifyContent: 'flex-end' }}>
                <Grid item>
                    <Button color="primary" onClick={() => setCreateModalOpen(true)}>
                        Add New organizer
                    </Button>
                </Grid>
            </Header>

            <ETBContainer>
                <OrganizerTableFeature />
            </ETBContainer>
            <Modal
                title="Add New Organizer"
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
            >
                <CreateOrUpdateOrganizerFeature
                    onSuccess={() => {
                        setCreateModalOpen(false);
                    }}
                />
            </Modal>
        </>
    );
}
