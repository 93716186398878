import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Button from 'src/view/components/button/Button';
import { BUTTON_SIZES } from 'src/shared/theme/enums';
import { VisibilityOutlined } from '@mui/icons-material';
import classnames from 'classnames';

const useStyles = makeStyles((theme: Theme) => ({
    tableHeader: {
        background: theme.palette.primary.main,
        color: theme.colors.white,
        padding: theme.spacing(1, 2),
        display: 'flex',
        justifyContent: 'space-between',
        borderRadius: `${theme.layout.borderRadius.regular}px ${theme.layout.borderRadius.regular}px 0 0`,
        alignItems: 'center',
    },
    tableTitle: {
        fontSize: '1.3rem',
        'font-weight': theme.typography.fontWeightBold,
        display: 'flex',
        alignItems: 'center',
    },
    tableSubTitle: {
        fontSize: '.775rem',
        color: theme.colors.lightGrey,
    },
    tableTitleSmall: {
        fontSize: '.875rem',
    },
    additionalTitle: {
        fontSize: '0.8rem',
        fontWeight: 'bold',
    },
    showMoreStatsButton: {
        marginLeft: theme.spacing(2),
        background: 'rgba(255, 255, 255, 0.1)',
        borderRadius: 4,
        '&:hover': {
            background: 'rgba(255,255,255, .2)',
        },
    },
    rightSlot: {
        marginLeft: 'auto',
    },
    totals: {
        display: 'flex',
        alignItems: 'center',
    },
    disableBorderRadus: {
        borderRadius: 0,
    },
}));

export interface TableHeaderProps {
    variant?: 'normal' | 'small';
    title: string;
    subTitle?: string;
    totalResults?: number | string;
    onClickShow?: () => void;
    showMoreText?: string;
    enableShowMoreIcon?: boolean;
    hideShowMoreButton?: boolean;
    disableBorderRadius?: boolean;
    rightSlot?: JSX.Element;
    additionalTitle?: string;
}

export default function TableHeader({
    variant = 'normal',
    title,
    subTitle,
    totalResults,
    onClickShow,
    showMoreText,
    hideShowMoreButton,
    enableShowMoreIcon = true,
    disableBorderRadius,
    rightSlot,
    additionalTitle,
}: TableHeaderProps) {
    const classes = useStyles();

    const renderShowButton = () => {
        return (
            <>
                {onClickShow && !hideShowMoreButton && (
                    <Button
                        startIcon={enableShowMoreIcon ? <VisibilityOutlined /> : null}
                        size={BUTTON_SIZES.TINY}
                        onClick={onClickShow}
                        color="primary"
                        className={classes.showMoreStatsButton}
                    >
                        {showMoreText || 'Show more statistics'}
                    </Button>
                )}
            </>
        );
    };

    return (
        <div
            className={classnames(
                classes.tableHeader,
                disableBorderRadius && classes.disableBorderRadus
            )}
        >
            <div>
                <div>
                    <span
                        className={classnames(
                            classes.tableTitle,
                            variant === 'small' && classes.tableTitleSmall
                        )}
                    >
                        {title}
                    </span>
                    <span className={classnames(classes.additionalTitle)}>{additionalTitle}</span>
                </div>
                <div>
                    <span className={classnames(classes.tableSubTitle)}>{subTitle}</span>
                </div>
            </div>

            {classes.rightSlot && <div className={classes.rightSlot}>{rightSlot}</div>}

            {totalResults ? (
                <div className={classes.totals}>
                    <span>{totalResults}</span>
                    {renderShowButton()}
                </div>
            ) : (
                renderShowButton()
            )}
        </div>
    );
}
