import { useEffect, useState } from 'react';
import useApiFetch from 'src/app/hooks/useApiFetch';
import { toastApiErrors } from 'src/app/utilities/helpers/toast-api-errors';
import PaginatedApiResponseBody from 'src/data/api/responses/PaginatedApiResponseBody';
import Row from 'src/data/models/row/row';
import seatingPlanCategoryService from 'src/data/services/seatingPlanCategoryService';
import AutoComplete from 'src/view/components/auto-complete/AutoComplete';
import {
    AutoCompleteOption,
    AutoCompleteOptions,
} from 'src/view/components/auto-complete/interfaces';

interface Props {
    name: string;
    seatingPlanCategoryId: string;
    blockId: string;
    onChange: (option?: AutoCompleteOption) => void;
    value?: AutoCompleteOption;
    onSuccessfullyCreate?: (option: AutoCompleteOption) => void;
    disabled?: boolean;
}

export default function RowsSelectDataWrapper({
    name,
    blockId,
    seatingPlanCategoryId,
    onChange,
    value,
    onSuccessfullyCreate,
    disabled,
}: Props) {
    const [option, setOption] = useState<AutoCompleteOption | undefined>(value);
    const [invalidatedAt, setInvalidatedAt] = useState<number | undefined>();

    useEffect(() => {
        setOption(value);
    }, [value]);

    const [{ data }, handleFetchBlocks] = useApiFetch<PaginatedApiResponseBody<Row>>();

    useEffect(() => {
        if (!blockId || disabled) return;

        handleFetchBlocks(
            seatingPlanCategoryService.fetchRowsBySeatingPlanCategoryId(
                seatingPlanCategoryId,
                blockId
            ),
            {
                useDefaultErrorHandler: true,
            }
        );
    }, [blockId, invalidatedAt]);

    const rowsOptions = (): AutoCompleteOptions => {
        if (!data) return [];

        return data.data.map((row) => ({
            label: row.name,
            value: row.id,
        }));
    };

    const onCreateRow = async (name: string) => {
        if (!blockId) return;

        try {
            const result = await seatingPlanCategoryService.createRow(
                name,
                seatingPlanCategoryId,
                blockId
            );
            const createdRow = { label: name, value: result.data.data.id };

            onSuccessfullyCreate?.(createdRow);
            setInvalidatedAt(Date.now());
        } catch (e) {
            toastApiErrors(e);
        }
    };

    return (
        <AutoComplete
            name={name}
            value={option}
            options={rowsOptions()}
            onChange={(value) => {
                if (value === null) value = undefined;
                onChange(value);
            }}
            placeholder={!blockId ? 'Select a block first (disabled)' : 'select or create'}
            disabled={disabled || !blockId}
            onCreateOption={onCreateRow}
            backgroundColor={'white'}
        />
    );
}
