import { zodResolver } from '@hookform/resolvers/zod';
import { Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { zodRequiredDropdownSelectSchema } from 'src/app/utilities/zod/zodRequiredDropdownSelectSchema';
import { paymentStatusStrings } from 'src/data/constants/order';
import { FINANCE_PAYMENT_TYPES, PAYMENT_STATUS } from 'src/data/enums/order';
import AutoComplete from 'src/view/components/auto-complete/AutoComplete';
import {
    AutoCompleteOption,
    AutoCompleteOptions,
} from 'src/view/components/auto-complete/interfaces';
import FormButtons from 'src/view/components/form/FormButtons';
import FormFieldError from 'src/view/components/form/FormFieldError';
import ModalSubTitle from 'src/view/components/modal/ModalSubtitle';
import z from 'zod';

const useStyles = makeStyles((theme: Theme) => ({
    spacingRight: {
        paddingRight: theme.spacing(1),
    },
}));
export interface ChangePaymentStatusFormValues {
    status: AutoCompleteOption;
    reason: AutoCompleteOption;
}

export const paymentStatusValidationForm = z.object({
    status: zodRequiredDropdownSelectSchema,
    reason: zodRequiredDropdownSelectSchema,
});

interface ChangePaymentStatusFormProps {
    onSubmit: SubmitHandler<ChangePaymentStatusFormValues>;
    defaultValues?: {
        status?: string;
        reason?: string;
    };
}

export default function ChangePaymentStatusForm({
    onSubmit,
    defaultValues,
}: ChangePaymentStatusFormProps) {
    const classes = useStyles();

    const statusOptions: AutoCompleteOptions = [
        {
            label: paymentStatusStrings[PAYMENT_STATUS.Approved],
            value: PAYMENT_STATUS.Approved,
        },
        {
            label: paymentStatusStrings[PAYMENT_STATUS.Partial],
            value: PAYMENT_STATUS.Partial,
        },
    ];

    const reasonOptions: AutoCompleteOptions = [
        {
            label: FINANCE_PAYMENT_TYPES.FINANCE_PAYMENT_RECEIVED,
            value: FINANCE_PAYMENT_TYPES.FINANCE_PAYMENT_RECEIVED,
        },
    ];

    const changePaymentStatusForm = useForm<ChangePaymentStatusFormValues>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        resolver: zodResolver(paymentStatusValidationForm),
    });

    const {
        control,
        handleSubmit,
        reset,
        formState: { isDirty },
    } = changePaymentStatusForm;

    const isSubmitButtonDisabled = !isDirty;

    useEffect(() => {
        if (!defaultValues) return;

        reset({
            status: statusOptions.find((o) => o.value === defaultValues.status),
            reason: reasonOptions.find((o) => o.value === defaultValues.reason),
        });
    }, [defaultValues?.status, defaultValues?.reason]);

    return (
        <Grid container>
            <Grid item xs={6} className={classes.spacingRight}>
                <ModalSubTitle>Status</ModalSubTitle>
                <Controller
                    name="status"
                    control={control}
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                        return (
                            <>
                                <AutoComplete
                                    name={name}
                                    onChange={(option) => {
                                        onChange(option);
                                    }}
                                    value={value}
                                    isClearable
                                    options={statusOptions}
                                    placeholder="Select a status"
                                />
                                <FormFieldError message={error?.message} />
                            </>
                        );
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <ModalSubTitle>Reason</ModalSubTitle>
                <Controller
                    name="reason"
                    control={control}
                    render={({ field: { onChange, value, name }, fieldState: { error } }) => {
                        return (
                            <>
                                <AutoComplete
                                    name={name}
                                    onChange={(option) => {
                                        onChange(option);
                                    }}
                                    value={value}
                                    isClearable
                                    options={reasonOptions}
                                    placeholder="Select a reason"
                                />
                                <FormFieldError message={error?.message} />
                            </>
                        );
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <FormButtons
                    buttons={[
                        {
                            children: 'Save',
                            onClick: () => handleSubmit(onSubmit)(),
                            disabled: isSubmitButtonDisabled,
                        },
                    ]}
                />
            </Grid>
        </Grid>
    );
}
