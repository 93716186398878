import { useState } from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'src/view/components/modal/Modal';
import useQueryParams from 'src/app/hooks/useQueryParams';
import OrganizerTable from 'src/app/components/tables/OrganizerTable';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';
import Filters, { FiltersArray } from 'src/app/components/forms/filters/Filters';
import { TableHeaderTotalFeature } from 'src/app/components/features/tables/TableHeaderTotalFeature';
import { config } from 'src/app/constants/config/config';
import CreateOrUpdateOrganizerFeature from 'src/app/components/features/organizer/CreateOrUpdateOrganizerFeature';
import { useOrganizers } from 'src/app/hooks/useOrganizers';
import DeleteOrganizerFeature from './DeleteOrganizerFeature';

export default function OrganizerTableFeature(): JSX.Element {
    const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false);
    const [organizerId, setOrganizerId] = useState<string>();

    const {
        values: { customFields },
        setCustomFields,
    } = useQueryParams('organizer');

    const { data: organizers, isLoading: isOrganizersLoading } = useOrganizers({
        q: customFields.q,
        page: Number(customFields['page']) || 1,
        pageSize: config.ITEMS_PER_PAGE_STANDARD,
    });

    const form = useForm({
        mode: 'onChange',
        defaultValues: customFields,
    });

    const { control } = form;

    const onEditClick = (organizerId: string) => {
        setEditModalOpen(true);
        setOrganizerId(organizerId);
    };

    const onDeleteClick = (organizerId: string) => {
        setConfirmationModalOpen(true);
        setOrganizerId(organizerId);
    };

    const arrayOfFilters: FiltersArray = [
        {
            name: 'searchTerm',
            onChange: (q) =>
                setCustomFields({
                    ...customFields,
                    q: q.length > 0 ? q : '',
                }),
            type: 'search',
            searchPlaceholder: 'Search organizer name',
            searchDefaultValue: customFields['q'] || '',
            label: 'Search',
        },
    ];

    return (
        <>
            <Filters control={control} filters={arrayOfFilters} />
            {isOrganizersLoading && <LoadingOverlay />}
            <TableHeaderTotalFeature
                title="Organizers"
                totalResults={organizers?.data.meta.total}
            />
            <OrganizerTable
                organizers={organizers?.data.data}
                loading={isOrganizersLoading}
                onEditClick={onEditClick}
                onDeleteClick={onDeleteClick}
                pagination={{
                    currentPage: organizers?.data.meta.currentPage || 1,
                    totalPages: organizers?.data.meta.totalPages || 1,
                    onPaginate: (page: number) =>
                        setCustomFields({ ...customFields, page: page.toString() }),
                }}
            />

            <Modal
                open={editModalOpen}
                onClose={() => setEditModalOpen(false)}
                title="Organizer Details"
            >
                <>
                    {organizerId && (
                        <CreateOrUpdateOrganizerFeature
                            organizerId={organizerId}
                            onSuccess={() => {
                                setEditModalOpen(false);
                            }}
                        />
                    )}
                </>
            </Modal>
            <Modal
                open={confirmationModalOpen}
                onClose={() => setConfirmationModalOpen(false)}
                title="Confirm your action"
            >
                <>
                    {organizerId && (
                        <DeleteOrganizerFeature
                            organizerId={organizerId}
                            onSuccess={() => {
                                setConfirmationModalOpen(false);
                            }}
                        />
                    )}
                </>
            </Modal>
        </>
    );
}
