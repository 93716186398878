import moment from 'moment';
import { PurchaseDetailsFormValues } from 'src/app/components/forms/CreatePurchaseDetailsForm';
import { PURCHASE_TYPE_ITEMS, type PurchaseType } from 'src/data/enums/purchase';
import Purchase from 'src/data/models/purchase/Purchase';

export default function mapPurchaseDataToViewModel(purchase: Purchase): PurchaseDetailsFormValues {
    const {
        originalPrice,
        externalReferenceId,
        supplierName,
        supplierId,
        paymentMethod,
        notes,
        purchasedAt,
        supplierUserAccount,
        finalized,
        purchaseType,
    } = purchase;

    const ptItem = Object.hasOwn(PURCHASE_TYPE_ITEMS, purchaseType)
        ? { label: PURCHASE_TYPE_ITEMS[purchaseType as PurchaseType], value: purchaseType }
        : undefined;

    return {
        currency: {
            value: originalPrice?.currency,
            label: originalPrice?.currency,
        },
        price: originalPrice?.value?.toString(),
        externalReference: externalReferenceId || '',
        supplier: {
            label: supplierName,
            value: supplierId,
        },
        paymentMethod: paymentMethod
            ? {
                  value: paymentMethod.type,
                  label: paymentMethod.type,
              }
            : null,
        creditcardNumber: paymentMethod?.creditCardInfo,
        internalNotes: notes || '',
        purchaseDate: moment(purchasedAt).toDate(),
        supplierUserAccount: supplierUserAccount || '',
        finalized,
        purchaseType: ptItem,
        paymentMethodPresent: !!paymentMethod,
    };
}
