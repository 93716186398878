export enum Roles {
    EMPLOYEES = 'Employees',
    DEVELOPERS = 'Developers',
    VENDING_MACHINE = 'VendingMachine',
    PLATFORM_PRICER = 'PlatformPricer',
    TICKET_MANAGEMENT = 'TicketManagement',
    INVENTORY = 'Inventory',
    ADMINISTRATOR = 'Administrator',
    PRODUCTLEAD = 'ProductLead',
    PRODUCT_SENIOR = 'ProductSenior',
    OPERATIONS_SENIOR = 'OperationsSenior',
    OPERATIONS_LEAD = 'OperationsLead',
    FINANCE = 'Finance',
    FINANCE_INTERN = 'FinanceIntern',
    FINANCE_JUNIOR = 'FinanceJunior',
    FINANCE_LEAD = 'FinanceLead',
    FINANCE_SENIOR = 'FinanceSenior',
}