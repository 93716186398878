import moment from 'moment';
import FilterOption from 'src/data/api/common/FilterOption';
import tableFilterFormHelper from './tableFilterFormHelper';

export function findDates(filterOptions: FilterOption[]) {
    const startDate = tableFilterFormHelper.getDateValue('purchasedAt', 'afterDate', filterOptions);
    const endDate = tableFilterFormHelper.getDateValue('purchasedAt', 'beforeDate', filterOptions);

    return [parseDate(startDate), parseDate(endDate)] as const;
}

function parseDate(text: string | undefined) {
    return text ? moment(text).toDate() : undefined;
}
