export enum ORDER_STATUS {
    Active = 'Active',
    Cancelled = 'Cancelled',
}

export enum PAYMENT_STATUS {
    Approved = 'Approved',
    Partial = 'Partial',
    Missing = 'Missing',
}

export enum FINANCE_PAYMENT_TYPES {
    FINANCE_PAYMENT_RECEIVED = 'FINANCE_PAYMENT_RECEIVED',
}

export enum SHIPPING_STATUS {
    Sent = 'Sent',
    PartiallySent = 'PartiallySent',
    NotSent = 'NotSent',
    Unknown = 'Unknown',
}

export enum ORDER_SOURCE_SYSTEM {
    Unspecified = 'Unspecified',
    Travelware = 'Travelware',
    Viagogo = 'Viagogo',
    Phone = 'Phone',
    LiveTickets = 'LiveTickets',
    Moddix = 'Moddix',
}

export enum ORDER_SOURCE {
    TW_Checkout = 'TW_Checkout',
    TW_DirectSale = 'TW_DirectSale',
    TW_Equipo = 'TW_Equipo',
    TW_Livetickets = 'TW_Livetickets',
    U_Livetickets = 'U_Livetickets',
    TW_OrderChange = 'TW_OrderChange',
    TW_Stubhub = 'TW_Stubhub',
    TW_Unknown = 'TW_Unknown',
    U_Unknown = 'U_Unknown',
    TW_Viagogo = 'TW_Viagogo',
    U_Viagogo = 'U_Viagogo',
    U_ExternalOrdersBot = 'U_ExternalOrdersBot',
    U_Manual = 'U_Manual',
    U_Moddix = 'U_Moddix',
    U_PlatformsAPI = 'U_PlatformsAPI',
}

export enum ASSIGNMENT_STATUS {
    ASSIGNED = 'Assigned',
    NOT_ASSIGNED = 'NotAssigned',
    PARTIALLY_ASSIGNED = 'PartiallyAssigned',
}

export enum READY_STATUS {
    READY = 'Ready',
    PARTIALLY_READY = 'PartiallyReady',
    NOT_READY = 'NotReady',
}
