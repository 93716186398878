import { SketchPicker as Picker, RGBColor, ColorChangeHandler } from 'react-color';

interface Props {
    color: RGBColor;
    onChange: ColorChangeHandler;
}

export default function SketchColor({ color, onChange }: Props) {
    return (
        <Picker
            color={color}
            onChange={onChange}
            onChangeComplete={onChange}
            disableAlpha
            presetColors={[]}
        />
    );
}
