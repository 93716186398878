import { zodResolver } from '@hookform/resolvers/zod';
import { Alert, CircularProgress, Grid, Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import moment, { Moment } from 'moment';
import { Controller, useForm } from 'react-hook-form';
import { useFetchEventsDataWrapper } from 'src/app/components/data-wrappers/event/FetchEventsDataWrapper';
import { useFetchSeriesDataWrapper } from 'src/app/components/data-wrappers/series/FetchSeriesDataWrapper';
import { csvTypes } from 'src/app/constants/constants/file-types/csv';
import { FormProps } from 'src/app/interfaces/form/formProps';
import { zodOptionalDdropdownSchema } from 'src/app/utilities/zod/zodOptionalDdropdownSchema';
import FilterOption from 'src/data/api/common/FilterOption';
import { useSpacingStyles } from 'src/shared/styles/spacingStyles';
import AutoComplete from 'src/view/components/auto-complete/AutoComplete';
import { AutoCompleteOption } from 'src/view/components/auto-complete/interfaces';
import Button from 'src/view/components/button/Button';
import DatePicker from 'src/view/components/date-picker/DatePicker';
import { Dropzone } from 'src/view/components/dropzone-input/Dropzone';
import FormFieldError from 'src/view/components/form/FormFieldError';
import { FormLabel } from 'src/view/components/form/FormLabel';
import z from 'zod';

const useStyles = makeStyles((theme: Theme) => ({
    button: {
        marginTop: theme.spacing(3),
    },
}));

const csvValidationScheme = z
    .object({
        startDate: z.object({}).optional(),
        endDate: z.object({}).optional(),
        event: zodOptionalDdropdownSchema,
        series: zodOptionalDdropdownSchema,
        file: z.instanceof(File, {
            message: 'File is required and its extension can only be a .csv',
        }),
    })
    .superRefine((schema, ctx) => {
        if (!schema.event && !schema.startDate) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'This is required when start date is not selected',
                path: ['event'],
            });

            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'This is required when event is not selected',
                path: ['startDate'],
            });
        }
    });

export interface CompareOrdersFormValues {
    startDate: Moment;
    endDate?: Moment;
    event?: AutoCompleteOption;
    series?: AutoCompleteOption;
    file: File;
}

export default function CompareOrdersForm({
    loading,
    onSubmit,
}: FormProps<CompareOrdersFormValues>) {
    const theme = useTheme();
    const classes = useStyles();
    const spacingClasses = useSpacingStyles();

    const { fetchSeries, loading: seriesLoading, data: seriesData } = useFetchSeriesDataWrapper({});
    const { fetchEvents, loading: eventsLoading, data: eventsData } = useFetchEventsDataWrapper({});

    const { handleSubmit, setValue, control, formState, watch, trigger, resetField, register } =
        useForm<CompareOrdersFormValues>({
            mode: 'onChange',
            resolver: zodResolver(csvValidationScheme),
        });

    register('file');
    const startDateValue = watch('startDate');
    const endDateValue = watch('endDate');
    const eventValue = watch('event');
    const seriesValue = watch('series');
    const file = watch('file');

    const renderEmptyGridItem = (md = 6) => (
        <Grid
            item
            xs={0}
            md={md}
            sx={{
                display: {
                    xs: 'none',
                    md: 'flex',
                },
            }}
        />
    );

    const datePickerOnChange = (
        name: keyof CompareOrdersFormValues,
        value: Moment | null
    ): void => {
        if (name === 'startDate' && value && moment(value).isAfter(endDateValue)) {
            setValue('endDate', value, { shouldValidate: true });

            return;
        }

        if (
            name === 'endDate' &&
            value &&
            endDateValue &&
            moment(endDateValue).isBefore(startDateValue)
        ) {
            setValue('startDate', value, { shouldValidate: true });

            return;
        }

        if (value === null) return;

        setValue(name, value, {
            shouldValidate: true,
        });
    };

    const getSeriesFilter = (eventId?: string): FilterOption[] => [
        {
            property: 'eventId',
            value: eventId || '',
        },
    ];

    const getEventsFilter = (seriesId?: string): FilterOption[] => [
        {
            property: 'seriesId',
            value: seriesId || '',
        },
    ];

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Alert severity="info" className={spacingClasses.spacingBottom}>
                        Only csv files without headers (when exporting to csv select to not have a
                        header row)
                    </Alert>
                </Grid>
                {renderEmptyGridItem()}
                <Grid item xs={12} md={3}>
                    <FormLabel>Start date</FormLabel>
                    <Controller
                        name="startDate"
                        control={control}
                        render={({ field: { name, value }, fieldState: { error } }) => (
                            <>
                                <DatePicker
                                    maxDate={moment(endDateValue)}
                                    placeholder="Purchase date"
                                    value={value || null}
                                    onChange={(e) => {
                                        datePickerOnChange(name, e);
                                        trigger('event');
                                    }}
                                />
                                <FormFieldError message={error?.message} />
                            </>
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormLabel>End date</FormLabel>
                    <Controller
                        name="endDate"
                        control={control}
                        render={({ field: { name, value }, fieldState: { error } }) => (
                            <>
                                <DatePicker
                                    minDate={moment(startDateValue)}
                                    placeholder="Purchase date"
                                    value={value || null}
                                    onChange={(e) => datePickerOnChange(name, e)}
                                />
                                <FormFieldError message={error?.message} />
                            </>
                        )}
                    />
                </Grid>
                {renderEmptyGridItem()}
                <Grid item xs={12} md={3}>
                    <FormLabel>Series</FormLabel>
                    <Controller
                        name="series"
                        control={control}
                        render={({ field: { name, value, onChange } }) => (
                            <AutoComplete
                                onChange={(newValue) => {
                                    if (newValue?.value !== value?.value) {
                                        setValue('event', undefined);
                                    }

                                    onChange(newValue);
                                    fetchEvents({ filter: getEventsFilter(newValue?.value) });
                                }}
                                onInputChange={(value) => {
                                    fetchSeries({
                                        q: value,
                                        filter: getSeriesFilter(eventValue?.value),
                                    });
                                }}
                                options={
                                    seriesData?.data.map((series) => ({
                                        label: series.name,
                                        value: series.id,
                                    })) || []
                                }
                                placeholder="Select a series"
                                name={name}
                                value={value}
                                loading={seriesLoading}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <FormLabel>Event</FormLabel>
                    <Controller
                        name="event"
                        control={control}
                        render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                            <>
                                <AutoComplete
                                    onChange={(newValue) => {
                                        onChange(newValue);
                                        fetchSeries({ filter: getSeriesFilter(newValue?.value) });
                                        trigger('startDate');
                                    }}
                                    onInputChange={(value) => {
                                        fetchEvents({
                                            q: value,
                                            filter: getEventsFilter(seriesValue?.value),
                                        });
                                    }}
                                    options={
                                        eventsData?.data.map((event) => ({
                                            label: event.name,
                                            value: event.id,
                                        })) || []
                                    }
                                    placeholder="Select a event"
                                    name={name}
                                    value={value}
                                    loading={eventsLoading}
                                />
                                <FormFieldError message={error?.message} />
                            </>
                        )}
                    />
                </Grid>
                {renderEmptyGridItem()}
                <Grid item xs={12} md={3}>
                    <>
                        <FormLabel>Csv file</FormLabel>

                        {file ? (
                            <>
                                <p>{file.name}</p>
                                <button onClick={() => resetField('file')}>Remove</button>
                            </>
                        ) : (
                            <Dropzone
                                uploadText={"Drag 'n drop files here"}
                                dragActiveText={'Drop file(s)...'}
                                accept={csvTypes}
                                maxFiles={1}
                                onDrop={(file) => {
                                    setValue('file', file[0], { shouldValidate: true });
                                }}
                            />
                        )}

                        {formState.errors.file && (
                            <FormFieldError
                                key={`${formState.errors.file?.type}`}
                                message={formState.errors.file?.message}
                            />
                        )}
                    </>
                </Grid>
            </Grid>
            <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleSubmit((values) => {
                    onSubmit(values);
                })}
                disabled={loading}
                startIcon={loading && <CircularProgress size={theme.layout.loader.sizes.small} />}
            >
                Uploaden
            </Button>
        </>
    );
}
