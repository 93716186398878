import React from 'react';
import { NavLink } from 'react-router-dom';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';
import { MenuItem } from 'src/app/interfaces/menu';

interface StylesProps {
    isHovering: boolean;
}

const baseLinkStyles = {
    padding: '0px 1.5rem',
    textDecoration: 'none',
    fontWeight: 'bold',
    transition: 'all 0.2s linear',
    cursor: 'pointer !important',
    height: '100%',
};

const useStyles = makeStyles((theme: Theme) => ({
    link: {
        ...baseLinkStyles,
        color: theme.colors.darkGrey,
        position: 'relative',
        '&:hover': {
            color: '#000',
        },
    },
    linkActive: {
        ...baseLinkStyles,
        color: '#000',
        borderBottom: '0px solid #000',
        '&:hover': {
            color: '#000',
            borderBottom: '0px solid #000',
        },
    },
    underline: ({ isHovering }: StylesProps) => ({
        width: '100%',
        height: '2px',
        backgroundColor: '#000',
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute',
        bottom: '0px',
        left: '0px',
        transition: 'all 0.2s ease-out',
        transformOrigin: '50%',
        transform: isHovering ? 'scaleX(1)' : 'scaleX(0)',
    }),
}));

export default function NavbarLink({ url, label, isClickable = true, idDropdownOpened }: MenuItem) {
    const [isHovering, setIsHovering] = useState(false);
    const classes = useStyles({ isHovering });

    const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (!isClickable || !url) event.preventDefault();
    };

    return (
        <NavLink
            to={url}
            onClick={handleLinkClick}
            className={({ isActive }) =>
                isActive || idDropdownOpened ? classes.linkActive : classes.link
            }
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
        >
            <p>{label}</p>
            <span className={classes.underline}></span>
        </NavLink>
    );
}
