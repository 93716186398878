import z from 'zod';
import { isCreditCardMethod } from '../../helpers/paymentMethod';
import { zodOptionalDdropdownSchema } from '../zodOptionalDdropdownSchema';
import { zodRequiredDropdownSelectSchema } from '../zodRequiredDropdownSelectSchema';
import { zodRequiredNumberInputSchema } from '../zodRequiredNumberInputSchema';
import { PURCHASE_TYPES } from 'src/data/enums/purchase';

export const zodCreateOrEditPurchaseValidationSchema = z
    .object({
        supplier: zodRequiredDropdownSelectSchema,
        supplierUserAccount: z.string(),
        purchaseDate: z.instanceof(Date),
        currency: zodRequiredDropdownSelectSchema,
        price: zodRequiredNumberInputSchema,
        externalReference: z.string().optional(),
        paymentMethod: zodOptionalDdropdownSchema.nullable(),
        paymentMethodPresent: z.boolean().optional(),
        creditcardNumber: z.string().nullable().optional(),
        internalNotes: z.string().nullable().optional(),
        finalized: z.boolean().optional(),
        purchaseType: z.object({
            label: z.string(),
            value: z.enum(PURCHASE_TYPES),
        }).optional(),
    })
    .superRefine((schema, ctx) => {
        if (schema.paymentMethod && isCreditCardMethod(schema?.paymentMethod?.label)) {
            if (!schema.creditcardNumber || schema.creditcardNumber.length !== 4) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    message: 'Please enter 4 digits',
                    path: ['creditcardNumber'],
                });
            }
        }

        if (schema.paymentMethodPresent && !schema.paymentMethod) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: 'Payment method can be changed, but not removed',
                path: ['paymentMethod'],
            });
        }
    });
