import { Alert } from '@mui/material';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ErrorViewModel } from 'src/view/view-models/ErrorViewModel';

const useStyles = makeStyles((theme: Theme) => ({
    containerSpacing: {
        marginTop: theme.spacing(1),
    },
    spacingBottom: {
        marginBottom: theme.spacing(1),
    },
    errorTextContainer: {
        display: 'flex',
        flexDirection: 'column',
        '& > *': {
            margin: theme.spacing(0, 0, 0.5, 0),
        },
    },
    secondaryText: {
        fontSize: '0.75rem',
        color: (props: ErrorsListProps) =>
            props.plainStyle ? theme.colors.lightGrey : theme.colors.grey,
        margin: 0,
    },
    unifiedErrorList: {
        padding: 0,
        paddingLeft: 12,
    },
}));

export interface ErrorsListProps {
    errorTitle?: string;
    errors: ErrorViewModel[];
    plainStyle?: boolean;
}

export default function ErrorsList(props: ErrorsListProps): JSX.Element {
    const classes = useStyles(props);
    const { errors, plainStyle } = props;

    if (errors.length === 0) return <></>;

    const wrapErrorText = (element: JSX.Element, index: number) => {
        if (plainStyle) {
            return (
                <div key={`plain-error-${index}`} className={classes.spacingBottom}>
                    {element}
                </div>
            );
        }

        return (
            <Alert
                key={`standard-error-${index}`}
                severity="error"
                className={classes.spacingBottom}
            >
                {element}
            </Alert>
        );
    };

    const formatErrorText = (error: ErrorViewModel): JSX.Element => {
        const { title, code, detail, source } = error;

        return (
            <div className={classes.errorTextContainer}>
                <h4>{title}</h4>
                {detail && <p>{detail}</p>}
                <span className={classes.secondaryText}>
                    {code} {source?.pointer ? `: ${source.pointer}` : ''}
                </span>
            </div>
        );
    };

    const formatUnifiedListErrorText = (errors: ErrorViewModel[]) => {
        const { errorTitle } = props;

        return (
            <div className={classes.errorTextContainer}>
                <h4>{errorTitle}</h4>
                <ul className={classes.unifiedErrorList}>
                    {errors.map((error) => (
                        <li>
                            {error.title && <span>{error.title}</span>}{' '}
                            <span className={classes.secondaryText}>
                                {error.detail && <p>{error.detail}</p>}({error.code}{' '}
                                {error.source?.pointer ? `: ${error.source.pointer}` : ''})
                            </span>
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    if (props.errorTitle) {
        return (
            <Alert severity="error" className={classes.spacingBottom}>
                {formatUnifiedListErrorText(errors)}
            </Alert>
        );
    }

    return (
        <div className={classes.containerSpacing}>
            {errors.map((error, index) => wrapErrorText(formatErrorText(error), index))}
        </div>
    );
}
