import { useMutation } from '@tanstack/react-query';
import seatingPlanService, {
    type CreateSeatingplanCategoryDto,
} from 'src/data/services/seatingPlanService';

type TData = Awaited<ReturnType<typeof seatingPlanService.createSeatingPlanCategory>>;
type TVariables = {
    seatingPlanId: string;
    dto: CreateSeatingplanCategoryDto;
};

export const useCreateSeatingPlanCategory = (options: {
    onSuccess?: (data: TData, variables: TVariables, context: unknown) => unknown;
}) => {
    return useMutation({
        mutationFn: ({ seatingPlanId, dto }: TVariables) =>
            seatingPlanService.createSeatingPlanCategory(seatingPlanId, dto),
        ...options,
    });
};
