export enum PAYMENT_METHODS {
    Amex = 'Amex',
    ABN = 'ABN',
    Enett = 'Enett',
    Ixaris = 'Ixaris',
    AirPlus = 'AirPlus',
    Moss = 'Moss',
    Invoice = 'Invoice',
    Paypal = 'Paypal',
}
