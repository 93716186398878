import axios from 'axios';
import { useEffect, useState } from 'react';
import { type UseFormReset } from 'react-hook-form';
import { CreateVenueFormValues } from 'src/app/components/forms/CreateVenueForm';
import { config } from 'src/app/constants/config/config';
import { useDebounce } from 'src/app/utilities/hooks/use-debounce';

interface GeocodingDto {
    address: string;
    city: string;
    postalCode: string;
    country: string | undefined;
}

type AddressComponent =
    | 'postal_code'
    | 'route'
    | 'street_number'
    | 'locality'
    | 'administrative_area_level_1'
    | 'country'
    | 'political';

type AddressComponents = {
    long_name: string;
    short_name: string;
    types: AddressComponent[];
}[];

interface GoogleMapResponse {
    results: {
        address_components: AddressComponents;
        formatted_address: string;
        geometry: {
            location: {
                lat: number;
                lng: number;
            };
        };
    }[];
    status:
        | 'OK'
        | 'ZERO_RESULTS'
        | 'OVER_DAILY_LIMIT'
        | 'OVER_QUERY_LIMIT'
        | 'REQUEST_DENIED'
        | 'INVALID_REQUEST'
        | 'UNKNOWN_ERROR';
}

/** Watch the changes of geo data and update them with Google Map API accordingly. */
export function useUpdateGeocoding(
    values: GeocodingDto | undefined,
    reset: UseFormReset<CreateVenueFormValues>
) {
    // Prevent sending a request per keystroke.
    const address = useDebounce<string | undefined>(values?.address, 500);
    const city = useDebounce<string | undefined>(values?.city, 500);
    const postalCode = useDebounce<string | undefined>(values?.postalCode, 500);
    const country = useDebounce<string | undefined>(values?.country, 500);

    const [nonMatchingFields, setNonMatchingFields] = useState<string[]>([]);

    useEffect(() => {
        if (!address || !city || !postalCode) return;

        const controller = new AbortController();

        const onUpdateAddress = async () => {
            const url = getGeocodingUrl(address, city, postalCode);
            try {
                const response = await axios.get<GoogleMapResponse>(url, {
                    signal: controller.signal,
                });

                if (response.status < 400) {
                    // If the given data leads to incorrect outcome,
                    // It should clean up the form.
                    const longitude = response.data.results[0]?.geometry.location.lng;
                    const latitude = response.data.results[0]?.geometry.location.lat;

                    const comparisonResult = getNonMatchingAddressFields(response.data.results[0]?.address_components, {
                        address,
                        city,
                        postalCode,
                        country
                    });

                    setNonMatchingFields(comparisonResult);

                    reset((currentValue) => {
                        return {
                            ...currentValue,
                            latitude,
                            longitude,
                        };
                    });
                }
            } catch (error) {
                // Abort operation.
            }
        };

        onUpdateAddress();

        return () => {
            // Cancel the previous request.
            controller.abort();
        };
    }, [address, city, postalCode, country]);

    return nonMatchingFields
}

function getGeocodingUrl(...args: string[]) {
    return (
        config.GOOGLE.GEOCODING_URL +
        '/geocode/json?address=' +
        args.join('+') +
        '&key=' +
        config.GOOGLE.API_KEY
    );
}

function getNonMatchingAddressFields(
    addressComponents: AddressComponents,
    geoCodingDto: GeocodingDto
): string[] {
    const { country, postalCode, address, city } = geoCodingDto;
    const nonMatchingFields: string[] = [];

    // Create a hash map for fast lookup of address components
    const addressComponentsMap = new Map<string, string>();
    addressComponents.forEach(component => {
        component.types.forEach(type => {
            addressComponentsMap.set(type, component.long_name?.replace(/\s/g, '').toLowerCase() || '');
        });
    });

    // Function to check if the component with specified type and name exists
    const hasComponent = (type: AddressComponent, name: string | undefined) =>
        addressComponentsMap.get(type) === name?.replace(/\s/g, '').toLowerCase();

    // Check country
    if (!hasComponent('country', country)) {
        nonMatchingFields.push('Country');
    }

    // Check postal code
    if (!hasComponent('postal_code', postalCode)) {
        nonMatchingFields.push('Postal code');
    }

    // Check street and street number
    const streetName = addressComponentsMap.get('route');
    const streetNumber = addressComponentsMap.get('street_number');
    if (!streetName || !streetNumber || address.replace(/\s/g, '').toLowerCase() !== `${streetName}${streetNumber}`) {
        nonMatchingFields.push('Address');
    }

    // Check city
    if (!hasComponent('locality', city)) {
        nonMatchingFields.push('City');
    }

    return nonMatchingFields;
}
