import { zodResolver } from '@hookform/resolvers/zod';
import Grid from '@material-ui/core/Grid';
import { Alert, Box, CircularProgress, Typography } from '@mui/material';
import moment, { Moment } from 'moment';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useCustomConfirm } from 'src/app/hooks/useCustomConfirm';
import { useGetEventById } from 'src/app/hooks/useGetEventById';
import { zodRequiredStringSchema } from 'src/app/utilities/zod/zodRequiredStringSchema';
import { EventDetails } from 'src/data/models/events/event';
import {
    BookingOptionFormFields,
    type BookingOptionType,
} from 'src/data/services/booking-options-service';
import { dateFormat } from 'src/shared/date';
import Checkbox from 'src/view/components/checkbox/Checkbox';
import Chip from 'src/view/components/chip/Chip';
import DateTimePickerMui from 'src/view/components/date-picker/DateTimePickerMui';
import FormButtons from 'src/view/components/form/FormButtons';
import FormFieldError from 'src/view/components/form/FormFieldError';
import { FormLabel } from 'src/view/components/form/FormLabel';
import Input from 'src/view/components/input/Input';
import Modal from 'src/view/components/modal/Modal';
import { z } from 'zod';
import { BookingOption } from './booking-option';
import useCreateBookingOption from './hooks/use-create-booking-option';
import useFetchEventBookingOptions from './hooks/use-fetch-event-booking-options';
import useUpdateBookingOption from './hooks/use-update-booking-option';

type BookingOptionModalProps = {
    eventId: string;
    bookingOption?: BookingOptionType;
    isOpen: boolean;
    onClose: () => void;
    edit?: boolean;
};

const momentSchema = z
    .custom<moment.Moment>((value) => moment.isMoment(value), {
        message: 'Invalid Moment.js date',
    })
    .or(z.string().refine((value) => moment(value).isValid(), { message: 'Invalid date' }));

const formSchema = z.object({
    startDate: momentSchema,
    endDate: z.nullable(momentSchema).optional(),
    name: zodRequiredStringSchema,
    isDefault: z.boolean().optional(),
});

type BookingOptionsFormSchema = z.infer<typeof formSchema>;

export const BookingOptionModal = ({
    bookingOption,
    onClose,
    isOpen,
    eventId,
}: BookingOptionModalProps) => {
    const wasDefaultOption = bookingOption?.isDefault;
    const { eventEndDate, eventStartDate, eventName, event } = useEventInfo(eventId);

    const { mutate: createBookingOption, isLoading: isCreating } = useCreateBookingOption(onClose);

    const { mutate: updateBookingOption, isLoading: isUpdating } = useUpdateBookingOption(onClose);

    const {
        control,
        watch,
        handleSubmit,
        setValue,
        formState: { isDirty },
    } = useForm<BookingOptionsFormSchema>({
        mode: 'all',
        defaultValues: bookingOption,
        resolver: zodResolver(formSchema),
    });

    const confirm = useCustomConfirm();

    useEffect(() => {
        if (eventStartDate && !bookingOption) {
            setValue('startDate', moment(eventStartDate).utc());
        }
    }, [eventStartDate]);

    const startDate = watch('startDate');
    const endDate = watch('endDate');

    const onFormSubmit = (data: BookingOptionsFormSchema) => {
        const bookingOptionDto: BookingOptionFormFields = {
            name: data.name,
            isDefault: data.isDefault || false,
            startDate: moment(data.startDate).utc(),
            endDate: data.endDate ? moment(data.endDate).utc() : null,
        };

        if (bookingOption) {
            updateBookingOption({
                id: bookingOption.id,
                bookingOptionData: bookingOptionDto,
            });

            return;
        }

        createBookingOption({
            eventId,
            bookingOptionData: [bookingOptionDto],
        });
    };

    useEffect(() => {
        // Clear the end date if it is before the start date
        if (startDate && endDate && moment(startDate).isAfter(endDate)) {
            setValue('endDate', null);
        }
    }, [startDate, endDate]);

    const { minDate, maxDate, getMinTime, getMaxtime } = useMinMaxDates(event);

    if (!event) return <p>No event was found.</p>;

    return (
        <Modal
            onClose={async () => {
                if (isDirty) {
                    const confirmed = await confirm({
                        title: 'Are you sure you want to close?',
                    });

                    if (!confirmed) {
                        return;
                    }
                }
                onClose();
            }}
            open={isOpen}
            title={eventName}
        >
            <Box>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Box display="flex" gap={1} alignItems={'center'} marginBottom={3}>
                            <Typography>Event start/end dates:</Typography>
                            <Chip label={moment(eventStartDate).utc().format(dateFormat)} />
                            {eventEndDate && (
                                <>
                                    -
                                    <Chip label={moment(eventEndDate).utc().format(dateFormat)} />
                                </>
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <FormLabel>Name*</FormLabel>
                        <Controller
                            name="name"
                            control={control}
                            render={({
                                field: { name, onChange, value },
                                fieldState: { error },
                            }) => {
                                return (
                                    <>
                                        <Input name={name} onChange={onChange} value={value} />
                                        <FormFieldError message={error?.message} />
                                    </>
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormLabel>Start Date*</FormLabel>
                        <Controller
                            name="startDate"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <>
                                    <DateTimePickerMui
                                        value={moment(value)}
                                        onChange={(e) => {
                                            onChange(e);
                                        }}
                                        minDate={minDate}
                                        maxDate={maxDate}
                                        minTime={getMinTime?.(value)}
                                        maxTime={getMaxtime?.(value)}
                                    />
                                    <FormFieldError message={error?.message} />
                                </>
                            )}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <FormLabel>End Date</FormLabel>
                        <Controller
                            name="endDate"
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <>
                                    <DateTimePickerMui
                                        disabled={!startDate}
                                        value={value ? moment(value) : null}
                                        onChange={onChange}
                                        onOpen={() => {
                                            // If the end date is not set, set it to the start date on opening the date picker
                                            // This will show the start date as the minimum date
                                            if (!value) {
                                                setValue(
                                                    'endDate',
                                                    moment(startDate || eventStartDate).utc()
                                                );
                                            }
                                        }}
                                        minDate={minDate}
                                        maxDate={maxDate}
                                        minTime={getMinTime?.(value, startDate)}
                                        maxTime={getMaxtime?.(value)}
                                    />
                                    {value && (
                                        <Typography
                                            variant="body2"
                                            onClick={() => {
                                                setValue('endDate', null);
                                            }}
                                            sx={{
                                                textDecoration: 'underline',
                                                cursor: 'pointer',
                                                textAlign: 'right',
                                            }}
                                        >
                                            Clear
                                        </Typography>
                                    )}

                                    <FormFieldError message={error?.message} />
                                </>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="isDefault"
                            control={control}
                            render={({ field: { name, value } }) => {
                                return (
                                    <>
                                        <FormLabel>
                                            <Typography
                                                display="inline-flex"
                                                variant="body2"
                                                fontWeight="bold"
                                            >
                                                Make this option default:
                                            </Typography>
                                            <Box display="inline-flex" marginRight="auto">
                                                <Checkbox
                                                    color="primary"
                                                    name={name}
                                                    checked={value || false}
                                                    onChange={async (e) => {
                                                        // If it is going to be set as default, show a confirmation dialog
                                                        if (e.target.checked) {
                                                            const hasAccepted = await confirm({
                                                                title: 'Are you sure you want to set this as default?',
                                                                description: (
                                                                    <OverrideDefaultBookingOptionModal
                                                                        eventId={eventId}
                                                                    />
                                                                ),
                                                                confirmationButtonProps: {
                                                                    color: 'warning',
                                                                },
                                                            });
                                                            if (hasAccepted) {
                                                                setValue('isDefault', true);
                                                            }
                                                            return;
                                                        }
                                                        // Set back to false if the user unchecks/declines
                                                        setValue('isDefault', false);
                                                    }}
                                                    disabled={wasDefaultOption}
                                                />
                                            </Box>
                                        </FormLabel>
                                        {wasDefaultOption && (
                                            <span>
                                                This Booking Option is set as default, therefore,
                                                this field cannot be unchecked.
                                            </span>
                                        )}
                                    </>
                                );
                            }}
                        />
                    </Grid>
                </Grid>

                <FormButtons
                    buttons={[
                        {
                            children: bookingOption ? 'Save' : 'Create',
                            startIcon: (isCreating || isUpdating) && <CircularProgress size={14} />,
                            disabled: isCreating || isUpdating,
                            onClick: () => handleSubmit(onFormSubmit)(),
                        },
                    ]}
                />
            </Box>
        </Modal>
    );
};

const OverrideDefaultBookingOptionModal = ({ eventId }: { eventId: string }) => {
    const { defaultBookingOption } = useGetDefaultBookingOption(eventId);
    return (
        <div>
            <Alert
                severity="warning"
                sx={{
                    marginBottom: 2,
                }}
            >
                <Typography>
                    This will override the current default option. This action is reversible, but
                    has consequences.
                </Typography>
            </Alert>
            {defaultBookingOption && <BookingOption option={defaultBookingOption} readonly />}
        </div>
    );
};

const useEventInfo = (eventId: string) => {
    const { data: eventData } = useGetEventById(eventId);
    const event = eventData?.data?.data;
    const eventStartDate = event?.dateTimeStart;
    const eventEndDate = event?.dateTimeEnd;
    const eventName = event?.name;
    return { event, eventStartDate, eventEndDate, eventName };
};

const useGetDefaultBookingOption = (eventId: string) => {
    const { data, isLoading, isError } = useFetchEventBookingOptions(eventId);
    const bookingOptions = data?.data?.data;
    const defaultBookingOption = bookingOptions?.find((option) => option.isDefault);
    return { defaultBookingOption, isLoading, isError };
};

/**
 * Custom hook that calculates the minimum and maximum dates for event booking options.
 *
 * @param event - The event details.
 * @returns An object containing the minimum date, maximum date, and helper functions to get the minimum and maximum times (hours and minutes).
 */
const useMinMaxDates = (event: EventDetails | undefined) => {
    if (!event) return {};

    const { dateTimeStart: eventStartDate, dateTimeEnd: eventEndDate } = event;

    // Minimum date is the event start date
    const minDate = moment(eventStartDate).utc();

    // Maximum date is the event end date if it exists, otherwise within the same day as the event start date
    const maxDate = eventEndDate
        ? moment(eventEndDate).utc()
        : moment(eventStartDate).utc().endOf('day');

    /** Minimum time is the event start time if the selected date is the same as the event start date
     * If "afterSpecificTime" is provided, it will be used as the minimum time
     * This is uesful when the minimum time should be after a specific time, e.g. AFTER the event start time
     *
     * Otherwise, the minimum time is the start of the day
     */
    const getMinTime = (
        selectedDate: Moment | string | undefined | null,
        afterSpecificTime?: Moment | string | undefined | null
    ) => {
        return moment(selectedDate).isSame(moment(eventStartDate), 'day')
            ? moment(afterSpecificTime || eventStartDate).utc()
            : moment().startOf('day');
    };

    /** By default allow until the END DATE of the event
     * If event doesn't have END DATE, allow within the same day as the event start date */
    const getMaxtime = (selectedDate: Moment | undefined | string | null) =>
        eventEndDate && moment(selectedDate).isSame(moment(eventEndDate), 'day')
            ? moment(eventEndDate).utc()
            : moment().endOf('day');

    return { minDate, maxDate, getMinTime, getMaxtime };
};
