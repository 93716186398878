import ErrorsList from 'src/view/components/errors-list/ErrorsList';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';
import { useSnackbar } from 'notistack';
import FormButtons from 'src/view/components/form/FormButtons';
import orderService from 'src/data/services/orderService';
import { useMutation } from '@tanstack/react-query';
import { parseErrors } from 'src/app/utilities/helpers/errors';

interface Props {
    eventId: string;
    orderIds: string[];
    onSucceed: () => void;
    manual?: boolean;
}

export default function SendEventOrdersActionFeature({
    eventId,
    orderIds,
    onSucceed,
    manual,
}: Props): JSX.Element {
    const { enqueueSnackbar } = useSnackbar();

    const {
        mutate: sendOrder,
        isLoading: isSendingOrder,
        error: sendOrderErrors,
    } = useMutation({
        mutationFn: async () => orderService.sendTicketsForOrder(eventId, orderIds),
        onSuccess: () => {
            enqueueSnackbar('Order(s) sent successfully', { variant: 'success' });
            onSucceed();
        },
        onError: () => {
            enqueueSnackbar('Sending order(s) failed', { variant: 'error' });
        },
    });

    const {
        mutate: sendManualOrder,
        isLoading: isSendingManualOrder,
        error: sendOrderManuallyErrors,
    } = useMutation({
        mutationFn: async () => orderService.markTicketsAsManuallySent(eventId, orderIds),
        onSuccess: () => {
            enqueueSnackbar('Order(s) marked as manually sent', { variant: 'success' });
            onSucceed();
        },
        onError: () => {
            enqueueSnackbar('Marking orders as manually sent failed', { variant: 'error' });
        },
    });

    const handleSendingOrder = async (isManualSending = false) => {
        isManualSending ? sendManualOrder() : sendOrder();
    };

    const loading = isSendingOrder || isSendingManualOrder;
    const errors = sendOrderErrors || sendOrderManuallyErrors;

    return (
        <>
            {loading && <LoadingOverlay />}
            {errors && <ErrorsList errors={parseErrors(errors)} />}

            <FormButtons
                buttons={[
                    {
                        children: `Send order(s) ${manual ? 'manually' : ''}`,
                        disabled: !!errors,
                        onClick: () => handleSendingOrder(manual),
                    },
                ]}
            />
        </>
    );
}
