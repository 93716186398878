import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Control, Controller, useFieldArray } from 'react-hook-form';
import { SeatingPlanCategoriesAutocompleteFeature } from 'src/app/components/features/seating-plan-categories/SeatingPlanCategoriesAutocompleteFeature';
import { CreatePurchaseFormValues } from 'src/app/components/forms/purchase/CreatePurchaseForm';
import calculatedExchangeRate from 'src/app/utilities/helpers/calculatedExchangeRate';
import { defaultCurrency } from 'src/shared/currencies';
import { useGenericStyles } from 'src/shared/styles/genericStyles';
import Button from 'src/view/components/button/Button';
import FormFieldError from 'src/view/components/form/FormFieldError';
import { FormLabel } from 'src/view/components/form/FormLabel';
import Input from 'src/view/components/input/Input';
import { BookingOptionsAutoComplete } from './BookingOptionsAutoComplete';

const useStyles = makeStyles((theme) => ({
    remove: {
        fontWeight: 'bold',
        marginTop: theme.spacing(0.5),
        color: theme.palette.error.main,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    link: {
        fontWeight: 'bold',
        textDecoration: 'underline',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    addMore: {
        color: theme.colors.linkBlue,
    },
    delete: {
        marginLeft: theme.spacing(4),
        color: theme.palette.error.main,
    },
    categoryValue: {
        wordBreak: 'break-all',
    },
}));

interface AddPurchaseTicketsFormProps {
    eventIndex: number;
    eventId: string;
    control: Control<CreatePurchaseFormValues>;
    onClickDeleteEvent?: () => void;
    currency: string;
    exchangeRate?: string | number;
}

const defaultEventTicketValues = {
    quantity: 0,
    seatingplanCategory: null,
    bookingOption: null,
    pricePerTicket: '',
    ticketsPerSplit: undefined,
};

export const AddPurchaseTicketsForm = ({
    eventIndex,
    eventId,
    control,
    exchangeRate,
    currency = defaultCurrency,
    onClickDeleteEvent,
}: AddPurchaseTicketsFormProps) => {
    const classes = useStyles();
    const genericClasses = useGenericStyles();
    const [currentlyEditedCategory, setCurrentlyEditedCategory] = useState<string | null>(null);
    const { fields, append, remove } = useFieldArray({
        control,
        name: `events.${eventIndex}.ticketInfo`,
    });

    useEffect(() => {
        if (fields.length !== 0) return;

        append(defaultEventTicketValues);
    }, []);

    return (
        <Grid container spacing={2}>
            <Grid item xs={2}>
                <FormLabel>Quantity*</FormLabel>
            </Grid>
            <Grid item xs={2}>
                <FormLabel>Category*</FormLabel>
            </Grid>
            <Grid item xs={2}>
                <FormLabel>Booking Option*</FormLabel>
            </Grid>
            <Grid item xs={2}>
                <FormLabel>
                    {currency === defaultCurrency ? 'Price*' : `Price (${currency})*`}
                </FormLabel>
            </Grid>
            <Grid item xs={2}>
                <FormLabel>Tickets per split</FormLabel>
            </Grid>
            <Grid item xs={2}></Grid>
            {fields.map((ticketInfo, ticketIndex) => (
                <React.Fragment key={ticketInfo.id}>
                    <Grid item xs={2}>
                        <Controller
                            name={`events.${eventIndex}.ticketInfo.${ticketIndex}.quantity`}
                            control={control}
                            render={({
                                field: { name, value, onChange },
                                fieldState: { error },
                            }) => (
                                <>
                                    <Input
                                        name={name}
                                        type="number"
                                        onChange={onChange}
                                        value={value}
                                        placeholder="Quantity"
                                    />
                                    <FormFieldError message={error?.message} />
                                </>
                            )}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Controller
                            name={`events.${eventIndex}.ticketInfo.${ticketIndex}.seatingplanCategory`}
                            control={control}
                            render={({
                                field: { value, onChange, name },
                                fieldState: { error },
                            }) => {
                                /** If the dropdown doesn't have any value, it by default means 'editing' mode */
                                const isEditingSeatingPlanCategory =
                                    currentlyEditedCategory === name || !value;
                                return (
                                    <>
                                        {isEditingSeatingPlanCategory ? (
                                            <>
                                                <SeatingPlanCategoriesAutocompleteFeature
                                                    name={name}
                                                    eventId={eventId}
                                                    onChange={(value) => {
                                                        if (value) setCurrentlyEditedCategory(null);

                                                        onChange(value);
                                                    }}
                                                    value={value}
                                                    enableLabelTooltip
                                                    hideArchivedSpcs
                                                    placeholder="Select a seating plan category"
                                                />
                                                <FormFieldError message={error?.message} />
                                            </>
                                        ) : (
                                            <div className={classes.categoryValue}>
                                                {value?.label} -{' '}
                                                <span
                                                    className={genericClasses.link}
                                                    onClick={() => setCurrentlyEditedCategory(name)}
                                                >
                                                    Change
                                                </span>
                                            </div>
                                        )}
                                    </>
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Controller
                            name={`events.${eventIndex}.ticketInfo.${ticketIndex}.bookingOption`}
                            control={control}
                            render={({
                                field: { value, onChange, name },
                                fieldState: { error },
                            }) => {
                                return (
                                    <>
                                        <BookingOptionsAutoComplete
                                            value={value}
                                            onChange={onChange}
                                            name={name}
                                            eventId={eventId}
                                        />
                                        <FormFieldError message={error?.message} />
                                    </>
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Controller
                            name={`events.${eventIndex}.ticketInfo.${ticketIndex}.pricePerTicket`}
                            control={control}
                            render={({
                                field: { name, value, onChange },
                                fieldState: { error },
                            }) => (
                                <>
                                    <Input
                                        name={name}
                                        onChange={onChange}
                                        value={value}
                                        placeholder="Price"
                                        type="number"
                                        greyInputHelperText={
                                            currency !== defaultCurrency
                                                ? `${calculatedExchangeRate(
                                                      value || 0,
                                                      exchangeRate || 1
                                                  )} ${defaultCurrency}`
                                                : ''
                                        }
                                    />
                                    <FormFieldError message={error?.message} />
                                </>
                            )}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Controller
                            name={`events.${eventIndex}.ticketInfo.${ticketIndex}.ticketsPerSplit`}
                            control={control}
                            render={({
                                field: { name, value, onChange },
                                fieldState: { error },
                            }) => (
                                <>
                                    <Input
                                        name={name}
                                        type="number"
                                        onChange={onChange}
                                        value={value}
                                        placeholder="Tickets per split"
                                    />
                                    <FormFieldError message={error?.message} />
                                </>
                            )}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            variant="text"
                            className={classes.remove}
                            onClick={() => remove(ticketIndex)}
                        >
                            Remove Tickets
                        </Button>
                    </Grid>
                </React.Fragment>
            ))}
            <Grid item xs={12} display="flex">
                <span
                    className={classnames(classes.link, classes.addMore)}
                    onClick={() => append(defaultEventTicketValues)}
                >
                    Add {fields.length > 0 ? 'More' : ''} Tickets
                </span>
                {onClickDeleteEvent && (
                    <span
                        className={classnames(classes.link, classes.delete)}
                        onClick={onClickDeleteEvent}
                    >
                        Delete Event
                    </span>
                )}
            </Grid>
        </Grid>
    );
};
