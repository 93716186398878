import { useEffect } from 'react';
import useApiFetch, { ErrorReturn } from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import Seat from 'src/data/models/seat/seat';
import seatService from 'src/data/services/seatService';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';

interface FetchSeatsDataWrapperChildrenParams
    extends DataWrapperChildrenParams<ApiResponseBody<Seat[]>> {
    fetchSeats: (eventId: string) => Promise<void | ErrorReturn>;
}

interface FetchSeatsDataWrapperProps
    extends DataWrapperProps<FetchSeatsDataWrapperChildrenParams>,
        BaseFetchOptions {
    eventId: string;
}

export const useFetchSeatsDataWrapper = ({
    includes = [],
    onError,
    onSuccess,
    invalidatedAt,
    eventId,
    page,
    pageSize,
    filter,
    sorting,
    q,
}: Omit<FetchSeatsDataWrapperProps, 'children'>) => {
    const [{ loading, data, errors, resetData }, handleFetchSeats] =
        useApiFetch<ApiResponseBody<Seat[]>>();

    const fetchSeatsCall = () => {
        return handleFetchSeats(
            seatService.getSeatsByEventId(eventId, {
                includes,
                page,
                pageSize,
                filter,
                sorting,
                q,
            }),
            {
                useDefaultErrorHandler: true,
                onFail: onError,
                onSuccess,
            }
        );
    };

    useEffect(() => {
        fetchSeatsCall();
    }, [page, pageSize, filter, sorting, q, invalidatedAt, eventId]);

    return {
        fetchSeats: fetchSeatsCall,
        data,
        loading,
        errors,
        resetData,
    };
};
