import { zodResolver } from '@hookform/resolvers/zod';
import { Alert, FormControlLabel, Grid, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FetchSalesProfilesDataWrapper } from 'src/app/components/data-wrappers/sales-profile/FetchSalesProfilesDataWrapper';
import { SeatingPlanCategoriesAutocompleteFeature } from 'src/app/components/features/seating-plan-categories/SeatingPlanCategoriesAutocompleteFeature';
import renderPriceInput from 'src/app/components/forms/helpers/renderPriceInput';
import { config } from 'src/app/constants/config/config';
import { ticketSalesCapCurrencyOptions } from 'src/app/utilities/mappers/mapCurrenciesToOptions';
import { zodOptionalNumberInputSchema } from 'src/app/utilities/zod/zodOptionalNumberInputSchema';
import { zodRequiredDropdownSelectSchema } from 'src/app/utilities/zod/zodRequiredDropdownSelectSchema';
import { zodRequiredNumberInputSchema } from 'src/app/utilities/zod/zodRequiredNumberInputSchema';
import { zodRequiredStringSchema } from 'src/app/utilities/zod/zodRequiredStringSchema';
import Price from 'src/data/models/common/price';
import { defaultCurrency } from 'src/shared/currencies';
import AutoComplete from 'src/view/components/auto-complete/AutoComplete';
import Checkbox from 'src/view/components/checkbox/Checkbox';
import FormButtons from 'src/view/components/form/FormButtons';
import FormFieldError from 'src/view/components/form/FormFieldError';
import Input from 'src/view/components/input/Input';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';
import ModalSubTitle from 'src/view/components/modal/ModalSubtitle';
import z from 'zod';
import useFetchEventBookingOptions from '../features/event-booking-options/hooks/use-fetch-event-booking-options';

interface PriceError {
    value: {
        message?: string;
        type: string;
    };
}

const useStyles = makeStyles((theme: Theme) => ({
    spacingRight: {
        paddingRight: theme.spacing(1),
    },
    smallFontSize: {
        '& span': {
            fontSize: theme.typography.pxToRem(14),
        },
    },
}));

const requiredNumberWithAllowedZeroInputSchema = z
    .string({ required_error: 'Please enter a number' })
    .nonempty('Please enter a number')
    .or(z.number())
    .pipe(z.coerce.number({ invalid_type_error: 'Please enter a number!' }));

const validationSchema = z.object({
    seatingPlanCategory: zodRequiredDropdownSelectSchema,
    bookingOption: zodRequiredDropdownSelectSchema,
    price: z
        .object({
            currency: zodRequiredStringSchema,
            value: zodRequiredNumberInputSchema,
        })
        .superRefine((val, ctx) => {
            if (val?.value < 0) {
                ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                    path: ['value'],
                    message: 'Please add a number',
                });
            }
        }),
    salesCap: requiredNumberWithAllowedZeroInputSchema,
    /** fixedQuantityStep is optional to be entered, but if nothing is entered then we default to 1 */
    fixedQuantityStep: zodOptionalNumberInputSchema.transform((value) => value || 1),
    salesProfiles: z.array(zodRequiredDropdownSelectSchema),
    allSalesProfiles: z.boolean().optional(),
});

export type CreateTicketSalesCapFormValues = z.infer<typeof validationSchema>;

interface Props {
    eventId: string;
    loading?: boolean;
    defaultValues?: CreateTicketSalesCapFormValues;
    editMode?: boolean;
    onFormSubmit: (values: CreateTicketSalesCapFormValues) => void;
}

export default function CreateTicketSalesCapForm({
    eventId,
    loading,
    defaultValues,
    editMode,
    onFormSubmit,
}: Props) {
    const classes = useStyles();

    const form = useForm<CreateTicketSalesCapFormValues>({
        mode: 'onChange',
        resolver: zodResolver(validationSchema),
        defaultValues: {
            price: { value: undefined, currency: defaultCurrency },
        },
    });

    const { setValue, watch, control, reset, handleSubmit } = form;

    const salesProfiles = watch('salesProfiles');
    const allSalesProfiles = watch('allSalesProfiles');

    const isAllSalesProfileInvalid =
        (allSalesProfiles === undefined && salesProfiles === undefined) ||
        (allSalesProfiles === false && salesProfiles?.length === 0);

    const isSubmitButtonDisabled = isAllSalesProfileInvalid;

    const { data: bookingOptionsData, error } = useFetchEventBookingOptions(eventId);

    const availableBookingOptions = useMemo(() => {
        const bookingOptions = bookingOptionsData?.data?.data || [];

        return bookingOptions.map(({ name, id }) => ({
            label: name,
            value: id,
        }));
    }, [bookingOptionsData]);

    const hasOnlyOneBookingOption = availableBookingOptions.length === 1;

    useEffect(() => {
        if (!defaultValues) return;

        reset(defaultValues);
    }, [defaultValues, reset]);

    useEffect(() => {
        if (editMode) return;

        // If there is only one booking option, select it by default
        if (hasOnlyOneBookingOption) {
            setValue('bookingOption', availableBookingOptions[0]);
        }
    }, [editMode, availableBookingOptions, hasOnlyOneBookingOption]);

    if (error) {
        return (
            <Alert severity="error">
                <span>
                    There was a problem while retrieving booking options. This makes creating a
                    ticket sales cap impossible. Please try again in a few minutes.
                </span>
            </Alert>
        );
    }

    return (
        <>
            {loading && <LoadingOverlay />}
            <Grid container>
                <Grid item xs={12}>
                    <ModalSubTitle>Seating Plan Category*</ModalSubTitle>
                    <Controller
                        name="seatingPlanCategory"
                        control={control}
                        render={({ field: { onChange, value, name }, fieldState: { error } }) => (
                            <>
                                <SeatingPlanCategoriesAutocompleteFeature
                                    name={name}
                                    eventId={eventId}
                                    value={value}
                                    onChange={onChange}
                                    placeholder="Search for a seatingplancategory"
                                    disabled={editMode}
                                    hideArchivedSpcs
                                    enableCreation
                                />
                                <FormFieldError message={error?.message} />
                            </>
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ModalSubTitle>Booking option*</ModalSubTitle>
                    <Controller
                        name="bookingOption"
                        control={control}
                        render={({ field: { onChange, value, name } }) => (
                            <>
                                {hasOnlyOneBookingOption && (
                                    <Typography variant="body2">
                                        This event has only one booking option. Selected by default.
                                    </Typography>
                                )}
                                <AutoComplete
                                    value={value}
                                    disabled={editMode || hasOnlyOneBookingOption}
                                    options={availableBookingOptions}
                                    onChange={onChange}
                                    name={name}
                                />
                            </>
                        )}
                    />
                </Grid>

                <Grid item xs={12}>
                    <ModalSubTitle>Retail Price*</ModalSubTitle>
                    <Controller
                        name="price"
                        control={control}
                        render={({ field: { value, onChange }, fieldState: { error } }) => {
                            const typedError = error as PriceError | undefined;

                            return (
                                <div data-cy="retail-price">
                                    {renderPriceInput({
                                        price: value,
                                        onChange: (price: Price) => onChange(price),
                                        valueError: typedError?.value?.message,
                                        alignCurrencyLeft: true,
                                        currencyOptions: ticketSalesCapCurrencyOptions,
                                    })}
                                </div>
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={6} className={classes.spacingRight}>
                    <ModalSubTitle>Sales Cap*</ModalSubTitle>
                    <Controller
                        name="salesCap"
                        control={control}
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <>
                                <Input
                                    type="number"
                                    name={name}
                                    value={value}
                                    onChange={onChange}
                                    placeholder="Sales Cap"
                                    data-cy="sales-cap"
                                />
                                <FormFieldError message={error?.message} />
                            </>
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <ModalSubTitle>Fixed Quantity Step</ModalSubTitle>
                    <Controller
                        name="fixedQuantityStep"
                        control={control}
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <>
                                <Input
                                    type="number"
                                    name={name}
                                    value={value}
                                    onChange={onChange}
                                    placeholder="Defaults to 1"
                                    data-cy="splits-count"
                                />
                                <FormFieldError message={error?.message} />
                            </>
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ModalSubTitle>Sales profiles</ModalSubTitle>
                    <FormControlLabel
                        className={classes.smallFontSize}
                        data-cy="sales-profiles"
                        control={
                            <Checkbox
                                checked={allSalesProfiles || false}
                                onChange={(_, checked: boolean) => {
                                    setValue('allSalesProfiles', checked, {
                                        shouldValidate: true,
                                    });

                                    if (!checked) return;

                                    setValue('salesProfiles', [], { shouldValidate: true });
                                }}
                            />
                        }
                        label="For all sales profiles"
                    />

                    <Controller
                        name="salesProfiles"
                        control={control}
                        render={({ field: { onChange, value, name } }) => (
                            <FetchSalesProfilesDataWrapper pageSize={config.ITEMS_PER_PAGE_LARGE}>
                                {({ data }) => (
                                    <AutoComplete
                                        value={value}
                                        options={
                                            data?.data.map((p) => ({
                                                label: p.name,
                                                value: p.id,
                                            })) || []
                                        }
                                        onChange={onChange}
                                        name={name}
                                        isClearable={false}
                                        isMulti
                                        disabled={allSalesProfiles}
                                        data-cy={`sales-profiles-${value}`}
                                    />
                                )}
                            </FetchSalesProfilesDataWrapper>
                        )}
                    />
                </Grid>
            </Grid>
            <FormButtons
                buttons={[
                    {
                        children: editMode ? 'Update Ticket Sales Cap' : 'Create Ticket Sales Cap',
                        onClick: handleSubmit(onFormSubmit),
                        disabled: isSubmitButtonDisabled,
                        dataCy: 'update-or-create-tickets-sales-cap-button',
                    },
                ]}
            />
        </>
    );
}
