import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Button from 'src/view/components/button/Button';
import classNames from 'classnames';
import SplitButton, { SplitButtonOption } from 'src/view/components/button/SplitButton';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';
import { useSpacingStyles } from 'src/shared/styles/spacingStyles';
import ButtonWithPopover from 'src/view/components/button/ButtonWithPopover';
import { ThemeColorType } from 'src/shared/theme/color';

const useStyles = makeStyles((theme: Theme) => ({
    checkboxToolbar: {
        background: theme.colors.white,
        minHeight: '50px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '0 16px',
        overflow: 'auto',
    },
    spacingLeft: {
        marginLeft: theme.spacing(2),
    },
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiButton-root': {
            flexShrink: 0,
        },
    },
    flexContainerRight: {
        marginLeft: 'auto',
    },
    groupedButtons: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        padding: theme.spacing(1),

        '& .MuiButton-root': {
            flexShrink: 0,
        },
    },
    itemsSelected: {
        flexShrink: 0,
        marginRight: theme.spacing(2),
    },
    toolbarContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexShrink: 0,
        flexGrow: 1,
    },
}));

type PositionType = 'left' | 'right';

export interface TableAction {
    label?: string;
    callback?: () => void;
    disabled?: boolean;
    position?: PositionType;
    color?: ThemeColorType;
    disabledButtonReasons?: string[];
    hidden?: boolean;
}

export interface TableSplitAction extends Omit<TableAction, 'label | callback'> {
    options: {
        label: string;
        callback: () => void;
        disabled?: boolean;
    }[];
}

export interface TableToolbarProps {
    loading?: boolean;
    selectedRows: number;
    actions?: TableAction[];
    splitActions?: TableSplitAction[];
    hidden?: boolean;
    className?: string;
}

export default function TableToolbar({
    loading,
    selectedRows,
    actions = [],
    splitActions = [],
    hidden,
    className,
}: TableToolbarProps): JSX.Element {
    const classes = useStyles();
    const spacingClasses = useSpacingStyles();

    const shownActions = actions.filter((action) => !action.hidden);

    actions?.forEach((action) => {
        if (!action.position) {
            action.position = 'left';
        }
    });

    splitActions?.forEach((splitAction) => {
        if (!splitAction.position) {
            splitAction.position = 'left';
        }
    });

    if (hidden) return <></>;

    const mapSplitOptions = (action: TableSplitAction): SplitButtonOption[] => {
        return action.options.map((o) => ({
            label: o.label,
            onClick: o.callback,
            disabled: o.disabled,
        }));
    };

    type TableActionButtons = TableAction[] | TableSplitAction[];

    const getButtons = (
        actions: TableActionButtons,
        position: PositionType = 'left'
    ): TableActionButtons => actions.filter((a) => a.position === position);

    const hasButtonsOnPosition = (actions: TableActionButtons, position: PositionType = 'left') =>
        getButtons(actions, position).length > 0;

    return (
        <div className={classNames(classes.checkboxToolbar, className)}>
            {loading && <LoadingOverlay />}
            <div className={classes.itemsSelected}>
                <b>{selectedRows}</b> Items selected
            </div>

            <div className={classes.toolbarContainer}>
                {hasButtonsOnPosition(shownActions, 'left') && (
                    <div className={classes.groupedButtons}>
                        {getButtons(shownActions, 'left').map((action) => (
                            <ButtonWithPopover
                                variant="contained"
                                onClick={action.callback}
                                disabled={action.disabled}
                                key={action.label}
                                color={action.color}
                                popoverTitle="Reason(s): "
                                popoverText={action.disabledButtonReasons}
                            >
                                {action.label}
                            </ButtonWithPopover>
                        ))}
                    </div>
                )}

                {hasButtonsOnPosition(splitActions, 'left') && (
                    <div className={classes.flexContainer}>
                        {getButtons(splitActions, 'left').map((action, index) => (
                            <SplitButton
                                key={`button-${index}`}
                                options={mapSplitOptions(action as TableSplitAction)}
                                className={spacingClasses.spacingRight}
                                disabled={action.disabled}
                            />
                        ))}
                    </div>
                )}

                {hasButtonsOnPosition(shownActions, 'right') && (
                    <div className={classNames(classes.flexContainer, classes.flexContainerRight)}>
                        {getButtons(shownActions, 'right').map((action) => (
                            <Button
                                key={`action-${action.label}`}
                                variant="contained"
                                onClick={action.callback}
                                className={spacingClasses.spacingLeft}
                                disabled={action.disabled}
                                color={action.color}
                            >
                                {action.label}
                            </Button>
                        ))}
                    </div>
                )}

                {hasButtonsOnPosition(splitActions, 'right') && (
                    <div className={classNames(classes.flexContainer, classes.flexContainerRight)}>
                        {getButtons(splitActions, 'right').map((action, index) => (
                            <SplitButton
                                key={`button-${index}`}
                                options={mapSplitOptions(action as TableSplitAction)}
                                className={spacingClasses.spacingLeft}
                                disabled={action.disabled}
                            />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
}
