import { useEffect, useState } from 'react';
import { SeatingPlansPreview } from 'src/app/components/previews/venues/seating-plans-preview';
import { useFetchSeatingPlansByVenueId } from 'src/app/hooks/seating-plans/useFetchSeatingPlansByVenueId';
import { parseErrors } from 'src/app/utilities/helpers/errors';
import ErrorsList from 'src/view/components/errors-list/ErrorsList';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';
import { SeatingPlanCategoriesList } from '../../features/venues/SeatingPlanCategoriesList';

export const SeatingPlansList = ({ venueId }: { venueId: string }) => {
    const { data: response, isLoading, isError, error } = useFetchSeatingPlansByVenueId(venueId);

    const [selectedSeatingPlanId, setSelectedSeatingPlanId] = useState<string | undefined>();

    useEffect(() => {
        if (!response) return;
        const seatingPlansCount = response.data.data.length;

        if (seatingPlansCount === 1 || !selectedSeatingPlanId) {
            setSelectedSeatingPlanId(response.data.data[0]?.id);
        } else if (seatingPlansCount === 0) {
            setSelectedSeatingPlanId(undefined);
        }
    }, [response, selectedSeatingPlanId]);

    if (isLoading) {
        return <LoadingOverlay />;
    }

    if (isError) {
        return <ErrorsList errors={parseErrors(error)} />;
    }

    return (
        <>
            <SeatingPlansPreview
                seatingPlans={response?.data?.data || []}
                venueId={venueId}
                activeSeatingPlanId={selectedSeatingPlanId}
                onClickItem={setSelectedSeatingPlanId}
            />

            {selectedSeatingPlanId && (
                <SeatingPlanCategoriesList seatingPlanId={selectedSeatingPlanId} />
            )}
        </>
    );
};
