import QueryParam from 'src/data/api/common/QueryParam';
import SortingOption from 'src/data/api/common/SortingOption';
import FilterOption from 'src/data/api/common/FilterOption';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';

export function getQueryParams(
    options: BaseFetchOptions,
    extraQueryParams: QueryParam[] = []
): { [key: string]: string } {
    const {
        sorting: sortingOptions,
        filter: filteringOptions,
        includes: includeOptions,
        page,
        pageSize,
    } = options;

    const queryParams: QueryParam[] = [];

    if (sortingOptions && sortingOptions.length > 0) {
        queryParams.push(sortingOptionsToQueryParam(sortingOptions));
    }

    if (filteringOptions && filteringOptions.length > 0) {
        filteringOptions.forEach((fo) => {
            queryParams.push(filterOptionToQueryParam(fo));
        });
    }

    if (includeOptions && includeOptions.length > 0) {
        queryParams.push(includesToQueryParam(includeOptions));
    }

    if (page) {
        queryParams.push(pageToQueryParam(page));
    }

    if (pageSize) {
        queryParams.push(pageSizeToQueryParam(pageSize));
    }

    if (extraQueryParams && extraQueryParams.length > 0) {
        queryParams.push(...extraQueryParams);
    }

    const paramsObject: { [key: string]: string } = {};

    queryParams.forEach((qP) => {
        paramsObject[qP.key] = qP.value;
    });

    return paramsObject;
}

export function sortingOptionsToQueryParam(sortingOptions: SortingOption[]): QueryParam {
    let sortValue = '';

    sortingOptions.forEach((so) => {
        if (sortValue.length !== 0) {
            sortValue += ',';
        }

        const directionOperator = so.direction === 'desc' ? '-' : '';

        sortValue += directionOperator + so.property;
    });

    return { key: 'sortBy', value: sortValue };
}

export function filterOptionToQueryParam(filterOption: FilterOption): QueryParam {
    let key = `filter[${filterOption.property}]`;

    if (filterOption.operation) {
        key += `[${filterOption.operation.toString()}]`;
    }

    return { key, value: filterOption.value };
}

export function includesToQueryParam(includes: string[]): QueryParam {
    return { key: 'include', value: includes.join(',') };
}

export function pageToQueryParam(page: number): QueryParam {
    return { key: 'page[number]', value: page.toString() };
}

export function pageSizeToQueryParam(pageSize: number): QueryParam {
    return { key: 'page[size]', value: pageSize.toString() };
}
