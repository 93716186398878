import moment from 'moment/moment';
import { localDateFormat } from 'src/shared/date';

export const formatEventDates = (dateTimeStart: string, dateTimeEnd?: string | null) => {
    const formattedDate = (date: string) => moment(date).format(localDateFormat);

    return `${formattedDate(dateTimeStart)}${
        dateTimeEnd ? ` - ${formattedDate(dateTimeEnd)}` : ''
    }`;
};

export const formatEventData = (
    name: string,
    dateTimeStart: string,
    dateTimeEnd?: string | null
) => {
    const formattedDate = (date: string) => moment(date).format(localDateFormat);

    return `${name} (${formattedDate(dateTimeStart)}${
        dateTimeEnd ? ` - ${formattedDate(dateTimeEnd)}` : ''
    })`;
};
