/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Grid } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CategoryTicket } from 'src/app/components/forms/purchase/AdvancedEditAddPurchaseTicketsForm';
import Toaster from 'src/app/utilities/helpers/Toaster';
import { parseErrors } from 'src/app/utilities/helpers/errors';
import purchaseService, { AddTicketsToPurchaseDTO } from 'src/data/services/purchaseService';
import ErrorsList from 'src/view/components/errors-list/ErrorsList';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';
import Modal from 'src/view/components/modal/Modal';
import {
    AdvancedEditAddPurchaseTicketsForm,
    type AddPurchaseTicketsFormValues,
} from '../../forms/purchase/AdvancedEditAddPurchaseTicketsForm';
import EditPurchasePricesForm, {
    EditPurchasePriceFormValuesProps,
    EditPurchasePricesFormSubmitValues,
    PurchasePricesPerCategory,
} from '../../forms/purchase/EditPurchasePricesForm';
import { PURCHASE_PRICES_QUERY } from './AdvancedEditPurchaseFormFeature';
import { PURCHASE_TICKETS_QUERY } from './PurchaseTicketsTableFeature';

export interface NewCategoryTicketsDto {
    eventId: string;
    quantity: number;
    seatingPlanCategoryId: string;
    ticketsPerSplit?: number;
}

interface AddPurchaseTicketsModalFeatureProps {
    purchaseId: string;
    purchasePrices: PurchasePricesPerCategory[];
    onSuccessfulAddTickets: (followUpModalData: EditPurchasePriceFormValuesProps[]) => void;
    onCloseModal: () => void;
}

interface CategoryTicketsType {
    quantity: number;
    originalPrice: {
        value: number | undefined;
        currency: string;
    };
    eventId: string;
    eventName: string;
    seatingPlanCategoryId: string;
    seatingPlanCategoryName: string;
    bookingOptionId: string;
    bookingOptionName: string;
    ticketsPerSplit?: number | undefined;
    additionalTickets?: number;
    oldQuantity?: number;
    pricePerTicket?: number;
}

const AddPurchaseTicketsModalFeature = ({
    purchaseId,
    purchasePrices,
    onCloseModal,
    onSuccessfulAddTickets,
}: AddPurchaseTicketsModalFeatureProps) => {
    const queryClient = useQueryClient();
    const confirm = useConfirm();
    const [isShowingAffectingChanges, setIsShowingAffectingChanges] = useState(false);
    const [ticketsToBeAdded, setTicketsToBeAdded] = useState<NewCategoryTicketsDto[]>([]);

    const [newCategoryTickets, setNewCategoryTickets] = useState<CategoryTicketsType[]>([]);
    const [existingCategoryTickets, setExistingCategoryTickets] = useState<CategoryTicketsType[]>(
        []
    );

    const [formPricesToBeDisplayed, setFormPricesToBeDisplayed] = useState<CategoryTicketsType[]>(
        []
    );

    const followUpModalData = useRef<EditPurchasePriceFormValuesProps[]>([]);

    const {
        mutate: addPurchaseTickets,
        error,
        isError,
        isLoading,
    } = useMutation({
        mutationKey: ['addTickets'],
        mutationFn: (dto: AddTicketsToPurchaseDTO) =>
            purchaseService.addTicketsToPurchase(purchaseId, dto),
        onSuccess: () => {
            Toaster.toast('Successfully added new tickets to the purchase!', {
                variant: 'success',
            });
            onSuccessfulAddTickets(followUpModalData.current);
            refetchRelevantQueries();
            onCloseModal();
        },
        onError: () => {
            Toaster.toastErrors(parseErrors(error));
        },
    });

    const refetchRelevantQueries = useCallback(() => {
        queryClient.invalidateQueries({ queryKey: [PURCHASE_PRICES_QUERY] });
        queryClient.invalidateQueries({ queryKey: [PURCHASE_TICKETS_QUERY] });
    }, [queryClient]);

    const newAndExistingCategoryTickets = useMemo(() => {
        const combinedCategoryTickets = _.cloneDeep(purchasePrices);

        const allCategoryTickets = [...newCategoryTickets, ...existingCategoryTickets];
        allCategoryTickets.forEach((newPrice) => {
            const matchingIndex = _.findIndex(
                combinedCategoryTickets,
                (price) =>
                    price.seatingPlanCategoryId === newPrice.seatingPlanCategoryId &&
                    price.bookingOptionId === newPrice.bookingOptionId &&
                    price.eventId === newPrice.eventId
            );

            if (matchingIndex !== -1) {
                combinedCategoryTickets[matchingIndex] = newPrice;
            } else {
                combinedCategoryTickets.push(newPrice);
            }
        });

        return combinedCategoryTickets;
    }, [existingCategoryTickets, newCategoryTickets, purchasePrices]);

    useEffect(() => {
        setFormPricesToBeDisplayed(newAndExistingCategoryTickets);
    }, [newCategoryTickets, existingCategoryTickets, newAndExistingCategoryTickets]);

    const updateExistingCategoryTickets = useCallback(
        (existingCategoryIndex: number, quantity: number, pricePerTicket: number) => {
            const existingCategory = purchasePrices[existingCategoryIndex];
            const updatedExistingCategoryTickets = {
                ...existingCategory,
                quantity: existingCategory.quantity + quantity,
                originalPrice: {
                    ...existingCategory.originalPrice,
                    value: pricePerTicket,
                },
                pricePerTicket,
                calculation: existingCategory.quantity
                    ? `${existingCategory.quantity} existing + ${quantity} new`
                    : null,
            };
            setExistingCategoryTickets((prevArray) => [
                ...prevArray,
                updatedExistingCategoryTickets,
            ]);
        },
        [purchasePrices]
    );

    const addNewCategoryTickets = useCallback((event: CategoryTicket) => {
        const {
            event: eventData,
            seatingplanCategory,
            quantity,
            pricePerTicket = 0,
            bookingOption,
        } = event;

        const newCategoryTickets = {
            eventId: eventData!.value,
            seatingPlanCategoryId: seatingplanCategory!.value,
            quantity,
            seatingPlanCategoryName: seatingplanCategory!.label,
            eventName: eventData!.label,
            originalPrice: { value: pricePerTicket, currency: 'N/A' },
            pricePerTicket,
            bookingOptionId: bookingOption!.value,
            bookingOptionName: bookingOption!.label,
        };

        setNewCategoryTickets((prevArray) => [...prevArray, newCategoryTickets]);
    }, []);

    const onShowAffectingChanges = useCallback(
        (values: AddPurchaseTicketsFormValues) => {
            setIsShowingAffectingChanges(true);
            setFormPricesToBeDisplayed([]);

            setTicketsToBeAdded(
                values.events.map((eventData) => ({
                    eventId: eventData.event!.value,
                    quantity: eventData.quantity,
                    ticketsPerSplit: eventData.ticketsPerSplit,
                    seatingPlanCategoryId: eventData.seatingplanCategory!.value,
                    bookingOptionId: eventData.bookingOption!.value,
                }))
            );

            setNewCategoryTickets([]);
            setExistingCategoryTickets([]);

            values.events.forEach((category) => {
                const { quantity, pricePerTicket, seatingplanCategory, event, bookingOption } =
                    category;

                const existingCategoryIndex = purchasePrices.findIndex(
                    (cat) =>
                        cat.seatingPlanCategoryId === seatingplanCategory?.value &&
                        cat.eventId === event?.value &&
                        cat.bookingOptionId === bookingOption?.value
                );

                if (existingCategoryIndex !== -1) {
                    updateExistingCategoryTickets(
                        existingCategoryIndex,
                        quantity,
                        pricePerTicket || 0
                    );
                } else {
                    addNewCategoryTickets(category);
                }
            });
        },
        [addNewCategoryTickets, purchasePrices, updateExistingCategoryTickets]
    );

    async function onSubmitAddTickets(values: EditPurchasePricesFormSubmitValues) {
        try {
            await confirm({
                title: 'Are you sure you want update the purchase with the new prices?',
                description: 'You can update the prices again later.',
            });

            followUpModalData.current = _.uniqBy(values.seatingPlanCategoryPrices, 'eventId');

            addPurchaseTickets({
                purchaseOriginalPrice: values.purchaseOriginalPrice,
                seatingPlanCategoryBookingOptionPrices: values.seatingPlanCategoryPrices,
                tickets: ticketsToBeAdded,
            });
        } catch (err) {
            /* Empty */
        }
    }

    return (
        <Modal open title="Add Tickets" width="fluid" onClose={onCloseModal}>
            <Grid>
                {isError && <ErrorsList errors={parseErrors(error)} />}
                {isLoading && <LoadingOverlay />}

                <>
                    <AdvancedEditAddPurchaseTicketsForm onSubmitForm={onShowAffectingChanges} />
                    {isShowingAffectingChanges && (
                        <EditPurchasePricesForm
                            purchasePrices={formPricesToBeDisplayed}
                            onSubmit={onSubmitAddTickets}
                        />
                    )}
                </>
            </Grid>
        </Modal>
    );
};

export default AddPurchaseTicketsModalFeature;
