import { useQuery } from '@tanstack/react-query';
import type BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import { TAG_TYPES } from 'src/data/enums/tagType';
import tagService from 'src/data/services/tagService';

export const GET_TAGS_QUERY_KEY = 'GET_TAGS_QUERY_KEY';

export const useGetTags = (tagType: TAG_TYPES, fetchOptions?: BaseFetchOptions) => {
    return useQuery({
        queryKey: [GET_TAGS_QUERY_KEY, tagType, fetchOptions],
        queryFn: ({ signal }) => tagService.getTags(tagType, fetchOptions, signal),
    });
};
