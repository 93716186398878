import { getInternalAxiosInstance } from 'src/data/api/api';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import QueryParam from 'src/data/api/common/QueryParam';
import { getQueryParams } from 'src/data/api/endpointUrlBuilder';
import ApiResponse from 'src/data/api/responses/ApiResponse';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import PaginatedApiResponseBody from 'src/data/api/responses/PaginatedApiResponseBody';
import {
    ORGANIZER_CREATE_ENDPOINT,
    ORGANIZERS_DELETE_ENDPOINT,
    ORGANIZERS_GET_BY_ID_ENDPOINT,
    ORGANIZERS_GET_ENDPOINT,
    ORGANIZERS_UPDATE_ENDPOINT,
} from 'src/data/constants/endpoints/organizers-endpoints';
import { EmptyBody } from 'src/data/models/common/emptyBody';

export interface Organizer {
    id: string;
    name: string;
    eventsCount: number;
}

async function getOrganizers(
    options?: BaseFetchOptions
): Promise<ApiResponse<PaginatedApiResponseBody<Organizer>>> {
    const extraQueryParams: QueryParam[] = [];

    if (options && options.q) {
        extraQueryParams.push({
            key: 'q',
            value: options.q,
        });
    }

    return await getInternalAxiosInstance()
        .get<PaginatedApiResponseBody<Organizer>>(ORGANIZERS_GET_ENDPOINT, {
            params: options && getQueryParams(options, extraQueryParams),
        })
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function createOrganizer(name: string): Promise<ApiResponse<ApiResponseBody<EmptyBody>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<EmptyBody>>(ORGANIZER_CREATE_ENDPOINT, { name })
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function getOrganizerById(
    organizerId: string
): Promise<ApiResponse<ApiResponseBody<Organizer>>> {
    return await getInternalAxiosInstance()
        .get<ApiResponseBody<Organizer>>(ORGANIZERS_GET_BY_ID_ENDPOINT(organizerId))
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function updateOrganizer(
    organizerId: string,
    name: string
): Promise<ApiResponse<ApiResponseBody<EmptyBody>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<EmptyBody>>(ORGANIZERS_UPDATE_ENDPOINT(organizerId), { name })
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function deleteOrganizer(
    organizerId: string
): Promise<ApiResponse<ApiResponseBody<EmptyBody>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<EmptyBody>>(ORGANIZERS_DELETE_ENDPOINT(organizerId))
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export default {
    getOrganizers,
    createOrganizer,
    getOrganizerById,
    deleteOrganizer,
    updateOrganizer,
};
