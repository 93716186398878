import useApiFetch from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import { EmptyBody } from 'src/data/models/common/emptyBody';
import ticketService from 'src/data/services/ticketService';

interface DeleteTicketFileByIdDataWrapperChildrenParams
    extends DataWrapperChildrenParams<ApiResponseBody<EmptyBody>> {
    deleteTicketFile: (ticketId: string, fileId: string) => Promise<ApiResponseBody<EmptyBody>>;
}

type DeleteTicketFileByIdDataWrapperProps = DataWrapperProps<
    DeleteTicketFileByIdDataWrapperChildrenParams,
    ApiResponseBody<EmptyBody>
> &
    BaseFetchOptions;

export const useDeleteTicketFileByIdDataWrapper = ({
    onError,
    onSuccess,
    disableDefaultSuccessMessage,
}: Omit<DeleteTicketFileByIdDataWrapperProps, 'children'>) => {
    const [{ loading, data, errors, resetData }, handleDeleteTicketFile] =
        useApiFetch<ApiResponseBody<EmptyBody>>();

    const deleteTicketFile = (ticketId: string, fileId: string) => {
        return handleDeleteTicketFile(ticketService.deleteTicketFile(ticketId, fileId), {
            defaultSuccessMessage: disableDefaultSuccessMessage ? null : 'Ticket has been deleted',
            useDefaultErrorHandler: true,
            onFail: onError,
            onSuccess,
        });
    };

    return {
        deleteTicketFile,
        data,
        loading,
        errors,
        resetData,
    };
};

export const DeleteTicketFileByIdDataWrapper = (props: DeleteTicketFileByIdDataWrapperProps) => {
    const { children } = props;
    const hookData = useDeleteTicketFileByIdDataWrapper(props);

    if (!children) return <></>;

    return <>{children(hookData)}</>;
};
