import type { ConfirmOptions } from 'material-ui-confirm';
import type { FieldValues, FormState } from 'react-hook-form';
import { useCustomConfirm } from 'src/app/hooks/useCustomConfirm';
import Modal, { type ModalProps } from 'src/view/components/modal/Modal';

interface PopUpFormProps<TFieldValues extends FieldValues> extends ModalProps {
    formState: FormState<TFieldValues>;
    confirmOptions?: ConfirmOptions;
}

export function PopUpForm<TFieldValues extends FieldValues = FieldValues>({
    formState,
    onClose,
    open,
    confirmOptions,
    children,
    ...props
}: PopUpFormProps<TFieldValues>) {
    const confirm = useCustomConfirm();

    return (
        <Modal
            {...props}
            open={open}
            onClose={async (...args) => {
                if (formState.isDirty && !formState.isSubmitSuccessful) {
                    const result = await confirm({
                        title: 'Are you sure you want to exit?',
                        description: 'There are unpublished changes',
                        ...confirmOptions,
                    });

                    if (result) {
                        onClose?.(...args);
                    }
                } else {
                    onClose?.(...args);
                }
            }}
        >
            {children}
        </Modal>
    );
}
