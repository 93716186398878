import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Alert } from '@mui/material';
import Page from 'src/view/components/page/Page';
import Header from 'src/view/components/header/Header';

const useStyles = makeStyles((theme: Theme) => ({
    pageContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
    },
    page: {
        padding: theme.spacing(2),
        height: '100%',
    },
}));

export default function NotAuthorized() {
    const classes = useStyles();

    return (
        <div className={classes.pageContainer}>
            <Header />
            <Page className={classes.page}>
                <Alert severity="warning" title="Not Authorized">
                    You are not authorized to access this page, please contact IT department.
                </Alert>
            </Page>
        </div>
    );
}
