import { TableHead as MUITableHead } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { withStyles } from '@mui/styles';

const TableHead = withStyles((theme: Theme) => ({
    root: {
        zIndex: 0,
        '& th': {
            fontWeight: theme.typography.fontWeightMedium,
        },
    },
}))(MUITableHead) as typeof MUITableHead;

export default TableHead;
