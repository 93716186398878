import { Button as MuiButton,ButtonProps as MuiButtonProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { BUTTON_SIZES } from 'src/shared/theme/enums';
import HelpPopover from 'src/view/components/help-popover/HelpPopover';

export interface ButtonWithPopoverProps extends Omit<MuiButtonProps, 'size'> {
    key?: string;
    danger?: boolean;
    white?: boolean;
    size?: BUTTON_SIZES;
    tinyBorderRadius?: boolean;
    target?: '_self' | '_blank' | '_parent' | '_top';
    popoverTitle?: string;
    popoverText?: string | string[];
}

const baseButtonContainerStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '30px',
    transition: 'all 0.3s ease-in-out',
};

const useStyles = makeStyles((theme: Theme) => ({
    buttonContainer: {
        ...baseButtonContainerStyles,
        flexDirection: 'row',
        flexShrink: 0,
    },
    buttonContainerWithPopover: {
        paddingRight: theme.spacing(1),
    },
    disabledButtonContainer: {
        ...baseButtonContainerStyles,
        flexDirection: 'row',
        backgroundColor: theme.palette.grey[300],
        flexShrink: 0,
    },
    customDisabledButtonStyle: {
        zIndex: '0',
        position: 'relative',
        display: 'flex',
        '&:disabled': {
            backgroundColor: theme.palette.grey[300],
            opacity: 1,
        },
    },
    hasPopover: {
        paddingRight: '16px',
        marginRight: '-16px',
    },
    button: (props: Partial<ButtonWithPopoverProps>) => {
        let padding = theme.layout.button[BUTTON_SIZES.SMALL].padding;
        let fontSize = theme.layout.button[BUTTON_SIZES.SMALL].fontSize;
        let color;
        let background;
        let backgroundHover;
        let border;

        if (props.size === BUTTON_SIZES.TINY) {
            padding = theme.layout.button[BUTTON_SIZES.TINY].padding;
            fontSize = theme.layout.button[BUTTON_SIZES.TINY].fontSize;
        }

        if (props.size === BUTTON_SIZES.MEDIUM) {
            padding = theme.layout.button[BUTTON_SIZES.MEDIUM].padding;
            fontSize = theme.layout.button[BUTTON_SIZES.MEDIUM].fontSize;
        }

        if (props.size === BUTTON_SIZES.LARGE) {
            padding = theme.layout.button[BUTTON_SIZES.LARGE].padding;
            fontSize = theme.layout.button[BUTTON_SIZES.LARGE].fontSize;
        }

        if (props.white) {
            color = theme.palette.primary.main;
            background = theme.colors.white || 'white';
            backgroundHover = theme.colors.lightGrey;
            border = 'none';
        }

        if (props.danger) {
            if (props.variant === 'text') {
                color = theme.palette.error.main;
                background = 'transparent';
            } else {
                background = theme.palette.error.main;
                color = theme.colors.white || 'white';
                backgroundHover = theme.palette.error.dark;
            }
        }

        return {
            padding,
            fontSize,
            border,
            background,
            color,
            'font-weight': theme.typography.fontWeightBold,
            borderRadius: props.tinyBorderRadius ? '' : theme.layout.borderRadius.large,
            boxShadow: 'none',
            textTransform: 'none',
            '&:hover': {
                boxShadow: 'none',
                background: backgroundHover,
                border,
            },
        };
    },
}));

const ButtonWithPopover = ({
    size = BUTTON_SIZES.SMALL,
    color = 'primary',
    disableRipple = true,
    variant = 'contained',
    tinyBorderRadius,
    danger,
    white,
    disabled,
    popoverTitle,
    popoverText,
    ...props
}: ButtonWithPopoverProps): JSX.Element => {
    const classes = useStyles({
        size,
        tinyBorderRadius,
        color,
        variant,
        danger,
        white,
    });
    const { children } = props;

    const hasPopover = popoverText && popoverText?.length;

    return (
        <div
            className={classNames(
                disabled ? classes.disabledButtonContainer : classes.buttonContainer,
                hasPopover && classes.buttonContainerWithPopover
            )}
        >
            <MuiButton
                disabled={disabled}
                variant={variant}
                color={color}
                disableRipple={disableRipple}
                {...props}
                className={classNames(
                    disabled && classes.customDisabledButtonStyle,
                    classes.button,
                    hasPopover && classes.hasPopover
                )}
            >
                {children}
            </MuiButton>
            {popoverText && popoverText?.length !== 0 && disabled && (
                <HelpPopover
                    iconZIndex={15}
                    content={{
                        title: popoverTitle || '',
                        text: popoverText,
                    }}
                />
            )}
        </div>
    );
};

export default ButtonWithPopover;
