import { ENDPOINT_VERSIONS } from './_endpoint-versions';

export const SEATING_PLAN_CREATE_SEATING_PLAN_CATEGORY_ENDPOINT = (seatingPlanId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/seating-plans/${seatingPlanId}/create-seating-plan-category`;

export const SEATING_PLAN_UPDATE_SEATING_PLAN_CATEGORY_ENDPOINT = (
    seatingPlanId: string,
    seatingplanCategoryId: string
) =>
    `/${ENDPOINT_VERSIONS.V2}/seating-plans/${seatingPlanId}/seating-plan-categories/${seatingplanCategoryId}/update`;

export const SEATING_PLAN_GET_CATEGORIES_ENDPOINT = (seatingPlanId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/seating-plans/${seatingPlanId}/seating-plan-categories`;

export const SEATING_PLAN_GET_BY_ID_ENDPOINT = (seatingPlanId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/seating-plans/${seatingPlanId}`;

export const SEATING_PLAN_UPDATE_ENDPOINT = (seatingPlanId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/seating-plans/${seatingPlanId}/update`;

export const SEATING_PLAN_UPDATE_SEATING_PLAN_CATEGORIES_ORDER_ENDPOINT = (seatingPlanId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/seating-plans/${seatingPlanId}/change-categories-sort-order`;

export const SEATING_PLAN_DELETE_ENDPOINT = (seatingPlanId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/seating-plans/${seatingPlanId}/delete`;
