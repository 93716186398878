import { useMutation } from '@tanstack/react-query';
import ChangeSeatDto from 'src/data/dtos/ChangeSeatDto';
import ticketsService from 'src/data/services/ticketsService';

type TData = Awaited<ReturnType<typeof ticketsService.updateTicketBlockRowSeat>>;

type TVariables = {
    id: string;
    dto: ChangeSeatDto;
};

export const useUpdateTicketBlockRowSeat = (
    onSuccess?: (data: TData, variables: TVariables, context: unknown) => unknown
) => {
    return useMutation({
        mutationFn: ({ id, dto }: TVariables) => ticketsService.updateTicketBlockRowSeat(id, dto),
        onSuccess,
    });
};
