import Table from 'src/view/components/table/table/Table';
import {
    NewTableColumn,
    TablePaginationData,
    TABLE_CELL_WIDTH,
} from 'src/view/components/table/table/Types';
import { EventCategory } from 'src/data/services/eventCategoriesService';
import Button from 'src/view/components/button/Button';
import { BUTTON_SIZES } from 'src/shared/theme/enums';
import { Typography } from '@mui/material';

export interface Props {
    data?: EventCategory[];
    loading: boolean;
    pagination?: TablePaginationData;
    onToggleArchivedStatus: (id: string, isCurrentlyArchived: boolean) => void;
}

export default function EventCategoriesTable({
    data,
    loading,
    pagination,
    onToggleArchivedStatus,
}: Props): JSX.Element {
    const columns: NewTableColumn<EventCategory>[] = [
        {
            key: 'name',
            title: 'Name',
            cellRenderer: (rowData) => {
                if (rowData.isArchived) {
                    return (
                        <p>
                            {rowData.name}{' '}
                            <Typography
                                sx={{
                                    fontSize: 12,
                                    marginLeft: 0.5,
                                    display: 'inline-flex',
                                    color: (theme) => theme.palette.warning.main,
                                }}
                            >
                                (Archived)
                            </Typography>
                        </p>
                    );
                }

                return rowData.name;
            },
            width: TABLE_CELL_WIDTH.MEDIUM,
        },
        {
            key: 'key',
            title: 'Key',
            cellRenderer: (rowData) => rowData.key,
            width: TABLE_CELL_WIDTH.SMALL,
        },
        {
            align: 'right',
            width: TABLE_CELL_WIDTH.SMALL,
            cellRenderer: (rowData) => {
                return (
                    <Button
                        size={BUTTON_SIZES.TINY}
                        color={rowData.isArchived ? 'warning' : 'primary'}
                        onClick={() => onToggleArchivedStatus(rowData.id, rowData.isArchived)}
                    >
                        {rowData.isArchived ? 'Unarchive' : 'Archive'}
                    </Button>
                );
            },
        },
    ];

    return (
        <Table<EventCategory>
            data={data}
            rowIdResolver={(rowData: EventCategory) => ({
                id: `event-category-${rowData.name}`,
            })}
            columns={columns}
            loading={loading}
            minWidth={650}
            pagination={pagination}
        />
    );
}
