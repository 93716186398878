import { zodResolver } from '@hookform/resolvers/zod';
import { Alert, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import AssignTicketsActionDataWrapper from 'src/app/components/data-wrappers/tickets/AssignTicketsActionDataWrapper';
import { CreateDownloadLinkDataWrapper } from 'src/app/components/data-wrappers/tickets/CreateDownloadLinkDataWrapper';
import TicketsTableFiltersDataWrapper from 'src/app/components/data-wrappers/tickets/TicketsTableFiltersDataWrapper';
import { TableHeaderTotalFeature } from 'src/app/components/features/tables/TableHeaderTotalFeature';
import { AddTicketsToSplitFeature } from 'src/app/components/features/tickets/AddTicketsToSplitFeature';
import { BatchEditTicketFeature } from 'src/app/components/features/tickets/BatchEditTicketFeature';
import { CreateSplitFeature } from 'src/app/components/features/tickets/CreateSplitFeature';
import { DeleteTicketsFromSplitFeature } from 'src/app/components/features/tickets/DeleteTicketsFromSplitFeature';
import TicketsTableDetailsFeature from 'src/app/components/features/tickets/TicketDetailsFeature';
import TicketsTable, { TicketsTableDataResolver } from 'src/app/components/tables/TicketsTable';
import { config } from 'src/app/constants/config/config';
import { useDeleteTicketsFiles } from 'src/app/hooks/tickets/use-delete-tickets-files';
import {
    FETCH_EVENT_TICKETS_QUERY_KEY,
    useFetchEventTickets,
} from 'src/app/hooks/tickets/useFetchEventTickets';
import useApiFetch from 'src/app/hooks/useApiFetch';
import { useCustomConfirm } from 'src/app/hooks/useCustomConfirm';
import { useLockTickets } from 'src/app/hooks/useLockTickets';
import useQueryParams from 'src/app/hooks/useQueryParams';
import { useUnlockTickets } from 'src/app/hooks/useUnlockTickets';
import Toaster from 'src/app/utilities/helpers/Toaster';
import { parseErrors } from 'src/app/utilities/helpers/errors';
import {
    canCreateSplitFromSelection,
    getCreateSplitDisabledButtonReasons,
    getDeleteSplitDisabledButtonReasons,
    selectedSplitTicketsCanBeDeleted,
    ticketsHaveDifferentCategories,
    ticketsHaveNoOrSameSplit,
    ticketsHaveSameSplit,
} from 'src/app/utilities/helpers/selectedTicketRows';
import UserPermissions from 'src/app/utilities/helpers/userPermissions';
import tableSortingToSortingOptionMapper from 'src/app/utilities/mappers/table/tableSortingToSortingOptionMapper';
import { zodCreateOrEditPurchaseValidationSchema } from 'src/app/utilities/zod/purchase/zodCreateOrEditPurchaseValidationSchema';
import FilterOption from 'src/data/api/common/FilterOption';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import { EmptyBody } from 'src/data/models/common/emptyBody';
import orderlineService from 'src/data/services/orderlineService';
import ticketsService from 'src/data/services/ticketsService';
import Modal, { ModalBody } from 'src/view/components/modal/Modal';
import { TableAction } from 'src/view/components/table-toolbar/TableToolbar';
import { RowIdResolver } from 'src/view/components/table/table/Table';
import { TableColumnSorting } from 'src/view/components/table/table/Types';
import { PurchaseDetailsFormValues } from '../../forms/CreatePurchaseDetailsForm';
import { PopUpForm } from '../../pop-up-form/pop-up-form';

const useStyles = makeStyles((theme: Theme) => ({
    alert: {
        marginBottom: theme.spacing(1),
    },
    purchaseTable: {
        overflowX: 'auto',
    },
    spacingBottom: {
        marginBottom: theme.spacing(4),
    },
    spacingBottomLarge: {
        marginBottom: theme.spacing(8),
    },
    spacing: {
        padding: theme.spacing(2),
    },
    spacingTop: {
        marginTop: theme.spacing(8),
    },
}));

type selectedRowsType = RowIdResolver<TicketsTableDataResolver>;

interface TicketsTableToolbarProps {
    enableDeleteTickets?: boolean;
    enableBatchEditTickets?: boolean;
    orderId?: string;
    orderLineId?: string;
    toolbarAdditionalItems?: string[];
    onSuccess?: () => void;
}

interface Props extends TicketsTableToolbarProps {
    eventId: string;
    invalidatedAt?: number;
    enableToolbar?: boolean;
    enableFilters?: boolean;
    initialSelectedRows?: selectedRowsType[];
    pageSize?: number;
    disableUrlWriting?: boolean;
    defaultSorting?: TableColumnSorting[];
    defaultFilters?: FilterOption[];
}

interface AssignTicketFunctionProps {
    orderId: string;
    orderLineId: string;
    ticketIds: string[];
}

export default function TicketsTableFeature({
    eventId,
    invalidatedAt,
    enableDeleteTickets = true,
    enableBatchEditTickets = false,
    orderId,
    orderLineId,
    toolbarAdditionalItems = [],
    onSuccess,
    enableToolbar = true,
    enableFilters = true,
    initialSelectedRows,
    pageSize = config.ITEMS_PER_PAGE_STANDARD,
    disableUrlWriting,
    defaultSorting,
    defaultFilters,
}: Props): JSX.Element {
    const confirm = useCustomConfirm();
    const classes = useStyles();
    const queryClient = useQueryClient();

    const canModifyTicketLockedState = UserPermissions.canModifyTicketLockedStatus;

    if ((!disableUrlWriting && defaultSorting) || (!disableUrlWriting && defaultFilters)) {
        // eslint-disable-next-line no-console
        console.warn(
            'You are using defaultSorting or defaultFilters, but not disableUrlWriting. when disableUrlWriting is not present, the defaultSorting should come from the url.'
        );
    }

    const ticketPurchaseDetailsForm = useForm<PurchaseDetailsFormValues>({
        mode: 'all',
        resolver: zodResolver(zodCreateOrEditPurchaseValidationSchema),
    });

    const {
        values: { filterOptions, sortingOptions, customFields },
        setFilterOptions,
        setSortingOptions,
        setCustomFields,
    } = useQueryParams('tickets', {
        defaultSorting,
        defaultFilters,
        disableUrlWriting,
    });

    const [{ loading: deleteIsLoading }, handleDelete] = useApiFetch<ApiResponseBody<EmptyBody>>();

    const [activeTicket, setActiveTicket] = useState<string | undefined>();
    const [selectedRows, setSelectedRows] = useState<selectedRowsType[]>(initialSelectedRows || []);
    const [detailsModalOpen, setDetailsModalOpen] = useState(false);

    const [showAssignModal, setShowAssignModal] = useState(false);
    const [showBatchEditModal, setShowBatchEditModal] = useState(false);
    const [showAddToSplitModal, setShowAddToSplitModal] = useState(false);
    const [showDeleteFromSplitModal, setShowDeleteFromSplitModal] = useState(false);
    const [showCreateNewSplitModal, setShowCreateNewSplitModal] = useState(false);

    function getTicketIds(tickets: selectedRowsType[]) {
        return tickets.map((ticket) => ticket.id);
    }

    useEffect(() => {
        setSelectedRows([]);
    }, [invalidatedAt]);

    const { mutate: lockTickets, isLoading: isLockingTickets } = useLockTickets({
        onSuccess,
    });

    const { mutate: unlockTickets, isLoading: isUnlockingTickets } = useUnlockTickets({
        onSuccess,
    });

    const handleSortingChange = (tableSorting: TableColumnSorting[]) => {
        if (tableSorting.length === 0) return;

        setSortingOptions(tableSorting);
    };

    const ticketsDeleteToolbarAction = (selectedRows: selectedRowsType[]): TableAction => {
        return {
            hidden: !enableDeleteTickets,
            disabled:
                deleteIsLoading ||
                selectedRows.length === 0 ||
                !selectedSplitTicketsCanBeDeleted(selectedRows),
            disabledButtonReasons: getDeleteSplitDisabledButtonReasons(selectedRows),
            label: 'Delete ticket(s)',
            position: 'right',
            color: 'error',
            callback: async () => {
                try {
                    setSelectedRows(selectedRows);
                    await confirm({
                        title: 'Are you sure you want to delete the ticket(s)?',
                        description: 'Deleting the ticket(s) will be permanent',
                    });

                    handleDelete(ticketsService.deleteTickets(getTicketIds(selectedRows)), {
                        onSuccess: () => setSelectedRows([]),
                    });
                } catch {
                    /* empty */
                }
            },
        };
    };

    const { mutate: assignTickets, isLoading: isAssignLoading } = useMutation({
        mutationFn: async ({ orderId, orderLineId, ticketIds }: AssignTicketFunctionProps) =>
            orderlineService.assignTickets(orderId, orderLineId, {
                ticketIds,
            }),
        onSuccess: () => {
            setSelectedRows([]);
            onSuccess?.();
        },
        onError: (e) => {
            const errors = parseErrors(e);
            Toaster.toastErrors(errors);
        },
    });

    const { deleteTicketsFiles } = useDeleteTicketsFiles(() => {
        setSelectedRows([]);
    });

    const assignTicketsAction = (selectedRows: selectedRowsType[]): TableAction => {
        const doesSelectionContainLockedTicket = selectedRows.some(
            (ticket) => ticket.data?.isLocked
        );

        return {
            hidden: !orderId || !orderLineId,
            label: 'Assign tickets',
            disabled:
                selectedRows.length === 0 || isAssignLoading || doesSelectionContainLockedTicket,
            disabledButtonReasons:
                selectedRows.length > 0 && doesSelectionContainLockedTicket
                    ? ['Selection contains a locked ticket.']
                    : [],
            callback: () => {
                if (!orderId || !orderLineId) return;

                setSelectedRows(selectedRows);

                const allTicketsAreAvailable: boolean =
                    selectedRows.filter((row) => row.data?.available === false).length === 0;

                if (allTicketsAreAvailable) {
                    assignTickets({
                        orderId,
                        orderLineId,
                        ticketIds: [...toolbarAdditionalItems, ...getTicketIds(selectedRows)],
                    });

                    return;
                }
            },
        };
    };

    const batchEditAction = (selectedRows: selectedRowsType[]): TableAction => ({
        hidden: !enableBatchEditTickets,
        label: 'Batch edit tickets',
        disabled: selectedRows.length === 0 || ticketsHaveDifferentCategories(selectedRows),
        callback: () => {
            setSelectedRows(selectedRows);
            setShowBatchEditModal(true);
        },
    });

    const lockTicketsAction = (selectedRows: selectedRowsType[]): TableAction => {
        const doesSelectionContainUnlockedTicket = selectedRows.some(({ data }) => data?.isLocked);
        return {
            hidden: !canModifyTicketLockedState,
            label: 'Lock tickets',
            disabled:
                selectedRows.length === 0 || doesSelectionContainUnlockedTicket || isLockingTickets,
            disabledButtonReasons: doesSelectionContainUnlockedTicket
                ? ['Selection contains already locked ticket(s).']
                : [],
            callback: () => {
                setSelectedRows(selectedRows);
                lockTickets(selectedRows.map(({ id }) => id));
            },
        };
    };

    const unlockTicketsAction = (selectedRows: selectedRowsType[]): TableAction => {
        const doesSelectionContainLockedTicket = selectedRows.some(({ data }) => !data?.isLocked);
        return {
            hidden: !canModifyTicketLockedState,
            label: 'Unlock tickets',
            disabled:
                selectedRows.length === 0 || doesSelectionContainLockedTicket || isUnlockingTickets,
            disabledButtonReasons: doesSelectionContainLockedTicket
                ? ['Selection contains already non-locked ticket(s).']
                : [],
            callback: () => {
                setSelectedRows(selectedRows);
                unlockTickets(selectedRows.map(({ id }) => id));
            },
        };
    };

    const addToSplitAction = (selectedRows: selectedRowsType[]): TableAction => ({
        label: ticketsHaveSameSplit(selectedRows) ? 'Change split' : 'Add to split',
        disabled:
            selectedRows.length === 0 ||
            ticketsHaveDifferentCategories(selectedRows) ||
            !ticketsHaveNoOrSameSplit(selectedRows),
        callback: () => {
            setSelectedRows(selectedRows);
            setShowAddToSplitModal(true);
        },
    });

    const deleteFromSplitAction = (selectedRows: selectedRowsType[]): TableAction => ({
        label: 'Delete from split',
        disabled: selectedRows.length === 0 || !selectedSplitTicketsCanBeDeleted(selectedRows),
        callback: () => {
            setSelectedRows(selectedRows);
            setShowDeleteFromSplitModal(true);
        },
        disabledButtonReasons: getDeleteSplitDisabledButtonReasons(selectedRows),
    });

    const deleteTicketFilesAction = (selectedRows: selectedRowsType[]): TableAction => {
        return {
            label: 'Delete ticket file(s)',
            disabled: selectedRows.length === 0,
            callback: async () => {
                const hasConfirmedDeletion = await confirm({
                    title: 'Are you sure you want to delete the ticket file(s)?',
                });

                if (hasConfirmedDeletion) {
                    setSelectedRows(selectedRows);
                    const ticketIds = selectedRows.map((row) => row.id);
                    deleteTicketsFiles(ticketIds);
                }
            },
        };
    };

    const makeNewSplitAction = (selectedRows: selectedRowsType[]) => ({
        label: 'Make new split',
        disabled: canCreateSplitFromSelection(selectedRows),
        callback: () => {
            setSelectedRows(selectedRows);
            setShowCreateNewSplitModal(true);
        },
        disabledButtonReasons: getCreateSplitDisabledButtonReasons(selectedRows),
    });

    const renderAssignTicketsModal = () => {
        if (selectedRows.length === 0 || !orderId || !orderLineId) return <></>;

        return (
            <Modal
                open={showAssignModal}
                title="Are you sure you want to assign selected ticket(s)?"
                onClose={() => setShowAssignModal(false)}
            >
                <>
                    <Alert severity="info" className={classes.spacingBottom}>
                        Some of the selected tickets are unavailable
                    </Alert>
                    <div className={classes.spacingTop}>
                        <AssignTicketsActionDataWrapper
                            orderId={orderId}
                            orderlineId={orderLineId}
                            ticketIds={[...toolbarAdditionalItems, ...getTicketIds(selectedRows)]}
                            onSucceed={() => {
                                setShowAssignModal(false);
                                setSelectedRows([]);
                                onSuccess?.();
                            }}
                        />
                    </div>
                </>
            </Modal>
        );
    };

    const renderBatchEditTicketsModal = () => (
        <Modal
            open={showBatchEditModal}
            onClose={async () => {
                await confirm({
                    title: 'Are you sure you want to close batch edit tickets?',
                    description: 'Unsaved changes will be lost',
                });

                setShowBatchEditModal(false);
            }}
            title="Batch edit tickets"
            width="fluid"
        >
            <ModalBody>
                <BatchEditTicketFeature
                    selectedTickets={selectedRows}
                    eventId={eventId}
                    onSuccess={() => {
                        setSelectedRows([]);
                        setShowBatchEditModal(false);
                        onSuccess?.();
                    }}
                />
            </ModalBody>
        </Modal>
    );

    const renderAddToSplitModal = () => (
        <Modal
            open={showAddToSplitModal}
            onClose={() => setShowAddToSplitModal(false)}
            title={ticketsHaveSameSplit(selectedRows) ? 'Change split' : 'Add to split'}
            width="fluid"
        >
            <ModalBody>
                <AddTicketsToSplitFeature
                    eventId={eventId}
                    selectedTickets={selectedRows}
                    onSuccess={() => {
                        setSelectedRows([]);
                        onSuccess?.();
                        setShowAddToSplitModal(false);
                    }}
                />
            </ModalBody>
        </Modal>
    );

    const renderDeleteFromSplitModal = () => (
        <Modal
            open={showDeleteFromSplitModal}
            onClose={() => setShowDeleteFromSplitModal(false)}
            title="Delete from split"
            width="fluid"
        >
            <ModalBody>
                <DeleteTicketsFromSplitFeature
                    eventId={eventId}
                    selectedTickets={selectedRows}
                    onSuccess={() => {
                        setSelectedRows([]);
                        onSuccess?.();
                        setShowDeleteFromSplitModal(false);
                    }}
                />
            </ModalBody>
        </Modal>
    );

    const renderMakeNewSplitModal = () => (
        <Modal
            open={showCreateNewSplitModal}
            onClose={() => setShowCreateNewSplitModal(false)}
            title="Create New Split"
            width="fluid"
        >
            <ModalBody>
                <CreateSplitFeature
                    eventId={eventId}
                    selectedTickets={selectedRows}
                    onSuccess={() => {
                        setSelectedRows([]);
                        onSuccess?.();
                        setShowCreateNewSplitModal(false);
                    }}
                />
            </ModalBody>
        </Modal>
    );

    const { data: eventTicketsResponse, isLoading: loading } = useFetchEventTickets(eventId, {
        filter: filterOptions,
        q: customFields.q,
        sorting: sortingOptions.map(tableSortingToSortingOptionMapper),
        pageSize,
        page: Number(customFields.page || 1),
        includes: ['purchaseSummary', 'splitStatistics'],
        sortBy: ['blockRowSeatName'],
    });

    return (
        <>
            {enableFilters && (
                <TicketsTableFiltersDataWrapper
                    eventId={eventId}
                    initialOptions={filterOptions}
                    initialSearch={customFields['q'] || ''}
                    onChangeFilterOptions={(options) => setFilterOptions(options)}
                    onChangeSearchTerm={(q: string) => {
                        setCustomFields({
                            ...customFields,
                            q: q?.length > 0 ? q : '',
                        });
                    }}
                />
            )}

            <CreateDownloadLinkDataWrapper
                onSuccess={(data) => {
                    window.open(data?.data.url, '_blank');
                }}
            >
                {() => (
                    <>
                        <TableHeaderTotalFeature
                            title="Tickets"
                            totalResults={eventTicketsResponse?.data.meta.total}
                            priceTotals={eventTicketsResponse?.data.meta.totalPriceSumPerCurrency}
                        />
                        <TicketsTable
                            includePurchaseData
                            includePurchaseStatistics
                            initialSelectedRows={selectedRows}
                            tickets={eventTicketsResponse?.data.data}
                            loading={loading}
                            defaultSorting={sortingOptions}
                            onChangeSorting={handleSortingChange}
                            warningRow={(ticket) => {
                                return !!ticket.isLocked;
                            }}
                            pagination={{
                                currentPage: eventTicketsResponse?.data.meta.currentPage || 1,
                                totalPages: eventTicketsResponse?.data.meta.totalPages || 1,
                                onPaginate: (page: number) =>
                                    setCustomFields({
                                        ...customFields,
                                        page: page.toString(),
                                    }),
                            }}
                            onClickDetails={(id) => {
                                setDetailsModalOpen(true);
                                setActiveTicket(id);
                            }}
                            tableToolbarActions={(selectedRows) =>
                                enableToolbar
                                    ? [
                                          assignTicketsAction(selectedRows),
                                          batchEditAction(selectedRows),
                                          addToSplitAction(selectedRows),
                                          deleteFromSplitAction(selectedRows),
                                          makeNewSplitAction(selectedRows),
                                          ticketsDeleteToolbarAction(selectedRows),
                                          lockTicketsAction(selectedRows),
                                          unlockTicketsAction(selectedRows),
                                          deleteTicketFilesAction(selectedRows),
                                      ]
                                    : []
                            }
                        />
                    </>
                )}
            </CreateDownloadLinkDataWrapper>
            <PopUpForm
                formState={ticketPurchaseDetailsForm.formState}
                title="Ticket Details"
                open={detailsModalOpen}
                onClose={() => {
                    setDetailsModalOpen(false);
                }}
                confirmOptions={{
                    title: 'Are you sure you want to exit editing details?',
                    description: 'Any unsaved changes will be lost',
                }}
            >
                <>
                    {activeTicket && (
                        <TicketsTableDetailsFeature
                            form={ticketPurchaseDetailsForm}
                            eventId={eventId}
                            ticketId={activeTicket}
                            showRelatedPurchase
                            showRelatedOrder
                            onDetailsUpdated={() =>
                                queryClient.invalidateQueries({
                                    queryKey: [FETCH_EVENT_TICKETS_QUERY_KEY],
                                    exact: false,
                                })
                            }
                        />
                    )}
                </>
            </PopUpForm>
            {renderAssignTicketsModal()}
            {renderBatchEditTicketsModal()}
            {renderAddToSplitModal()}
            {renderDeleteFromSplitModal()}
            {renderMakeNewSplitModal()}
        </>
    );
}
