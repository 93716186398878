/**
 * File name is prefixed with _ to make sure it stays on top of the folder.
 *
 * Previously, ETBaaS was two different repos consisting of BE and FE.
 * When some breaking changes were introduced to a certain endopint, we introduced minor versioning per endpoint.
 * Otherwise, it would not be possible to merge to master BE without breaking the FE.
 *
 * Now that BE and FE are in the same repo, this approach is less relevant, but some endpoints still actively use it.
 */
export const ENDPOINT_VERSIONS = {
    V2: 'v2',
    V2_1: 'v2.1',
    V2_2: 'v2.2',
};
