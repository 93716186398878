import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    warningText: {
        color: theme.palette.error.main,
        fontStyle: 'italic',
        marginLeft: theme.spacing(1),
        fontSize: '.75rem',
    },
}));

interface WarningTextProps {
    text: string;
}

export const WarningText = ({ text }: WarningTextProps) => {
    const classes = useStyles();

    return <span className={classes.warningText}>({text})</span>;
};
