import { zodResolver } from '@hookform/resolvers/zod';
import { Grid, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect } from 'react';
import { ColorResult } from 'react-color';
import { Controller, useForm } from 'react-hook-form';
import { zodRequiredDropdownSelectSchema } from 'src/app/utilities/zod/zodRequiredDropdownSelectSchema';
import { zodRequiredStringSchema } from 'src/app/utilities/zod/zodRequiredStringSchema';
import { Team } from 'src/data/models/team/team';
import { countryList } from 'src/shared/countries';
import AutoComplete from 'src/view/components/auto-complete/AutoComplete';
import FormButtons from 'src/view/components/form/FormButtons';
import FormFieldError from 'src/view/components/form/FormFieldError';
import Input from 'src/view/components/input/Input';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';
import ModalSubTitle from 'src/view/components/modal/ModalSubtitle';
import SketchColor from 'src/view/components/sketch-color/SketchColor';
import z from 'zod';

const useStyles = makeStyles((theme: Theme) => ({
    spacingRight: {
        paddingRight: theme.spacing(1),
    },
}));

const validationSchema = z.object({
    name: zodRequiredStringSchema,
    shortName: zodRequiredStringSchema,
    abbreviation: zodRequiredStringSchema,
    city: zodRequiredStringSchema,
    country: zodRequiredDropdownSelectSchema,
    color: z.object({
        r: z.number().min(0),
        g: z.number().min(0),
        b: z.number().min(0),
    }),
});

export type CreateTeamFormValues = z.infer<typeof validationSchema>;

export enum TEAM_TYPE {
    homeTeam = 'homeTeam',
    awayTeam = 'awayTeam',
}

interface Props {
    loading?: boolean;
    teamName?: string;
    onFormSubmit: (values: CreateTeamFormValues) => void;
    team?: Team;
}

export default function CreateTeamForm({ loading, teamName, onFormSubmit, team }: Props) {
    const classes = useStyles();
    const form = useForm<CreateTeamFormValues>({
        mode: 'onChange',
        resolver: zodResolver(validationSchema),
        defaultValues: {
            color: {
                r: 34,
                g: 25,
                b: 77,
            },
        },
    });

    const {
        setValue,
        control,
        handleSubmit,
        formState: { isDirty },
        reset,
    } = form;

    const isSubmitButtonDisabled = loading || !isDirty;

    useEffect(() => {
        if (!team) return;

        const country = countryList.find((c) => c.value === team.countryCode) || null;

        reset({
            ...team,
            country: {
                label: country?.label,
                value: country?.value,
            },
        });
    }, [reset, team]);

    useEffect(() => {
        if (!team) return;

        const country = countryList.find((c) => c.value === team.countryCode) || null;

        reset({
            ...team,
            country: {
                label: country?.label,
                value: country?.value,
            },
        });
    }, [reset, team]);

    useEffect(() => {
        if (!teamName) return;
        setValue('name', teamName, { shouldValidate: true });
    }, [setValue, teamName]);

    return (
        <>
            {loading && <LoadingOverlay />}
            <Grid container>
                <Grid item xs={12}>
                    <ModalSubTitle>Team Name*</ModalSubTitle>
                    <Controller
                        name="name"
                        control={control}
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <>
                                <Input
                                    name={name}
                                    value={value}
                                    onChange={onChange}
                                    placeholder="Team name"
                                    grey
                                />
                                <FormFieldError message={error?.message} />
                            </>
                        )}
                    />
                </Grid>
                <Grid item xs={6} className={classes.spacingRight}>
                    <ModalSubTitle>Short Name*</ModalSubTitle>
                    <Controller
                        name="shortName"
                        control={control}
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <>
                                <Input
                                    name={name}
                                    value={value}
                                    onChange={onChange}
                                    placeholder="Short name"
                                    grey
                                />
                                <FormFieldError message={error?.message} />
                            </>
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <ModalSubTitle>Abbreviation*</ModalSubTitle>
                    <Controller
                        name="abbreviation"
                        control={control}
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <>
                                <Input
                                    name={name}
                                    value={value}
                                    onChange={onChange}
                                    placeholder="Abbreviation"
                                    grey
                                />
                                <FormFieldError message={error?.message} />
                            </>
                        )}
                    />
                </Grid>
                <Grid item xs={6} className={classes.spacingRight}>
                    <ModalSubTitle>City*</ModalSubTitle>
                    <Controller
                        name="city"
                        control={control}
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <>
                                <Input
                                    name={name}
                                    value={value}
                                    onChange={onChange}
                                    placeholder="City"
                                    grey
                                />
                                <FormFieldError message={error?.message} />
                            </>
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <ModalSubTitle>Country*</ModalSubTitle>
                    <Controller
                        name="country"
                        control={control}
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <>
                                <AutoComplete
                                    name={name}
                                    value={value}
                                    options={countryList}
                                    onChange={onChange}
                                    placeholder="Select a country"
                                />
                                <FormFieldError message={error?.message} />
                            </>
                        )}
                    />
                </Grid>
                <Grid xs={12}>
                    <ModalSubTitle>Color*</ModalSubTitle>
                    <Controller
                        name="color"
                        control={control}
                        render={({ field: { value }, fieldState: { error } }) => (
                            <>
                                <SketchColor
                                    color={value}
                                    onChange={(value: ColorResult) =>
                                        setValue('color', value.rgb, { shouldValidate: true })
                                    }
                                />
                                <FormFieldError message={error?.message} />
                            </>
                        )}
                    />
                </Grid>
            </Grid>
            <FormButtons
                buttons={[
                    {
                        children: team ? 'Save Team' : 'Create Team',
                        onClick: () => handleSubmit(onFormSubmit)(),
                        disabled: isSubmitButtonDisabled,
                    },
                ]}
            />
        </>
    );
}
