import useApiFetch, { ErrorReturn } from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import eventService from 'src/data/services/eventService';
import { EmptyBody } from 'src/data/models/common/emptyBody';

interface UpdateEventSeriesDataWrapperChildrenParams
    extends DataWrapperChildrenParams<ApiResponseBody<EmptyBody>> {
    updateEventSeries: (seriesId: string) => Promise<void | ErrorReturn>;
}

interface UpdateEventSeriesDataWrapperProps
    extends DataWrapperProps<UpdateEventSeriesDataWrapperChildrenParams> {
    eventId: string;
}

export const useUpdateEventSeriesDataWrapper = ({
    eventId,
    onError,
    onSuccess,
}: Omit<UpdateEventSeriesDataWrapperProps, 'children'> & BaseFetchOptions) => {
    const [{ loading, errors, resetData, data }, handleUpdateSeries] =
        useApiFetch<ApiResponseBody<EmptyBody>>();

    const updateEventSeriesCall = async (seriesId: string) => {
        await handleUpdateSeries(eventService.updateEventSeries(eventId, seriesId), {
            useDefaultErrorHandler: true,
            onFail: onError,
            onSuccess,
        });
    };

    return {
        updateEventSeries: updateEventSeriesCall,
        data,
        loading,
        errors,
        resetData,
    };
};
