import { getInternalAxiosInstance } from 'src/data/api/api';
import ApiResponse from 'src/data/api/responses/ApiResponse';
import { INFO_ENDPOINT } from 'src/data/constants/endpoints/info-endpoints';

export interface ApplicationVersionResponse {
    branchName: string;
    deployedAt: string;
}

async function getApplicationVersion(): Promise<ApiResponse<ApplicationVersionResponse>> {
    return await getInternalAxiosInstance()
        .get<ApplicationVersionResponse>(INFO_ENDPOINT)
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export default {
    getApplicationVersion,
};
