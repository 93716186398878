import { CircularProgress } from '@mui/material';
import SupplierDetailsForm, {
    SupplierDetailsFormValues,
    type DisableAction,
} from 'src/app/components/forms/suppliers/SupplierDetailsForm';
import useArchiveSupplier from 'src/app/hooks/supplier/useArchiveSupplier';
import useCreateSupplier from 'src/app/hooks/supplier/useCreateSupplier';
import useDeleteSupplier from 'src/app/hooks/supplier/useDeleteSupplier';
import { useGetSupplierById } from 'src/app/hooks/supplier/useGetSupplierById';
import { useUnarchiveSupplier } from 'src/app/hooks/supplier/useUnarchiveSupplier';
import useUpdateSupplier from 'src/app/hooks/supplier/useUpdateSupplier';
import { parseErrors } from 'src/app/utilities/helpers/errors';
import { useGenericStyles } from 'src/shared/styles/genericStyles';
import ErrorsList from 'src/view/components/errors-list/ErrorsList';

interface SupplierDetailsFormFeatureProps {
    supplierId?: string | null;
    onSuccess?: () => void;
    onDisableSupplier?: (action: DisableAction) => void;
}

export const SupplierDetailsFormFeature = ({
    supplierId,
    onSuccess,
    onDisableSupplier,
}: SupplierDetailsFormFeatureProps) => {
    const genericClasses = useGenericStyles();

    const {
        data: getSupplierByIdData,
        isError: isGetSupplierByIdError,
        error: getSupplierByIdErrors,
    } = useGetSupplierById(supplierId || '', {
        enabled: !!supplierId,
    });

    const {
        mutate: createSupplier,
        isLoading: isCreatingSupplier,
        error: createSupplierErrors,
        isError: isCreatingSupplierError,
    } = useCreateSupplier(onSuccess);

    const {
        mutate: updateSupplier,
        isLoading: isUpdatingSupplier,
        error: updateSupplierErrors,
        isError: isUpdatingSupplierError,
    } = useUpdateSupplier(onSuccess);

    const {
        mutate: archiveSupplier,
        isLoading: isArchivingSupplier,
        error: archivingSupplierErrors,
        isError: isArchivingError,
    } = useArchiveSupplier(onSuccess);

    const {
        mutate: unarchiveSupplier,
        isLoading: isUnarchivingSupplier,
        error: unarchivingSupplierErrors,
        isError: isUnarchivingError,
    } = useUnarchiveSupplier(onSuccess);

    const {
        mutate: deleteSupplier,
        isLoading: isDeletingSupplier,
        error: deletingSupplierErrors,
        isError: isDeletingSupplierError,
    } = useDeleteSupplier(onSuccess);

    const onFormSubmit = (values: SupplierDetailsFormValues) => {
        const { name } = values;

        const dto = { name };

        supplierId
            ? updateSupplier({
                  id: supplierId,
                  dto,
              })
            : createSupplier(dto);
    };

    const hasErrors =
        isUpdatingSupplierError ||
        isCreatingSupplierError ||
        isGetSupplierByIdError ||
        isArchivingError ||
        isUnarchivingError ||
        isDeletingSupplierError;

    if (supplierId && !getSupplierByIdData && !hasErrors)
        return (
            <div className={genericClasses.center}>
                <CircularProgress />
            </div>
        );

    const isLoading =
        isUnarchivingSupplier || isUpdatingSupplier || isCreatingSupplier || isArchivingSupplier;

    return (
        <>
            {hasErrors && (
                <>
                    <ErrorsList errors={parseErrors(createSupplierErrors)} />
                    <ErrorsList errors={parseErrors(updateSupplierErrors)} />
                    <ErrorsList errors={parseErrors(getSupplierByIdErrors)} />
                    <ErrorsList errors={parseErrors(archivingSupplierErrors)} />
                    <ErrorsList errors={parseErrors(deletingSupplierErrors)} />
                    <ErrorsList errors={parseErrors(unarchivingSupplierErrors)} />
                </>
            )}
            <SupplierDetailsForm
                defaultValues={{
                    name: getSupplierByIdData?.data.data.name,
                }}
                editMode={!!supplierId}
                onSubmit={onFormSubmit}
                loading={isLoading}
                archiveLoading={isArchivingSupplier || isUnarchivingSupplier}
                deleteLoading={isDeletingSupplier}
                canBeArchived={!!getSupplierByIdData?.data.data.canBeArchived}
                canBeDeleted={!!getSupplierByIdData?.data.data.canBeDeleted}
                isArchived={!!getSupplierByIdData?.data.data.isArchived}
                onSupplierActionConfirmation={async (action) => {
                    if (!supplierId) return;
                    if (action === 'archive') archiveSupplier(supplierId);
                    if (action === 'unarchive') unarchiveSupplier(supplierId);
                    if (action === 'delete') deleteSupplier(supplierId);
                    onDisableSupplier?.(action);
                }}
            />
        </>
    );
};
