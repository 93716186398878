import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { CSSProperties } from '@mui/styles';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import classNames from 'classnames';
import { WheelEvent } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
    inputContainer: (props: Partial<InputPropsType>) => {
        let background: CSSProperties['color'] = 'white';
        let borderColor = theme.colors.lightGrey;

        if (props.grey) {
            background = theme.colors.lightestGrey;
        }

        if (props.border) {
            borderColor = theme.colors.mediumGrey;
        }

        if (props.disabled) {
            background = theme.colors.inputDisabledBackground;
        }

        return {
            width: '100%',
            backgroundColor: background,
            border: 'none',
            '& > div': {
                width: '100%',
                marginTop: 0,
                '& .MuiInputBase-multiline': {
                    padding: 0,
                },
                '& .MuiOutlinedInput-root:not(.MuiInputBase-multiline)': {
                    height: 45,
                },
            },
            '& > div > div': {
                width: '100%',
                backgroundColor: background,
                '&::before': {
                    content: 'none',
                },
                '&::after': {
                    content: 'none',
                },
                '& input': {
                    padding: theme.spacing(1.25),
                    fontSize: theme.spacing(1.75),
                    borderRadius: theme.layout.borderRadius.regular,
                    MozAppearance: 'textfield',
                    '&::placeholder': {
                        fontStyle: 'normal',
                    },
                    '&:focus': {
                        borderColor: theme.colors.mediumGrey,
                    },
                    '&::-webkit-outer-spin-button': {
                        WebkitAppearance: 'none',
                        margin: 0,
                    },
                    '&::-webkit-inner-spin-button': {
                        WebkitAppearance: 'none',
                        margin: 0,
                    },
                },
                '& textarea': {
                    backgroundColor: background,
                    padding: theme.spacing(1.5),
                    fontSize: theme.spacing(1.75),
                    borderRadius: theme.layout.borderRadius.regular,
                    border: '1px solid',
                    borderColor: borderColor,
                    '&::placeholder': {
                        fontStyle: 'normal',
                    },
                    '&:focus': {
                        borderColor: borderColor,
                    },
                },
            },
            '& .Mui-disabled fieldset, .Mui-disabled:hover fieldset': {
                border: 'none !important',
            },
        };
    },
    input: {
        '&:hover fieldset': {
            borderColor: 'grey',
        },
    },
    noBorder: {
        '& .MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${theme.colors.lightGrey}`,
        },
    },
    greyHelperText: {
        marginTop: theme.spacing(0.125),
        minHeight: theme.spacing(2.5),
        right: theme.spacing(1.25),
        color: theme.colors.darkGrey,
    },
    errorHelperText: {
        marginTop: theme.spacing(0.125),
        minHeight: theme.spacing(2.5),
        right: theme.spacing(1.25),
        color: theme.palette.error.main,
    },
}));

export interface InputProps {
    inputHelperText?: string;
    greyInputHelperText?: string;
    grey?: boolean;
    border?: boolean;
    containerClassName?: string;
    noBorder?: boolean;
    padding?: CSSProperties['padding'];
    disableManualInput?: boolean;
}

export type InputPropsType = InputProps & TextFieldProps;

const Input = ({
    inputHelperText,
    greyInputHelperText,
    grey,
    border,
    value = '',
    containerClassName,
    noBorder,
    disableManualInput,
    ...props
}: InputPropsType): JSX.Element => {
    const classes = useStyles({ grey, border, disabled: props.disabled });

    const showHelperBlock = inputHelperText && inputHelperText.length > 0;
    const showGreyHelperBlock = greyInputHelperText && greyInputHelperText.length > 0;

    const onWheel = (e: WheelEvent<HTMLDivElement>) => {
        // Prevents the content of the input field changing with scroll-wheel
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: Should be relatively harmless error
        e.target.blur();
    };

    const preventManualInput = (e: React.KeyboardEvent<HTMLDivElement>) => {
        e.preventDefault();
    };

    const handleKeydown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (disableManualInput) preventManualInput(e);
    };

    return (
        <div
            className={classNames(
                containerClassName,
                classes.inputContainer,
                noBorder && classes.noBorder
            )}
        >
            <TextField
                onWheel={onWheel}
                {...props}
                className={classes.input}
                value={value}
                onKeyDown={handleKeydown}
            />
            {showHelperBlock && <div className={classes.errorHelperText}>{inputHelperText}</div>}
            {showGreyHelperBlock && (
                <div className={classes.greyHelperText}>{greyInputHelperText}</div>
            )}
        </div>
    );
};

export default Input;
