import { Alert, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { BatchUploadTicketFilesFeature } from 'src/app/components/features/tickets/BatchUploadTicketFilesFeature';
import { useFetchEventById } from 'src/app/hooks/events/useFetchEventById';
import { ETBContainer } from 'src/view/components/container/container';
import Header from 'src/view/components/header/Header';

const BatchUploadTickets = () => {
    const { eventId } = useParams<{ eventId: string }>();
    const { data, isError } = useFetchEventById(
        eventId || '',
        {},
        {
            enabled: !!eventId,
        }
    );

    if (!eventId) {
        return <Alert severity="warning">No event ID found in the url</Alert>;
    }

    if (isError) {
        return <Alert severity="error">We could not find the event</Alert>;
    }

    return (
        <>
            <Header>
                <Grid container spacing={0.5}>
                    <Grid item>
                        <h1>{data?.data.data.name} - Batch upload tickets</h1>
                    </Grid>
                </Grid>
            </Header>
            <ETBContainer>
                <BatchUploadTicketFilesFeature eventId={eventId} eventName={data?.data.data.name} />
            </ETBContainer>
        </>
    );
};

export default BatchUploadTickets;
