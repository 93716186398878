import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import defaultFilterOptions from 'src/app/constants/constants/filters/defaultFilterOptions';
import { ORDERLINE_STATUS } from 'src/data/enums/orderline';
import _ from 'lodash';
import FilterOption from 'src/data/api/common/FilterOption';
import formatValuesToParams from '../../utilities/helpers/formatValuesToParams';
import { sourceSystemOptions } from 'src/app/constants/constants/sourceSystem';
import Filters, { FiltersArray } from 'src/app/components/forms/filters/Filters';
import {
    FilterAutoCompleteOption,
    FilterAutoCompleteOptions,
} from 'src/view/components/filters/AutoComplete/AutoComplete';
import { Grid } from '@mui/material';
import { useSpacingStyles } from 'src/shared/styles/spacingStyles';
import { shippingStatusStrings } from 'src/data/constants/order';
import { SHIPPING_STATUS } from 'src/data/enums/order';
import { FilteredOnNotification } from 'src/view/components/filters/FilteredOnNotification/FilteredOnNotification';
import { useFetchOrderById } from 'src/app/hooks/orders/useFetchOrderById';

export interface OrderlineFiltersFormValues {
    category?: FilterAutoCompleteOptions;
    assignedSeatingPlanCategoryId?: FilterAutoCompleteOptions;
    isAssigned?: FilterAutoCompleteOption;
    status?: FilterAutoCompleteOptions;
    quantityParity?: FilterAutoCompleteOption;
    sourceSystem?: FilterAutoCompleteOption;
    searchTerm?: string;
    orderId?: string;
    shippingStatus?: FilterAutoCompleteOptions;
    hasAssignmentInstructions?: FilterAutoCompleteOption;
    hasOrderInternalNotes?: FilterAutoCompleteOption;
}

export const shippingStatusOptions: FilterAutoCompleteOptions = [
    {
        label: shippingStatusStrings[SHIPPING_STATUS.NotSent],
        value: SHIPPING_STATUS.NotSent,
    },
    {
        label: shippingStatusStrings[SHIPPING_STATUS.Sent],
        value: SHIPPING_STATUS.Sent,
    },
    {
        label: shippingStatusStrings[SHIPPING_STATUS.Unknown],
        value: SHIPPING_STATUS.Unknown,
    },
];

export const statusOptions: FilterAutoCompleteOptions = [
    {
        value: ORDERLINE_STATUS.ACTIVE,
        label: ORDERLINE_STATUS.ACTIVE,
    },
    {
        value: ORDERLINE_STATUS.CANCELLED,
        label: ORDERLINE_STATUS.CANCELLED,
    },
    {
        value: ORDERLINE_STATUS.UNKNOWN,
        label: ORDERLINE_STATUS.UNKNOWN,
    },
];

export const assignedStatusOptions: FilterAutoCompleteOptions = [
    {
        value: undefined,
        label: 'All',
    },
    {
        value: 'true',
        label: 'Show assigned only',
    },
    {
        value: 'false',
        label: 'Show unassigned only',
    },
];

export const assignmentInstructionsOptions: FilterAutoCompleteOptions = [
    {
        value: 'true',
        label: 'Orders with instructions only',
    },
];

export const orderInternalNotesOptions = [
    {
        value: 'true',
        label: 'Orders with notes only',
    },
];

export const { quantityOptions } = defaultFilterOptions;

interface Props {
    defaultValues: OrderlineFiltersFormValues;
    categoryOptions: FilterAutoCompleteOptions;
    assignedCategoryOptions: FilterAutoCompleteOptions;
    statusOptions: FilterAutoCompleteOptions;
    assignmentOptions: FilterAutoCompleteOptions;
    shippingStatusOptions: FilterAutoCompleteOptions;
    quantityOptions: FilterAutoCompleteOptions;
    onChangeFilterOptions?: (options: FilterOption[]) => void;
    loading?: boolean;
    onChangeSearchTerm?: (q: string) => void;
    initialSearchTerm?: string;
}

export default function OrderlineFiltersForm({
    defaultValues,
    categoryOptions,
    assignedCategoryOptions,
    statusOptions,
    assignmentOptions,
    quantityOptions,
    onChangeFilterOptions,
    loading,
    onChangeSearchTerm,
    initialSearchTerm,
    shippingStatusOptions,
}: Props) {
    const spacingClasses = useSpacingStyles();

    const [urlInvalidatedAt, setUrlInvalidatedAt] = useState<number | undefined>();
    const [formIsInitialized, setFormIsInitialized] = useState(false);

    const orderlineFiltersForm = useForm<OrderlineFiltersFormValues>({
        mode: 'onChange',
        defaultValues,
    });

    const { control, reset, watch, setValue } = orderlineFiltersForm;
    const category = watch('category');
    const assignedSeatingPlanCategoryId = watch('assignedSeatingPlanCategoryId');
    const isAssigned = watch('isAssigned');
    const status = watch('status');
    const quantityParity = watch('quantityParity');
    const sourceSystem = watch('sourceSystem');
    const orderId = watch('orderId');
    const shippingStatus = watch('shippingStatus');
    const hasAssignmentInstructions = watch('hasAssignmentInstructions');
    const hasOrderInternalNotes = watch('hasOrderInternalNotes');

    useEffect(() => {
        if (!defaultValues || formIsInitialized) return;

        reset(defaultValues);
        setFormIsInitialized(true);
    }, [defaultValues, formIsInitialized, reset]);

    useEffect(() => {
        if (!onChangeFilterOptions || !urlInvalidatedAt) return;

        const ticketsFilter = _.compact([
            formatValuesToParams.formatOptionsToParam('seatingPlanCategoryId', category),
            formatValuesToParams.formatOptionsToParam('assignedSeatingPlanCategoryId', assignedSeatingPlanCategoryId),
            formatValuesToParams.formatOptionToParam('isAssigned', isAssigned),
            formatValuesToParams.formatOptionsToParam('status', status),
            formatValuesToParams.formatOptionToParam('shippingStatus', shippingStatus),
            formatValuesToParams.formatOptionToParam('quantityParity', quantityParity),
            formatValuesToParams.formatOptionToParam('sourceSystem', sourceSystem),
            formatValuesToParams.formatOptionToParam(
                'hasOrderInternalNotes',
                hasOrderInternalNotes
            ),
            formatValuesToParams.formatOptionToParam(
                'hasAssignmentInstructions',
                hasAssignmentInstructions
            ),
            formatValuesToParams.formatInputToParam('orderId', orderId),
        ]).filter((f) => f.value !== undefined);

        onChangeFilterOptions(ticketsFilter);
    }, [urlInvalidatedAt]);

    const onInputChange = (value: string) => {
        setValue('searchTerm', value, {
            shouldValidate: true,
        });

        onChangeSearchTerm?.(value);
    };

    const arrayOfFilters: FiltersArray = [
        {
            type: 'autocomplete',
            options: categoryOptions,
            name: 'category',
            filterPlaceholderProps: {
                selectedText: 'Category selected',
                placeholder: 'Select a category',
            },
            onChange: () => setUrlInvalidatedAt(Date.now()),
            isMulti: true,
            disabled: loading,
        },
        {
            type: 'autocomplete',
            options: assignedCategoryOptions,
            name: 'assignedSeatingPlanCategoryId',
            filterPlaceholderProps: {
                selectedText: 'Assigned Categories selected',
                placeholder: 'Select assigned category',
            },
            onChange: () => setUrlInvalidatedAt(Date.now()),
            isMulti: true,
            disabled: loading,
        },
        {
            type: 'autocomplete',
            options: assignmentOptions,
            name: 'isAssigned',
            filterPlaceholderProps: {
                placeholder: 'Select assigned status',
            },
            onChange: () => setUrlInvalidatedAt(Date.now()),
        },
        {
            type: 'autocomplete',
            options: statusOptions,
            name: 'status',
            filterPlaceholderProps: {
                selectedText: 'Status selected',
                placeholder: 'Select status',
            },
            onChange: () => setUrlInvalidatedAt(Date.now()),
            isMulti: true,
        },
        {
            type: 'autocomplete',
            options: shippingStatusOptions,
            name: 'shippingStatus',
            filterPlaceholderProps: {
                selectedText: 'Sending status selected',
                placeholder: 'Select sending status',
            },
            onChange: () => setUrlInvalidatedAt(Date.now()),
            disabled: loading,
        },
        {
            type: 'autocomplete',
            options: quantityOptions,
            name: 'quantityParity',
            filterPlaceholderProps: {
                placeholder: 'Select quantity (odd/even)',
            },
            onChange: () => setUrlInvalidatedAt(Date.now()),
        },
        {
            type: 'autocomplete',
            options: sourceSystemOptions,
            name: 'sourceSystem',
            filterPlaceholderProps: {
                placeholder: 'Select source system',
            },
            onChange: () => setUrlInvalidatedAt(Date.now()),
        },
        {
            type: 'autocomplete',
            options: assignmentInstructionsOptions,
            name: 'hasAssignmentInstructions',
            filterPlaceholderProps: {
                placeholder: 'Assignment instructions',
            },
            onChange: () => setUrlInvalidatedAt(Date.now()),
        },
        {
            type: 'autocomplete',
            options: orderInternalNotesOptions,
            name: 'hasOrderInternalNotes',
            filterPlaceholderProps: {
                placeholder: 'Internal notes',
            },
            onChange: () => setUrlInvalidatedAt(Date.now()),
        },
        {
            name: 'searchTerm',
            onChange: onInputChange,
            type: 'search',
            searchPlaceholder: 'Search on external order reference',
            searchDefaultValue: initialSearchTerm,
        },
    ];

    const { data: orderData, isLoading: isFetchingOrder } = useFetchOrderById(orderId || '', {
        enabled: !!orderId,
    });

    return (
        <Grid container>
            <Filters control={control} filters={arrayOfFilters} />
            {orderData && !isFetchingOrder && (
                <Grid item xs={12} className={spacingClasses.spacingBottom}>
                    <FilteredOnNotification
                        label="order number"
                        value={orderData?.data.data.orderNumber.toUpperCase() || ''}
                        onClickCancel={() => {
                            setValue('orderId', undefined);
                            setUrlInvalidatedAt(Date.now());
                        }}
                    />
                </Grid>
            )}
        </Grid>
    );
}
