import { ENDPOINT_VERSIONS } from './_endpoint-versions';

export const SEATING_PLAN_CATEGORIES_FETCH_BY_ID_ENDPOINT = (seatingPlanCategoryId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/seating-plan-categories/${seatingPlanCategoryId}`;

export const SEATING_PLAN_CATEGORIES_FETCH_BLOCKS_ENDPOINT = (seatingPlanCategoryId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/seating-plan-categories/${seatingPlanCategoryId}/blocks`;

export const SEATING_PLAN_CATEGORIES_CREATE_BLOCK_ENDPOINT = (seatingPlanCategoryId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/seating-plan-categories/${seatingPlanCategoryId}/blocks/create`;

export const SEATING_PLAN_CATEGORIES_FETCH_ROWS_ENDPOINT = (
    seatingPlanCategoryId: string,
    blockId: string
) =>
    `/${ENDPOINT_VERSIONS.V2}/seating-plan-categories/${seatingPlanCategoryId}/blocks/${blockId}/rows`;

export const SEATING_PLAN_CATEGORIES_CREATE_ROW_ENDPOINT = (
    seatingPlanCategoryId: string,
    blockId: string
) =>
    `/${ENDPOINT_VERSIONS.V2}/seating-plan-categories/${seatingPlanCategoryId}/blocks/${blockId}/rows/create`;

export const SEATING_PLAN_CATEGORIES_UPLOAD_IMAGE_ENDPOINT = (seatingPlanCategoryId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/seating-plan-categories/${seatingPlanCategoryId}/upload-image`;

export const SEATING_PLAN_CATEGORIES_UPLOAD_ITINERARY_ENDPOINT = (seatingPlanCategoryId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/seating-plan-categories/${seatingPlanCategoryId}/upload-itinerary-file`;

export const SEATING_PLAN_CATEGORIES_DELETE_ITINERARY_ENDPOINT = (seatingPlanCategoryId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/seating-plan-categories/${seatingPlanCategoryId}/delete-itinerary-files`;

export const SEATING_PLAN_CATEGORIES_EDIT_ITINERARY_FILE_ENDPOINT = (
    seatingPlanCategoryId: string,
    fileUploadId: string
) =>
    `/${ENDPOINT_VERSIONS.V2}/seating-plan-categories/${seatingPlanCategoryId}/itinerary-files/${fileUploadId}/edit-itinerary-file`;

export const SEATING_PLAN_CATEGORIES_DELETE_ENDPOINT = (id: string) =>
    `/${ENDPOINT_VERSIONS.V2}/seating-plan-categories/${id}/delete`;

export const SEATING_PLAN_CATEGORIES_ARCHIVE_ENDPOINT = (id: string) =>
    `/${ENDPOINT_VERSIONS.V2}/seating-plan-categories/${id}/archive`;

export const SEATING_PLAN_CATEGORIES_UN_ARCHIVE_ENDPOINT = (id: string) =>
    `/${ENDPOINT_VERSIONS.V2}/seating-plan-categories/${id}/unarchive`;
