import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
    editText: {
        'font-weight': theme.typography.fontWeightBold,
        color: theme.colors.blue,
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));

export interface FormEditTextProps {
    text: string;
    onClick: () => void;
}

export default function FormEditText({ text, onClick }: FormEditTextProps) {
    const classes = useStyles();

    return (
        <div className={classes.editText} onClick={onClick}>
            <span>{text}</span>
        </div>
    );
}
