import { useCallback, useEffect, useMemo, useRef } from 'react';
import {
    CreateVenueForm,
    type CreateVenueFormValues,
} from 'src/app/components/forms/CreateVenueForm';
import useApiFetch from 'src/app/hooks/useApiFetch';
import type ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import type { IdResponse } from 'src/data/models/common/idResponse';
import type { Venue, VenueOnUpdate } from 'src/data/models/venue/venue';
import venuesService from 'src/data/services/venuesService';
import { countryList } from 'src/shared/countries';

interface Props {
    venueId?: string;
    onSuccessfullyCreateVenue?: (id: string, name: string) => void;
    onSuccessfullyUpdateVenue?: () => void;
}

export default function CreateVenueFormDataWrapper({
    venueId,
    onSuccessfullyCreateVenue,
    onSuccessfullyUpdateVenue,
}: Props): JSX.Element {
    const { data, loading } = useFetchVenue(venueId);
    const { updateVenue, isUpdating } = useUpdateVenue(onSuccessfullyUpdateVenue);
    const { createVenue, isCreating } = useCreateVenue(onSuccessfullyCreateVenue);

    return (
        <CreateVenueForm
            loading={loading || isCreating || isUpdating}
            formDefaultValues={data}
            onFormSubmit={venueId ? (values) => updateVenue(venueId, values) : createVenue}
        />
    );
}

function useFetchVenue(venueId: string | undefined) {
    const [{ data, loading }, fetchVenue] = useApiFetch<ApiResponseBody<Venue>>();

    useEffect(() => {
        if (!venueId) return;
        fetchVenue(venuesService.getVenueById(venueId), { useDefaultErrorHandler: true });
    }, [venueId]);

    const formValues = useMemo<Partial<CreateVenueFormValues> | undefined>(() => {
        if (!venueId || !data) return;

        const { name, address } = data.data;
        const {
            addressLine1,
            addressLine2,
            postalCode,
            city,
            countryCode,
            location: { latitude, longitude },
        } = address;

        const mapVenueToFormValues = {
            venueName: name,
            address: addressLine1,
            addressLine2: addressLine2,
            postalCode: postalCode,
            city: city,
            country: countryList.find((country) => country.value === countryCode),
            longitude,
            latitude,
        };

        return mapVenueToFormValues;
    }, [data]);

    return { data: formValues, loading };
}

function useUpdateVenue(onSuccess?: () => void) {
    const [{ loading: isUpdating }, update] = useApiFetch<ApiResponseBody<VenueOnUpdate>>();
    const onSuccessRef = useRef(onSuccess);

    const updateVenue = useCallback(
        async (venueId: string, values: CreateVenueFormValues) => {
            const {
                venueName,
                address,
                addressLine2,
                postalCode,
                city,
                country,
                latitude,
                longitude,
            } = values;

            const addressDto = {
                addressLine1: address,
                addressLine2,
                postalCode,
                city,
                countryCode: country.value,
                lat: latitude,
                lng: longitude,
            };

            await update(venuesService.updateVenue(venueId, venueName, addressDto), {
                useDefaultErrorHandler: true,
                onSuccess: onSuccessRef.current,
            });
        },
        [update]
    );

    return { updateVenue, isUpdating };
}

function useCreateVenue(onSuccess?: (id: string, name: string) => void) {
    const [{ loading: isCreating }, create] = useApiFetch<ApiResponseBody<IdResponse>>();
    const onSuccessRef = useRef(onSuccess);

    const createVenue = useCallback(
        async (values: CreateVenueFormValues) => {
            const {
                venueName,
                address,
                addressLine2,
                postalCode,
                city,
                country,
                latitude,
                longitude,
            } = values;

            const venueAddress = {
                addressLine1: address,
                addressLine2,
                postalCode,
                city,
                countryCode: country?.value,
                lat: latitude,
                lng: longitude,
            };

            await create(venuesService.createVenue(venueName, venueAddress), {
                useDefaultErrorHandler: true,
                onSuccess: (data) => {
                    data && onSuccessRef.current?.(data.data.id, venueName);
                },
            });
        },
        [create]
    );

    return { isCreating, createVenue };
}
