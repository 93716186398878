import { useQuery } from '@tanstack/react-query';
import seatingPlanService from 'src/data/services/seatingPlanService';

const GET_SEATING_PLAN_BY_ID_QUERY_KEY = "GET_SEATING_PLAN_BY_ID";

export const useGetSeatingPlanById = (seatingPlanId: string | undefined) => {
    return useQuery({
        queryKey: [GET_SEATING_PLAN_BY_ID_QUERY_KEY, seatingPlanId],
        queryFn: () => seatingPlanService.getSeatingplanById(seatingPlanId ?? ""),
        enabled: !!seatingPlanId,
    });
};
