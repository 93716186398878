import { parseErrors } from 'src/app/utilities/helpers/errors';
import DictionarySkeleton from 'src/view/components/dictionary-skeleton/DictionarySkeleton';
import ErrorsList from 'src/view/components/errors-list/ErrorsList';
import OrderDetailsForm from 'src/app/components/forms/OrderDetailsForm';
import mapOrderDataToViewModel from 'src/app/utilities/mappers/mapOrderDataToFormValues';
import { useFetchOrderById } from 'src/app/hooks/orders/useFetchOrderById';

const OrderDetailsFeature = ({ orderId }: { orderId: string }) => {
    const {
        data: orderResponse,
        isLoading,
        isError,
        error,
    } = useFetchOrderById(orderId, {
        enabled: !!orderId,
    });

    if (isLoading) return <DictionarySkeleton rows={3} />;

    if (isError) return <ErrorsList errors={parseErrors(error)} />;

    return (
        <OrderDetailsForm
            defaultValues={mapOrderDataToViewModel(orderResponse.data.data)}
            disableEdit
        />
    );
};

export default OrderDetailsFeature;
