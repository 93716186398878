import { useMutation, useQueryClient } from '@tanstack/react-query';
import Toaster from 'src/app/utilities/helpers/Toaster';
import { parseErrors } from 'src/app/utilities/helpers/errors';
import { deleteTicketFiles } from 'src/data/services/ticketService';
import { FETCH_EVENT_TICKETS_QUERY_KEY } from './useFetchEventTickets';

export const useDeleteTicketsFiles = (onSuccess?: () => void) => {
    const queryClient = useQueryClient();

    const { mutate: deleteTicketsFiles } = useMutation({
        mutationFn: (ticketIds: string[]) => deleteTicketFiles(ticketIds),
        onSuccess: () => {
            queryClient.invalidateQueries([FETCH_EVENT_TICKETS_QUERY_KEY]);
            Toaster.toast('Successfully deleted all the tickets files.', {
                variant: 'success',
            });
            onSuccess?.();
        },
        onError: (error) => Toaster.toastErrors(parseErrors(error)),
    });

    return {
        deleteTicketsFiles,
    };
};
