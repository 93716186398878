import { getInternalAxiosInstance } from 'src/data/api/api';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import QueryParam from 'src/data/api/common/QueryParam';
import { getQueryParams } from 'src/data/api/endpointUrlBuilder';
import ApiResponse from 'src/data/api/responses/ApiResponse';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import PaginatedApiResponseBody from 'src/data/api/responses/PaginatedApiResponseBody';
import {
    SUPPLIERS_ARCHIVE_ENDPOINT,
    SUPPLIERS_CREATE_ENDPOINT,
    SUPPLIERS_DELETE_ENDPOINT,
    SUPPLIERS_GET_BY_ID_ENDPOINT,
    SUPPLIERS_GET_ENDPOINT,
    SUPPLIERS_UNARCHIVE_ENDPOINT,
    SUPPLIERS_UPDATE_ENDPOINT,
} from 'src/data/constants/endpoints/suppliers-endpoints';
import { IdResponse } from 'src/data/models/common/idResponse';
import { Supplier } from 'src/data/models/supplier/supplier';
import { EmptyBody } from '../models/common/emptyBody';

async function getSuppliers(
    options?: BaseFetchOptions
): Promise<ApiResponse<PaginatedApiResponseBody<Supplier>>> {
    const extraQueryParams: QueryParam[] = [];

    if (options?.q) {
        extraQueryParams.push({
            key: 'filter[query]',
            value: options.q,
        });
    }

    extraQueryParams.push({
        key: 'filter[withArchived]',
        value: 'true',
    });

    return await getInternalAxiosInstance()
        .get<PaginatedApiResponseBody<Supplier>>(SUPPLIERS_GET_ENDPOINT, {
            params: options && getQueryParams(options, extraQueryParams),
        })
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function getSupplierById(
    supplierId: string
): Promise<ApiResponse<ApiResponseBody<Supplier>>> {
    return await getInternalAxiosInstance()
        .get<ApiResponseBody<Supplier>>(SUPPLIERS_GET_BY_ID_ENDPOINT(supplierId))
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export interface CreateSupplierDto {
    name: string;
}

async function createSupplier(
    dto: CreateSupplierDto
): Promise<ApiResponse<ApiResponseBody<IdResponse>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<IdResponse>>(SUPPLIERS_CREATE_ENDPOINT, dto)
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function updateSupplier(
    supplierId: string,
    dto: CreateSupplierDto
): Promise<ApiResponse<ApiResponseBody<IdResponse>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<IdResponse>>(SUPPLIERS_UPDATE_ENDPOINT(supplierId), dto)
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function archiveSupplier(
    supplierId: string
): Promise<ApiResponse<ApiResponseBody<EmptyBody>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<EmptyBody>>(SUPPLIERS_ARCHIVE_ENDPOINT(supplierId))
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function unarchiveSupplier(
    supplierId: string
): Promise<ApiResponse<ApiResponseBody<EmptyBody>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<EmptyBody>>(SUPPLIERS_UNARCHIVE_ENDPOINT(supplierId))
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function deleteSupplier(
    supplierId: string
): Promise<ApiResponse<ApiResponseBody<EmptyBody>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<EmptyBody>>(SUPPLIERS_DELETE_ENDPOINT(supplierId))
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export default {
    getSuppliers,
    getSupplierById,
    createSupplier,
    updateSupplier,
    archiveSupplier,
    unarchiveSupplier,
    deleteSupplier,
};
