import useApiFetch, { ErrorReturn } from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import seatingPlanCategoryService from 'src/data/services/seatingPlanCategoryService';
import { IdResponse } from 'src/data/models/common/idResponse';

interface CreateBlockDataWrapperChildrenParams
    extends DataWrapperChildrenParams<ApiResponseBody<IdResponse>> {
    createBlock: (name: string, seatingplanCategoryId: string) => Promise<void | ErrorReturn>;
}

export type OnCreateBlockSuccessType = IdResponse & { name: string };

type CreateBlockDataWrapperProps = DataWrapperProps<
    CreateBlockDataWrapperChildrenParams,
    OnCreateBlockSuccessType
> &
    BaseFetchOptions;

export const useCreateBlockDataWrapper = ({
    onError,
    onSuccess,
}: Omit<CreateBlockDataWrapperProps, 'children'>) => {
    const [{ data, loading, errors, resetData }, handleCreateBlock] =
        useApiFetch<ApiResponseBody<IdResponse>>();

    const createBlockCall = (name: string, seatingplanCategoryId: string) => {
        return handleCreateBlock(
            seatingPlanCategoryService.createBlock(name, seatingplanCategoryId),
            {
                useDefaultErrorHandler: true,
                onFail: onError,
                onSuccess: (data) => {
                    onSuccess?.({
                        id: data?.data.id || name,
                        name,
                    });
                },
            }
        );
    };

    return {
        createBlock: createBlockCall,
        data,
        loading,
        errors,
        resetData,
    };
};
