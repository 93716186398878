import { zodResolver } from '@hookform/resolvers/zod';
import { CircularProgress, FormHelperText, Grid } from '@mui/material';
import { useTheme } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';
import { Controller, useForm } from 'react-hook-form';
import { FormProps } from 'src/app/interfaces/form/formProps';
import { zodRequiredStringSchema } from 'src/app/utilities/zod/zodRequiredStringSchema';
import { useSpacingStyles } from 'src/shared/styles/spacingStyles';
import Button from 'src/view/components/button/Button';
import FormFieldError from 'src/view/components/form/FormFieldError';
import Input from 'src/view/components/input/Input';
import z from 'zod';

const useStyles = makeStyles((theme) => ({
    searchButton: {
        marginTop: theme.spacing(0.5),
    },
}));

const searchSplitsFormValidation = z.object({
    id: zodRequiredStringSchema,
});

type FormFields = z.infer<typeof searchSplitsFormValidation>;

export const SearchSplitsForm = ({
    onSubmit,
    defaultValues,
    loading,
    disableSubmit,
}: FormProps<FormFields>) => {
    const theme = useTheme();
    const spacingClasses = useSpacingStyles();
    const classes = useStyles();

    const { control, handleSubmit } = useForm<FormFields>({
        defaultValues,
        resolver: zodResolver(searchSplitsFormValidation),
    });

    return (
        <Grid container>
            <Grid item xs={8}>
                <Controller
                    name="id"
                    control={control}
                    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
                        <>
                            <Input
                                name={name}
                                value={value.toUpperCase()}
                                onChange={onChange}
                                placeholder="Enter an ID"
                            />
                            <FormFieldError message={error?.message} />
                            <FormHelperText>
                                The split ID can be found in the tickets overview in the split
                                position column.
                            </FormHelperText>
                        </>
                    )}
                />
            </Grid>
            <Grid item xs={3}>
                <Button
                    className={classnames(spacingClasses.spacingLeft, classes.searchButton)}
                    onClick={handleSubmit(onSubmit)}
                    disabled={loading || disableSubmit}
                    startIcon={
                        loading && <CircularProgress size={theme.layout.loader.sizes.small} />
                    }
                >
                    Search
                </Button>
            </Grid>
        </Grid>
    );
};
