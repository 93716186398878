import { Theme, useTheme } from '@mui/material';

interface PencilSvgProps {
    sizeInPx: number;
    containerColor?: string;
    pencilColor?: string;
}

export default function PencilSvg({
    sizeInPx = 18,
    containerColor,
    pencilColor,
}: PencilSvgProps): JSX.Element {
    const theme = useTheme<Theme>();

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={sizeInPx}
            height={sizeInPx}
            viewBox="0 0 18 18"
        >
            <g id="Group_1462" data-name="Group 1462" transform="translate(-1335 -434)">
                <circle
                    id="Ellipse_287"
                    data-name="Ellipse 287"
                    cx="9"
                    cy="9"
                    r="9"
                    transform="translate(1335 434)"
                    fill={containerColor || theme.palette.primary.main}
                />
                <g id="Group_422" data-name="Group 422" transform="translate(1338.998 438.004)">
                    <path
                        id="Path_7"
                        data-name="Path 7"
                        d="M6.178,82.636l-5.5,5.5a.22.22,0,0,0-.057.1L.006,90.69a.217.217,0,0,0,.21.269.215.215,0,0,0,.052-.007l2.449-.61a.217.217,0,0,0,.1-.057l5.505-5.5Z"
                        transform="translate(0 -80.966)"
                        fill={pencilColor || theme.colors.white}
                    />
                    <path
                        id="Path_8"
                        data-name="Path 8"
                        d="M336.89,1.412,336.277.8a1.11,1.11,0,0,0-1.532,0l-.751.75,2.145,2.145.751-.75a1.084,1.084,0,0,0,0-1.532Z"
                        transform="translate(-327.202 -0.493)"
                        fill={pencilColor || theme.colors.white}
                    />
                </g>
            </g>
        </svg>
    );
}
