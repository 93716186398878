import IconComponent from 'src/view/icons/IconComponent';
import CheckIcon from 'src/view/icons/CheckIcon';
import CancelIcon from '@mui/icons-material/Cancel';
import { SIZES } from 'src/shared/theme/enums';

export const renderBooleanIcon = (success: boolean) =>
    success ? (
        <IconComponent Icon={CheckIcon} success size={SIZES.SMALL} />
    ) : (
        <IconComponent Icon={CancelIcon} error size={SIZES.SMALL} />
    );
