import { useCallback, useEffect } from 'react';
import {
    OrderlinesAutoAssignForm,
    OrderlinesAutoAssignFormValues,
} from 'src/app/components/forms/order-lines/OrderlinesAutoAssignForm';
import { AutoCompleteOptions } from 'src/view/components/auto-complete/interfaces';
import { useSpacingStyles } from 'src/shared/styles/spacingStyles';
import ErrorsList from 'src/view/components/errors-list/ErrorsList';
import { useRunAutoAssignDataWrapper } from 'src/app/components/data-wrappers/orderline/RunAutoAssignDataWrapper';
import { useFetchAutoAssignFormOptionsDataWrapper } from 'src/app/components/data-wrappers/orderline/FetchAutoAssignFormOptions';
import getIsGuaranteedSeatsDisabled from 'src/app/utilities/helpers/getIsGuaranteedSeatsDisabled';

interface OrderlinesAutoAssignFormFeatureProps {
    eventId: string;
}

export const OrderlinesAutoAssignFormFeature = ({
    onSuccess,
    eventId,
}: { onSuccess?: () => void } & OrderlinesAutoAssignFormFeatureProps) => {
    const spacingClasses = useSpacingStyles();
    const {
        fetchAutoAssignFormOptions,
        data: optionsData,
        loading: loadingOptions,
    } = useFetchAutoAssignFormOptionsDataWrapper({});
    const {
        runAutoAssign,
        errors,
        loading: runAutoAssignIsLoading,
    } = useRunAutoAssignDataWrapper({
        onSuccess,
    });

    useEffect(
        () => {
            fetchAutoAssignFormOptions(eventId);
        },
        // TODO: Remove disabling rule below once hook is updated with React-Query
        // // eslint-disable-next-line react-hooks/exhaustive-deps
        [eventId]
    );

    const seatingplanCategoryOptions: () => AutoCompleteOptions = useCallback(() => {
        if (!optionsData) return [];

        return optionsData?.data.seatingPlanCategoryResult.map((seatingplanCategory) => ({
            label: seatingplanCategory.name,
            value: seatingplanCategory.id,
        }));
    }, [optionsData]);

    const algorithmOptions: () => AutoCompleteOptions = useCallback(() => {
        if (!optionsData) return [];

        return optionsData?.data.algorithmOptions.map((algo) => ({
            label: algo,
            value: algo,
        }));
    }, [optionsData]);

    const onSubmit = async (values: OrderlinesAutoAssignFormValues) => {
        const { seatingplanCategory, algorithm } = values;

        if (!seatingplanCategory || !algorithm) return;

        const fallBackSeatGuaranteeValue = getIsGuaranteedSeatsDisabled(algorithm.value) ? 0 : 2;

        await runAutoAssign(eventId, {
            seatingplanCategoryId: seatingplanCategory.value,
            selectedAlgorithm: algorithm.value,
            seatGuarantee: values.seatGuarantee || fallBackSeatGuaranteeValue,
        });
    };

    return (
        <>
            <h2 className={spacingClasses.noMarginTop}>Auto Assign</h2>
            <OrderlinesAutoAssignForm
                onSubmit={onSubmit}
                seatingplanCategoryOptions={seatingplanCategoryOptions()}
                algorithmOptions={algorithmOptions()}
                loading={loadingOptions || runAutoAssignIsLoading}
            />
            <ErrorsList errors={errors} />
        </>
    );
};
