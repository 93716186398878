import { ENDPOINT_VERSIONS } from './_endpoint-versions';

export const ORDERS_GET_ENDPOINT = `/${ENDPOINT_VERSIONS.V2_1}/orders`;

export const ORDERS_UPDATE_INTERNAL_NOTES_ENDPOINT = (orderId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/orders/${orderId}/update-internal-notes`;

export const ORDERS_GET_BY_ID_GET_ENDPOINT = (orderId: string) =>
    `/${ENDPOINT_VERSIONS.V2_2}/orders/${orderId}`;

export const ORDERS_GET_ORDERLINE_BY_ID_ENDPOINT = (orderId: string, orderLineId: string) =>
    `/${ENDPOINT_VERSIONS.V2_1}/orders/${orderId}/order-lines/${orderLineId}`;

export const ORDERS_UPDATE_ORDERLINE_ASSIGNMENT_INSTRUCTIONS_ENDPOINT = (
    orderId: string,
    orderLineId: string
) =>
    `/${ENDPOINT_VERSIONS.V2}/orders/${orderId}/order-lines/${orderLineId}/update-assignment-instructions`;

export const ORDERS_ASSIGN_TICKET_FOR_ORDERLINE_ENDPOINT = (orderId: string, orderLineId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/orders/${orderId}/order-lines/${orderLineId}/assigned-tickets`;

export const ORDERS_ORDERLINE_UNASSIGN_TICKETS = `/${ENDPOINT_VERSIONS.V2}/orders/unassign-tickets`;

export const ORDERS_CHANGE_PAYMENT_STATUS_ENDPOINT = (orderId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/orders/${orderId}/change-payment-status`;

export const ORDERS_ORDER_REVOKE_MANUALLY_SENT_TICKETS = (orderId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/orders/${orderId}/revoke-manual-send-tickets`;

export const ORDERS_TICKETS_BY_ORDER_ID = (orderId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/orders/${orderId}/tickets`;

export const ORDERS_ORDERLINE_TICKETS = (orderId: string, orderLineId: string) =>
    `/${ENDPOINT_VERSIONS.V2_1}/orders/${orderId}/order-lines/${orderLineId}/assigned-tickets`;

export const ORDERS_COMPARE_ENDPOINT = `/${ENDPOINT_VERSIONS.V2_1}/orders/compare-orders`;

export const ORDERS_GET_AUTO_ASSIGN_OPTIONS = `/${ENDPOINT_VERSIONS.V2}/orders/auto-assign-options`;

export const ORDERS_POST_AUTO_ASSIGN = `/${ENDPOINT_VERSIONS.V2}/orders/auto-assign`;

export const ORDERS_POST_AUTO_ASSIGN_RESULT = `/${ENDPOINT_VERSIONS.V2}/orders/auto-assignment-results`;
