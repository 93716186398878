export type NestedKeyOf<ObjectType> = {
    [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object
        ? `${Key}` | `${Key}.${NestedKeyOf<ObjectType[Key]>}`
        : `${Key}`;
}[keyof ObjectType & (string | number)];

export function generateRandomId(prefix: string): string {
    return Math.random()
        .toString(36)
        .replace('0.', prefix || '');
}
