import CancelIcon from '@mui/icons-material/Cancel';
import { Modal as MUIModal, ModalProps as MUIModalProps, Paper } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { CSSProperties } from 'react';
import Button, { ButtonProps } from 'src/view/components/button/Button';
import { BaseComponent } from 'src/view/interfaces/BaseComponent';

export interface ModalProps extends MUIModalProps {
    title?: string;
    subTitle?: string | JSX.Element;
    buttons?: ButtonProps[];
    width?: 'small' | 'regular' | 'large' | 'x-large' | 'fluid';
    alignTitleLeft?: boolean;
    enableCloseIcon?: boolean;
}

const getWidth = (width: ModalProps['width']): CSSProperties['width'] => {
    switch (width) {
        case 'small':
            return 500;
        case 'large':
            return 800;
        case 'x-large':
            return 1350;
        case 'fluid':
            return '75%';
        default:
            return 600;
    }
};

const useStyles = makeStyles((theme: Theme) => ({
    modal: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        overflowY: 'scroll',
    },
    container: ({ width }: Partial<ModalProps>) => ({
        position: 'relative',
        outline: 'none',
        display: 'flex',
        flexDirection: 'column',
        minHeight: 175,
        width: getWidth(width),
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.layout.borderRadius.small,
        padding: theme.spacing(3, 4),
        boxShadow: theme.shadows[5],
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            minWidth: 1,
        },
        margin: theme.spacing(3, 0),
    }),
    title: {
        fontSize: '1.5rem',
        marginBottom: '1rem',
        color: theme.palette.primary.dark,
        textAlign: 'left',
        'font-weight': theme.typography.fontWeightBold,
    },
    subTitle: {
        fontSize: '1rem',
        color: theme.colors.grey,
        marginTop: 0,
    },
    modalBody: {
        flex: 1,
        overflowX: 'hidden',
    },
    modalFooter: {
        bottom: 0,
        display: 'flex',
        paddingTop: theme.spacing(2),
        justifyContent: 'flex-end',
        flexGrow: 0,
        '& .MuiButton-root:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
    },
    closeIcon: {
        cursor: 'pointer',
        position: 'absolute',
        right: 5,
        top: 5,
        '& svg': {
            transition: 'transform 100ms linear 10ms, 10ms',
            transform: 'rotate(0)',
            '&:hover': {
                transform: 'rotate(-90deg)',
            },
        },
    },
}));

export const ModalBody = ({ children }: BaseComponent) => {
    const classes = useStyles({});

    return <div className={classes.modalBody}>{children}</div>;
};

export const ModalFooter = ({ children }: BaseComponent) => {
    const classes = useStyles({});

    return <div className={classes.modalFooter}>{children}</div>;
};

const Modal = ({
    title,
    subTitle,
    open,
    onClose,
    children,
    buttons,
    alignTitleLeft,
    enableCloseIcon = true,
    ...props
}: ModalProps): JSX.Element => {
    const classes = useStyles({
        title,
        open,
        onClose,
        children,
        buttons,
        alignTitleLeft,
        ...props,
    });

    return (
        <MUIModal
            open={open}
            onClose={onClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
            disableAutoFocus
            disableEnforceFocus
            {...props}
        >
            <Paper className={classes.container}>
                {enableCloseIcon && (
                    <div className={classes.closeIcon}>
                        <CancelIcon onClick={(e) => onClose?.(e, 'backdropClick')} />
                    </div>
                )}
                {title && <div className={classes.title}>{title}</div>}
                {subTitle && <p className={classes.subTitle}>{subTitle}</p>}

                {children}

                {buttons && buttons.length > 0 && (
                    <div className={classes.modalFooter}>
                        {buttons.map((buttonProps: ButtonProps, i: number) => (
                            <Button key={`modal-button-${i}`} {...buttonProps} />
                        ))}
                    </div>
                )}
            </Paper>
        </MUIModal>
    );
};

export default Modal;
