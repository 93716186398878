import { useMutation, useQueryClient } from '@tanstack/react-query';
import CreateTeamDto from 'src/data/dtos/CreateTeamDto';
import { createTeam } from 'src/data/services/teamsService';
import { TEAMS_QUERY_KEY } from './useFetchTeams';
import Toaster from 'src/app/utilities/helpers/Toaster';

export const useCreateTeam = (onSuccess?: (id: string, name: string) => void) => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (dto: CreateTeamDto) => createTeam(dto),
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries([TEAMS_QUERY_KEY]);
            Toaster.toast(`Successfully created team - ${variables.name}`, {
                variant: 'success',
            });
            onSuccess?.(data.data.data.id, variables.name);
        },
    });
};
