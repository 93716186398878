import useApiFetch from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import ticketManagementService from 'src/data/services/ticketManagementService';
import { DataWrapperCallback } from 'src/app/interfaces/dataWrapperCallback';

interface FetchETicketDownloadLinkDataWrapperChildrenParams
    extends DataWrapperChildrenParams<string> {
    fetchDownloadLink: (id: string, callbacks?: DataWrapperCallback<string>) => Promise<string>;
}

type FetchETicketDownloadLinkDataWrapperProps = DataWrapperProps<
    FetchETicketDownloadLinkDataWrapperChildrenParams,
    string
> &
    BaseFetchOptions;

export const useFetchETicketDownloadLinkDataWrapper = ({
    onError,
    onSuccess,
}: Omit<FetchETicketDownloadLinkDataWrapperProps, 'children'>) => {
    const [{ loading, data, errors, resetData }, handleFetchDownloadLink] = useApiFetch<string>();

    const fetchETicketDownloadLinkCall = (
        ticketId: string,
        callbacks?: DataWrapperCallback<string>
    ) => {
        return handleFetchDownloadLink(ticketManagementService.getTicketLink(ticketId), {
            useDefaultErrorHandler: true,
            onFail: () => {
                onError?.();
                callbacks?.onFail?.();
            },
            onSuccess: (data) => {
                onSuccess?.(data);
                callbacks?.onSuccess?.(data);
            },
        });
    };

    return {
        fetchDownloadLink: fetchETicketDownloadLinkCall,
        data,
        loading,
        errors,
        resetData,
    };
};
