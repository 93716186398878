import { getTicketManagementAxiosInstance } from 'src/data/api/api';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import QueryParam from 'src/data/api/common/QueryParam';
import { getQueryParams } from 'src/data/api/endpointUrlBuilder';
import ApiResponse from 'src/data/api/responses/ApiResponse';
import PaginatedApiResponseBody from 'src/data/api/responses/PaginatedApiResponseBody';
import {
    TICKETS_MANAGEMENT_GET_ENDPOINT,
    TICKETS_MANAGEMENT_GET_TICKETS_FILE_ENDPOINT,
    TICKETS_MANAGEMENT_GET_TICKET_LINK_ENDPOINT,
} from 'src/data/constants/endpoints/tickets-endpoints';
import ParsedTicket from 'src/data/models/tickets/parsedTicket';

async function getParsedTickets(
    options: BaseFetchOptions
): Promise<ApiResponse<PaginatedApiResponseBody<ParsedTicket>>> {
    const extraQueryParams: QueryParam[] = [];

    if (options.q) {
        extraQueryParams.push({
            key: 'filter[query]',
            value: options.q,
        });
    }

    return await getTicketManagementAxiosInstance()
        .get<PaginatedApiResponseBody<ParsedTicket>>(TICKETS_MANAGEMENT_GET_ENDPOINT, {
            params: options && getQueryParams(options, extraQueryParams),
        })
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function getTicketLink(ticketId: string): Promise<ApiResponse<string>> {
    return await getTicketManagementAxiosInstance()
        .get<string>(TICKETS_MANAGEMENT_GET_TICKET_LINK_ENDPOINT(ticketId))
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function getTicketsFile(ticketIds: string[]): Promise<ApiResponse<string>> {
    return await getTicketManagementAxiosInstance()
        .post<string>(TICKETS_MANAGEMENT_GET_TICKETS_FILE_ENDPOINT, ticketIds)
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export default { getParsedTickets, getTicketLink, getTicketsFile };
