import { useState } from 'react';
import { Grid } from '@mui/material';
import Modal from 'src/view/components/modal/Modal';
import Header from 'src/view/components/header/Header';
import { ETBContainer } from 'src/view/components/container/container';
import SeatingPlanCategoryPropertiesFeature from 'src/app/components/features/seating-plan-category-properties/SeatingPlanCategoryPropertiesFeature';
import SeatingPlanCategoryPropertyForm from 'src/app/components/forms/seating-plan-category-property/SeatingPlanCategoryPropertyForm';
import { useCreateSeatingPlanCategoryPropertyDataWrapper } from 'src/app/components/data-wrappers/seating-plan-category-properties/CreateSeatingPlanCategoryPropertyDataWrapper';
import Button from 'src/view/components/button/Button';

export default function SeatingPlanCategoryPropertiesOverview() {
    const [tableInvalidatedAt, setTableInvalidatedAt] = useState<number | undefined>();
    const [createModalOpen, setCreateModalOpen] = useState(false);

    const { loading, createSeatingplanCategoryProperty } =
        useCreateSeatingPlanCategoryPropertyDataWrapper({
            onSuccess: () => {
                setTableInvalidatedAt(Date.now());
                setCreateModalOpen(false);
            },
        });

    return (
        <>
            <Header GridProps={{ justifyContent: 'flex-end' }}>
                <Grid item>
                    <Button color="primary" onClick={() => setCreateModalOpen(true)}>
                        Add New Seating Plan Category Property
                    </Button>
                </Grid>
            </Header>

            <ETBContainer>
                <SeatingPlanCategoryPropertiesFeature tableInvalidatedAt={tableInvalidatedAt} />
            </ETBContainer>
            <Modal
                title="Add new seating plan category property"
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
            >
                <SeatingPlanCategoryPropertyForm
                    loading={loading}
                    defaultValues={null}
                    onFormSubmit={createSeatingplanCategoryProperty}
                />
            </Modal>
        </>
    );
}
