import { Grid } from '@mui/material';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { UseFormWatch, useForm } from 'react-hook-form';
import { FetchPurchaseByIdDataWrapper } from 'src/app/components/data-wrappers/purchase/FetchPurchaseByIdDataWrapper';
import { FetchSplitsDataWrapper } from 'src/app/components/data-wrappers/splits/FetchSplitsDataWrapper';
import Filters, { FiltersArray } from 'src/app/components/forms/filters/Filters';
import { useFetchOrderById } from 'src/app/hooks/orders/useFetchOrderById';
import { purchaseStatusOptions } from 'src/app/utilities/helpers/filter-options/purchase';
import formatValuesToParams from 'src/app/utilities/helpers/formatValuesToParams';
import FilterOption from 'src/data/api/common/FilterOption';
import { dateOnlyFormat } from 'src/shared/date';
import { useSpacingStyles } from 'src/shared/styles/spacingStyles';
import {
    AutoCompleteOption,
    AutoCompleteOptions,
} from 'src/view/components/auto-complete/interfaces';
import { FilterAutoCompleteOptions } from 'src/view/components/filters/AutoComplete/AutoComplete';
import { FilteredOnNotification } from 'src/view/components/filters/FilteredOnNotification/FilteredOnNotification';

export const availableOptions: FilterAutoCompleteOptions = [
    {
        label: 'Available',
        value: 'true',
    },
    {
        label: 'Not available',
        value: 'false',
    },
];

export const lockedTicketFilterOptions: FilterAutoCompleteOptions = [
    {
        label: 'Locked tickets only',
        value: 'true',
    },
    {
        label: 'Unlocked tickets only',
        value: 'false',
    },
];

export interface TicketsTableFiltersFormValues {
    purchaseId?: string;
    orderId?: string;
    splitId?: string;
    category?: AutoCompleteOptions;
    purchaseStatus?: AutoCompleteOptions;
    available?: AutoCompleteOption;
    searchTerm?: string;
    isLocked?: AutoCompleteOption;
    ticketId?: string;
    supplierIds?: AutoCompleteOptions;
}

interface Props {
    eventId: string;
    defaultValues?: TicketsTableFiltersFormValues;
    categoriesOptions: FilterAutoCompleteOptions;
    supplierOptions: FilterAutoCompleteOptions;
    availableOptions: FilterAutoCompleteOptions;
    onChangeFilterOptions?: (options: FilterOption[]) => void;
    onChangeSearchTerm?: (q: string) => void;
    loading?: boolean;
}

export default function TicketsFiltersForm({
    eventId,
    defaultValues,
    categoriesOptions,
    supplierOptions,
    availableOptions,
    onChangeFilterOptions,
    onChangeSearchTerm,
    loading,
}: Props): JSX.Element {
    const spacingClasses = useSpacingStyles();
    const [urlInvalidatedAt, setUrlInvalidatedAt] = useState<number | undefined>();

    const ticketsFilterForm = useForm<TicketsTableFiltersFormValues>({
        mode: 'onChange',
        defaultValues,
    });

    const { watch, setValue, control } = ticketsFilterForm;

    const filtersValues = useFilterValues(watch);

    const { searchTerm, purchaseId, orderId, splitId, ticketId } = watch();

    useEffect(() => {
        if (!onChangeFilterOptions || !urlInvalidatedAt) return;

        onChangeFilterOptions(filtersValues);
    }, [filtersValues]);

    const onInputChange = (value: string) => {
        setValue('searchTerm', value, {
            shouldValidate: true,
        });

        onChangeSearchTerm?.(value);
    };

    const onDropdownValueChange = useCallback(() => {
        setUrlInvalidatedAt(Date.now());
    }, []);

    const arrayOfFilters: FiltersArray = [
        {
            type: 'autocomplete',
            options: categoriesOptions.filter((category) => !category.isArchived),
            name: 'category',
            filterPlaceholderProps: {
                placeholder: 'Select categories',
            },
            onChange: onDropdownValueChange,
            isMulti: true,
            disabled: loading,
        },
        {
            type: 'autocomplete',
            options: purchaseStatusOptions,
            name: 'purchaseStatus',
            filterPlaceholderProps: {
                placeholder: 'Select purchase status',
                selectedText: 'purchase status selected',
            },
            onChange: onDropdownValueChange,
            isMulti: true,
        },
        {
            type: 'autocomplete',
            options: availableOptions,
            name: 'available',
            filterPlaceholderProps: {
                placeholder: 'Select availability',
            },
            onChange: onDropdownValueChange,
        },
        {
            type: 'autocomplete',
            options: lockedTicketFilterOptions,
            name: 'isLocked',
            filterPlaceholderProps: {
                placeholder: 'Locked status',
            },
            onChange: onDropdownValueChange,
        },
        {
            type: 'autocomplete',
            options: supplierOptions.filter((supplier) => !supplier.isArchived),
            name: 'supplierIds',
            filterPlaceholderProps: {
                placeholder: 'Select supplier(s)',
            },
            onChange: onDropdownValueChange,
            isMulti: true,
            disabled: loading,
        },
        {
            name: 'searchTerm',
            onChange: onInputChange,
            type: 'search',
            searchPlaceholder: 'Search on block/row/seat, notes and supplier',
            searchDefaultValue: searchTerm,
        },
    ];

    const { data: orderData, isLoading: isFetchingOrder } = useFetchOrderById(orderId || '', {
        enabled: !!orderId,
    });

    return (
        <Grid container>
            <Filters control={control} filters={arrayOfFilters} />

            {orderData && !isFetchingOrder && (
                <Grid item xs={12} className={spacingClasses.spacingBottom}>
                    <FilteredOnNotification
                        label="order number"
                        value={orderData?.data.data.orderNumber.toUpperCase() || ''}
                        onClickCancel={() => {
                            setValue('orderId', undefined);
                            setUrlInvalidatedAt(Date.now());
                        }}
                    />
                </Grid>
            )}

            {purchaseId && (
                <Grid item xs={12} className={spacingClasses.spacingTop}>
                    <FetchPurchaseByIdDataWrapper purchaseId={purchaseId || ''}>
                        {({ data }) => (
                            <FilteredOnNotification
                                label="purchase from"
                                value={
                                    data
                                        ? `
                                    ${moment(data?.data.purchasedAt).format(
                                        dateOnlyFormat
                                    )} - ${data?.data.supplierName}`
                                        : ''
                                }
                                onClickCancel={() => {
                                    setValue('purchaseId', undefined);
                                    setUrlInvalidatedAt(Date.now());
                                }}
                            />
                        )}
                    </FetchPurchaseByIdDataWrapper>
                </Grid>
            )}

            {splitId && (
                <Grid item xs={12} className={spacingClasses.spacingTop}>
                    <FetchSplitsDataWrapper
                        eventId={eventId}
                        filter={[{ property: 'splitId', value: splitId }]}
                    >
                        {({ data }) => (
                            <FilteredOnNotification
                                label="split"
                                value={data?.data[0].splitName || ''}
                                onClickCancel={() => {
                                    setValue('splitId', undefined);
                                    setUrlInvalidatedAt(Date.now());
                                }}
                            />
                        )}
                    </FetchSplitsDataWrapper>
                </Grid>
            )}
            {ticketId && (
                <Grid item xs={12} className={spacingClasses.spacingTop}>
                    <FilteredOnNotification
                        label="ticket with ID"
                        value={ticketId}
                        onClickCancel={() => {
                            setValue('ticketId', undefined);
                            setUrlInvalidatedAt(Date.now());
                        }}
                    />
                </Grid>
            )}
        </Grid>
    );
}

/** Returns the filter objects of various type of filters responsible for different type of filtering operations. */
const useFilterValues = (watch: UseFormWatch<TicketsTableFiltersFormValues>) => {
    const {
        category,
        available,
        purchaseId,
        orderId,
        splitId,
        purchaseStatus,
        isLocked,
        ticketId,
        supplierIds,
    } = watch();

    return useMemo(() => {
        return [
            formatValuesToParams.formatOptionsToParam('seatingPlanCategoryId', category),
            formatValuesToParams.formatOptionsToParam('supplierIds', supplierIds),
            formatValuesToParams.formatOptionToParam('available', available),
            formatValuesToParams.formatOptionToParam('isLocked', isLocked),
            formatValuesToParams.formatInputToParam('purchaseId', purchaseId),
            formatValuesToParams.formatInputToParam('orderId', orderId),
            formatValuesToParams.formatInputToParam('splitId', splitId),
            formatValuesToParams.formatOptionsToParam('purchaseStatus', purchaseStatus),
            formatValuesToParams.formatInputToParam('ticketId', ticketId),
        ].filter((f): f is FilterOption => f?.value !== undefined);
    }, [
        category,
        available,
        purchaseId,
        orderId,
        splitId,
        purchaseStatus,
        isLocked,
        ticketId,
        supplierIds,
    ]);
};
