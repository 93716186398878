import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { useMemo } from 'react';
import EventsFilterForm, {
    type EventsFilterFormValues,
} from 'src/app/components/forms/EventsFilterForm';
import { parseErrors } from 'src/app/utilities/helpers/errors';
import { removeSpaces } from 'src/app/utilities/helpers/general';
import tableFilterFormHelper from 'src/app/utilities/helpers/tableFilterFormHelper';
import type FilterOption from 'src/data/api/common/FilterOption';
import { STATUSES } from 'src/data/models/events/event';
import eventService from 'src/data/services/eventService';
import ErrorsList from 'src/view/components/errors-list/ErrorsList';
import type { FilterAutoCompleteOptions } from 'src/view/components/filters/AutoComplete/AutoComplete';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';

interface Props {
    initialOptions: FilterOption[];
    initialSearch?: string;
    onChangeFilterOptions?: (options: FilterOption[]) => void;
    onChangeSearchTerm?: (q: string) => void;
}

const EventsFilterFormFeature = ({
    initialOptions,
    initialSearch,
    onChangeFilterOptions,
    onChangeSearchTerm,
}: Props) => {
    const {
        data: eventsFiltersResponse,
        isLoading,
        isError,
        error,
    } = useQuery({
        queryKey: ['events-filter-options', initialOptions],
        queryFn: async () => eventService.fetchEventFilterOptions({ filter: initialOptions }),
    });

    const { seriesOptions, organizerOptions, seatingPlansOptions } = useMemo(() => {
        const eventsFilters = eventsFiltersResponse?.data.data;
        const seriesOptions: FilterAutoCompleteOptions = (eventsFilters?.series ?? []).map(
            (series) => ({ label: series.name, value: series.id })
        );

        const organizerOptions: FilterAutoCompleteOptions = (eventsFilters?.organizers ?? []).map(
            (organizer) => ({ label: organizer.name, value: organizer.id })
        );

        const seatingPlans = eventsFilters?.seatingPlans ?? [];

        const seatingPlansOptions: FilterAutoCompleteOptions = seatingPlans.map((s) => {
            const hasOtherSeatingPlansWithTheSameName = seatingPlans
                .filter((sp) => sp.id !== s.id)
                .some((seatingPlan) => removeSpaces(seatingPlan.name) === removeSpaces(s.name));

            return {
                label: hasOtherSeatingPlansWithTheSameName
                    ? `${s.name} (${s.venueName})`
                    : `${s.name}`,
                value: s.id,
            };
        });

        return { seriesOptions, organizerOptions, seatingPlansOptions };
    }, [eventsFiltersResponse?.data.data]);

    const selectedStatus = useMemo(
        () =>
            tableFilterFormHelper.getInitialAutocompleteValues(
                'status',
                initialOptions,
                STATUSES.map(status => ({ label: status, value: status}))
            )?.[0],
        [initialOptions]
    );

    const selectedSeries = useMemo(
        () =>
            tableFilterFormHelper.getInitialAutocompleteValues(
                'seriesId',
                initialOptions,
                seriesOptions
            ),
        [seriesOptions, initialOptions]
    );

    const selectedOrganizers = useMemo(
        () =>
            tableFilterFormHelper.getInitialAutocompleteValues(
                'organizerId',
                initialOptions,
                organizerOptions
            ),
        [organizerOptions, initialOptions]
    );

    const selectedSeatingPlans = useMemo(
        () =>
            tableFilterFormHelper.getInitialAutocompleteValues(
                'seatingPlan',
                initialOptions,
                seatingPlansOptions
            )?.[0],
        [initialOptions, seatingPlansOptions]
    );

    const selectedStartDate = (() => {
        const dateFromUrl = tableFilterFormHelper.getDateValue(
            'dateTimeStart',
            'afterDate',
            initialOptions
        );

        return dateFromUrl ? moment(dateFromUrl).toDate() : undefined;
    })();

    const selectedEndDate = (() => {
        const dateFromUrl = tableFilterFormHelper.getDateValue(
            'dateTimeStart',
            'beforeDate',
            initialOptions
        );

        return dateFromUrl ? moment(dateFromUrl).toDate() : undefined;
    })();

    const formDefaultValues: EventsFilterFormValues = {
        series: selectedSeries,
        organizers: selectedOrganizers,
        seatingPlan: selectedSeatingPlans ?? null,
        startDate: selectedStartDate ?? null,
        endDate: selectedEndDate ?? null,
        searchTerm: initialSearch,
        teamId: initialOptions.find((o) => o.property === 'teamId')?.value,
        organizerId: initialOptions.find((o) => o.property === 'organizerId')?.value,
        status: selectedStatus ?? null,
    };

    if (isLoading) {
        return <LoadingOverlay />;
    }

    if (isError) {
        return <ErrorsList errors={parseErrors(error)} />;
    }

    return (
        <EventsFilterForm
            defaultValues={formDefaultValues}
            seriesOptions={seriesOptions}
            organizerOptions={organizerOptions}
            seatingPlanOptions={seatingPlansOptions}
            onChangeFilterOptions={onChangeFilterOptions}
            onChangeSearchTerm={onChangeSearchTerm}
        />
    );
};

export default EventsFilterFormFeature;
