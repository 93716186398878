import { Alert } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { QUERY_KEY as ORGANIZERS_QUERY_KEY } from 'src/app/hooks/useOrganizers';
import organizerService from 'src/data/services/organizerService';
import FormButtons from 'src/view/components/form/FormButtons';
import { FormLabel } from 'src/view/components/form/FormLabel';
import Input from 'src/view/components/input/Input';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';

interface Props {
    onSuccess?: () => void;
    organizerId?: string;
    disabled?: boolean;
}

const CreateOrUpdateOrganizerFeature = ({ onSuccess, organizerId, disabled }: Props) => {
    const [value, setValue] = useState('');
    const queryClient = useQueryClient();

    const {
        isLoading,
        isError,
        data: organizerData,
        error,
    } = useQuery({
        queryKey: ['organizer', organizerId],
        queryFn: () => organizerService.getOrganizerById(organizerId ?? ''),
        enabled: !!organizerId,
    });

    const {
        mutate: createOrganizer,
        isLoading: isCreateLoading,
        isError: isCreateError,
        error: createError,
    } = useMutation({
        mutationFn: (organizerName: string) => organizerService.createOrganizer(organizerName),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [ORGANIZERS_QUERY_KEY] });
            onSuccess?.();
        },
    });

    const {
        mutate: updateOrganizer,
        isLoading: isUpdateLoading,
        isError: isUpdateError,
        error: updateError,
    } = useMutation({
        mutationFn: (organizerName: string) =>
            organizerService.updateOrganizer(organizerId ?? '', organizerName),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [ORGANIZERS_QUERY_KEY] });
            onSuccess?.();
        },
    });

    const onSubmit = useCallback(() => {
        if (organizerId) {
            return updateOrganizer(value);
        }

        return createOrganizer(value);
    }, [createOrganizer, organizerId, updateOrganizer, value]);

    useEffect(() => {
        if (!organizerData?.data) return;
        setValue(organizerData.data.data.name);
    }, [organizerData]);

    return (
        <>
            {(isCreateLoading || (isLoading && organizerId) || isUpdateLoading) && (
                <LoadingOverlay />
            )}
            {isCreateError && <Errors error={createError} />}
            {isUpdateError && <Errors error={updateError} />}
            {isError ? (
                <Errors error={error} />
            ) : (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormLabel>Name</FormLabel>
                        <Input
                            name="Organizer"
                            value={value}
                            onChange={(
                                event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                            ) => setValue(event.currentTarget.value)}
                            placeholder="Organizer name"
                            disabled={disabled || isCreateLoading}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormLabel># of events</FormLabel>
                        <Input
                            name="eventsCount"
                            value={organizerData?.data?.data?.eventsCount}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormButtons
                            buttons={[
                                {
                                    disabled: isCreateLoading || isUpdateLoading,
                                    children: organizerId ? 'Update' : 'Create',
                                    onClick: () => onSubmit(),
                                },
                            ]}
                        />
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default CreateOrUpdateOrganizerFeature;

const Errors = ({ error }: { error: unknown }) => {
    const errorsObject =
        error instanceof AxiosError &&
        isErrors(error.response?.data) &&
        error.response?.data.errors;

    if (errorsObject) {
        const errorMessages = Object.entries(errorsObject).map(([field, messages]) => {
            return messages.map((message: string) => {
                return (
                    <Alert severity="error" key={field}>
                        {field} - {message}
                    </Alert>
                );
            });
        });

        return <div>{errorMessages}</div>;
    }

    if (error instanceof AxiosError || error instanceof Error) {
        return <Alert severity="error">{error.message}</Alert>;
    }

    return <Alert severity="error">Unknown error</Alert>;
};

type ErrorResponseData = {
    errors: {
        [key: string]: string[];
    };
};

function isErrors(errorResponseData: unknown): errorResponseData is ErrorResponseData {
    if (typeof errorResponseData !== 'object' || errorResponseData === null) {
        return false;
    }

    if (!Object.prototype.hasOwnProperty.call(errorResponseData, 'errors')) {
        return false;
    }

    const errors = (errorResponseData as { errors: unknown }).errors;
    if (typeof errors !== 'object' || errors === null) {
        return false;
    }

    return Object.keys(errors).every(
        (key) =>
            typeof key === 'string' && Array.isArray((errors as { [key: string]: unknown })[key])
    );
}
