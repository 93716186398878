import makeStyles from '@mui/styles/makeStyles';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

export interface ActivityItemProps {
    date: string;
    auditType: string;
    userFullName?: string;
    userEmail?: string;
    message: string;
}

const useStyles = makeStyles((theme) => ({
    title: {
        color: theme.colors.darkGrey,
    },
    activityItem: {
        padding: `0 ${theme.spacing(1)}`,
        display: 'flex',
        flexDirection: 'column',
        background: theme.colors.lightGrey,
        '&:not(:last-of-type)': {
            borderBottom: `1px solid ${theme.colors.bluishGrey}`,
        },
    },
    activityTitle: {
        color: theme.colors.darkGrey,
        marginBottom: 0,
        fontSize: '.925rem',
    },
    activityDate: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1),
        marginBottom: 0,
        color: theme.colors.darkGrey,
        fontSize: '.925rem',
        '& svg': {
            height: '.925rem',
            width: '.925rem',
            marginRight: theme.spacing(1),
        },
    },
    activityMessage: {
        fontSize: theme.typography.subtitle2.fontSize,
    },
}));

const ActivityItem = ({
    date,
    auditType,
    userFullName,
    userEmail,
    message,
}: ActivityItemProps): JSX.Element => {
    const classes = useStyles();

    return (
        <div className={classes.activityItem}>
            <p className={classes.activityTitle}>
                {userFullName ? (
                    <span>
                        <b>{userFullName}</b>:
                    </span>
                ) : userEmail ? (
                    <span>
                        <b>{userEmail}</b>:
                    </span>
                ) : (
                    'Unknown:'
                )}{' '}
                {auditType}
            </p>
            <p className={classes.activityDate}>
                <CalendarMonthIcon />
                {date}
            </p>
            <p className={classes.activityMessage}>{message}</p>
        </div>
    );
};

export default ActivityItem;
