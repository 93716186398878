import { type Dispatch, type SetStateAction } from 'react';
import Modal from 'src/view/components/modal/Modal';
import CreateOrUpdateSeatingPlanFeature from './CreateOrUpdateSeatingPlanFeature';

export function CreateEditSeatingPlanModal({
    venueId,
    seatingPlan,
    setIsModalOpenState,
}: {
    venueId: string;
    seatingPlan?: {
        name: string;
        id: string;
    };
    setIsModalOpenState: Dispatch<SetStateAction<boolean>>;
}) {
    return (
        <Modal
            title={seatingPlan?.name ? `Update ${seatingPlan?.name}` : 'Create Seatingplan'}
            open={true}
            onClose={() => setIsModalOpenState(false)}
            width="small"
        >
            <CreateOrUpdateSeatingPlanFeature
                venueId={venueId}
                seatingplanId={seatingPlan?.id}
                onMutationSuccess={() => setIsModalOpenState(false)}
            />
        </Modal>
    );
}
