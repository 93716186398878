import { useState } from 'react';
import { Grid } from '@mui/material';
import Modal from 'src/view/components/modal/Modal';
import Header from 'src/view/components/header/Header';
import useQueryParams from 'src/app/hooks/useQueryParams';
import { ETBContainer } from 'src/view/components/container/container';
import { Team } from 'src/data/models/team/team';
import SeriesGroupsTableFeature from 'src/app/components/features/series-groups/SeriesGroupsTableFeature';
import { useCreateSeriesGroupDataWrapper } from 'src/app/components/data-wrappers/series-groups/CreateSeriesGroupDataWrapper';
import CreateSeriesGroupForm from 'src/app/components/forms/CreateSeriesGroupForm';
import Filters, { FiltersArray } from 'src/app/components/forms/filters/Filters';
import { useForm } from 'react-hook-form';
import { config } from 'src/app/constants/config/config';
import Button from 'src/view/components/button/Button';

export default function SeriesGroupsOverview() {
    const [createOpen, setCreateOpen] = useState(false);
    const [seriesGroupsInvalidatedAt, setSeriesGroupsInvalidatedAt] = useState<
        number | undefined
    >();
    const [activeSeriesGroup, setActiveSeriesGroup] = useState<Team | undefined>();

    const invalidateSeriesGroup = () => {
        setSeriesGroupsInvalidatedAt(Date.now());
    };

    const { createSeriesGroup, loading: createSeriesGroupIsLoading } =
        useCreateSeriesGroupDataWrapper({
            onSuccess: () => {
                setCreateOpen(false);
                invalidateSeriesGroup();
            },
        });

    const {
        values: { customFields },
        setCustomFields,
    } = useQueryParams('seriesGroups');

    const form = useForm({
        mode: 'onChange',
        defaultValues: customFields,
    });

    const { control } = form;

    const onCloseModal = () => {
        setCreateOpen(false);
        setActiveSeriesGroup(undefined);
    };

    const renderCreateSeriesGroupModal = () => {
        const title = activeSeriesGroup ? `Edit ${activeSeriesGroup.name}` : 'Add New Series Group';

        return (
            <Modal title={title} open={createOpen} onClose={onCloseModal}>
                <CreateSeriesGroupForm
                    actionLoading={createSeriesGroupIsLoading}
                    onFormSubmit={(values) =>
                        createSeriesGroup({
                            name: values.name || '',
                        })
                    }
                />
            </Modal>
        );
    };

    const arrayOfFilters: FiltersArray = [
        {
            name: 'searchTerm',
            onChange: (q: string) =>
                setCustomFields({
                    ...customFields,
                    q,
                }),
            type: "search",
            searchPlaceholder: 'Search on series group name',
            searchDefaultValue: customFields['q'] || '',
            label: 'Search',
        },
    ];

    const handleOnPaginate = (page: number) =>
        setCustomFields({
            ...customFields,
            page: page.toString(),
        });

    return (
        <>
            {renderCreateSeriesGroupModal()}

            <Header GridProps={{ justifyContent: 'flex-end' }}>
                <Grid item>
                    <Button color="primary" onClick={() => setCreateOpen(true)}>
                        Create Series Group
                    </Button>
                </Grid>
            </Header>
            <ETBContainer>
                <Filters control={control} filters={arrayOfFilters} />
                <SeriesGroupsTableFeature
                    q={customFields.q || ''}
                    invalidatedAt={seriesGroupsInvalidatedAt}
                    page={Number(customFields.page || 1)}
                    onPaginate={handleOnPaginate}
                    pageSize={config.ITEMS_PER_PAGE_STANDARD}
                />
            </ETBContainer>
        </>
    );
}
