import { PAYMENT_METHODS } from 'src/data/enums/paymentMethods';

export const creditCards = [
    PAYMENT_METHODS.ABN,
    PAYMENT_METHODS.Amex,
    PAYMENT_METHODS.AirPlus,
    PAYMENT_METHODS.Moss,
    PAYMENT_METHODS.Enett,
    PAYMENT_METHODS.Ixaris,
];

export const isCreditCardMethod = (paymentMethod: PAYMENT_METHODS) => {
    return creditCards.includes(paymentMethod);
};
