type HelpContentKeys = 'ReadyTickets';

type HelpContent = {
    [key in HelpContentKeys]: {
        title: string;
        text: string;
    };
};

export const helpContent: HelpContent = {
    ReadyTickets: {
        title: 'Ready tickets have a file',
        text: 'tickets that are ready have an uploaded file and are therefor ready to be send to the customer.',
    },
};
