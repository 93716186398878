import useApiFetch from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import { IdResponse } from 'src/data/models/common/idResponse';
import splitService, { CreateSplitDto } from 'src/data/services/splitService';

interface CreateNewSplitDataWrapperChildrenParams
    extends DataWrapperChildrenParams<ApiResponseBody<IdResponse>> {
    createSplit: (dto: CreateSplitDto) => Promise<ApiResponseBody<IdResponse>>;
}

type CreateNewSplitDataWrapperProps = DataWrapperProps<
    CreateNewSplitDataWrapperChildrenParams,
    ApiResponseBody<IdResponse>
> &
    BaseFetchOptions;

export const useCreateNewSplitDataWrapper = ({
    onError,
    onSuccess,
}: Omit<CreateNewSplitDataWrapperProps, 'children'>) => {
    const [{ data, loading, errors, resetData }, handleCreateDownloadLink] =
        useApiFetch<ApiResponseBody<IdResponse>>();

    const createNewSplitCall = (dto: CreateSplitDto) => {
        return handleCreateDownloadLink(splitService.createNewSplit(dto), {
            useDefaultErrorHandler: true,
            defaultSuccessMessage: 'Split has been created',
            onFail: onError,
            onSuccess,
        });
    };

    return {
        createSplit: createNewSplitCall,
        data,
        loading,
        errors,
        resetData,
    };
};

export const CreateNewSplitDataWrapper = (props: CreateNewSplitDataWrapperProps) => {
    const { children } = props;
    const hookData = useCreateNewSplitDataWrapper(props);

    if (!children) return <></>;

    return <>{children(hookData)}</>;
};
