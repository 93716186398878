import { config } from 'src/app/constants/config/config';
import { CustomFields } from 'src/app/constants/constants/filters/filters';

export interface PathConfig {
    rootOnly?: boolean;
}

export const defaultCustomFields: CustomFields = {
    page: '1',
};

interface AppendOptions {
    removeSlash?: boolean;
}

const appendSafely = (suffix?: string, options?: AppendOptions) => {
    if (!suffix) return '';

    if (suffix.startsWith('/')) {
        suffix = suffix.substring(1);
    }

    if (options?.removeSlash) {
        return suffix;
    }

    return `/${suffix}`;
};

const urlService = {
    root(suffix?: string, options?: AppendOptions): string {
        return `/${appendSafely(suffix, {
            removeSlash: true,
            ...options,
        })}`;
    },

    getBasicAdministration(suffix?: string): string {
        return `/basic-administration${appendSafely(suffix)}`;
    },

    getTickets(suffix?: string): string {
        return `/basic-administration${appendSafely(suffix)}`;
    },

    getEvent(eventId: string, suffix?: string): string {
        return `/event/${eventId}${appendSafely(suffix)}`;
    },

    getTravelWareSystemAdmin(suffix?: string) {
        return `${config.TRAVELWARE_BASE_URL}/admin/system${appendSafely(suffix)}`;
    },

    getTravelwareOrders(suffix?: string) {
        return `${config.TRAVELWARE_BASE_URL}/orders${appendSafely(suffix)}`;
    },

    getPurchases(suffix?: string) {
        return `/purchases${appendSafely(suffix)}`;
    },

    getOrders(suffix?: string) {
        return `/orders${appendSafely(suffix)}`;
    },

    getEventsOverview(suffix?: string, options?: AppendOptions) {
        return `/${appendSafely(suffix, {
            removeSlash: true,
            ...options,
        })}`;
    },

    getTicketManagement(suffix?: string) {
        return `/ticket-management${appendSafely(suffix)}`;
    },

    getLogin() {
        return '/login';
    },

    getNotAuthorized() {
        return '/not-authorized';
    },
};

export default urlService;
