import { useGenericStyles } from 'src/shared/styles/genericStyles';
import CancelIcon from '@mui/icons-material/Cancel';
import { SIZES } from 'src/shared/theme/enums';
import Chip from 'src/view/components/chip/Chip';
import { useSpacingStyles } from 'src/shared/styles/spacingStyles';

interface FilteredOnNotificationProps {
    label: string;
    value: string;
    onClickCancel: () => void;
}

export const FilteredOnNotification = ({
    label,
    value,
    onClickCancel,
}: FilteredOnNotificationProps) => {
    const genericClasses = useGenericStyles();
    const spacingClasses = useSpacingStyles();

    return (
        <Chip
            className={spacingClasses.spacingBottom}
            label={
                <span>
                    Filtered on {label}: <strong>{value}</strong>
                </span>
            }
            clickable={false}
            icon={
                <CancelIcon
                    className={genericClasses.clickable}
                    fontSize={SIZES.SMALL}
                    onClick={onClickCancel}
                />
            }
        />
    );
};
