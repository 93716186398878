import { useQuery } from '@tanstack/react-query';
import Toaster from 'src/app/utilities/helpers/Toaster';
import { parseErrors } from 'src/app/utilities/helpers/errors';
import { fetchOrderById } from 'src/data/services/orderService';

type Options = {
    enabled: boolean;
};

export const FETCH_ORDER_BY_ID_QUERY_KEY = 'FETCH_ORDER_BY_ID';

export const useFetchOrderById = (id: string, options?: Options) => {
    return useQuery({
        queryKey: [FETCH_ORDER_BY_ID_QUERY_KEY, id],
        queryFn: () => fetchOrderById(id),
        onError: (e) => {
            Toaster.toastErrors(parseErrors(e));
        },
        ...options,
    });
};
