import makeStyles from '@mui/styles/makeStyles';
import { BaseComponent } from 'src/view/interfaces/BaseComponent';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

interface FormLabelProps extends BaseComponent {
    url?: string;
    urlText?: string;
    linkExplanation?: string;
}

const useStyles = makeStyles((theme) => ({
    text: {
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(0.5),
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    link: {
        margin: 0,
        padding: 0,
    },
}));

export const FormLabel = ({ children, style, className, url, urlText }: FormLabelProps) => {
    const classes = useStyles();

    return (
        <div className={classnames(classes.text, className)} style={style}>
            {children}

            {url && (
                <div className={classnames(classes.link)}>
                    <Link to={url}>{urlText}</Link>
                </div>
            )}
        </div>
    );
};
