import AccessTimeIcon from '@mui/icons-material/AccessTime';
import GitHubIcon from '@mui/icons-material/GitHub';
import ModeStandbyIcon from '@mui/icons-material/ModeStandby';
import { Box } from '@mui/material';
import moment from 'moment';
import { useEffect } from 'react';
import useApiFetch from 'src/app/hooks/useApiFetch';
import infoService, { ApplicationVersionResponse } from 'src/data/services/infoService';
import { dateFormat } from 'src/shared/date';

export const EnvironmentDisplayFeature = () => {
    const [{ data }, handleFetchInfo] = useApiFetch<ApplicationVersionResponse>();

    useEffect(() => {
        handleFetchInfo(infoService.getApplicationVersion());
    }, [handleFetchInfo]);

    if (!data) return <></>;

    return (
        <Box
            sx={{
                backgroundColor: '#FFA500',
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 1,
                position: 'fixed',
                bottom: 0,
                right: 0,
                borderTopLeftRadius: '12px',
                zIndex: 9999,
                color: 'white',
                pointerEvents: 'none',
                boxShadow: '0 -3px 8px rgba(0,0,0,0.3)',
                '&>*': {
                    padding: 0,
                    margin: 0,
                },
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    gap: '12px',
                    '&>*': {
                        display: 'flex',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        justifyItems: 'center',
                    },
                }}
            >
                <Box>
                    <ModeStandbyIcon /> : {getSubdomain()}
                </Box>
                <Box>
                    <GitHubIcon /> : {data.branchName}
                </Box>
                <Box>
                    <AccessTimeIcon /> : {moment(data.deployedAt).local().format(dateFormat)}
                </Box>
            </Box>
        </Box>
    );
};

/** https://test-1.etbaas.eventstravel.io returns test-1
 * https://staging.etbaas.eventstravel.io returns staging
 */
function getSubdomain() {
    const url = new URL(window.location.href);

    return url?.hostname?.split('.')[0] || '';
}

