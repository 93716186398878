import FilterOption, { OperationFilter } from 'src/data/api/common/FilterOption';
import {
    AutoCompleteOption,
    AutoCompleteOptions,
} from 'src/view/components/auto-complete/interfaces';

const formatMultipleOptionsToParam = (selectedOptions?: AutoCompleteOptions): string => {
    let paramString = '';

    if (!selectedOptions || selectedOptions?.length === 0) return paramString;

    selectedOptions.forEach((option: AutoCompleteOption, index: number) => {
        if (index === 0) {
            paramString += `${option.value}`;

            return;
        }

        paramString += `,${option.value}`;
    });

    return paramString;
};

function formatOptionToParam(
    property: string,
    option?: AutoCompleteOption | null
): FilterOption | undefined {
    if (!option) return;

    return {
        property,
        value: option?.value,
    };
}

function formatOptionsToParam(
    property: string,
    selectedOptions?: AutoCompleteOptions
): FilterOption | undefined {
    if (selectedOptions === undefined) return;

    return {
        property,
        value: formatMultipleOptionsToParam(selectedOptions),
    };
}

function formatDateToParam(
    property: string,
    value: string,
    operation?: OperationFilter
): FilterOption | undefined {
    if (value.length === 0) return;

    return {
        property,
        value,
        operation,
    };
}
function formatInputToParam(
    property: string,
    value?: string,
    operation?: OperationFilter
): FilterOption | undefined {
    if (!value || value.length === 0) return;

    return {
        property,
        value,
        operation,
    };
}

export default {
    formatOptionToParam,
    formatOptionsToParam,
    formatInputToParam,
    formatDateToParam,
};
