import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Alert, Box, CircularProgress, Grid, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { OrderlineSeatSection } from 'src/app/components/tables/helpers/OrderlinesTableHelpers';
import { ORDERLINE_STATUS, ORDERLINE_TYPE } from 'src/data/enums/orderline';
import Price from 'src/data/models/common/price';
import Orderline from 'src/data/models/orderline/orderline';
import { dateFormat } from 'src/shared/date';
import { formatPrice } from 'src/shared/helpers/formatPrice';
import Button from 'src/view/components/button/Button';
import DictionaryGrid from 'src/view/components/dictionary-grid/DictionaryGrid';
import { Divider } from 'src/view/components/divider/Divider';
import FormButtons from 'src/view/components/form/FormButtons';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';
import Modal from 'src/view/components/modal/Modal';
import { ActivityLogFeature } from '../features/activity-log/ActivityLogFeature';

export interface OrderlineDetailsFormValues {
    category?: string;
    client?: string;
    orderNumber?: string;
    sourceSystem?: string;
    externalOrderReference?: string;
    invoiceNumber?: string;
    internalNotes?: string;
    salesChannel?: string;
    salesDate?: string;
    quantity?: number;
    blockRowSeat?: { [key: string]: string | undefined };
    totalOriginalPrice?: Price;
    assignmentInstructions?: string;
}

const getBlockRowSeat = (orderline?: Orderline) => {
    const block = orderline?.blockNumber;
    const row = orderline?.rowNumber;
    const seat = orderline?.seatNumber;

    return {
        block,
        row,
        seat,
    };
};

const formatSalesDate = (date?: string): string => {
    if (!date) return 'Unknown';

    return moment(date).local().format(dateFormat);
};

export const mapInitialOrderlineDetailsFormValues = (
    orderline?: Orderline
): OrderlineDetailsFormValues => ({
    category: orderline?.seatingPlanCategoryName,
    client: orderline?.orderSummary.clientEmail,
    orderNumber: orderline?.orderSummary.orderNumber,
    sourceSystem: orderline?.orderSummary.sourceSystem,
    externalOrderReference: orderline?.orderSummary?.externalOrderReference,
    invoiceNumber: orderline?.orderSummary.invoiceNumber,
    internalNotes: orderline?.orderSummary.internalNotes,
    salesChannel: orderline?.orderSummary.salesChannel,
    salesDate: formatSalesDate(orderline?.orderSummary.orderedAt),
    quantity: orderline?.quantity,
    blockRowSeat:
        orderline?.type === ORDERLINE_TYPE.TICKET_ORDERLINE
            ? getBlockRowSeat(orderline)
            : undefined,
    totalOriginalPrice: orderline?.totalOriginalPrice,
    assignmentInstructions: orderline?.assignmentInstructions,
});

interface Props {
    edit?: boolean;
    loading?: boolean;
    defaultValues?: OrderlineDetailsFormValues;
    assignTicketsOpen?: boolean;
    orderline?: Orderline;
    onAssignButtonClick?: (orderline?: Orderline | undefined) => void;
    onFormSubmit?: (values: OrderlineDetailsFormValues) => void;
    onToggleEditMode?: () => void;
}

export default function OrderlineDetailsForm({
    edit,
    defaultValues,
    orderline,
    loading,
    onAssignButtonClick,
    onFormSubmit,
    onToggleEditMode,
}: Props): JSX.Element {
    const theme = useTheme();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const form = useForm<OrderlineDetailsFormValues>({
        reValidateMode: 'onChange',
    });

    const { getValues, control, reset } = form;

    useEffect(() => {
        if (!defaultValues) return;

        reset(defaultValues);
    }, [defaultValues, reset]);

    const category = getValues().category,
        client = getValues().client,
        orderNumber = getValues().orderNumber,
        externalOrderReference = getValues().externalOrderReference,
        invoiceNumber = getValues().invoiceNumber,
        salesDate = getValues().salesDate,
        quantity = getValues().quantity,
        totalOriginalPrice = getValues().totalOriginalPrice,
        formBlockRowSeat = getValues().blockRowSeat,
        internalNotes = getValues().internalNotes,
        assignmentInstructions = getValues().assignmentInstructions;

    const status = orderline?.status,
        assignedTickets = orderline?.assignedTickets;

    if (loading && !defaultValues) {
        return <LoadingOverlay />;
    }

    return (
        <>
            <form>
                {status === ORDERLINE_STATUS.CANCELLED && (
                    <Alert severity="error">This orderline has been cancelled</Alert>
                )}
                <h2>Orderline details</h2>
                <DictionaryGrid>
                    <>
                        <Grid item xs={4}>
                            Category
                        </Grid>
                        <Grid item xs={8}>
                            {category}
                        </Grid>
                        <Grid item xs={4}>
                            Quantity
                        </Grid>
                        <Grid item xs={8}>
                            {quantity}
                        </Grid>
                        <Grid item xs={4}>
                            Total price
                        </Grid>
                        <Grid item xs={8}>
                            <span>{totalOriginalPrice?.currency}</span>
                            &nbsp;
                            <span>
                                {formatPrice({
                                    currency: totalOriginalPrice?.currency,
                                    amount: Number(totalOriginalPrice?.value || 0),
                                    showCurrency: false,
                                })}
                            </span>
                        </Grid>
                        <Grid item xs={4}>
                            Assignment instructions
                        </Grid>
                        <Grid item xs={8}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'flex-start',
                                    justifyItems: 'center',
                                }}
                            >
                                {edit ? (
                                    <Controller
                                        name="assignmentInstructions"
                                        control={control}
                                        render={({ field: { value, onChange, name } }) => (
                                            <TextField
                                                value={value || ''}
                                                name={name}
                                                onChange={onChange}
                                                multiline
                                                rows={3}
                                                size="small"
                                                variant="outlined"
                                            />
                                        )}
                                    />
                                ) : (
                                    <span>{assignmentInstructions}</span>
                                )}
                                <Button
                                    onClick={() => setIsModalOpen(true)}
                                    color="primary"
                                    variant="text"
                                    startIcon={<AccessTimeIcon />}
                                >
                                    History
                                </Button>
                            </Box>
                        </Grid>
                        {onAssignButtonClick && (
                            <>
                                <Grid item xs={4}>
                                    Block/Row/Seat
                                </Grid>
                                <Grid item xs={8}>
                                    <OrderlineSeatSection
                                        orderline={orderline}
                                        assignedTickets={assignedTickets}
                                        status={status}
                                        blockRowSeat={{
                                            block: formBlockRowSeat?.block,
                                            row: formBlockRowSeat?.row,
                                            seat: formBlockRowSeat?.seat,
                                        }}
                                        onClickAssign={onAssignButtonClick}
                                    />
                                </Grid>
                            </>
                        )}
                    </>
                </DictionaryGrid>

                <Divider />

                <h2>Order Details</h2>
                <DictionaryGrid>
                    <>
                        <Grid item xs={4}>
                            Client Email
                        </Grid>
                        <Grid item xs={8}>
                            {client}
                        </Grid>
                        <Grid item xs={4}>
                            Ordered At
                        </Grid>
                        <Grid item xs={8}>
                            {salesDate}
                        </Grid>
                        <Grid item xs={4}>
                            Order number
                        </Grid>
                        <Grid item xs={8}>
                            {orderNumber}
                        </Grid>
                        <Grid item xs={4}>
                            External order reference
                        </Grid>
                        <Grid item xs={8}>
                            {externalOrderReference}
                        </Grid>
                        <Grid item xs={4}>
                            Invoice number
                        </Grid>
                        <Grid item xs={8}>
                            {invoiceNumber}
                        </Grid>
                        <Grid item xs={4}>
                            Internal notes
                        </Grid>
                        <Grid item xs={8}>
                            {internalNotes}
                        </Grid>
                        <Grid item xs={4}>
                            Status
                        </Grid>
                        <Grid item xs={8}>
                            {orderline?.orderSummary.status}
                        </Grid>
                        <Grid item xs={4}>
                            Source
                        </Grid>
                        <Grid item xs={8}>
                            {orderline?.orderSummary.source}
                        </Grid>
                        <Grid item xs={4}>
                            Source System
                        </Grid>
                        <Grid item xs={8}>
                            {orderline?.orderSummary.sourceSystem}
                        </Grid>
                    </>
                </DictionaryGrid>
                {edit !== undefined && (
                    <FormButtons
                        buttons={[
                            {
                                children: edit ? 'Save' : 'Edit',
                                disabled: loading,
                                startIcon: loading && (
                                    <CircularProgress size={theme.layout.loader.sizes.small} />
                                ),
                                onClick: () =>
                                    edit ? onFormSubmit?.(getValues()) : onToggleEditMode?.(),
                            },
                        ]}
                    />
                )}
            </form>
            {isModalOpen && (
                <Modal open={true} onClose={() => setIsModalOpen(false)} width="x-large">
                    <ActivityLogFeature entityId={orderline?.id || ''} />
                </Modal>
            )}
        </>
    );
}
