import { getInternalAxiosInstance } from 'src/data/api/api';
import ApiResponse from 'src/data/api/responses/ApiResponse';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import {
    TICKETS_BATCH_EDIT_ENDPOINT,
    TICKETS_CREATE_FILE_LINK_ENDPOINT,
    TICKETS_DELETE_FILE_ENDPOINT,
    TICKETS_DELETE_TICKETS_ENDPOINT,
    TICKETS_UPDATE_ENDPOINT,
    TICKETS_UPDATE_FILE_ENDPOINT,
} from 'src/data/constants/endpoints/tickets-endpoints';
import UpdateTicketDto from 'src/data/dtos/UpdateTicketDto';
import { EmptyBody } from 'src/data/models/common/emptyBody';

async function updateTicket(
    id: string,
    dto: UpdateTicketDto
): Promise<ApiResponse<ApiResponseBody<EmptyBody>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<EmptyBody>>(TICKETS_UPDATE_ENDPOINT(id), dto)
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export interface TicketDownloadLinkResponse {
    url: string;
    expiry: string;
}

async function createTicketDownloadLink(
    ticketId: string,
    fileId: string
): Promise<ApiResponse<ApiResponseBody<TicketDownloadLinkResponse>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<TicketDownloadLinkResponse>>(
            TICKETS_CREATE_FILE_LINK_ENDPOINT(ticketId, fileId)
        )
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function deleteTicketFile(
    ticketId: string,
    fileId: string
): Promise<ApiResponse<ApiResponseBody<EmptyBody>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<EmptyBody>>(TICKETS_DELETE_FILE_ENDPOINT(ticketId, fileId))
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export async function deleteTicketFiles(ticketIds: string[]) {
    return await getInternalAxiosInstance().post<ApiResponseBody<EmptyBody>>(
        TICKETS_DELETE_TICKETS_ENDPOINT,
        {
            ticketIds,
        }
    );
}

async function updateTicketPdf(
    ticketId: string,
    file: File
): Promise<ApiResponse<ApiResponseBody<EmptyBody>>> {
    const formData = new FormData();
    formData.append('file', file);

    return await getInternalAxiosInstance()
        .post<ApiResponseBody<EmptyBody>>(TICKETS_UPDATE_FILE_ENDPOINT(ticketId), formData)
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export interface BatchEditTicketDto {
    ticketId: string;
    block: string;
    row?: string;
    seat?: string | null;
}

async function batchEditTickets(
    dto: BatchEditTicketDto[]
): Promise<ApiResponse<ApiResponseBody<EmptyBody>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<EmptyBody>>(TICKETS_BATCH_EDIT_ENDPOINT, { tickets: dto })
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export default {
    updateTicket,
    createTicketDownloadLink,
    deleteTicketFile,
    updateTicketPdf,
    batchEditTickets,
};
