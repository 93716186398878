import { yupResolver } from '@hookform/resolvers/yup';
import moment, { isDate } from 'moment';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
    defaultTicketsSentEarliestDays,
    defaultTicketsSentLatestDays,
} from 'src/app/constants/constants/form/events';
import mapEventDetailsToFormValues from 'src/app/utilities/mappers/mapEventDetailsToFormValues';
import { EVENT_TYPE, EventDetails } from 'src/data/models/events/event';
import {
    type AutoCompleteOption,
    type AutoCompleteOptions,
} from 'src/view/components/auto-complete/interfaces';
import * as Yup from 'yup';

export interface CreateEventFormValues {
    eventType?: AutoCompleteOption;
    contestants?: AutoCompleteOptions;
    artists?: AutoCompleteOptions;
    matches?: { id: string; name: string }[];
    homeTeam?: AutoCompleteOption;
    awayTeam?: AutoCompleteOption;
    eventName?: string;
    startDate?: string;
    endDate?: string;
    dateConfirmed: boolean;
    eventCategory?: AutoCompleteOption;
    eventTags?: AutoCompleteOptions;
    description?: string;
    series?: AutoCompleteOption;
    organizer?: AutoCompleteOption;
    venue?: AutoCompleteOption;
    seatingPlan?: AutoCompleteOption;
    active: boolean;
    ticketsSentEarliestDays?: number;
    ticketsSentLatestDays?: number;
    allowAwayFans: boolean;
    blacklistedCountries?: AutoCompleteOptions;
    blacklistedNationalities?: AutoCompleteOptions;
    requireFullCustomerAddress: boolean;
    hasMultipleBookingOptions: boolean;
}

export type UseCreateEventFormReturn = ReturnType<typeof useCreateEventForm>;

const isDefinedNumber = (value?: null | number): value is number => {
    return value !== null && value !== undefined && typeof value === 'number';
};

const validationSchema = Yup.object({
    eventType: Yup.object().required('Please select an event type'),
    homeTeam: Yup.object().when('eventType', {
        is: (eventType?: AutoCompleteOption) => eventType?.value === EVENT_TYPE.TeamSports,
        then: Yup.object().required('Please select a team'),
        otherwise: Yup.object().notRequired(),
    }),
    awayTeam: Yup.object()
        .when('eventType', {
            is: (eventType?: AutoCompleteOption) => eventType?.value === EVENT_TYPE.TeamSports,
            then: Yup.object().required('Please select a team'),
            otherwise: Yup.object().notRequired(),
        })
        .test('match', 'Away team cannot be equal to home team', function (awayTeam) {
            if (this.parent.eventType?.value !== EVENT_TYPE.TeamSports) return true;

            return awayTeam?.value !== this.parent.homeTeam?.value;
        }),
    eventName: Yup.string().required('Please fill in an event name'),
    eventCategory: Yup.object().required('Please fill in an event category'),
    series: Yup.object().required('Please select a series'),
    organizer: Yup.object().required('Please select an organizer'),
    venue: Yup.object().required('Please select a venue'),
    seatingPlan: Yup.object().required('Please select a seating plan'),
    startDate: Yup.date()
        .required('Please select a start date')
        .min(new Date(), 'Please select a date in the future'),
    endDate: Yup.date().test(
        'end_date_is_after_start',
        'The end date should be after the start date',
        function (value) {
            const { startDate } = this.parent;

            if (isDate(startDate) && isDate(value)) {
                return moment(value).isAfter(moment(startDate));
            }

            return true;
        }
    ),
    ticketsSentEarliestDays: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable()
        .test(
            'isLessThanSentEarliest',
            'This value should be greater than tickets sent latest',
            function (value) {
                const { ticketsSentLatestDays } = this.parent;

                if (!isDefinedNumber(value)) {
                    value = defaultTicketsSentEarliestDays;
                }

                if (!isDefinedNumber(ticketsSentLatestDays)) {
                    this.parent.ticketsSentLatestDays = defaultTicketsSentLatestDays;
                }

                return value >= this.parent.ticketsSentLatestDays && value >= 0;
            }
        ),
    ticketsSentLatestDays: Yup.number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable()
        .test(
            'isLessThanSentEarliest',
            'This value should be less than tickets sent earliest',
            function (value) {
                const { ticketsSentEarliestDays } = this.parent;

                if (!isDefinedNumber(value)) {
                    value = defaultTicketsSentLatestDays;
                }

                if (!isDefinedNumber(ticketsSentEarliestDays)) {
                    this.parent.ticketsSentEarliestDays = defaultTicketsSentEarliestDays;
                }

                return value <= this.parent.ticketsSentEarliestDays && value >= 0;
            }
        ),
});

export function useCreateEventForm(eventDetails?: EventDetails) {
    const defaultValues = useMemo(() => {
        return eventDetails
            ? mapEventDetailsToFormValues(eventDetails)
            : {
                  allowAwayFans: true,
                  requireFullCustomerAddress: false,
                  ticketsSentEarliestDays: defaultTicketsSentEarliestDays,
                  ticketsSentLatestDays: defaultTicketsSentLatestDays,
              };
    }, [eventDetails]);

    return useForm<CreateEventFormValues>({
        mode: 'onChange',
        // TODO: fix this ts error
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        resolver: yupResolver(validationSchema),
        defaultValues,
    });
}
