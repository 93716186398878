import { useSnackbar } from 'notistack';
import DismissableToast from 'src/view/components/dismissable-toast/DismissableToast';
import EventUrlService from '../pages/events/eventUrlService';
import { Link } from '@mui/material';

interface ToastArguments {
    title: string;
    eventId: string;
    ticketId: string;
}

const useTicketFileAlreadyExistsToast = () => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const showFileAlreadyExistsToast = ({ title, eventId, ticketId }: ToastArguments) => {
        const snackbarKey = Date.now();
        const dismissToast = () => closeSnackbar(snackbarKey);

        const toastContent = (
            <DismissableToast title={title} dismissText="Dismiss" onDismiss={dismissToast}>
                <div>
                    This file has already been added to another ticket.
                    <Link
                        target="_blank"
                        href={EventUrlService.tickets(eventId, {
                            ticketId: ticketId,
                        })}
                        onClick={dismissToast}
                    >
                        See ticket
                    </Link>
                </div>
            </DismissableToast>
        );

        enqueueSnackbar(toastContent, {
            variant: 'warning',
            persist: true,
            key: snackbarKey,
        });
    };

    return showFileAlreadyExistsToast;
};

export default useTicketFileAlreadyExistsToast;
