import { useEffect } from 'react';
import { Grid } from '@mui/material';
import Input from 'src/view/components/input/Input';
import ModalSubTitle from 'src/view/components/modal/ModalSubtitle';
import { Controller, useForm } from 'react-hook-form';
import FormButtons from 'src/view/components/form/FormButtons';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';

const validationSchema = Yup.object({
    name: Yup.string().required(),
});

export interface CreateOrUpdateSeatingplanFormValues {
    name: string;
}

interface Props {
    loading?: boolean;
    editMode?: boolean;
    formDefaultValues?: Partial<CreateOrUpdateSeatingplanFormValues>;
    onFormSubmit: (values: CreateOrUpdateSeatingplanFormValues) => void;
}

export default function CreateOrUpdateSeatingPlanForm({
    loading,
    editMode = false,
    formDefaultValues,
    onFormSubmit,
}: Props) {
    const form = useForm<CreateOrUpdateSeatingplanFormValues>({
        mode: 'onChange',
        resolver: yupResolver(validationSchema),
    });

    const {
        control,
        reset,
        formState: { isValid, isDirty },
        handleSubmit,
    } = form;

    const isSubmitButtonDisabled = !isValid || !isDirty || loading;

    useEffect(() => {
        if (!formDefaultValues) return;

        reset(formDefaultValues);
    }, [formDefaultValues, reset]);

    return (
        <>
            {loading && <LoadingOverlay />}
            <Grid container>
                <Grid item xs={12}>
                    <ModalSubTitle>Name</ModalSubTitle>
                    <Controller
                        name="name"
                        control={control}
                        render={({ field: { name, onChange, value } }) => (
                            <Input
                                name={name}
                                value={value}
                                onChange={onChange}
                                placeholder="Seatingplan name"
                                grey
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <FormButtons
                buttons={[
                    {
                        children: editMode ? 'Update Seatingplan' : 'Create Seatingplan',
                        onClick: () => handleSubmit(onFormSubmit)(),
                        disabled: isSubmitButtonDisabled,
                    },
                ]}
            />
        </>
    );
}
