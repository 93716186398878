import { useEffect, useState } from 'react';
import Modal from 'src/view/components/modal/Modal';
import { useFetchSeriesGroupsDataWrapper } from 'src/app/components/data-wrappers/series-groups/FetchSeriesGroupsDataWrapper';
import SeriesGroupsOverviewTable from 'src/app/components/tables/SeriesGroupsTable';
import { UpdateSeriesGroupFormFeature } from 'src/app/components/features/series-groups/UpdateSeriesGroupFeature';
import { useDeleteSeriesGroupDataWrapper } from 'src/app/components/data-wrappers/series-groups/DeleteSeriesGroupsDataWrapper';
import { TableHeaderTotalFeature } from 'src/app/components/features/tables/TableHeaderTotalFeature';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';
import { useConfirm } from 'material-ui-confirm';

export interface SeriesGroupTableFeatureProps {
    invalidatedAt?: number;
    q?: string;
    page: number;
    onPaginate: (page: number) => void;
    pageSize: number;
}

export default function SeriesGroupsTableFeature({
    invalidatedAt,
    q,
    onPaginate,
    page,
    pageSize,
}: SeriesGroupTableFeatureProps): JSX.Element {
    const confirm = useConfirm();
    const { deleteSeriesGroup, loading: deleteSeriesGroupIsLoading } =
        useDeleteSeriesGroupDataWrapper({});
    const [activeSeriesGroup, setActiveSeriesGroup] = useState<string | undefined>();
    const [seriesGroupsInvalidatedAt, setSeriesGroupsInvalidatedAt] = useState<
        number | undefined
    >();

    const {
        data,
        loading: seriesGroupsLoading,
        fetchSeriesGroups,
    } = useFetchSeriesGroupsDataWrapper({
        invalidatedAt: seriesGroupsInvalidatedAt,
        q,
        page,
        pageSize,
    });

    useEffect(() => {
        if (!invalidatedAt) return;

        setSeriesGroupsInvalidatedAt(invalidatedAt);
    }, [invalidatedAt]);

    const isLoading = seriesGroupsLoading || deleteSeriesGroupIsLoading;
    return (
        <>
            {isLoading && <LoadingOverlay />}
            <TableHeaderTotalFeature title="Series Groups" totalResults={data?.data.length} />
            <SeriesGroupsOverviewTable
                seriesGroups={data?.data || []}
                loading={seriesGroupsLoading}
                onClickDetails={(id) => setActiveSeriesGroup(id)}
                onClickDelete={async (id, name) => {
                    await confirm({
                        title: `Are you sure you want to delete ${name}?`,
                        description: 'Deleting a series group will be permanent',
                    });

                    deleteSeriesGroup(id, {
                        onSuccess: () => {
                            fetchSeriesGroups();
                        },
                    });
                }}
                pagination={{
                    currentPage: data?.meta.currentPage || 1,
                    totalPages: data?.meta.totalPages || 1,
                    onPaginate: onPaginate,
                }}
            />

            {activeSeriesGroup && (
                <Modal
                    open={!!activeSeriesGroup}
                    onClose={() => setActiveSeriesGroup(undefined)}
                    title="Update series group"
                >
                    <UpdateSeriesGroupFormFeature
                        seriesGroupId={activeSeriesGroup}
                        onSuccess={() => {
                            setActiveSeriesGroup(undefined);
                            setSeriesGroupsInvalidatedAt(Date.now());
                        }}
                    />
                </Modal>
            )}
        </>
    );
}
