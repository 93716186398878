import makeStyles from '@mui/styles/makeStyles';

export const useScrollbarStyles = makeStyles(() => ({
    hideScrollbar: {
        '& *::-webkit-scrollbar': {
            display: 'none',
        },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
    },
}));
