import { useEffect } from 'react';
import useApiFetch from 'src/app/hooks/useApiFetch';
import EventTicketsExportLink from 'src/data/models/exports/eventTicketsExportLink';
import exportsService from 'src/data/services/exportsService';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import ErrorsList from 'src/view/components/errors-list/ErrorsList';
import { useSnackbar } from 'notistack';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';
import FormButtons from 'src/view/components/form/FormButtons';

interface Props {
    eventId: string;
}

interface Props {
    eventId: string;
}

export default function ExportModalDataWrapper({ eventId }: Props): JSX.Element {
    const { enqueueSnackbar } = useSnackbar();

    const [{ loading, data, errors }, handleFetch] =
        useApiFetch<ApiResponseBody<EventTicketsExportLink>>();

    const handleFetchExportLink = () => handleFetch(exportsService.exportEventTickets(eventId));

    useEffect(() => {
        if (!data) return;

        const exportLink = data.data.url;

        if (exportLink) {
            window.open(exportLink, '_blank');
            return;
        }

        enqueueSnackbar('data is invalid, please contact IT department', { variant: 'error' });
    }, [data]);

    if (errors.length > 0) {
        return <ErrorsList errors={errors} />;
    }

    if (loading) {
        return <LoadingOverlay />;
    }

    return (
        <FormButtons
            buttons={[
                {
                    children: 'Download',
                    onClick: handleFetchExportLink,
                },
            ]}
        />
    );
}
