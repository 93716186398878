import { Button, ButtonProps, Theme as MuiTheme, Tooltip, useTheme } from '@mui/material';
import { CSSProperties } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import { default as classNames, default as classnames } from 'classnames';
import { useState } from 'react';
import Select, { ActionMeta, Theme } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { InputActionMeta } from 'react-select/src/types';
import { useScrollbarStyles } from 'src/shared/styles/scrollbarStyles';
import { customStyles } from 'src/view/components/auto-complete/constants';
import {
    AutoCompleteOption,
    AutoCompleteOptions,
    AutoCompleteValueOption,
} from 'src/view/components/auto-complete/interfaces';

const useStyles = makeStyles((theme: MuiTheme) => ({
    selectContainer: {
        display: 'flex',
        flex: 1,
    },
    autoComplete: {
        flex: 1,
    },
    button: {
        background: theme.colors.white,
        border: `1px solid #0000003b`,
        borderLeft: 0,
        boxShadow: 'none',
        textTransform: 'capitalize',
        color: theme.palette.primary.main,
        fontSize: theme.spacing(1.5),
        borderRadius: `0 ${theme.layout.borderRadius.regular}px ${theme.layout.borderRadius.regular}px 0`,
        '& svg': {
            fill: theme.colors.grey,
        },
    },
}));

export type AutoCompleteOnChange = (
    value: AutoCompleteValueOption,
    action?: ActionMeta<AutoCompleteOption>
) => void;

export interface AutoCompleteProps {
    buttonProps?: ButtonProps;
    placeholder?: string;
    options: AutoCompleteOptions;
    value?: AutoCompleteValueOption;
    isClearable?: boolean;
    onChange: AutoCompleteOnChange;
    onCreateOption?: ((value: string) => void) | ((value: string) => Promise<void>);
    disabled?: boolean;
    name: string;
    backgroundColor?: CSSProperties['color'];
    isMulti?: boolean;
    loading?: boolean;
    onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
    enableLabelTooltip?: boolean;
}

export default function AutoComplete({
    buttonProps,
    options,
    placeholder,
    value,
    onChange,
    onCreateOption,
    disabled,
    isClearable = true,
    name,
    backgroundColor,
    isMulti,
    loading,
    onInputChange,
    enableLabelTooltip,
    ...props
}: AutoCompleteProps): JSX.Element {
    const classes = useStyles();
    const scrollbarStyles = useScrollbarStyles();
    const theme = useTheme<MuiTheme>();
    const [openTooltip, setOpenTooltip] = useState(false);

    const themeConfig = (themeConfig: Theme) => ({
        ...themeConfig,
        colors: {
            ...themeConfig.colors,
            primary: theme.palette.primary.main,
            primary25: theme.palette.primary.light,
        },
    });

    const onChangeValue = (e: AutoCompleteOnChange) => {
        onChange(e);

        if (enableLabelTooltip && openTooltip) {
            setOpenTooltip(false);
        }
    };

    const renderAutoComplete = () => {
        return (
            <>
                {!onCreateOption && (
                    <Select
                        key={`key-${name}-${value}`}
                        isDisabled={disabled}
                        menuPosition="fixed"
                        theme={themeConfig}
                        onChange={onChangeValue}
                        value={value}
                        styles={customStyles(
                            theme,
                            !!buttonProps,
                            !disabled ? backgroundColor : undefined
                        )}
                        placeholder={placeholder}
                        options={options}
                        isClearable={isClearable}
                        isMulti={isMulti}
                        isLoading={loading}
                        onInputChange={onInputChange}
                        filterOption={filterByLabelOnly}
                    />
                )}

                {onCreateOption && (
                    <CreatableSelect
                        key={`key-${name}-${value}`}
                        isDisabled={disabled}
                        menuPosition="fixed"
                        theme={themeConfig}
                        onChange={onChangeValue}
                        value={value}
                        styles={customStyles(
                            theme,
                            !!buttonProps,
                            !disabled ? backgroundColor : undefined
                        )}
                        placeholder={placeholder}
                        options={options}
                        isClearable={isClearable}
                        onCreateOption={onCreateOption}
                        isMulti={isMulti}
                        isLoading={loading}
                        onInputChange={onInputChange}
                        filterOption={filterByLabelOnly}
                    />
                )}
            </>
        );
    };

    return (
        <div
            className={classnames(classes.selectContainer, scrollbarStyles.hideScrollbar)}
            onMouseEnter={() => enableLabelTooltip && setOpenTooltip(true)}
            onMouseLeave={() => enableLabelTooltip && setOpenTooltip(false)}
            {...props}
        >
            {enableLabelTooltip && value ? (
                <Tooltip title={value?.label} open={openTooltip} placement="top-start">
                    <div style={{ width: '100%' }}>{renderAutoComplete()}</div>
                </Tooltip>
            ) : (
                renderAutoComplete()
            )}

            {buttonProps && (
                <Button
                    {...buttonProps}
                    className={classNames(classes.button, buttonProps.className)}
                    disableRipple
                >
                    {buttonProps.children}
                </Button>
            )}
        </div>
    );
}

/**
 * A helper function that filters options based on their label using the createFilter function from react-select.
 * This function is designed to filter options by label only, ignoring the value.
 * It's particularly useful when the value represents a database ID, which users typically don't want to filter by.
 * 
 * For example:
 * - Suppose a user wants to find a block named "block-7".
 * - The user types "7" into the input field
 * - Thanks to this function, the criteria returns only the block with { label: 'block-7', value: 'database-id-dont-matter' } and excludes { label: 'block-15', value: 'id-containing-**7**' }.
 * 
 * This behavior is discussed in detail at: https://github.com/JedWatson/react-select/issues/3403
 */
const filterByLabelOnly = (option: AutoCompleteOption, inputValue: string): boolean =>
(option.label.toString().toLowerCase().match(inputValue.toLowerCase()) || []).length > 0;