import { CSSProperties } from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((t) => ({
    divider: ({ variant, marginTop, marginBottom }: Partial<DividerProps>) => ({
        marginTop,
        marginBottom,
        height: 1,
        width: '100%',
        background: variant === 'dark' ? t.colors.grey : t.colors.mediumGrey,
    }),
}));

interface DividerProps {
    variant?: 'normal' | 'dark';
    marginTop?: CSSProperties['marginTop'];
    marginBottom?: CSSProperties['marginBottom'];
}

export const Divider = ({
    variant = 'normal',
    marginTop = 16,
    marginBottom = 16,
}: DividerProps) => {
    const classes = useStyles({ variant, marginTop, marginBottom });

    return <div className={classes.divider} />;
};
