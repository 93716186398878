import useApiFetch from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import filesService, { SetFilesToTicketsDto } from 'src/data/services/filesService';
import { EmptyBody } from 'src/data/models/common/emptyBody';
import { DataWrapperCallback } from 'src/app/interfaces/dataWrapperCallback';

interface SetFilesToTicketsDataWrapperChildrenParams
    extends DataWrapperChildrenParams<ApiResponseBody<EmptyBody>> {
    setFilesToTickets: (
        dto: SetFilesToTicketsDto,
        callbacks?: DataWrapperCallback
    ) => Promise<ApiResponseBody<EmptyBody>>;
}

type SetFilesToTicketsDataWrapperProps = DataWrapperProps<
    SetFilesToTicketsDataWrapperChildrenParams,
    ApiResponseBody<EmptyBody>
> &
    BaseFetchOptions;

export const useSetFilesToTicketsDataWrapper = ({
    onError,
    onSuccess,
}: Omit<SetFilesToTicketsDataWrapperProps, 'children'>) => {
    const [{ data, loading, errors, resetData }, handleSetFilesToTickets] =
        useApiFetch<ApiResponseBody<EmptyBody>>();

    const setFilesToTickets = (dto: SetFilesToTicketsDto, callbacks?: DataWrapperCallback) => {
        return handleSetFilesToTickets(filesService.setFilesToTickets(dto), {
            useDefaultErrorHandler: false,
            defaultSuccessMessage: 'Files are succesfully attached to tickets',
            onFail: () => {
                onError?.();
                callbacks?.onFail?.();
            },
            onSuccess: () => {
                onSuccess?.();
                callbacks?.onSuccess?.();
            },
        });
    };

    return {
        setFilesToTickets,
        data,
        loading,
        errors,
        resetData,
    };
};
