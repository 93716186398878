import useApiFetch from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import filesService from 'src/data/services/filesService';

interface UploadFileDataWrapperChildrenParams
    extends DataWrapperChildrenParams<ApiResponseBody<string>> {
    uploadFile: (file: File) => Promise<ApiResponseBody<string>>;
}

type UploadFileDataWrapperProps = DataWrapperProps<
    UploadFileDataWrapperChildrenParams,
    ApiResponseBody<string>
> &
    BaseFetchOptions;

export const useUploadFileDataWrapper = ({
    onError,
    onSuccess,
}: Omit<UploadFileDataWrapperProps, 'children'>) => {
    const [{ data, loading, errors, resetData }, handleUploadFile] =
        useApiFetch<ApiResponseBody<string>>();

    const uploadFile = (file: File) => {
        return handleUploadFile(filesService.uploadFile(file), {
            useDefaultErrorHandler: true,
            onFail: onError,
            onSuccess,
        });
    };

    return {
        uploadFile,
        data,
        loading,
        errors,
        resetData,
    };
};
