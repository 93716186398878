import useApiFetch, { ErrorReturn } from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import orderService, {
    CompareOrdersDto,
    CompareOrdersResponse,
} from 'src/data/services/orderService';

interface CompareOrdersDataWrapperChildrenParams
    extends DataWrapperChildrenParams<ApiResponseBody<CompareOrdersResponse>> {
    compareOrders: (dto: CompareOrdersDto) => Promise<void | ErrorReturn>;
}

type CompareOrdersDataWrapperProps = DataWrapperProps<
    CompareOrdersDataWrapperChildrenParams,
    ApiResponseBody<CompareOrdersResponse>
> &
    BaseFetchOptions;

export const useCompareOrdersDataWrapper = ({
    onError,
    onSuccess,
}: Omit<CompareOrdersDataWrapperProps, 'children'>) => {
    const [{ data, loading, errors, resetData }, handleCompareOrders] =
        useApiFetch<ApiResponseBody<CompareOrdersResponse>>();

    const compareOrdersCall = (dto: CompareOrdersDto) => {
        return handleCompareOrders(orderService.compareOrders(dto), {
            useDefaultErrorHandler: true,
            onFail: onError,
            onSuccess,
        });
    };

    return {
        compareOrders: compareOrdersCall,
        data,
        loading,
        errors,
        resetData,
    };
};
