import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Box, Link } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { parseErrors } from 'src/app/utilities/helpers/errors';
import UnprocessedTicketFile, {
    MatchingTickets,
} from 'src/data/models/tickets/unprocessedTicketFile';
import { useSpacingStyles } from 'src/shared/styles/spacingStyles';
import Button from 'src/view/components/button/Button';
import ErrorsList from 'src/view/components/errors-list/ErrorsList';
import FormFieldError from 'src/view/components/form/FormFieldError';
import { FormLabel } from 'src/view/components/form/FormLabel';
import Input from 'src/view/components/input/Input';
import StatusLabel from 'src/view/components/status-symbol/StatusSymbol';
import * as Yup from 'yup';

interface LinkTicketFormValues {
    block: string;
    row: string;
    seat: string;
    ticketIndex: number;
}

interface MatchingTicketsData {
    data?: MatchingTickets[];
    isLoading: boolean;
    isError: boolean;
    error: unknown;
}

interface LinkTicketFormProps {
    ticketFile: UnprocessedTicketFile;
    onSkip?: () => void;
    onTicketDataChange?: (data: { block: string; row: string; seat: string }) => void;
    onSubmit: SubmitHandler<LinkTicketFormValues>;
    matchingTickets?: MatchingTicketsData;
    submitting?: boolean;
    file: {
        name: string;
        link: string;
    };
}

const linkTicketFormSchema = Yup.object({
    block: Yup.string().trim().required('Block is required'),
    row: Yup.string().trim().required('Row is required'),
    seat: Yup.string().trim().required('Seat is required'),
    ticketIndex: Yup.number().required('Ticket is required'),
});

const useStyles = makeStyles((theme) => ({
    highlightedRow: {
        backgroundColor: theme.palette.success.light,
        color: theme.palette.success.contrastText,
    },
}));

const Wrapper = ({ children }: { children: React.ReactNode }) => {
    const spacingClasses = useSpacingStyles();

    return (
        <Box width={['100%', '100%', '60%']} className={spacingClasses.spacingBottom}>
            {children}
        </Box>
    );
};

const LinkTicketFileForm = ({
    onSubmit,
    onSkip,
    onTicketDataChange,
    ticketFile,
    matchingTickets,
    submitting,
    file,
}: LinkTicketFormProps) => {
    const {
        handleSubmit,
        control,
        watch,
        formState: { isValid },
        register,
        resetField,
    } = useForm<LinkTicketFormValues>({
        defaultValues: {
            block: ticketFile.block ?? undefined,
            row: ticketFile.row ?? undefined,
            seat: ticketFile.seat ?? undefined,
        },
        resolver: yupResolver(linkTicketFormSchema),
        reValidateMode: 'onChange',
    });

    const classes = useStyles();

    const block = watch('block');
    const row = watch('row');
    const seat = watch('seat');
    const ticketIndex = watch('ticketIndex');

    const variant = ticketFile.confidence > 50 ? 'success' : 'error';

    useEffect(
        () => resetField('ticketIndex', { defaultValue: matchingTickets?.data ? 0 : undefined }),
        [matchingTickets, resetField]
    );

    useEffect(() => {
        onTicketDataChange?.({ block, row, seat });
    }, [block, row, seat, onTicketDataChange]);

    return (
        <div>
            <Box
                sx={{
                    mb: 2,
                }}
            >
                <Link
                    sx={{
                        fontWeight: 'bold',
                        fontStyle: 'italic',
                        fontSize: '0.95rem',
                        color: (theme) => theme.palette.text.secondary,
                    }}
                    href={file.link}
                >
                    {file.name}
                </Link>
            </Box>

            <Wrapper>
                <FormLabel>Block</FormLabel>
                <Controller
                    name="block"
                    control={control}
                    render={({
                        field: { name, value, onChange, ref, onBlur },
                        fieldState: { error },
                    }) => (
                        <>
                            <Input
                                name={name}
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                inputRef={ref}
                            />
                            <FormFieldError message={error?.message} />
                        </>
                    )}
                />
            </Wrapper>
            <Wrapper>
                <FormLabel>Row</FormLabel>
                <Controller
                    name="row"
                    control={control}
                    render={({
                        field: { name, value, onChange, ref, onBlur },
                        fieldState: { error },
                    }) => (
                        <>
                            <Input
                                name={name}
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                inputRef={ref}
                            />
                            <FormFieldError message={error?.message} />
                        </>
                    )}
                />
            </Wrapper>
            <Wrapper>
                <FormLabel>Seat</FormLabel>
                <Controller
                    name="seat"
                    control={control}
                    render={({
                        field: { name, value, onChange, ref, onBlur },
                        fieldState: { error },
                    }) => (
                        <>
                            <Input
                                name={name}
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                inputRef={ref}
                            />
                            <FormFieldError message={error?.message} />
                        </>
                    )}
                />
            </Wrapper>

            <Box py={2} display="flex" gap={1}>
                <Box fontWeight="bold">Confidence:</Box>
                <StatusLabel label={`${ticketFile.confidence}%`} variant={variant} />
            </Box>

            {matchingTickets?.isError && <ErrorsList errors={parseErrors(matchingTickets.error)} />}

            {matchingTickets?.data && matchingTickets.data.length > 1 && (
                <Alert severity="info">{`${matchingTickets.data.length} have been found. Please select the correct ticket`}</Alert>
            )}

            {block && row && seat && matchingTickets && (
                <TableContainer>
                    <Table sx={{ width: '100%' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox"></TableCell>
                                <TableCell>Block | Row | Seat</TableCell>
                                <TableCell>Seating Plan Category</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!matchingTickets.isLoading &&
                                matchingTickets.data &&
                                matchingTickets.data.length === 0 && (
                                    <TableRow>
                                        <TableCell align="center" colSpan={3}>
                                            No matching tickets found
                                        </TableCell>
                                    </TableRow>
                                )}
                            {matchingTickets.isLoading && (
                                <TableRow>
                                    <TableCell padding="checkbox">
                                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                    </TableCell>
                                    <TableCell>
                                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                    </TableCell>
                                    <TableCell>
                                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                                    </TableCell>
                                </TableRow>
                            )}

                            {!matchingTickets.isLoading &&
                                (matchingTickets.data ?? []).map((ticket, index) => {
                                    const rootClass =
                                        (matchingTickets.data ?? []).length > 1 &&
                                        ticketIndex === index
                                            ? classes.highlightedRow
                                            : undefined;
                                    return (
                                        <TableRow key={ticket.id}>
                                            <TableCell
                                                padding="checkbox"
                                                classes={{
                                                    root: rootClass,
                                                }}
                                            >
                                                <input
                                                    type="radio"
                                                    {...register('ticketIndex')}
                                                    value={index}
                                                    checked={ticketIndex === index}
                                                />
                                            </TableCell>
                                            <TableCell
                                                classes={{
                                                    root: rootClass,
                                                }}
                                            >{`${ticket.blockNumber} | ${ticket.rowNumber} | ${ticket.seatNumber}`}</TableCell>
                                            <TableCell
                                                classes={{
                                                    root: rootClass,
                                                }}
                                            >
                                                {ticket.seatingPlanCategoryName}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            <Box display="flex" gap={2} mt={2}>
                <Button
                    disabled={!isValid || submitting}
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                >
                    Link Ticket
                </Button>
                <Button onClick={onSkip} type="button" variant="text">
                    Skip
                </Button>
            </Box>
        </div>
    );
};

export default LinkTicketFileForm;
