import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, IconButton, Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import OrderPrice from 'src/app/components/table-data/OrderPrice';
import {
    OrderlineSeatSection,
    findOrderlineTicketBlockRowSeat,
} from 'src/app/components/tables/helpers/OrderlinesTableHelpers';
import EventUrlService from 'src/app/pages/events/eventUrlService';
import travelwareUrlService from 'src/app/pages/events/travelwareUrlService';
import { orderTravelwareSourcePrefix } from 'src/data/constants/order';
import { SHIPPING_STATUS } from 'src/data/enums/orderline';
import Orderline, { OrderlineTableDataResolver } from 'src/data/models/orderline/orderline';
import { SIZES } from 'src/shared/theme/enums';
import OrderNumberDisplay from 'src/view/components/order-number-display/OrderNumberDisplay';
import Popover from 'src/view/components/popover/Popover';
import ShippingStatus from 'src/view/components/shipping-status/ShippingStatus';
import Table, { RowIdResolver, TableProps } from 'src/view/components/table/table/Table';
import {
    NewTableColumn,
    TABLE_CELL_HEIGHT,
    TABLE_CELL_WIDTH,
    TableColumnSorting,
    TablePaginationData,
} from 'src/view/components/table/table/Types';
import { WarningText } from 'src/view/components/warning-text/WarningText';
import IconComponent from 'src/view/icons/IconComponent';

const useStyles = makeStyles((theme) => ({
    link: {
        color: theme.colors.blue,
        marginLeft: '0.2rem',
    },
}));

export interface OrderlinesTableProps {
    eventId: string;
    orderlines: Orderline[];
    loading?: boolean;
    pagination?: TablePaginationData;
    onSortingChange?: (sortings: TableColumnSorting[]) => void;
    onClickDetails: (orderline: Orderline) => void;
    onClickAssign: (orderline: Orderline) => void;
    initialSelectedRows: RowIdResolver<OrderlineTableDataResolver>[];
    tableToolbarActions?: TableProps<Orderline, OrderlineTableDataResolver>['tableToolbarActions'];
}
export default function OrderlinesTable({
    eventId,
    loading,
    orderlines,
    pagination,
    onSortingChange,
    onClickDetails,
    onClickAssign,
    initialSelectedRows,
    tableToolbarActions,
}: OrderlinesTableProps): JSX.Element {
    const classes = useStyles();

    const columns: NewTableColumn<Orderline>[] = [
        {
            key: 'orderSummary.clientEmail',
            title: 'Customer',
            width: TABLE_CELL_WIDTH.LARGE,
            cellRenderer: (rowData) => rowData.orderSummary?.clientEmail,
        },
        {
            key: 'seatingPlanCategoryName',
            isSortable: true,
            title: 'Category',
            width: TABLE_CELL_WIDTH.EXTRA_LARGE,
            cellRenderer: (rowData) => {
                const {
                    assignedSeatingPlanCategoryId,
                    seatingPlanCategoryId,
                    seatingPlanCategoryName,
                    assignedSeatingPlanCategoryName,
                    seatingplanCategoryIsArchived,
                } = rowData;

                const isAssignedToDifferentCategory =
                    assignedSeatingPlanCategoryId &&
                    assignedSeatingPlanCategoryId !== seatingPlanCategoryId;

                return (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyItems: 'center',
                            alignItems: 'center',
                            gap: 0.5,
                        }}
                    >
                        <Typography fontSize={14}>{seatingPlanCategoryName}</Typography>
                        {seatingplanCategoryIsArchived && <WarningText text="archived" />}
                        {isAssignedToDifferentCategory && (
                            <Popover
                                text={
                                    <IconComponent Icon={WarningIcon} warning size={SIZES.SMALL} />
                                }
                            >
                                <Box
                                    sx={{
                                        padding: 2,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: 'bold',
                                            fontSize: 18,
                                            marginBottom: 2,
                                            color: (theme) => theme.palette.warning.main,
                                        }}
                                        variant="body2"
                                    >
                                        This order is assigned to a different category
                                    </Typography>
                                    <Typography fontSize={14}>
                                        <strong>Order category:</strong> {seatingPlanCategoryName}
                                    </Typography>
                                    <Typography fontSize={14}>
                                        <strong>Assigned category:</strong>{' '}
                                        {assignedSeatingPlanCategoryName}
                                    </Typography>
                                </Box>
                            </Popover>
                        )}
                    </Box>
                );
            },
        },
        {
            key: 'orderSummary.orderNumber',
            sortingKey: 'orderNumber',
            title: <>Order Number</>,
            width: TABLE_CELL_WIDTH.LARGE,
            isSortable: true,
            cellRenderer: (rowData) => {
                if (!rowData.orderSummary) return <></>;

                const { source, orderNumber, externalOrderReference } = rowData.orderSummary;

                return (
                    <OrderNumberDisplay
                        orderNumber={orderNumber}
                        externalOrderReference={externalOrderReference}
                        externalUrl={
                            source.startsWith(orderTravelwareSourcePrefix)
                                ? travelwareUrlService.orderNumberRedirect(orderNumber)
                                : ''
                        }
                    />
                );
            },
        },
        {
            key: 'quantity',
            title: 'Quantity',
            width: TABLE_CELL_WIDTH.SMALL,
        },
        {
            key: 'totalOriginalPrice',
            title: 'Total price',
            width: TABLE_CELL_WIDTH.LARGE,
            cellRenderer: (rowData) => {
                const { totalSalesPrice, totalOriginalPrice } = rowData;

                return (
                    <OrderPrice
                        oldSystemPrice={totalOriginalPrice}
                        newSystemPrice={totalSalesPrice}
                    />
                );
            },
        },
        {
            key: 'assignedTickets',
            title: 'Block/Row/Seat',
            width: TABLE_CELL_WIDTH.MEGA,
            cellRenderer: (rowData) => {
                const { shippingStatus, status, assignedTickets } = rowData;

                const blockRowSeat = findOrderlineTicketBlockRowSeat(rowData);

                return (
                    <OrderlineSeatSection
                        onClickAssign={onClickAssign}
                        orderline={rowData}
                        shippingStatus={shippingStatus}
                        assignedTickets={assignedTickets}
                        status={status}
                        blockRowSeat={blockRowSeat}
                    />
                );
            },
        },
        {
            key: 'orderSummary.sourceSystem',
            title: 'Source System',
            width: TABLE_CELL_WIDTH.MEDIUM,
            cellRenderer: (rowData) => rowData.orderSummary.sourceSystem,
        },
        {
            key: 'shippingStatus',
            title: 'Shipping status',
            width: TABLE_CELL_WIDTH.LARGE,
            cellRenderer: (rowData) => {
                return (
                    <>
                        <ShippingStatus status={rowData.shippingStatus} />{' '}
                        {(rowData.shippingStatus === SHIPPING_STATUS.NOT_SENT ||
                            rowData.shippingStatus === SHIPPING_STATUS.UNKNOWN) && (
                            <Link
                                className={classes.link}
                                href={EventUrlService.orders(eventId, {
                                    orderId: rowData.orderId,
                                })}
                            >
                                See order
                            </Link>
                        )}
                    </>
                );
            },
            truncate: true,
        },
        {
            key: 'internalNotes',
            title: 'Order Internal Notes',
            width: TABLE_CELL_WIDTH.LARGE,
            cellRenderer: (rowData) => (
                <Popover text={rowData.orderSummary?.internalNotes} isLink={false}>
                    <span>{rowData.orderSummary?.internalNotes}</span>
                </Popover>
            ),
            truncate: true,
        },
        {
            key: 'assignmentInstructions',
            title: 'Assignment Instructions',
            width: TABLE_CELL_WIDTH.LARGE,
            cellRenderer: (rowData) => (
                <Popover text={rowData.assignmentInstructions} isLink={false}>
                    <span>{rowData.assignmentInstructions}</span>
                </Popover>
            ),
            truncate: true,
        },
        {
            width: TABLE_CELL_WIDTH.SMALL,
            align: 'center',
            cellRenderer: (rowData) => {
                return (
                    <IconButton
                        color="primary"
                        onClick={() => onClickDetails(rowData)}
                        size="small"
                    >
                        <VisibilityOutlinedIcon />
                    </IconButton>
                );
            },
        },
    ];

    return (
        <Table<Orderline, OrderlineTableDataResolver>
            data={orderlines}
            rowIdResolver={({ id, canChangeAssignedTickets, assignedTickets }: Orderline) => ({
                id,
                data: {
                    canChangeAssignedTickets,
                    assignedTickets,
                },
            })}
            columns={columns}
            loading={loading}
            pagination={pagination}
            minWidth={650}
            onSortingChange={onSortingChange}
            enableCheckboxes
            initialSelectedRows={initialSelectedRows}
            rowHeight={TABLE_CELL_HEIGHT.LARGE}
            tableToolbarActions={tableToolbarActions}
        />
    );
}
