import { Theme, useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import AutoComplete from 'src/view/components/auto-complete/AutoComplete';
import FormFieldError from 'src/view/components/form/FormFieldError';
import Input from 'src/view/components/input/Input';
import { Grid } from '@mui/material';
import classNames from 'classnames';
import Price from 'src/data/models/common/price';
import { AutoCompleteOptions } from 'src/view/components/auto-complete/interfaces';

const useStyles = makeStyles((theme: Theme) => ({
    priceInput: {
        '& .MuiInputBase-input': {
            padding: theme.spacing(1.5),
        },
    },
    currencySelector: {
        '& > div': {
            width: '100%',
        },
    },
    paddingRight: {
        paddingRight: `0 !important`,
    },
    paddingLeft: {
        paddingLeft: `0 !important`,
    },
    spacingRight: {
        paddingRight: theme.spacing(1),
    },
    marginBottom: {
        marginBottom: theme.spacing(2.5),
    },
}));

interface Props {
    price: Price;
    onChange: (price: Price) => void;
    currencyError?: string;
    valueError?: string;
    alignCurrencyLeft?: boolean;
    currencyOptions: AutoCompleteOptions;
    disabled?: boolean;
}

export default function renderPriceInput({
    price,
    onChange,
    currencyError,
    valueError,
    alignCurrencyLeft = false,
    currencyOptions,
    disabled,
}: Props): JSX.Element {
    const theme = useTheme();
    const classes = useStyles();

    const priceInput = (
        <Grid
            item
            xs={8}
            className={classNames(
                classes.currencySelector,
                !alignCurrencyLeft && classes.spacingRight
            )}
        >
            <Input
                disabled={disabled}
                type="number"
                className={classes.priceInput}
                value={price?.value}
                onChange={(event) => {
                    const priceValue = event.currentTarget.value;

                    const newPrice: Price = {
                        ...price,
                        value: Number(priceValue) || undefined,
                    };

                    onChange(newPrice);
                }}
                border
            />
            <FormFieldError message={valueError} />
        </Grid>
    );

    return (
        <Grid container>
            {!alignCurrencyLeft && priceInput}
            <Grid
                item
                xs={4}
                className={classNames(
                    classes.currencySelector,
                    alignCurrencyLeft && classes.spacingRight
                )}
            >
                <AutoComplete
                    name="currency"
                    onChange={(selectedCurrency) => {
                        if (!selectedCurrency) return;

                        const newPrice: Price = {
                            ...price,
                            currency: selectedCurrency.value,
                        };

                        onChange(newPrice);
                    }}
                    value={currencyOptions.find((currency) => currency.value === price.currency)}
                    backgroundColor={theme.colors.white}
                    options={currencyOptions}
                    isClearable={false}
                    disabled={disabled}
                />
                <FormFieldError message={currencyError} />
            </Grid>
            {alignCurrencyLeft && priceInput}
        </Grid>
    );
}
