import Button from 'src/view/components/button/Button';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { ChildlessBaseComponent } from 'src/view/interfaces/BaseComponent';

const useStyles = makeStyles((theme: Theme) => ({
    paginationContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'transparent',
    },
    spacingRight: {
        marginRight: theme.spacing(1),
    },
    tinyButton: {
        width: '2.5rem',
        minWidth: '2.5rem',
    },
    currentPage: {
        minWidth: '50px',
        textAlign: 'center',
        marginRight: theme.spacing(1),
        'font-weight': theme.typography.fontWeightBold,
    },
}));

export interface TablePaginationProps extends ChildlessBaseComponent {
    totalPages: number;
    currentPage: number;
    onPaginate: (pageNumber: number) => void;
    disabled?: boolean;
}

export default function TablePagination({
    totalPages,
    currentPage,
    onPaginate,
    disabled,
    className,
}: TablePaginationProps): JSX.Element {
    const classes = useStyles();

    return (
        <div className={classNames(classes.paginationContainer, className)}>
            <Button
                className={classes.spacingRight}
                tinyBorderRadius
                disabled={currentPage === 1 || disabled}
                onClick={() => onPaginate(1)}
            >
                First
            </Button>
            <Button
                className={classNames(classes.spacingRight, classes.tinyButton)}
                tinyBorderRadius
                disabled={currentPage === 1 || disabled}
                onClick={() => onPaginate(currentPage - 1)}
            >
                <KeyboardArrowLeftIcon />
            </Button>
            <div className={classes.currentPage}>{`${currentPage} / ${totalPages}`}</div>
            <Button
                className={classNames(classes.spacingRight, classes.tinyButton)}
                tinyBorderRadius
                disabled={currentPage === totalPages || disabled}
                onClick={() => onPaginate(currentPage + 1)}
            >
                <KeyboardArrowRightIcon />
            </Button>
            <Button
                tinyBorderRadius
                disabled={currentPage === totalPages || disabled}
                onClick={() => onPaginate(totalPages)}
            >
                Last
            </Button>
        </div>
    );
}
