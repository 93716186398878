import { Alert, Box, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import AssignTicketsTableFeature from 'src/app/components/features/tickets/AssignedTicketsTableFeature';
import TicketsTableFeature from 'src/app/components/features/tickets/TicketsTableFeature';
import OrderlineDetailsForm, {
    OrderlineDetailsFormValues,
    mapInitialOrderlineDetailsFormValues,
} from 'src/app/components/forms/OrderlineDetailsForm';
import { useFetchEventById } from 'src/app/hooks/events/useFetchEventById';
import { FETCH_EVENT_TICKETS_QUERY_KEY } from 'src/app/hooks/tickets/useFetchEventTickets';
import useApiFetch from 'src/app/hooks/useApiFetch';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import Orderline from 'src/data/models/orderline/orderline';
import orderlineService from 'src/data/services/orderlineService';
import { AutoCompleteOption } from 'src/view/components/auto-complete/interfaces';
import { Divider } from 'src/view/components/divider/Divider';
import {
    FilterAutoComplete,
    FilterAutoCompleteOption,
} from 'src/view/components/filters/AutoComplete/AutoComplete';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';
import { useFetchEventsDataWrapper } from '../../data-wrappers/event/FetchEventsDataWrapper';

interface Props {
    orderId: string;
    orderLineId: string;
    onOrderlineInvalidated?: () => void;
    onSuccessfullyAssignTickets?: () => void;
}

export default function AssignTicketsFeature({
    orderId,
    orderLineId,
    onOrderlineInvalidated,
    onSuccessfullyAssignTickets,
}: Props) {
    const { eventId } = useParams<{ eventId: string }>();
    const { fetchEventsOptions, seriesName } = useFetchEventOptions(eventId);

    // We need this in case the user wants to filter by another event from the same serie.
    const [selectedEvent, setSelectedEvent] = useState<FilterAutoCompleteOption | undefined>();

    const queryClient = useQueryClient();
    const [orderLineFormValues, setOrderlineFormValues] = useState<OrderlineDetailsFormValues>();
    const [seatingPlanCategory, setSeatingPlanCategory] = useState<
        AutoCompleteOption | undefined
    >();
    const [{ loading: orderlineIsLoading, data: orderLineData }, handleFetchOrderline] =
        useApiFetch<ApiResponseBody<Orderline>>();

    const assignedTicketIds = useMemo(
        () => orderLineData?.data.assignedTickets.map((t) => t.id),
        [orderLineData]
    );

    useEffect(() => {
        handleFetchOrderline(orderlineService.fetchOrderlineById(orderId, orderLineId), {
            useDefaultErrorHandler: true,
        });
    }, [handleFetchOrderline, orderId, orderLineId]);

    useEffect(() => {
        if (!orderLineData) return;

        if (!seatingPlanCategory) {
            const { seatingPlanCategoryName, seatingPlanCategoryId } = orderLineData.data;

            if (!seatingPlanCategoryId) return;

            setSeatingPlanCategory({
                label: seatingPlanCategoryName,
                value: seatingPlanCategoryId,
            });
        }

        setOrderlineFormValues(mapInitialOrderlineDetailsFormValues(orderLineData.data));
    }, [orderLineData, seatingPlanCategory]);

    const defaultFilters = useMemo(() => {
        // They are always the same
        const filters = [
            { property: 'available', value: 'true' },
            { property: 'purchaseStatus', value: 'Confirmed,Completed' },
        ];

        // If nothing is selected, or the selected event is the same as the original event, add the seating plan category filter
        // For other events we don't have the data from orderLineData, therefore we can't prefill it
        const shouldIncludeSeatingPlanCategoryFilter =
            !selectedEvent?.value || selectedEvent?.value === eventId;
        if (shouldIncludeSeatingPlanCategoryFilter) {
            const seatingPlanCategoryId = orderLineData?.data.seatingPlanCategoryId || '';
            filters.push({
                property: 'seatingPlanCategoryId',
                value: seatingPlanCategoryId,
            });
        }

        return filters;
    }, [selectedEvent, eventId, orderLineData?.data.seatingPlanCategoryId]);

    /** We use this to allow the user to fetch assignable tickets from ANOTHER EVENT but WITHIN THE SAME SERIES
     * This is why we need to keep track of the currently selected event, because user can move between events.
     * This event id is used only for Assign Tickets Modal and NOT for already Assigned Tickets
     */
    const currentlySelectedEventId = useMemo(() => {
        return (typeof selectedEvent?.value === 'string' ? selectedEvent?.value : eventId) || '';
    }, [selectedEvent, eventId]);

    if (!eventId) {
        return <Alert severity="error">No eventId was found</Alert>;
    }

    return (
        <>
            {orderlineIsLoading && <LoadingOverlay />}

            <OrderlineDetailsForm
                loading={orderlineIsLoading && !orderLineData}
                defaultValues={orderLineFormValues}
                orderline={orderLineData?.data}
            />

            <AssignTicketsTableFeature
                eventId={currentlySelectedEventId}
                seatingPlanCategory={seatingPlanCategory}
                orderId={orderId}
                orderLineId={orderLineId}
                toolbarAdditionalItems={assignedTicketIds}
                onSuccess={() => {
                    onOrderlineInvalidated?.();
                    onSuccessfullyAssignTickets?.();
                }}
            />

            <Divider />

            {seriesName && (
                <FilterByAnotherEventFromSerieFilter
                    fetchEventsOptions={fetchEventsOptions}
                    eventId={eventId}
                    seriesName={seriesName}
                    selectedEvent={selectedEvent}
                    onChange={setSelectedEvent}
                />
            )}

            {orderLineData?.data && (
                <TicketsTableFeature
                    key={currentlySelectedEventId}
                    defaultSorting={[{ column: 'blockRowSeatName', direction: 'asc' }]}
                    defaultFilters={defaultFilters}
                    eventId={currentlySelectedEventId}
                    orderId={orderId}
                    orderLineId={orderLineId}
                    enableDeleteTickets={false}
                    toolbarAdditionalItems={assignedTicketIds}
                    onSuccess={() => {
                        onOrderlineInvalidated?.();
                        onSuccessfullyAssignTickets?.();
                        queryClient.invalidateQueries({
                            queryKey: [FETCH_EVENT_TICKETS_QUERY_KEY],
                            exact: false,
                        });
                    }}
                    disableUrlWriting
                />
            )}
        </>
    );
}

const useFetchEventOptions = (eventId: string | undefined) => {
    const eventData = useFetchEventById(eventId || '', {});

    const fetchEventsOptions = useMemo(
        () => ({
            filter: [
                {
                    property: 'seriesId',
                    value: eventData.data?.data.data.seriesId || '',
                },
            ],
            pageSize: 1000,
        }),
        [eventId, eventData.data?.data.data.seriesId]
    );

    return {
        fetchEventsOptions,
        seriesName: eventData.data?.data.data.seriesName || '',
    };
};

const FilterByAnotherEventFromSerieFilter = ({
    eventId,
    seriesName,
    selectedEvent,
    fetchEventsOptions,
    onChange,
}: {
    eventId: string;
    seriesName: string;
    fetchEventsOptions: BaseFetchOptions;
    selectedEvent: FilterAutoCompleteOption | undefined;
    onChange: Dispatch<SetStateAction<FilterAutoCompleteOption | undefined>>;
}) => {
    const { data: fetchedEventData } = useFetchEventsDataWrapper(fetchEventsOptions);

    const filterByEventOptions = useMemo(
        () =>
            fetchedEventData?.data
                .map((event) => ({
                    label: event.name,
                    value: event.id,
                }))
                // Filter out the original/initial event
                .filter((event) => event.value !== eventId) || [],
        [eventId, fetchedEventData]
    );

    if (!fetchedEventData) return <>Loading...</>;

    return (
        <Box
            sx={{
                background: (theme) => theme.colors.lightGrey,
                padding: 2,
            }}
        >
            <Typography marginBottom={1}>
                Select another event from <strong>{seriesName}</strong>:
            </Typography>

            <Box>
                <FilterAutoComplete
                    filterPlaceholderProps={{ placeholder: 'Select event' }}
                    value={selectedEvent}
                    options={filterByEventOptions}
                    onChange={(val) => {
                        onChange(val as FilterAutoCompleteOption | undefined);
                    }}
                    disabled={false}
                    isMulti={false}
                    preserveSearchValue={true}
                />
            </Box>
        </Box>
    );
};
