import useApiFetch from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import { EmptyBody } from 'src/data/models/common/emptyBody';
import seatingPlanCategoryPropertyService from 'src/data/services/seatingPlanCategoryPropertyService';

interface FetchSeatingPlanCategoryPropertyDataWrapperChildrenParams
    extends DataWrapperChildrenParams<ApiResponseBody<EmptyBody>> {
    deleteSeatingplanCategoryProperty: (id: string) => Promise<ApiResponseBody<EmptyBody>>;
}

type DeleteSeatingPlanCategoryPropertyDataWrapperProps = DataWrapperProps<
    FetchSeatingPlanCategoryPropertyDataWrapperChildrenParams,
    ApiResponseBody<EmptyBody>
>;

export const useDeleteSeatingplanCategoryProperty = ({
    onError,
    onSuccess,
}: Omit<DeleteSeatingPlanCategoryPropertyDataWrapperProps, 'children'> & BaseFetchOptions) => {
    const [{ data, loading, errors, resetData }, handleDeleteSeatingplanCategoryProperty] =
        useApiFetch<ApiResponseBody<EmptyBody>>();

    const deleteSeatingplanCategoryPropertyCall = (id: string) => {
        return handleDeleteSeatingplanCategoryProperty(
            seatingPlanCategoryPropertyService.deleteProperty(id),
            {
                useDefaultErrorHandler: true,
                onFail: onError,
                onSuccess,
            }
        );
    };

    return {
        deleteSeatingPlanCategoryProperty: deleteSeatingplanCategoryPropertyCall,
        data,
        loading,
        errors,
        resetData,
    };
};
