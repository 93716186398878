import { useEffect } from 'react';
import { CircularProgress, FormHelperText, Grid } from '@mui/material';
import classNames from 'classnames';
import { FormLabel } from 'src/view/components/form/FormLabel';
import { Controller, useForm } from 'react-hook-form';
import FormFieldError from 'src/view/components/form/FormFieldError';
import Input from 'src/view/components/input/Input';
import { nameUpdateRoles } from 'src/app/utilities/helpers/userPermissions';
import FormButtons from 'src/view/components/form/FormButtons';
import { useSpacingStyles } from 'src/shared/styles/spacingStyles';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as Yup from 'yup';
import { useTheme } from '@mui/styles';
import { FormProps } from 'src/app/interfaces/form/formProps';

export interface EditEventNameFormValues {
    name: string;
}

interface EditEventNameFormProps extends FormProps<EditEventNameFormValues> {
    canEditName?: boolean;
}

const validationSchema = Yup.object({
    name: Yup.string().required('Enter an event name'),
});

export const EditEventNameForm = ({
    loading,
    onSubmit,
    defaultValues,
    values,
    canEditName,
}: EditEventNameFormProps) => {
    const spacingClasses = useSpacingStyles();
    const theme = useTheme();

    const {
        control,
        handleSubmit,
        reset,
        formState: { isDirty },
    } = useForm<EditEventNameFormValues>({
        mode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues,
    });

    const isSubmitButtonDisabled = loading || !isDirty || !canEditName;

    useEffect(() => {
        if (!values) return;

        reset(values);
    }, [values]);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <h2
                        className={classNames(
                            spacingClasses.noMarginBottom,
                            spacingClasses.noMarginTop
                        )}
                    >
                        Change event name
                    </h2>
                </Grid>

                <Grid item xs={12}>
                    <FormLabel>Event Name*</FormLabel>
                    <Controller
                        name="name"
                        control={control}
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <>
                                <Input
                                    name={name}
                                    value={value}
                                    onChange={onChange}
                                    placeholder="Event name"
                                    disabled={!canEditName}
                                />

                                <FormHelperText>
                                    Only employees with the following rights can change the name of
                                    an event: <strong>{nameUpdateRoles.join(', ')}</strong>. If you
                                    have questions about your role(s), please contact the system
                                    admin.
                                </FormHelperText>
                                <FormFieldError message={error?.message} />
                            </>
                        )}
                    />
                </Grid>
            </Grid>
            <FormButtons
                buttons={[
                    {
                        children: 'Update name',
                        startIcon: loading && (
                            <CircularProgress size={theme.layout.loader.sizes.small} />
                        ),
                        disabled: isSubmitButtonDisabled,
                        onClick: () => handleSubmit(onSubmit)(),
                    },
                ]}
            />
        </>
    );
};
