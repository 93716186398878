import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ReplayIcon from '@mui/icons-material/Replay';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import WarningIcon from '@mui/icons-material/Warning';
import { IconButton, Theme, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import moment from 'moment';
import { EventsPopOver } from 'src/app/components/pop-overs/orders/EventsPopOver';
import OrderPrice from 'src/app/components/table-data/OrderPrice';
import { renderBooleanIcon } from 'src/app/components/tables/helpers/EventOrdersTableHelpers';
import eventUrlService from 'src/app/pages/events/eventUrlService';
import travelwareUrlService from 'src/app/pages/events/travelwareUrlService';
import {
    orderTravelwareSourcePrefix,
    paymentStatusStrings,
    shippingStatusStrings,
} from 'src/data/constants/order';
import { ORDER_STATUS, PAYMENT_STATUS, SHIPPING_STATUS } from 'src/data/enums/order';
import EventOrder from 'src/data/models/order/EventOrder';
import helpContentService from 'src/data/services/helpContentService';
import { dateFormat } from 'src/shared/date';
import { formatPrice } from 'src/shared/helpers/formatPrice';
import { useGenericStyles } from 'src/shared/styles/genericStyles';
import { useSpacingStyles } from 'src/shared/styles/spacingStyles';
import { useTableStyles } from 'src/shared/styles/tableStyles';
import { BUTTON_SIZES, SIZES } from 'src/shared/theme/enums';
import SplitButton, { SplitButtonOption } from 'src/view/components/button/SplitButton';
import HelpPopover from 'src/view/components/help-popover/HelpPopover';
import OrderNumberDisplay from 'src/view/components/order-number-display/OrderNumberDisplay';
import Popover from 'src/view/components/popover/Popover';
import Table, { RowIdResolver, TableProps } from 'src/view/components/table/table/Table';
import {
    NewTableColumn,
    TABLE_CELL_WIDTH,
    TABLE_ROW_TYPE,
    TableColumnSorting,
    TablePaginationData,
} from 'src/view/components/table/table/Types';
import IconComponent from 'src/view/icons/IconComponent';

const useStyles = makeStyles((theme: Theme) => ({
    iconCol: {
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            marginRight: theme.spacing(0.5),
        },
    },
    bold: {
        fontWeight: 'bold',
    },
    success: {
        color: theme.palette.success.main,
    },
    warning: {
        color: theme.palette.warning.main,
    },
    error: {
        color: theme.palette.error.main,
    },
    new: {
        color: theme.colors.grey,
    },
    link: {
        color: theme.colors.linkBlue,
        textDecoration: 'underline',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    paymentStatus: {
        display: 'flex',
    },
    paymentStatusText: {
        marginRight: theme.spacing(0.5),
    },
    disabled: {
        color: theme.colors.grey,
        pointerEvents: 'none',
    },
    eventPriceColumn: {
        display: 'flex',
        alignItems: 'center',
    },
}));

export enum ORDER_TABLE_VARIANTS {
    Orders = 'Orders',
    EventOrders = 'EventOrders',
}

export interface OrderTableDataResolver {
    canSendAutomatically: boolean;
    canMarkAsSent: boolean;
}

export interface OrdersTableProps {
    variant?: ORDER_TABLE_VARIANTS;
    eventId?: string;
    orders: EventOrder[];
    onClickSend: (orderIds: RowIdResolver<OrderTableDataResolver>[]) => void;
    onClickSendManually: (orderIds: RowIdResolver<OrderTableDataResolver>[]) => void;
    onClickSendingOptions: (orderIds: RowIdResolver<OrderTableDataResolver>[]) => void;
    onChangePaymentStatus: (orderIds: RowIdResolver<OrderTableDataResolver>[]) => void;
    loading?: boolean;
    pagination?: TablePaginationData;
    onSortingChange?: (sortings: TableColumnSorting[]) => void;
    onChangeSelectedRows?: (selectedRows: RowIdResolver<OrderTableDataResolver>[]) => void;
    enableCheckboxes?: boolean;
    onClickDetails: (id: string) => void;
    tableToolbarSplitActions?: TableProps<
        EventOrder,
        OrderTableDataResolver
    >['tableToolbarSplitActions'];
}

export default function OrdersOverviewTable({
    variant = ORDER_TABLE_VARIANTS.Orders,
    eventId,
    orders,
    onClickSend,
    onClickSendManually,
    onChangePaymentStatus,
    onClickSendingOptions,
    onChangeSelectedRows,
    loading,
    pagination,
    onSortingChange,
    enableCheckboxes,
    onClickDetails,
    tableToolbarSplitActions,
}: OrdersTableProps): JSX.Element {
    const classes = useStyles();
    const tableClasses = useTableStyles();
    const spacingClasses = useSpacingStyles();
    const genericClasses = useGenericStyles();
    const columns: NewTableColumn<EventOrder>[] = [];

    const renderShippingStatus = (status: SHIPPING_STATUS) => {
        let colorClass = '';

        if (status === SHIPPING_STATUS.NotSent) {
            colorClass = classes.error;
        }

        if (status === SHIPPING_STATUS.Sent) {
            colorClass = classes.success;
        }

        if (status === SHIPPING_STATUS.PartiallySent) {
            colorClass = classes.warning;
        }

        if (status === SHIPPING_STATUS.Unknown) {
            colorClass = classes.new;
        }

        return (
            <span className={classnames(classes.bold, colorClass)}>
                {shippingStatusStrings[status]}
            </span>
        );
    };

    const renderPaymentStatus = (status: PAYMENT_STATUS) => {
        switch (status) {
            case PAYMENT_STATUS.Approved:
                return (
                    <div
                        className={classnames(
                            classes.iconCol,
                            classes.bold,
                            classes.success,
                            classes.paymentStatusText
                        )}
                    >
                        {renderBooleanIcon(true)} {paymentStatusStrings[PAYMENT_STATUS.Approved]}
                    </div>
                );
            case PAYMENT_STATUS.Partial:
                return (
                    <div
                        className={classnames(
                            classes.iconCol,
                            classes.bold,
                            classes.warning,
                            classes.paymentStatusText
                        )}
                    >
                        <IconComponent Icon={WarningIcon} warning size={SIZES.SMALL} />{' '}
                        {paymentStatusStrings[PAYMENT_STATUS.Partial]}
                    </div>
                );
            default:
                return (
                    <div
                        className={classnames(
                            classes.iconCol,
                            classes.bold,
                            classes.error,
                            classes.paymentStatusText
                        )}
                    >
                        {renderBooleanIcon(false)} {paymentStatusStrings[PAYMENT_STATUS.Missing]}
                    </div>
                );
        }
    };

    const renderSendingStatus = (eventOrder: EventOrder) => {
        if (eventOrder.sentManually) {
            return <span className={classnames(classes.bold, classes.success)}>Sent manually</span>;
        }

        if (!eventOrder.canSendAutomatically && !eventOrder.canMarkAsSent) {
            return (
                <span className={classnames(classes.bold, classes.error)}>
                    {renderShippingStatus(eventOrder.shippingStatus)}
                </span>
            );
        }

        if (eventOrder.shippingStatus === SHIPPING_STATUS.Sent) {
            return (
                <>
                    <span className={classnames(classes.bold, classes.success)}>
                        {shippingStatusStrings[SHIPPING_STATUS.Sent]}
                    </span>{' '}
                    {eventOrder.canSendAutomatically && (
                        <>
                            (
                            <span
                                className={classnames(
                                    classes.link,
                                    eventOrder.status === ORDER_STATUS.Cancelled && classes.disabled
                                )}
                                onClick={() => onClickSendingOptions([{ id: eventOrder.id }])}
                            >
                                Options
                            </span>
                            )
                        </>
                    )}
                </>
            );
        }

        if (!eventId) {
            return (
                <span className={classnames(classes.bold)}>
                    {renderShippingStatus(eventOrder.shippingStatus)}
                </span>
            );
        }

        const options: SplitButtonOption[] = [
            {
                label: 'Send',
                onClick: () => onClickSend([{ id: eventOrder.id }]),
                disabled: !eventOrder.canSendAutomatically,
            },
            {
                label: 'Send manually',
                onClick: () => onClickSendManually([{ id: eventOrder.id }]),
                disabled: !eventOrder.canMarkAsSent,
            },
        ];

        return <SplitButton options={options} size={BUTTON_SIZES.TINY} />;
    };

    columns.push({
        key: 'orderedAt',
        title: 'Ordered at',
        width: TABLE_CELL_WIDTH.MEDIUM,
        cellRenderer: (rowData) => {
            if (!rowData.orderedAt) return 'Unknown order date';

            return (
                <>
                    <span>{moment(rowData.orderedAt).local().format(dateFormat)}</span>
                    {rowData.status === ORDER_STATUS.Cancelled && <div>(Cancelled)</div>}
                </>
            );
        },
    });

    if (eventId) {
        columns.push({
            key: 'deliveryContactData',
            title: 'Delivery contact email',
            width: TABLE_CELL_WIDTH.LARGE,
            cellRenderer: (rowData) => rowData.deliveryContactData?.email || '',
        });
    }

    columns.push(
        {
            hidden: !!eventId,
            key: 'numEvents',
            title: '# of events',
            width: TABLE_CELL_WIDTH.TINY,
            cellRenderer: (rowData) => {
                if (rowData.numEvents === 0) return 0;

                return (
                    <EventsPopOver
                        linkText="Go to orders"
                        text={rowData.numEvents}
                        orderId={rowData.id}
                    />
                );
            },
        },
        {
            key: 'externalReferenceId',
            title: <>Order Number</>,
            width: TABLE_CELL_WIDTH.EXTRA_LARGE,
            cellRenderer: (rowData) => {
                const { externalOrderReference, source, orderNumber } = rowData;

                return (
                    <OrderNumberDisplay
                        orderNumber={orderNumber}
                        externalOrderReference={externalOrderReference}
                        externalUrl={
                            source.startsWith(orderTravelwareSourcePrefix)
                                ? travelwareUrlService.orderNumberRedirect(orderNumber)
                                : ''
                        }
                    />
                );
            },
        },
        {
            key: 'source',
            title: 'Source',
            width: TABLE_CELL_WIDTH.MEDIUM,
            cellRenderer: (rowData) => {
                return rowData.source;
            },
        },
        {
            key: 'seatingPlanCategoryName',
            title: 'Category',
            width: TABLE_CELL_WIDTH.LARGE,
            cellRenderer: (rowData) => {
                return rowData.seatingPlanCategoryName;
            },
        }
    );

    if (eventId) {
        columns.push(
            {
                key: 'numEvents',
                title: 'Total events',
                width: 80,
                cellRenderer: (rowData) => rowData.numEvents,
            },
            {
                key: 'numTicketsCurrentEvent',
                title: 'Ordered tickets',
                width: TABLE_CELL_WIDTH.SMALL,
                cellRenderer: (rowData) => {
                    const { numAssignedTickets, numTicketsCurrentEvent } = rowData;
                    return (
                        <>
                            {numAssignedTickets > 0 ? (
                                <a
                                    href={eventUrlService.tickets(eventId, {
                                        orderId: rowData.id,
                                        available: false,
                                    })}
                                >
                                    {numTicketsCurrentEvent}
                                </a>
                            ) : (
                                <span>{numTicketsCurrentEvent}</span>
                            )}
                            <span className={tableClasses.totalText}>
                                ({rowData.numTickets} total)
                            </span>
                        </>
                    );
                },
            },
            {
                key: 'numAssignedTickets',
                title: 'Assigned',
                width: 80,
                cellRenderer: (rowData) => {
                    const { numAssignedTickets, numTicketsCurrentEvent } = rowData;
                    const allTicketsAssigned = numAssignedTickets === numTicketsCurrentEvent;

                    return (
                        <div className={classes.iconCol}>
                            {renderBooleanIcon(allTicketsAssigned)}
                            <a
                                href={eventUrlService.orderlines(eventId, {
                                    orderId: rowData.id,
                                })}
                            >
                                {numAssignedTickets}/{numTicketsCurrentEvent}
                            </a>
                        </div>
                    );
                },
            },
            {
                key: 'numReadyTickets',
                title: (
                    <>
                        Tickets ready
                        <HelpPopover
                            content={helpContentService.getHelpContentByName('ReadyTickets')}
                        />
                    </>
                ),
                width: 80,
                cellRenderer: (rowData) => {
                    const { numReadyTickets, numTicketsCurrentEvent } = rowData;
                    const allTicketsReady = numReadyTickets === numTicketsCurrentEvent;

                    return (
                        <div className={classes.iconCol}>
                            {renderBooleanIcon(allTicketsReady)}
                            <a
                                href={eventUrlService.tickets(eventId, {
                                    orderId: rowData.id,
                                    available: false,
                                })}
                            >
                                {numReadyTickets}/{numTicketsCurrentEvent}
                            </a>
                        </div>
                    );
                },
            }
        );
    }

    columns.push(
        {
            key: 'paymentStatus',
            title: 'Payment',
            width: TABLE_CELL_WIDTH.MEDIUM,
            cellRenderer: (rowData) => {
                return (
                    <div className={classes.paymentStatus}>
                        {renderPaymentStatus(rowData.paymentStatus)}
                        <span
                            className={classnames(
                                classes.link,
                                rowData.status === ORDER_STATUS.Cancelled && classes.disabled
                            )}
                            onClick={() => onChangePaymentStatus([{ id: rowData.id }])}
                        >
                            {/* <IconComponent
                                    Icon={DriveFileRenameOutlineOutlinedIcon}
                                    color="#000"
                                    size={SIZES.MEDIUM}
                                /> */}
                            Edit
                        </span>
                    </div>
                );
            },
        },
        {
            key: 'eventPrice',
            title: 'Event order price',
            hidden: variant !== ORDER_TABLE_VARIANTS.EventOrders,
            width: TABLE_CELL_WIDTH.MEDIUM,
            cellRenderer: (rowData) => {
                const { eventPrice, orderPrice, salesPrice } = rowData;

                if (!eventPrice) return '';

                const orderPriceIsEqualToEventPrice =
                    orderPrice && eventPrice.value === orderPrice.value;

                return (
                    <div className={classes.eventPriceColumn}>
                        <OrderPrice newSystemPrice={salesPrice} oldSystemPrice={eventPrice} />

                        {!orderPriceIsEqualToEventPrice && orderPrice && (
                            <Popover
                                text={<InfoOutlinedIcon className={tableClasses.tableRowIcon} />}
                            >
                                <div>
                                    Total order value is:
                                    {formatPrice({
                                        currency: orderPrice.currency,
                                        amount: orderPrice.value ?? 0,
                                    })}
                                </div>
                            </Popover>
                        )}
                    </div>
                );
            },
        },
        {
            key: 'orderPrice',
            title: 'Order price',
            hidden: variant !== ORDER_TABLE_VARIANTS.Orders,
            width: TABLE_CELL_WIDTH.MEDIUM,
            cellRenderer: (rowData) => {
                const { orderPrice, salesPrice } = rowData;

                if (!orderPrice) return '';

                return <OrderPrice oldSystemPrice={orderPrice} newSystemPrice={salesPrice} />;
            },
        },
        {
            key: 'internalNotes',
            title: 'Internal notes',
            width: TABLE_CELL_WIDTH.MEDIUM,
            cellRenderer: (rowData) => {
                return (
                    <Popover text={rowData.internalNotes} isLink={false}>
                        <span>{rowData.internalNotes}</span>
                    </Popover>
                );
            },
            truncate: true,
        },
        {
            key: 'shippingStatus',
            title: 'Sending',
            hidden: variant === ORDER_TABLE_VARIANTS.Orders,
            width: TABLE_CELL_WIDTH.MEDIUM,
            cellRenderer: (rowData) => {
                return (
                    <div className={genericClasses.center}>
                        {renderSendingStatus(rowData)}
                        {rowData.wasRevoked && (
                            <Tooltip title="Revoked">
                                <ReplayIcon
                                    className={spacingClasses.spacingLeft}
                                    fontSize="small"
                                    color="disabled"
                                />
                            </Tooltip>
                        )}
                    </div>
                );
            },
        },
        {
            align: 'right',
            width: TABLE_CELL_WIDTH.TINY,
            cellRenderer: (rowData) => {
                return (
                    <IconButton
                        onClick={() => onClickDetails(rowData.id)}
                        color="primary"
                        size={'small'}
                    >
                        <VisibilityOutlinedIcon />
                    </IconButton>
                );
            },
        }
    );

    return (
        <Table<EventOrder, OrderTableDataResolver>
            data={orders}
            rowIdResolver={(rowData: EventOrder) => ({
                id: rowData.id,
                data: {
                    canSendAutomatically: rowData.canSendAutomatically,
                    canMarkAsSent: rowData.canMarkAsSent,
                    seatingPlanCategoryName: rowData.seatingPlanCategoryName,
                },
            })}
            rowProps={(rowData: EventOrder) => ({
                type:
                    rowData.status === ORDER_STATUS.Cancelled
                        ? TABLE_ROW_TYPE.danger
                        : TABLE_ROW_TYPE.default,
            })}
            columns={columns}
            loading={loading}
            pagination={pagination}
            minWidth={650}
            onSortingChange={onSortingChange}
            enableCheckboxes={enableCheckboxes}
            onChangeSelectedRows={onChangeSelectedRows}
            tableToolbarSplitActions={tableToolbarSplitActions}
        />
    );
}
