import { zodResolver } from '@hookform/resolvers/zod';
import { CircularProgress, FormHelperText, Grid } from '@mui/material';
import { useTheme } from '@mui/styles';
import classNames from 'classnames';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { config } from 'src/app/constants/config/config';
import { useOrganizers } from 'src/app/hooks/useOrganizers';
import { FormProps } from 'src/app/interfaces/form/formProps';
import BasicAdministrationUrlService from 'src/app/pages/basic-administration/basicAdministrationUrlService';
import { organizerUpdateRoles } from 'src/app/utilities/helpers/userPermissions';
import { zodRequiredDropdownSelectSchema } from 'src/app/utilities/zod/zodRequiredDropdownSelectSchema';
import { useSpacingStyles } from 'src/shared/styles/spacingStyles';
import AutoComplete from 'src/view/components/auto-complete/AutoComplete';
import FormButtons from 'src/view/components/form/FormButtons';
import FormFieldError from 'src/view/components/form/FormFieldError';
import { FormLabel } from 'src/view/components/form/FormLabel';
import z from 'zod';

interface EditEventOrganizerFormProps extends FormProps<EditEventOrganizerFormValues> {
    canEditOrganizer?: boolean;
}

const validationSchema = z.object({
    organizer: zodRequiredDropdownSelectSchema.nullable(),
});

export type EditEventOrganizerFormValues = z.infer<typeof validationSchema>;

export const EditEventOrganizerForm = ({
    loading,
    onSubmit,
    defaultValues,
    values,
    canEditOrganizer,
}: EditEventOrganizerFormProps) => {
    const spacingClasses = useSpacingStyles();
    const theme = useTheme();
    const { data: organizers } = useOrganizers({
        pageSize: config.ITEMS_PER_PAGE_LARGE,
    });

    const {
        control,
        handleSubmit,
        reset,
        formState: { isDirty },
    } = useForm<EditEventOrganizerFormValues>({
        mode: 'onChange',
        resolver: zodResolver(validationSchema),
        defaultValues,
    });

    const isSubmitButtonDisabled = loading || !isDirty || !canEditOrganizer;

    useEffect(() => {
        if (!values) return;

        reset(values);
    }, [values]);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <h2
                        className={classNames(
                            spacingClasses.noMarginBottom,
                            spacingClasses.noMarginTop
                        )}
                    >
                        Change event organizer
                    </h2>
                </Grid>

                <Grid item xs={12}>
                    <FormLabel
                        url={BasicAdministrationUrlService.organizers()}
                        urlText="Create Organizer"
                    >
                        Organizer*
                    </FormLabel>

                    <Controller
                        name="organizer"
                        control={control}
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <>
                                <AutoComplete
                                    onChange={onChange}
                                    value={value}
                                    name={name}
                                    options={
                                        organizers?.data.data.map((o) => ({
                                            label: o.name,
                                            value: o.id,
                                        })) || []
                                    }
                                    disabled={!canEditOrganizer}
                                />

                                <FormHelperText>
                                    Only employees with the following rights can change the
                                    organizer of an event:{' '}
                                    <strong>{organizerUpdateRoles.join(', ')}</strong>. If you have
                                    questions about your role(s), please contact the system admin.
                                </FormHelperText>
                                <FormFieldError message={error?.message} />
                            </>
                        )}
                    />
                </Grid>
            </Grid>
            <FormButtons
                buttons={[
                    {
                        children: 'Update organizer',
                        startIcon: loading && (
                            <CircularProgress size={theme.layout.loader.sizes.small} />
                        ),
                        disabled: isSubmitButtonDisabled,
                        onClick: () => handleSubmit(onSubmit)(),
                    },
                ]}
            />
        </>
    );
};
