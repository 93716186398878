import Header from 'src/view/components/header/Header';
import { Grid } from '@mui/material';
import { ETBContainer } from 'src/view/components/container/container';
import AdvancedEditPurchaseFormFeature from 'src/app/components/features/purchase/AdvancedEditPurchaseFormFeature';

const AdvancedEditPurchase = () => (
    <>
        <Header>
            <Grid container spacing={0.5}>
                <Grid item>
                    <h1>Advanced Edit Purchase</h1>
                </Grid>
            </Grid>
        </Header>
        <ETBContainer>
            <AdvancedEditPurchaseFormFeature />
        </ETBContainer>
    </>
);

export default AdvancedEditPurchase;
