import { useQuery } from '@tanstack/react-query';
import seatingPlanCategoryService from 'src/data/services/seatingPlanCategoryService';

export const SEATING_PLAN_CATEGORY_QUERY = 'SEATING_PLAN_CATEGORY';

type QueryOptions = {
    enabled: boolean;
};

export const useFetchSeatingPlanCategoryById = (
    seatingplanCategoryId: string,
    queryOptions: QueryOptions
) => {
    return useQuery({
        queryKey: [SEATING_PLAN_CATEGORY_QUERY, seatingplanCategoryId],
        queryFn: () => {
            return seatingPlanCategoryService.getSeatingplanCategoryById(seatingplanCategoryId);
        },
        ...queryOptions,
    });
};
