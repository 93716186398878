import { OpenInNew } from '@mui/icons-material';
import { Link, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { TextCopier } from 'src/view/components/text-copier/TextCopier';
import _ from 'lodash';

interface Props {
    externalOrderReference: string;
    orderNumber: string;
    externalUrl: string;
}

const useStyles = makeStyles((theme) => ({
    externalChip: {
        backgroundColor: '#eee',
        borderRadius: '5px',
        fontSize: '0.6rem',
        padding: '0.1rem 0.2rem',
        '-webkit-user-select': 'none',
        '-ms-user-select': 'none',
        userSelect: 'none',
        alignSelf: 'center',
    },
    hasExternal: {
        color: theme.colors.grey,
        fontSize: '0.8rem',
    },
    orderNumber: {
        lineHeight: '0.5rem',
        display: 'flex',
        alignItems: 'center',
    },
    externalOrderRefContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    textCopier: {
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
    },
}));

export default function OrderNumberDisplay({
    orderNumber,
    externalUrl,
    externalOrderReference,
}: Props) {
    const classes = useStyles();
    const hasExternal = !!externalOrderReference;

    return (
        <>
            <div>
                {externalOrderReference && (
                    <div className={classes.externalOrderRefContainer}>
                        <Tooltip title={externalOrderReference}>
                            <div>{_.truncate(externalOrderReference, { length: 15 })} </div>
                        </Tooltip>
                        <TextCopier
                            className={classes.textCopier}
                            value={externalOrderReference}
                            hideLabel
                            svgSize="small"
                        />
                        <span className={classes.externalChip}>ext</span>
                    </div>
                )}

                <div
                    className={classNames(classes.orderNumber, hasExternal && classes.hasExternal)}
                >
                    {orderNumber.toUpperCase()}
                    {externalUrl && (
                        <Link href={externalUrl} sx={{ marginLeft: '0.5rem' }} target="_blank">
                            <OpenInNew fontSize={'inherit'} />
                        </Link>
                    )}
                </div>
            </div>
        </>
    );
}
