import useApiFetch from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import PaginatedApiResponseBody from 'src/data/api/responses/PaginatedApiResponseBody';
import PaginationMeta from 'src/data/api/responses/PaginationMeta';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import orderlineService from 'src/data/services/orderlineService';
import Orderline from 'src/data/models/orderline/orderline';
import useDeepCompareEffect from 'use-deep-compare-effect';

interface FetchOrderlinesDataWrapperChildrenParams
    extends DataWrapperChildrenParams<PaginatedApiResponseBody<Orderline, PaginationMeta>> {
    fetchOrderlines: () => Promise<PaginatedApiResponseBody<Orderline, PaginationMeta>>;
}

interface FetchOrderlinesDataWrapperProps
    extends DataWrapperProps<
            FetchOrderlinesDataWrapperChildrenParams,
            PaginatedApiResponseBody<Orderline, PaginationMeta>
        >,
        BaseFetchOptions {
    eventId: string;
}

export const useFetchOrderlinesDataWrapper = ({
    includes = [],
    onError,
    onSuccess,
    invalidatedAt,
    eventId,
    page,
    pageSize,
    filter,
    sorting,
    q,
}: Omit<FetchOrderlinesDataWrapperProps, 'children'>) => {
    const [{ loading, data, errors, resetData }, handleFetchOrderlines] =
        useApiFetch<PaginatedApiResponseBody<Orderline, PaginationMeta>>();

    const fetchOrderlinesCall = () => {
        return handleFetchOrderlines(
            orderlineService.fetchOrderlines(eventId, {
                includes,
                page,
                pageSize,
                filter,
                sorting,
                q,
            }),
            {
                useDefaultErrorHandler: true,
                onFail: onError,
                onSuccess,
            }
        );
    };

    useDeepCompareEffect(() => {
        fetchOrderlinesCall();
    }, [page, pageSize, filter, sorting, q, invalidatedAt, eventId]);

    return {
        fetchOrderlines: fetchOrderlinesCall,
        data,
        loading,
        errors,
        resetData,
    };
};

export const FetchOrderlinesDataWrapper = (props: FetchOrderlinesDataWrapperProps) => {
    const { children } = props;
    const hookData = useFetchOrderlinesDataWrapper(props);

    if (!children) return <></>;

    return <>{children(hookData)}</>;
};
