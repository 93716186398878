import { useQuery } from '@tanstack/react-query';
import { parseErrors } from 'src/app/utilities/helpers/errors';
import Toaster from 'src/app/utilities/helpers/Toaster';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import { fetchEventById } from 'src/data/services/eventService';

type Options = {
    enabled: boolean;
};

export const FETCH_EVENT_BY_ID_QUERY_KEY = 'FETCH_EVENT_BY_ID';

export const useFetchEventById = (
    id: string,
    fetchOptions: BaseFetchOptions,
    options?: Options
) => {
    return useQuery({
        queryKey: [FETCH_EVENT_BY_ID_QUERY_KEY, id, fetchOptions],
        queryFn: () => fetchEventById(id, fetchOptions),
        onError: (e) => {
            Toaster.toastErrors(parseErrors(e));
        },
        ...options,
        enabled: !!id
    });
};
