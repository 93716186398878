enum AuditType {
    TicketSalesCapCreated = 'TicketSalesCapCreated',
    TicketSalesCapUpdated = 'TicketSalesCapUpdated',
    TicketSalesCapDeleted = 'TicketSalesCapDeleted',
    TicketsSent = 'TicketsSent',
    OrderMarkedAsSent = 'OrderMarkedAsSent',
    OrderLineAssignedTickets = 'OrderLineAssignedTickets',
    OrderLineInfoChanged = 'OrderLineInfoChanged',
    OrderPaymentStatusChanged = 'OrderPaymentStatusChanged',
    TicketFileAdded = 'TicketFileAdded',
    TicketFileRemoved = 'TicketFileRemoved',
    TicketFileReplaced = 'TicketFileReplaced',
}

export default AuditType;
