import { FormHelperText, Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect } from 'react';
import { config } from 'src/app/constants/config/config';
import Button from 'src/view/components/button/Button';
import { P1Logo } from 'src/view/icons/logos/P1Logo';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center" fontSize=".7rem">
            COPYRIGHT &copy; {new Date().getFullYear()} P1 TRAVEL
        </Typography>
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        height: '100vh',
    },
    loginWrapper: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('lg')]: {
            padding: `0 ${theme.spacing(1)}`,
        },
    },

    imageWrapper: {
        flex: 1,
        background: 'url(/images/login-screen.jpg)',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        [theme.breakpoints.down('lg')]: {
            display: 'none',
        },
    },
    formWrapper: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center',
        alignItems: 'center',
        maxWidth: 350,
    },
    loginPaper: {
        marginTop: theme.spacing(4),
        width: '100%',
    },
    header: {
        marginTop: 0,
        marginBottom: theme.spacing(0.5),
        color: theme.palette.primary.main,
    },
    button: {
        minWidth: 150,
        marginTop: theme.spacing(3),
    },
    helperText: {
        width: '100%',
        fontSize: '.7rem',
        padding: `0 ${theme.spacing(2)}`,
    },
    copyRight: {
        position: 'absolute',
        bottom: theme.spacing(1),
        left: theme.spacing(1),
    },
}));

export default function LoginPage() {
    const classes = useStyles();

    useEffect(() => {
        localStorage.removeItem('account_id');
        document.body.classList.add('custom');
        return () => document.body.classList.remove('custom');
    });

    const goToLogin = () => {
        window.location.href = config.AUTH.COGNITO_URL;
    };

    return (
        <div className={classes.container}>
            <div className={classes.loginWrapper}>
                <div className={classes.formWrapper}>
                    <P1Logo />
                    <Paper className={classes.loginPaper}>
                        <h1 className={classes.header}>ETBaaS</h1>
                        <Typography variant="subtitle2" fontWeight="normal">
                            Inventory management system
                        </Typography>
                        <Button fullWidth className={classes.button} onClick={goToLogin}>
                            Login
                        </Button>
                    </Paper>
                    <FormHelperText className={classes.helperText}>
                        Something wrong with your credentials or rights? Contact the IT department.
                    </FormHelperText>
                </div>
                <div className={classes.copyRight}>
                    <Copyright />
                </div>
            </div>
            <div className={classes.imageWrapper} />
        </div>
    );
}
