import moment from 'moment';
import { EventDetails } from 'src/data/models/events/event';
import { countryList } from 'src/shared/countries';
import { dateTimeFormat } from 'src/shared/date';
import { generateRandomId } from 'src/shared/utils';
import {
    AutoCompleteOption,
    AutoCompleteOptions,
} from 'src/view/components/auto-complete/interfaces';

interface EditEventFormValues {
    eventType?: AutoCompleteOption;
    contestants?: AutoCompleteOptions;
    artists?: AutoCompleteOptions;
    matches?: { id: string; name: string }[];
    homeTeam?: AutoCompleteOption;
    awayTeam?: AutoCompleteOption;
    eventName?: string;
    startDate?: string;
    endDate?: string;
    dateConfirmed: boolean;
    eventCategory?: AutoCompleteOption;
    eventTags?: AutoCompleteOptions;
    description?: string;
    series?: AutoCompleteOption;
    organizer?: AutoCompleteOption;
    venue?: AutoCompleteOption;
    seatingPlan?: AutoCompleteOption;
    active: boolean;
    ticketsSentLatestDays: number;
    ticketsSentEarliestDays: number;
    allowAwayFans?: boolean;
    requireFullCustomerAddress?: boolean;
    blacklistedCountries?: AutoCompleteOptions;
    blacklistedNationalities?: AutoCompleteOptions;
    hasMultipleBookingOptions: boolean;
}

export default function mapEventDetailsToFormValues(data: EventDetails): EditEventFormValues {
    const {
        eventType,
        name,
        dateTimeStart,
        dateTimeEnd,
        dateConfirmed,
        eventCategory,
        matches,
        performerNames,
        homeTeam,
        awayTeam,
        venueId,
        venueName,
        tags,
        description,
        seriesId,
        seriesName,
        organizerId,
        organizerName,
        seatingPlanId,
        seatingPlanName,
        isActive,
        ticketsSentLatestDays,
        ticketsSentEarliestDays,
        restrictions,
        hasMultipleBookingOptions,
    } = data;

    const selectedCountries = countryList.filter(
        (c) => restrictions.blacklistedCountryCodes?.includes(c.value)
    );

    const selectedNationalities = countryList.filter(
        (c) => restrictions.blacklistedNationalityCodes?.includes(c.value)
    );

    return {
        eventType: { label: eventType, value: eventType },
        eventName: name,
        startDate: dateTimeStart ? moment.utc(dateTimeStart).format(dateTimeFormat) : undefined,
        endDate: dateTimeEnd ? moment.utc(dateTimeEnd).format(dateTimeFormat) : undefined,
        dateConfirmed: dateConfirmed,
        eventCategory: {
            label: eventCategory,
            value: eventCategory,
        },
        series: {
            label: seriesName,
            value: seriesId,
        },
        organizer: {
            label: organizerName,
            value: organizerId,
        },
        venue: {
            label: venueName,
            value: venueId,
        },
        seatingPlan: {
            label: seatingPlanName,
            value: seatingPlanId,
        },
        homeTeam: homeTeam
            ? {
                  label: homeTeam.name,
                  value: homeTeam.id,
              }
            : undefined,
        awayTeam: awayTeam
            ? {
                  label: awayTeam.name,
                  value: awayTeam.id,
              }
            : undefined,
        eventTags: tags?.map((tag) => ({ label: tag.name, value: tag.id })),
        contestants: performerNames?.map((performer) => ({ label: performer, value: performer })),
        artists: performerNames?.map((performer) => ({ label: performer, value: performer })),
        matches: matches?.map((match) => ({ id: generateRandomId('match'), name: match })) || [],
        description,
        active: isActive,
        ticketsSentEarliestDays,
        ticketsSentLatestDays,
        allowAwayFans: restrictions.allowAwayFans,
        requireFullCustomerAddress: restrictions.requireFullCustomerAddress,
        blacklistedCountries: selectedCountries.map((o) => ({
            label: o.label,
            value: o.value,
        })),
        blacklistedNationalities: selectedNationalities.map((o) => ({
            label: o.label,
            value: o.value,
        })),
        hasMultipleBookingOptions,
    };
}
