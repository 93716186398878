import React from 'react';
import { NavLink } from 'react-router-dom';
import { Theme, Collapse, List } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';
import NavbarSubNavigationLink from 'src/app/components/buttons/navbar/NavbarSubNavigationLink';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { MenuItem } from 'src/app/interfaces/menu';

const baseLinkStyles = {
    padding: '0.5rem 1rem',
    textDecoration: 'none',
    fontWeight: 'bold',
    transition: 'all 0.2s linear',
    display: 'flex',
    alignItems: 'center',
};

const useStyles = makeStyles((theme: Theme) => ({
    link: {
        ...baseLinkStyles,
        color: theme.colors.white,
        position: 'relative',
        '&:hover, &:active': {
            backgroundColor: theme.palette.tertiary.main,
        },
    },
    linkActive: {
        ...baseLinkStyles,
        color: theme.colors.white,
        backgroundColor: theme.palette.tertiary.main,
    },
    icon: {
        marginRight: '5px',
    },
}));

export default function NavbarLinkMobile({
    url,
    label,
    isClickable = true,
    Icon,
    subItems = [],
    onLinkClicked,
}: MenuItem) {
    const classes = useStyles();
    const [areSubItemsVisible, setAreSubItemsVisible] = useState(false);

    const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (!url || !isClickable) event.preventDefault();

        if (subItems.length) {
            setAreSubItemsVisible((prevState) => !prevState);

            return;
        }

        if (onLinkClicked !== undefined) {
            onLinkClicked();
        }
    };

    const renderExpandIcon = () => {
        if (areSubItemsVisible) {
            return <ExpandLess />;
        }

        return <ExpandMore />;
    };

    return (
        <>
            <NavLink
                to={url}
                onClick={handleLinkClick}
                className={({ isActive }) => (isActive ? classes.linkActive : classes.link)}
            >
                {Icon && <Icon className={classes.icon} />}

                <p>{label}</p>
                {!!subItems.length && renderExpandIcon()}
            </NavLink>

            <Collapse in={areSubItemsVisible} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {subItems.map((subRoute) => (
                        <NavbarSubNavigationLink
                            key={`${url}${subRoute?.url}`}
                            label={subRoute?.label || ''}
                            url={subRoute?.url || ''}
                            onLinkClicked={onLinkClicked}
                            isMobile
                        />
                    ))}
                </List>
            </Collapse>
        </>
    );
}
