import React, { useState } from 'react';
import { Popover, Button } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
    addressDetails: {
        padding: '2%',
        minWidth: '200px',
    },
}));

interface MarkerProps {
    lat: number;
    lng: number;
    imgUrl: string;
    address: string;
}

export default function Marker({ imgUrl, address }: MarkerProps) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const id = anchorEl ? 'simple-popover' : undefined;

    return (
        <>
            <Button aria-describedby={id} onClick={handleClick}>
                <img src={imgUrl} alt="venue" />
            </Button>
            <Popover
                id={id}
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 60,
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {address.split(',').map((address, index) => (
                    <div key={`address-${index}`} className={classes.addressDetails}>
                        {address}
                    </div>
                ))}
            </Popover>
        </>
    );
}
