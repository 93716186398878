import { getInternalAxiosInstance } from 'src/data/api/api';
import ApiResponse from 'src/data/api/responses/ApiResponse';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import {
    SPLITS_CREATE_ENDPOINT,
    SPLITS_UPDATE_ENDPOINT,
} from 'src/data/constants/endpoints/splits-endppints';
import { IdResponse } from 'src/data/models/common/idResponse';

export interface SplitTicket {
    ticketId: string;
    sortOrder: number;
}

export interface CreateSplitDto {
    eventId: string;
    tickets?: SplitTicket[];
}

async function createSplit(dto: CreateSplitDto): Promise<ApiResponse<ApiResponseBody<IdResponse>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<IdResponse>>(SPLITS_CREATE_ENDPOINT, dto)
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export interface UpdateSplitDto {
    splitId: string;
    tickets?: SplitTicket[];
}

async function updateSplit(dto: UpdateSplitDto): Promise<ApiResponse<ApiResponseBody<IdResponse>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<IdResponse>>(SPLITS_UPDATE_ENDPOINT, dto)
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export default {
    createNewSplit: createSplit,
    updateSplit,
};
