import Order from 'src/data/models/order/Order';
import { OrderDetailsFormValues } from 'src/app/components/forms/OrderDetailsForm';

export default function mapOrderDataToViewModel(order: Order): OrderDetailsFormValues {
    const {
        totalPrice,
        externalReferenceId,
        internalNotes,
        numOrderedTickets,
        orderNumber,
        source,
    } = order;

    return {
        externalReferenceId,
        orderNotes: internalNotes,
        numberOfTickets: numOrderedTickets,
        totalPrice,
        orderNumber,
        source,
    };
}
