import { useEffect, useState } from 'react';
import { ActionMeta } from 'react-select';
import { PERFORMERS_TYPE } from 'src/app/components/forms/event/CreateEventForm';
import { toastApiErrors } from 'src/app/utilities/helpers/toast-api-errors';
import performersService from 'src/data/services/performersService';
import AsyncAutoComplete from 'src/view/components/auto-complete/AsyncAutoComplete';
import {
    AutoCompleteOption,
    AutoCompleteOptions,
    AutoCompleteValueOption,
} from 'src/view/components/auto-complete/interfaces';

interface Props {
    type: PERFORMERS_TYPE;
    name: string;
    value?: AutoCompleteOptions;
    onChange: (value: AutoCompleteValueOption, action?: ActionMeta<AutoCompleteOption>) => void;
    onSuccessfullyCreate: (options: AutoCompleteOptions) => void;
    disabled?: boolean;
}

export default function PerformerSelectDataWrapper({
    type,
    name,
    value,
    onChange,
    onSuccessfullyCreate,
    disabled,
}: Props) {
    const [options, setOptions] = useState<AutoCompleteOptions>(value || []);

    useEffect(() => {
        if (!value) {
            setOptions([]);

            return;
        }

        setOptions(value);
    }, [value]);

    const fetchPerformerOptions = async (query: string): Promise<AutoCompleteOptions> => {
        if (disabled) return [];

        const filter = [
            {
                property: 'type',
                value: type,
            },
        ];

        try {
            const res = await performersService.getPerformers({ q: query, filter });

            if (!res.data.data) return [];

            return res.data.data.map((o) => ({
                label: o.name,
                value: o.id,
            }));
        } catch (e) {
            toastApiErrors(e);

            return [];
        }
    };

    const onCreatePerformer = async (name: string) => {
        try {
            const result = await performersService.createPerformer(name, type);

            const id = result.data.data.id;
            const newOptions = options.concat({ label: name, value: id });

            onSuccessfullyCreate(newOptions);
        } catch (e) {
            toastApiErrors(e);
        }
    };

    return (
        <AsyncAutoComplete
            name={name}
            value={options}
            loadOptions={fetchPerformerOptions}
            onChange={onChange}
            placeholder="Select or create"
            onCreateOption={onCreatePerformer}
            isMulti
            disabled={disabled}
        />
    );
}
