import { Theme } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { useLocation, useMatch, useNavigate, useParams } from 'react-router-dom';
import eventTabs from 'src/app/constants/constants/routing/eventTabs';
import { useFetchEventById } from 'src/app/hooks/events/useFetchEventById';
import { AutoAssignPage } from 'src/app/pages/events/AutoAssignPage';
import EventDetails from 'src/app/pages/events/EventDetails';
import EventOrdersOverview from 'src/app/pages/events/EventOrdersOverview';
import EventTtiOverview from 'src/app/pages/events/EventTtiOverview';
import eventUrlService from 'src/app/pages/events/eventUrlService';
import Orderlines from 'src/app/pages/events/Orderlines';
import SplitsOverview from 'src/app/pages/events/SplitsOverview';
import TicketsOverview from 'src/app/pages/events/TicketsOverview';
import PurchasesOverview from 'src/app/pages/purchases/PurchasesOverview';
import urlService from 'src/app/services/urlService';
import { parseErrors } from 'src/app/utilities/helpers/errors';
import { EventDetails as EventDetailsType } from 'src/data/models/events/event';
import Breadcrumbs from 'src/view/components/breadcrump/BreadCrumbs';
import BreadcrumbItem from 'src/view/components/breadcrump/Item/BreadCrumbItem';
import { ETBContainer } from 'src/view/components/container/container';
import ErrorsList from 'src/view/components/errors-list/ErrorsList';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';
import { TabPanel } from 'src/view/components/tabs/TabPanel';
import { TextCopier } from 'src/view/components/text-copier/TextCopier';

type EventPageParams = {
    eventId: string;
};

const useStyles = makeStyles((theme: Theme) => ({
    subNavigationBar: {
        background: theme.palette.primary.main,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        padding: '0 2rem',
        '& .MuiTab-root': {
            padding: '1.225rem 1.5rem;',
        },
        '& .MuiTabs-flexContainer .MuiButtonBase-root': {
            color: theme.colors.white,
        },
        '& .MuiTabs-scrollButtons': {
            color: theme.colors.white,
        },
    },
    eventIdContainer: {
        fontSize: theme.typography.subtitle2.fontSize,
        color: theme.colors.grey,
        '& svg': {
            height: 15,
            width: 15,
        },
    },
    copyEventIdButton: {
        marginRight: theme.spacing(0.5),
    },
}));

const EventPage = (): JSX.Element => {
    const location = useLocation();
    const match = useMatch(location.pathname);
    const navigate = useNavigate();
    const params = useParams<EventPageParams>();

    const activeTabRoute = window.location.pathname.split('/').pop() || eventTabs.DETAILS;

    const { eventId } = params;

    const [activeEvent, setActiveEvent] = useState<undefined | EventDetailsType>();
    const lastHash = getLastHash(location.pathname, match?.pathname || '');
    const isHashValid = Object.values(eventTabs)
        .map((tab) => tab.toString())
        .includes(lastHash);
    const [activeTab, setActiveTab] = React.useState<string>(eventTabs.DETAILS);
    const [loadedTabs, setLoadedTabs] = useState<string[]>([]);
    const classes = useStyles();

    useEffect(() => {
        const firstLoadTab = !!lastHash && isHashValid ? lastHash : eventTabs.DETAILS;
        setLoadedTabs([firstLoadTab]);
        setActiveTab(firstLoadTab);
    }, [lastHash, isHashValid]);

    const {
        isLoading: eventIsLoading,
        data: eventData,
        error: eventErrors,
        isError: isFetchingEventError,
    } = useFetchEventById(
        eventId || '',
        {},
        {
            enabled: !!eventId,
        }
    );

    useEffect(() => {
        if (!eventData) return;

        document.title = eventData.data.data.name;
        setActiveEvent(eventData.data.data);
    }, [eventData]);

    useEffect(() => {
        if (activeTabRoute !== activeTab) {
            setActiveTab(activeTabRoute);
        }
    }, [location, activeTab]);

    const handleTabChange = (_e: React.SyntheticEvent, value: string) => {
        setActiveTab(value);
        if (!loadedTabs.includes(value)) setLoadedTabs([...loadedTabs, value]);

        if (value === eventTabs.TICKETS && eventId) {
            navigate(eventUrlService.tickets(eventId));

            return;
        }

        if (value === eventTabs.SPLITS && eventId) {
            navigate(eventUrlService.splits(eventId));

            return;
        }

        if (value === eventTabs.ORDER_LINES && eventId) {
            navigate(eventUrlService.orderlines(eventId));

            return;
        }

        if (value === eventTabs.ORDERS && eventId) {
            navigate(eventUrlService.orders(eventId));

            return;
        }

        if (value === eventTabs.PURCHASES && eventId) {
            navigate(eventUrlService.purchases(eventId));

            return;
        }

        if (eventId) {
            navigate(urlService.getEvent(eventId, value));
        }
    };

    const getEventName = () => {
        if (eventData?.data) {
            return eventData.data.data.name;
        }

        return '';
    };

    if (eventIsLoading) {
        return <LoadingOverlay />;
    }

    if (!eventIsLoading && isFetchingEventError) {
        return (
            <ETBContainer>
                <ErrorsList errors={parseErrors(eventErrors)} />
            </ETBContainer>
        );
    }

    return (
        <>
            <div className="container-flex container--event event__content content">
                {!!activeEvent && (
                    <>
                        <div className="content">
                            <div className={classes.subNavigationBar}>
                                <Tabs
                                    value={activeTab || false}
                                    onChange={handleTabChange}
                                    indicatorColor="primary"
                                    textColor="inherit"
                                    className="table-grid__row--tabs"
                                    variant="scrollable"
                                    scrollButtons
                                    allowScrollButtonsMobile
                                >
                                    <Tab label="Details" value={eventTabs.DETAILS} />
                                    <Tab label="Purchases" value={eventTabs.PURCHASES} />
                                    <Tab label="Tickets" value={eventTabs.TICKETS} />
                                    <Tab label="Orders" value={eventTabs.ORDERS} />
                                    <Tab label="Orderlines" value={eventTabs.ORDER_LINES} />
                                    <Tab label="Splits" value={eventTabs.SPLITS} />
                                    <Tab label="TTI" value={eventTabs.TTI} />
                                </Tabs>
                            </div>

                            {activeEvent && eventId && (
                                <ETBContainer
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Breadcrumbs separator="›" aria-label="breadcrumb">
                                        {!!activeEvent.seriesName && (
                                            <BreadcrumbItem label={activeEvent.seriesName} />
                                        )}
                                        <BreadcrumbItem label={activeEvent.name} />
                                    </Breadcrumbs>
                                    <TextCopier value={eventId} />
                                </ETBContainer>
                            )}

                            <div className="=content__scroll event__tabs">
                                <TabPanel value={eventTabs.DETAILS} activeTab={activeTab}>
                                    {activeTab === eventTabs.DETAILS && <EventDetails />}
                                </TabPanel>

                                <TabPanel value={eventTabs.ORDER_LINES} activeTab={activeTab}>
                                    {activeTab === eventTabs.ORDER_LINES && (
                                        <Orderlines eventName={getEventName()} />
                                    )}
                                </TabPanel>

                                <TabPanel value={eventTabs.AUTO_ASSIGN} activeTab={activeTab}>
                                    {activeTab === eventTabs.AUTO_ASSIGN && (
                                        <AutoAssignPage eventId={eventId} />
                                    )}
                                </TabPanel>

                                <TabPanel value={eventTabs.ORDERS} activeTab={activeTab}>
                                    {activeTab === eventTabs.ORDERS && (
                                        <EventOrdersOverview eventName={getEventName()} />
                                    )}
                                </TabPanel>

                                <TabPanel value={eventTabs.PURCHASES} activeTab={activeTab}>
                                    {activeTab === eventTabs.PURCHASES && (
                                        <PurchasesOverview
                                            eventId={eventId}
                                            eventName={getEventName()}
                                        />
                                    )}
                                </TabPanel>

                                <TabPanel value={eventTabs.TICKETS} activeTab={activeTab}>
                                    {activeTab === eventTabs.TICKETS && (
                                        <TicketsOverview eventName={getEventName()} />
                                    )}
                                </TabPanel>

                                <TabPanel value={eventTabs.SPLITS} activeTab={activeTab}>
                                    {activeTab === eventTabs.SPLITS && (
                                        <SplitsOverview eventName={getEventName()} />
                                    )}
                                </TabPanel>

                                <TabPanel value={eventTabs.TTI} activeTab={activeTab}>
                                    {activeTab === eventTabs.TTI && (
                                        <EventTtiOverview eventName={getEventName()} />
                                    )}
                                </TabPanel>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

const getLastHash = (locationPathname: string, matchUrl: string) => {
    if (!locationPathname || !matchUrl) return '';
    const [, urlAfterMatch] = locationPathname.split(matchUrl);
    return urlAfterMatch.trim().charAt(0) === '/'
        ? urlAfterMatch.trim().substring(1)
        : urlAfterMatch.trim();
};

export default EventPage;
