import { Alert } from '@mui/material';
import { useEffect, useState } from 'react';
import TicketsTable, {
    TicketsTableDataResolver,
    TicketsTableProps,
} from 'src/app/components/tables/TicketsTable';
import { config } from 'src/app/constants/config/config';
import useApiFetch from 'src/app/hooks/useApiFetch';
import tableSortingToSortingOptionMapper from 'src/app/utilities/mappers/table/tableSortingToSortingOptionMapper';
import FilterOption from 'src/data/api/common/FilterOption';
import PaginatedApiResponseBody from 'src/data/api/responses/PaginatedApiResponseBody';
import Ticket from 'src/data/models/tickets/ticket';
import ticketsService, { fetchAssignedOrderlineTickets } from 'src/data/services/ticketsService';
import ErrorsList from 'src/view/components/errors-list/ErrorsList';
import { RowIdResolver } from 'src/view/components/table/table/Table';
import { TableColumnSorting } from 'src/view/components/table/table/Types';
import z from 'zod';

export interface Props {
    eventId?: string;
    filter?: FilterOption[];
    q?: string;
    invalidatedAt?: number;
    defaultSorting: TableColumnSorting[];
    page: number;
    initialSelectedRows?: RowIdResolver<TicketsTableDataResolver>[];
    pageSize?: number;
    onChangePage: (page: number) => void;
    onChangeSelectedRows?: (selectedRows: RowIdResolver<TicketsTableDataResolver>[]) => void;
    onDeleteTicketFile: (ticketId: string, fileId: string) => void;
    onUploadTicketSuccess: () => void;
    onChangeSorting: (options: TableColumnSorting[]) => void;
    orderId?: string;
    orderLineId?: string;
}

const schema = z
    .object({
        orderId: z.string().optional(),
        orderLineId: z.string().optional(),
        eventId: z.string().optional(),
    })
    .refine((data) => {
        if ((data.orderId || data.orderLineId) && !(data.orderId && data.orderLineId)) {
            throw new Error(
                'Both Order ID and Orderline ID must be present if either one is provided'
            );
        }
        if (!(data.orderId || data.orderLineId) && !data.eventId) {
            throw new Error('Event ID is required if Order ID and Orderline ID are not provided.');
        }
        return true;
    });

export default function TicketsTableDataWrapper({
    eventId,
    filter,
    q,
    invalidatedAt,
    onChangeSelectedRows,
    defaultSorting,
    onChangeSorting,
    page,
    onChangePage,
    initialSelectedRows,
    pageSize = config.ITEMS_PER_PAGE_STANDARD,
    orderId,
    orderLineId,
    ...props
}: Props & TicketsTableProps): JSX.Element {
    const [{ loading, data: ticketsData, errors }, handleFetch] =
        useApiFetch<PaginatedApiResponseBody<Ticket>>();
    const [isMissingPropsErrorMessage, setIsMissingPropsErrorMessage] = useState<null | string>(
        null
    );

    const includes = ['purchaseSummary'];

    const fetchData = () => {
        try {
            schema.parse({ orderId, orderLineId, eventId });
        } catch (error) {
            if (error instanceof Error) {
                setIsMissingPropsErrorMessage(error.message);
            }
        }

        // If we have orderId and orderLineId, we are fetching tickets for a specific orderline
        if (orderId && orderLineId) {
            handleFetch(
                fetchAssignedOrderlineTickets(orderId, orderLineId, {
                    sorting: defaultSorting.map(tableSortingToSortingOptionMapper),
                    page,
                    pageSize,
                })
            );

            return;
        }

        eventId &&
            handleFetch(
                ticketsService.fetchEventTickets(eventId, {
                    sorting: defaultSorting.map(tableSortingToSortingOptionMapper),
                    filter,
                    page,
                    pageSize,
                    includes,
                    q,
                })
            );
    };

    useEffect(() => {
        fetchData();
    }, [eventId, defaultSorting, page, q, filter, invalidatedAt]);

    const handleSortingChange = (tableSorting: TableColumnSorting[]) => {
        if (defaultSorting.length === 0 && tableSorting.length === 0) return;

        onChangeSorting(tableSorting);
    };

    const handlePageChange = (page: number) => {
        onChangePage(page);
    };

    if (errors.length > 0) {
        return <ErrorsList errors={errors} />;
    }

    if (isMissingPropsErrorMessage) {
        return <Alert severity="error">{isMissingPropsErrorMessage}</Alert>;
    }

    return (
        <TicketsTable
            {...props}
            initialSelectedRows={initialSelectedRows}
            tickets={ticketsData?.data}
            loading={loading}
            defaultSorting={defaultSorting}
            onChangeSorting={handleSortingChange}
            onChangeSelectedRows={onChangeSelectedRows}
            pagination={{
                currentPage: ticketsData?.meta?.currentPage || 1,
                totalPages: ticketsData?.meta?.totalPages || 1,
                onPaginate: handlePageChange,
            }}
        />
    );
}
