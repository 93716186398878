import { useMutation } from '@tanstack/react-query';
import supplierService, { CreateSupplierDto } from 'src/data/services/supplierService';

type TData = Awaited<ReturnType<typeof supplierService.updateSupplier>>;
type TVariables = {
    id: string;
    dto: CreateSupplierDto;
};

const useUpdateSupplier = (
    onSuccess?: (data: TData, variables: TVariables, context: unknown) => void
) => {
    return useMutation({
        mutationFn: ({ dto, id }: TVariables) => {
            return supplierService.updateSupplier(id, dto);
        },
        onSuccess,
    });
};

export default useUpdateSupplier;
