import z from 'zod';

export const zodRequiredNumberInputSchema = z
    .string()
    .or(z.number().min(1))
    .pipe(
        z.coerce
            .number({ invalid_type_error: 'Please enter a number!' })
            .min(1, 'Please enter a higher amount than 0')
    );
