import { Alert, Grid } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import ExportModalDataWrapper from 'src/app/components/data-wrappers/tickets/ExportModalDataWrapper';
import AddContractFeature from 'src/app/components/features/purchase/AddContractFeature';
import TicketsTableFeature from 'src/app/components/features/tickets/TicketsTableFeature';
import TicketsUrlService from 'src/app/components/features/tickets/TicketsUrlService';
import { FETCH_EVENT_TICKETS_QUERY_KEY } from 'src/app/hooks/tickets/useFetchEventTickets';
import { useSpacingStyles } from 'src/shared/styles/spacingStyles';
import Button from 'src/view/components/button/Button';
import { ETBContainer } from 'src/view/components/container/container';
import Header from 'src/view/components/header/Header';
import Modal from 'src/view/components/modal/Modal';

interface TicketsOverviewProps {
    eventName: string;
}

const TicketsOverview = ({ eventName }: TicketsOverviewProps) => {
    const spacingClasses = useSpacingStyles();
    const queryClient = useQueryClient();
    const { eventId } = useParams<{ eventId: string }>();
    const [ticketsInvalidatedAt, setTicketsInvalidatedAt] = useState<number | undefined>();
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [exportModalOpen, setExportModalOpen] = useState(false);
    const [addContractsOpen, setAddContractsOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const confirmationModalOnConfirm = () => {
        setShowConfirmationModal(false);
    };

    const renderConfirmCloseManualPurchaseModal = () => (
        <Modal
            open={showConfirmationModal}
            title="Are you sure you want to close this manual purchase?"
            onClose={() => setShowConfirmationModal(false)}
            buttons={[
                {
                    children: 'Confirm',
                    onClick: confirmationModalOnConfirm,
                },
            ]}
        >
            <>
                If you close this modal, all your changes to this manual purchase will be lost. Are
                you still sure that you want to close this modal?
            </>
        </Modal>
    );

    const onContractCreated = () => {
        enqueueSnackbar('Contract tickets added', { variant: 'success' });
        setAddContractsOpen(false);
        setTicketsInvalidatedAt(Date.now());
        queryClient.invalidateQueries({
            queryKey: [FETCH_EVENT_TICKETS_QUERY_KEY],
            exact: false,
        });
    };

    if (!eventId) {
        return <Alert severity="error">No eventId was found</Alert>;
    }

    const renderAddContractTicketsModal = () => (
        <Modal
            title="Add contract tickets"
            open={addContractsOpen}
            width="large"
            onClose={() => {
                setAddContractsOpen(false);
            }}
        >
            <AddContractFeature eventId={eventId} onCreated={onContractCreated} />
        </Modal>
    );

    return (
        <>
            <Header>
                <Grid item>
                    <h1>{eventName}</h1>
                </Grid>
                <Grid item>
                    <Button
                        onClick={() => setExportModalOpen(true)}
                        variant="text"
                        white
                        className={spacingClasses.spacingRight}
                    >
                        Exports
                    </Button>
                    <Button
                        onClick={() => setAddContractsOpen(true)}
                        className={spacingClasses.spacingRight}
                    >
                        Add contract ticket(s)
                    </Button>
                    <Button
                        href={TicketsUrlService.batchUploadTickets(eventId)}
                        className={spacingClasses.spacingRight}
                    >
                        Batch upload tickets
                    </Button>
                </Grid>
            </Header>
            <ETBContainer>
                <TicketsTableFeature
                    eventId={eventId}
                    invalidatedAt={ticketsInvalidatedAt}
                    enableDeleteTickets
                    enableBatchEditTickets
                    onSuccess={() =>
                        queryClient.invalidateQueries({
                            queryKey: [FETCH_EVENT_TICKETS_QUERY_KEY],
                            exact: false,
                        })
                    }
                />

                <Modal
                    open={exportModalOpen}
                    title="Export Tickets"
                    onClose={() => setExportModalOpen(false)}
                >
                    <>
                        Export tickets by clicking the download button
                        <ExportModalDataWrapper eventId={eventId} />
                    </>
                </Modal>

                {renderConfirmCloseManualPurchaseModal()}
                {renderAddContractTicketsModal()}
            </ETBContainer>
        </>
    );
};

export default TicketsOverview;
