import Popover from 'src/view/components/popover/Popover';
import { SIZES } from 'src/shared/theme/enums';
import IconComponent from 'src/view/icons/IconComponent';
import WarningIcon from '@mui/icons-material/Warning';

const OrderPriceWarningPopover = () => {
    return (
        <Popover text={<IconComponent Icon={WarningIcon} warning size={SIZES.SMALL} />}>
            <p>
                The price might not be the actual sales values, the value is only based on the price
                registered in ETBaas (TSC price)
            </p>
        </Popover>
    );
};

export default OrderPriceWarningPopover;
