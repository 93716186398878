import { useState } from 'react';
import { Grid } from '@mui/material';
import Modal from 'src/view/components/modal/Modal';
import Header from 'src/view/components/header/Header';
import { ETBContainer } from 'src/view/components/container/container';
import Button from 'src/view/components/button/Button';
import EventCategoriesTableFeature from 'src/app/components/features/event-categories/EventCategoriesTableFeature';
import EventCategoriesDetailsForm from 'src/app/components/forms/event-categories/EventCategoriesDetailsForm';

export default function EventCategoriesOverview() {
    const [createModalOpen, setCreateModalOpen] = useState(false);

    return (
        <>
            <Header GridProps={{ justifyContent: 'flex-end' }}>
                <Grid item>
                    <Button color="primary" onClick={() => setCreateModalOpen(true)}>
                        Add new event category
                    </Button>
                </Grid>
            </Header>

            <ETBContainer>
                <EventCategoriesTableFeature />
            </ETBContainer>
            {createModalOpen && (
                <Modal
                    title="Add new event category"
                    open
                    onClose={() => setCreateModalOpen(false)}
                >
                    <EventCategoriesDetailsForm
                        onSuccess={() => {
                            setCreateModalOpen(false);
                        }}
                    />
                </Modal>
            )}
        </>
    );
}
