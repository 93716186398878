import { CSSProperties } from 'react';
import { FormControlLabel } from '@mui/material';
import Checkbox from 'src/view/components/checkbox/Checkbox';
import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';

const getCheckboxStyling = (
    color: CSSProperties['color'],
    checkedColor: CSSProperties['color']
) => ({
    color,
    '& [type=checkbox]:checked + svg': {
        color,
    },
    '&:hover ': {
        '& [type=checkbox]:checked + svg': {
            color: checkedColor,
        },
    },
});

const useStyles = makeStyles((theme: Theme) => ({
    controlLabel: {
        '& span': {
            fontSize: '0.9rem',
        },
        '&:hover': {
            opacity: '0.9',
        },
    },
    checkbox: getCheckboxStyling(theme.palette.primary.main, theme.palette.primary.dark),
    checkboxLight: getCheckboxStyling(theme.colors.white, theme.colors.grey),
}));

interface CheckboxWithLabelProps {
    variant?: 'dark' | 'light';
    name?: string;
    label: string;
    checked: boolean;
    value?: boolean;
    onChange: (checked: boolean) => void;
}

const CheckboxWithLabel = ({
    variant = 'dark',
    label,
    checked,
    onChange,
}: CheckboxWithLabelProps) => {
    const classes = useStyles();

    return (
        <div>
            <FormControlLabel
                className={classes.controlLabel}
                label={label}
                control={
                    <Checkbox
                        className={classnames(
                            variant === 'light' ? classes.checkboxLight : classes.checkbox
                        )}
                        checked={checked}
                        onChange={(event) => onChange(event.target.checked)}
                    />
                }
            />
        </div>
    );
};

export default CheckboxWithLabel;
