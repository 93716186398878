import useApiFetch from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import orderlineService from 'src/data/services/orderlineService';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import { DataWrapperCallback } from 'src/app/interfaces/dataWrapperCallback';
import { EmptyBody } from 'src/data/models/common/emptyBody';

type ResponseType = ApiResponseBody<EmptyBody>;

interface UndoAutoAssignResultOrderlineDataWrapperChildrenParams
    extends DataWrapperChildrenParams<ResponseType> {
    undoAutoAssignResultOrderline: (
        orderlineIds: string[],
        callbacks?: DataWrapperCallback<ResponseType>
    ) => Promise<ResponseType>;
}

type UndoAutoAssignResultOrderlineDataWrapperProps = DataWrapperProps<
    UndoAutoAssignResultOrderlineDataWrapperChildrenParams,
    ResponseType
> &
    BaseFetchOptions;

export const useUndoAutoAssignResultOrderlineDataWrapper = ({
    onError,
    onSuccess,
}: Omit<UndoAutoAssignResultOrderlineDataWrapperProps, 'children'>) => {
    const [{ loading, data, errors, resetData }, handleUndoFetchAutoAssignResultOrderline] =
        useApiFetch<ResponseType>();

    const undoAutoAssignResultOrderline = (
        orderlineIds: string[],
        callbacks?: DataWrapperCallback<ResponseType>
    ) => {
        return handleUndoFetchAutoAssignResultOrderline(
            orderlineService.unassignTickets({ orderLineIds: orderlineIds }),
            {
                useDefaultErrorHandler: true,
                defaultSuccessMessage: 'Undo assign tickets successfull',
                onFail: () => {
                    onError?.();
                    callbacks?.onFail?.();
                },
                onSuccess: (data) => {
                    onSuccess?.(data);
                    callbacks?.onSuccess?.(data);
                },
            }
        );
    };

    return {
        undoAutoAssignResultOrderline,
        data,
        loading,
        errors,
        resetData,
    };
};

export const UndoAutoAssignResultOrderlineDataWrapper = (
    props: UndoAutoAssignResultOrderlineDataWrapperProps
) => {
    const { children } = props;
    const hookData = useUndoAutoAssignResultOrderlineDataWrapper(props);

    if (!children) return <></>;

    return <>{children(hookData)}</>;
};
