import { PAYMENT_STATUS, SHIPPING_STATUS } from 'src/data/enums/order';

export const paymentStatusStrings = {
    [PAYMENT_STATUS.Approved]: 'Approved',
    [PAYMENT_STATUS.Missing]: 'Missing',
    [PAYMENT_STATUS.Partial]: 'Partial',
};

export const shippingStatusStrings = {
    [SHIPPING_STATUS.Sent]: 'Sent',
    [SHIPPING_STATUS.PartiallySent]: 'Partially sent',
    [SHIPPING_STATUS.NotSent]: 'Not sent',
    [SHIPPING_STATUS.Unknown]: 'Unknown',
};

export const orderTravelwareSourcePrefix = 'TW_';
