import { useEffect } from 'react';
import useApiFetch from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import PaginatedApiResponseBody from 'src/data/api/responses/PaginatedApiResponseBody';
import purchaseService from 'src/data/services/purchaseService';
import PurchaseStatistic from 'src/data/models/purchase/PurchaseStatistic';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import PaginationMeta from 'src/data/api/responses/PaginationMeta';

interface FetchPurchaseStatisticsDataWrapperChildrenParams
    extends DataWrapperChildrenParams<PaginatedApiResponseBody<PurchaseStatistic>> {
    fetchPurchaseStatistics: () => Promise<PaginatedApiResponseBody<PurchaseStatistic, PaginationMeta>>;
}

interface FetchPurchaseStatisticsDataWrapperProps
    extends DataWrapperProps<
            FetchPurchaseStatisticsDataWrapperChildrenParams,
            PaginatedApiResponseBody<PurchaseStatistic>
        >,
        BaseFetchOptions {
    purchaseId: string;
}

export const useFetchPurchaseStatisticsDataWrapper = ({
    onError,
    onSuccess,
    purchaseId,
    page,
}: Omit<FetchPurchaseStatisticsDataWrapperProps, 'children'>) => {
    const [{ loading, data, errors, resetData }, handleFetchPurchaseStatistics] =
        useApiFetch<PaginatedApiResponseBody<PurchaseStatistic>>();

    const fetchPurchaseStatistics = () => {
        return handleFetchPurchaseStatistics(
            purchaseService.fetchPurchaseStatistics(purchaseId, { page }),
            {
                useDefaultErrorHandler: true,
                onFail: onError,
                onSuccess,
            }
        );
    };

    useEffect(() => {
        fetchPurchaseStatistics();
    }, [purchaseId]);

    return {
        fetchPurchaseStatistics,
        data,
        loading,
        errors,
        resetData,
    };
};

export const FetchPurchaseStatisticsDataWrapper = (
    props: FetchPurchaseStatisticsDataWrapperProps
) => {
    const { children } = props;
    const hookData = useFetchPurchaseStatisticsDataWrapper(props);

    if (!children) return <></>;

    return <>{children(hookData)}</>;
};
