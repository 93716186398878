import AuditType from 'src/data/enums/auditType';

const mapToReadableAuditType = (auditType: AuditType) => {
    switch (auditType) {
        case AuditType.TicketSalesCapCreated:
            return 'created a ticket sales cap';
        case AuditType.TicketSalesCapUpdated:
            return 'updated a ticket sales cap';
        case AuditType.TicketSalesCapDeleted:
            return 'deleted a ticket sales cap';
        case AuditType.TicketsSent:
            return 'send tickets';
        case AuditType.OrderMarkedAsSent:
            return 'marked an order as sent';
        case AuditType.OrderLineAssignedTickets:
            return 'assigned tickets';
        case AuditType.OrderLineInfoChanged:
            return 'changed orderline information';
        case AuditType.OrderPaymentStatusChanged:
            return 'changed an order paymentstatus';
        case AuditType.TicketFileAdded:
            return 'added a file to a ticket';
        case AuditType.TicketFileRemoved:
            return 'removed a file from a ticket';
        case AuditType.TicketFileReplaced:
            return 'replaced a file on a ticket';
        default:
            return auditType;
    }
};

export default mapToReadableAuditType;
