import FilterOption from 'src/data/api/common/FilterOption';
import { AutoCompleteOptions } from 'src/view/components/auto-complete/interfaces';
import { FilterAutoCompleteOptions } from 'src/view/components/filters/AutoComplete/AutoComplete';

function mapFilterOptionsToAutocompleteOptions(
    data?: { name: string; id: string }[]
): AutoCompleteOptions {
    if (!data || data.length === 0) return [];

    return data.map((s) => ({
        label: s.name,
        value: s.id,
    }));
}

function getDateValue(
    property: string,
    operation: string,
    initialOptions: FilterOption[]
): string | undefined {
    const filterOption = initialOptions?.find(
        (option) => option.property === property && option.operation === operation
    );

    return filterOption?.value;
}

// TODO: Clean up this function
function getInitialAutocompleteValues(
    filterOptionProperty: string,
    initialOptions: FilterOption[],
    autocompleteOptions: FilterAutoCompleteOptions
): FilterAutoCompleteOptions | undefined {
    const filterOption = initialOptions?.find((option) => option.property === filterOptionProperty);

    if (!filterOption) return [];

    const filterValues = filterOption.value?.split(',');
    const autocompleteValues: FilterAutoCompleteOptions = [];

    filterValues?.forEach((value) => {
        const existingOption = autocompleteOptions.find((_) => _.value === value);

        if (!existingOption) return;

        autocompleteValues.push(existingOption);
    });

    return autocompleteValues;
}

export default {
    mapFilterOptionsToAutocompleteOptions,
    getInitialAutocompleteValues,
    getDateValue,
};
