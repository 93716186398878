import useApiFetch from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import { IdResponse } from 'src/data/models/common/idResponse';
import CreatePurchaseDto from 'src/data/dtos/CreatePurchaseDto';
import purchaseService from 'src/data/services/purchaseService';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';

interface CreatePurchaseDataWrapperChildrenParams
    extends DataWrapperChildrenParams<ApiResponseBody<IdResponse>> {
    createPurchase: (dto: CreatePurchaseDto) => Promise<ApiResponseBody<IdResponse>>;
}

type CreatePurchaseDataWrapperProps = DataWrapperProps<
    CreatePurchaseDataWrapperChildrenParams,
    ApiResponseBody<IdResponse>
> &
    BaseFetchOptions;

export const useCreatePurchaseDataWrapper = ({
    onError,
    onSuccess,
}: Omit<CreatePurchaseDataWrapperProps, 'children'>) => {
    const [{ loading, data, errors, resetData }, handleFetchPurchase] =
        useApiFetch<ApiResponseBody<IdResponse>>();

    const createPurchaseCall = (dto: CreatePurchaseDto) => {
        return handleFetchPurchase(purchaseService.createPurchase(dto), {
            useDefaultErrorHandler: true,
            onFail: onError,
            onSuccess,
        });
    };

    return {
        createPurchase: createPurchaseCall,
        data,
        loading,
        errors,
        resetData,
    };
};
