import { useQuery } from '@tanstack/react-query';
import type BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import eventService from 'src/data/services/eventService';

type Options = {
    enabled: boolean;
};

export const GET_EVENT_BY_ID_QUERY_KEY = 'GET_EVENT_BY_ID';

export const useGetEventById = (
    eventId: string,
    fetchOptions?: BaseFetchOptions,
    options?: Options
) => {
    return useQuery({
        queryKey: [GET_EVENT_BY_ID_QUERY_KEY, eventId, fetchOptions],
        queryFn: () => eventService.fetchEventById(eventId, fetchOptions),
        ...options,
    });
};
