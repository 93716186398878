import { useMutation } from '@tanstack/react-query';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import CreateVenueFormDataWrapper from 'src/app/components/data-wrappers/venues/CreateVenueFormDataWrapper';
import SeriesCreateFormFeature from 'src/app/components/features/series/SeriesCreateFormFeature';
import CreateTeamFormFeature from 'src/app/components/features/teams/CreateTeamFormFeature';
import { TEAM_TYPE } from 'src/app/components/forms/CreateTeamForm';
import { useCustomConfirm } from 'src/app/hooks/useCustomConfirm';
import Toaster from 'src/app/utilities/helpers/Toaster';
import { parseErrors } from 'src/app/utilities/helpers/errors';
import type CreateEventDto from 'src/data/dtos/CreateEventDto';
import { EVENT_TYPE } from 'src/data/models/events/event';
import eventService from 'src/data/services/eventService';
import { AutoCompleteOption } from 'src/view/components/auto-complete/interfaces';
import Modal from 'src/view/components/modal/Modal';
import CreateEventForm from '../forms/event/CreateEventForm';
import {
    CreateEventFormValues,
    type UseCreateEventFormReturn,
} from '../forms/event/useCreateEventForm';

interface Props {
    form: UseCreateEventFormReturn;
    onEventCreated?: () => void;
}

export default function CreateEventFeature({ form, onEventCreated }: Props): JSX.Element {
    const [showCreateVenueModal, setShowCreateVenueModal] = useState(false);
    const [showCreateSeriesModal, setShowCreateSeriesModal] = useState(false);
    const [showCreateTeamModal, setShowCreateTeamModal] = useState(false);
    const [homeTeamName, setHomeTeamName] = useState('');
    const [awayTeamName, setAwayTeamName] = useState('');
    const [teamTitle, setTeamTitle] = useState('');
    const [seriesName, setSeriesName] = useState('');
    const confirm = useCustomConfirm();

    const {
        mutate: createEvent,
        isError: isCreateEventError,
        error: createEventError,
        isLoading: isCreateEventLoading,
    } = useMutation({
        mutationFn: (dto: CreateEventDto) => eventService.createEvent(dto),
        onSuccess: () => onEventCreated?.(),
    });

    useEffect(() => {
        if (!isCreateEventError || !createEventError) return;
        Toaster.toastErrors(parseErrors(createEventError));
    }, [isCreateEventError, createEventError]);

    const [createdVenueOption, setCreatedVenueOption] = useState<AutoCompleteOption | undefined>();
    const [createdSeriesOption, setCreatedSeriesOption] = useState<
        AutoCompleteOption | undefined
    >();
    const [createdHomeTeamOption, setCreatedHomeTeamOption] = useState<
        AutoCompleteOption | undefined
    >();
    const [createdAwayTeamOption, setCreatedAwayTeamOption] = useState<
        AutoCompleteOption | undefined
    >();

    const onCreateTeam = (teamName: string, title: string) => {
        if (title === TEAM_TYPE.homeTeam) {
            setHomeTeamName(teamName);
            setTeamTitle('Home Team');
            setShowCreateTeamModal(true);

            return;
        }

        setAwayTeamName(teamName);
        setTeamTitle('Away Team');
        setShowCreateTeamModal(true);
    };

    const onCreateOrUpdateTeamSuccess = useCallback((id?: string, name?: string) => {
        if (!id || !name) return;

        setShowCreateTeamModal(false);
        if (teamTitle === 'Home Team') {
            setCreatedHomeTeamOption({
                label: name,
                value: id,
            });

            return;
        }

        setCreatedAwayTeamOption({
            label: name,
            value: id,
        });
    }, []);

    const onSuccessfullyCreateVenue = (id: string, name: string) => {
        setShowCreateVenueModal(false);

        // FIXME: id and name are not returned from the callback and venu option will be undefined
        // doesn't seem to be a breaking issue but probably affects quality of life
        // https://travelflow.atlassian.net/browse/ETBAAS-3731
        setCreatedVenueOption({
            label: name,
            value: id,
        });
    };

    const onCreateEventSubmit = async (values: CreateEventFormValues) => {
        const {
            eventName,
            description,
            eventType,
            startDate,
            endDate,
            dateConfirmed,
            active,
            eventCategory,
            eventTags,
            homeTeam,
            awayTeam,
            contestants,
            matches,
            series,
            venue,
            seatingPlan,
            organizer,
            artists,
            ticketsSentEarliestDays,
            ticketsSentLatestDays,
            allowAwayFans,
            requireFullCustomerAddress,
            blacklistedCountries,
            blacklistedNationalities,
            hasMultipleBookingOptions,
        } = values;

        const dto: CreateEventDto = {
            name: eventName,
            eventCategoryId: eventCategory?.value,
            description: description,
            eventType: eventType?.value,
            dateTimeStart: moment.utc(startDate).format(),
            dateTimeEnd: endDate ? moment.utc(endDate).format() : undefined,
            dateConfirmed: dateConfirmed,
            isActive: active,
            seriesId: series?.value,
            venueId: venue?.value,
            seatingPlanId: seatingPlan?.value,
            organizerId: organizer?.value,
            homeTeamId: homeTeam?.value,
            awayTeamId: awayTeam?.value,
            performerIds:
                eventType?.value === EVENT_TYPE.Tennis
                    ? contestants?.map((c) => c.value)
                    : artists?.map((c) => c.value),
            matches: matches?.map((m) => m.name),
            tagIds: eventTags?.map((t) => t.value),
            ticketsSentEarliestDays: ticketsSentEarliestDays ?? 3,
            ticketsSentLatestDays: ticketsSentLatestDays ?? 1,
            restrictions: {
                allowAwayFans,
                requireFullCustomerAddress,
                blacklistedCountryCodes: blacklistedCountries?.map((o) => o.value) || [],
                blacklistedNationalityCodes: blacklistedNationalities?.map((o) => o.value) || [],
            },
            hasMultipleBookingOptions,
        };

        const isAfterNextYear = moment.utc(startDate).year() > moment(new Date()).year() + 1;
        const canCreate = isAfterNextYear
            ? await confirm({
                  title: 'Are you sure you want to create this event?',
                  description: `This event starts in the year ${moment.utc(startDate).year()}`,
                  confirmationText: 'Create Event',
              })
            : true;

        if (canCreate) createEvent(dto);
    };

    return (
        <>
            <CreateEventForm
                form={form}
                actionLoading={isCreateEventLoading}
                seriesOption={createdSeriesOption}
                venueOption={createdVenueOption}
                homeTeamOption={createdHomeTeamOption}
                awayTeamOption={createdAwayTeamOption}
                onCreateHomeTeam={(name: string) => onCreateTeam(name, TEAM_TYPE.homeTeam)}
                onCreateAwayTeam={(name: string) => onCreateTeam(name, TEAM_TYPE.awayTeam)}
                onCreateVenue={() => {
                    setShowCreateVenueModal(true);
                }}
                onCreateSeries={(seriesName: string) => {
                    setSeriesName(seriesName);
                    setShowCreateSeriesModal(true);
                }}
                onFormSubmit={onCreateEventSubmit}
            />

            <Modal
                open={showCreateVenueModal}
                title={'Create venues'}
                onClose={() => setShowCreateVenueModal(false)}
            >
                <CreateVenueFormDataWrapper onSuccessfullyCreateVenue={onSuccessfullyCreateVenue} />
            </Modal>
            <Modal
                open={showCreateTeamModal}
                title={teamTitle}
                onClose={() => setShowCreateTeamModal(false)}
            >
                <CreateTeamFormFeature
                    teamName={teamTitle === 'Home Team' ? homeTeamName : awayTeamName}
                    onCreateOrUpdateTeamSuccess={onCreateOrUpdateTeamSuccess}
                />
            </Modal>
            <Modal
                open={showCreateSeriesModal}
                title={'Create Series'}
                onClose={() => setShowCreateSeriesModal(false)}
            >
                <SeriesCreateFormFeature
                    onSucceed={() => setShowCreateSeriesModal(false)}
                    seriesName={seriesName}
                    onCreateSeries={(option: AutoCompleteOption) => setCreatedSeriesOption(option)}
                />
            </Modal>
        </>
    );
}
