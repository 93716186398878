import { Box, Grid, List, ListItem, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import groupBy from 'lodash/groupBy';
import { Link } from 'react-router-dom';
import EventUrlService from 'src/app/pages/events/eventUrlService';
import { ORDERLINE_STATUS, ORDERLINE_TYPE, SHIPPING_STATUS } from 'src/data/enums/orderline';
import Orderline, { AssignedTicket } from 'src/data/models/orderline/orderline';
import { formatPrice } from 'src/shared/helpers/formatPrice';
import { BUTTON_SIZES } from 'src/shared/theme/enums';
import BlockRowSeat from 'src/view/components/block-row-seat/BlockRowSeat';
import { BlockRowSeatRange } from 'src/view/components/block-row-seat/BlockRowSeatRange';
import Button from 'src/view/components/button/Button';
import HtmlListTooltip from 'src/view/components/tooltip/HtmlListTooltip';
import PencilSvg from 'src/view/icons/svg/pencilSvg';
import { useCreateDownloadLinkDataWrapper } from '../../data-wrappers/tickets/CreateDownloadLinkDataWrapper';

interface SeatDetails {
    block?: string;
    row?: string;
    seat?: string;
}

interface TicketPromiseSeatSectionProps {
    orderline: Orderline;
    shippingStatus?: SHIPPING_STATUS;
    assignedTickets?: AssignedTicket[];
    status?: string;
    onClickAssign: (orderline: Orderline) => void;
}

interface OrderlineSeatSectionProps {
    orderline?: Orderline;
    shippingStatus?: SHIPPING_STATUS;
    assignedTickets?: AssignedTicket[];
    status?: string;
    blockRowSeat: SeatDetails;
    onClickAssign: (orderline: Orderline) => void;
}

const allTicketsAssigned = (orderline?: Orderline): boolean =>
    orderline?.quantity === orderline?.assignedTickets.length;

const useStyles = makeStyles((theme) => ({
    assignedTicketButton: {
        marginRight: theme.spacing(1),
    },
    assignedTicketSplits: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        cursor: 'pointer',
        display: 'flex',
    },
    assignedTicketSplitsDisabled: {
        cursor: 'not-allowed',
    },

    assignSection: {
        marginLeft: 0,
        minWidth: '88px',
        padding: theme.spacing(0.5, 1),
    },
    cancelled: {
        color: theme.palette.error.main,
        borderRadius: theme.layout.borderRadius.large,
        padding: theme.spacing(0.6, 2),
        border: `2px solid ${theme.palette.error.main}`,
    },
    blockRowSeat: {
        display: 'flex',
        alignItems: 'center',
    },
    assignedTickets: {
        display: 'flex',
        alignItems: 'center',
        minWidth: '128px',
    },
    normalCursor: {
        cursor: 'initial',
    },
}));

const AssignTicketButton = ({
    shippingStatus,
    groupedTicketsBySplit,
    onClickAssign,
    orderline,
}: {
    shippingStatus: SHIPPING_STATUS | undefined;
    groupedTicketsBySplit: _.Dictionary<AssignedTicket[]>;
    onClickAssign: (orderline: Orderline) => void;
    orderline: Orderline;
}): JSX.Element => {
    const theme = useTheme();
    const classes = useStyles();

    const shippingStatusIsSent = shippingStatus === SHIPPING_STATUS.SENT;
    const splitsCount = Object.keys(groupedTicketsBySplit).length;

    return (
        <>
            {splitsCount === 0 ? (
                <Button
                    className={classes.assignedTicketButton}
                    size={BUTTON_SIZES.TINY}
                    onClick={() => onClickAssign(orderline)}
                    disabled={shippingStatusIsSent || orderline.canChangeAssignedTickets === false}
                >
                    Assign ticket(s)
                </Button>
            ) : (
                <div
                    className={`${classes.assignedTicketSplits} ${
                        shippingStatusIsSent ? classes.assignedTicketSplitsDisabled : ''
                    }`}
                    onClick={() => {
                        if (!shippingStatusIsSent) {
                            onClickAssign(orderline);
                        }
                    }}
                >
                    <PencilSvg
                        sizeInPx={18}
                        containerColor={
                            shippingStatusIsSent ? theme.colors.grey : theme.palette.primary.main
                        }
                    />
                </div>
            )}
        </>
    );
};

const TicketPromiseSeatSection = ({
    shippingStatus,
    assignedTickets,
    status,
    orderline,
    onClickAssign,
}: TicketPromiseSeatSectionProps): JSX.Element => {
    const classes = useStyles();

    if (status === ORDERLINE_STATUS.CANCELLED) {
        return (
            <span className={classes.cancelled}>
                <b>Cancelled</b>
            </span>
        );
    }

    const groupedTicketsBySplit = groupBy(assignedTickets, 'splitId');

    const { createDownloadLink } = useCreateDownloadLinkDataWrapper({
        onSuccess: (data) => window.open(data?.data.url, '_blank'),
    });

    const ticketAssignedTicketFilesList = orderline.assignedTickets.map((ticket) => {
        return (
            <ListItem
                divider
                key={ticket.id}
                sx={{
                    padding: 0,
                    paddingY: 1,
                }}
            >
                <Grid container>
                    <Grid item xs={6}>
                        <Typography fontSize={15}>
                            <Link
                                to={EventUrlService.tickets(ticket.eventId, {
                                    ticketId: ticket.id,
                                })}
                                target="_blank"
                            >
                                Ticket
                            </Link>
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Box display="flex" flexDirection="column">
                            {ticket.fileNames?.map((file) => {
                                return (
                                    <Typography
                                        sx={{
                                            fontSize: 14,
                                            textDecoration: 'underline',
                                            cursor: 'pointer',
                                            color: (theme) => theme.colors.blue,
                                            ':hover': {
                                                opacity: 0.8,
                                            },
                                        }}
                                        onClick={() => createDownloadLink(ticket.id, file.id)}
                                    >
                                        {file.name}
                                    </Typography>
                                );
                            })}
                        </Box>
                    </Grid>
                </Grid>
            </ListItem>
        );
    });

    const tooltipTitleData = (
        <Grid container paddingTop={1}>
            <Grid item xs={6} fontSize={14} fontWeight="bold">
                Link to ticket
            </Grid>
            <Grid item xs={6} fontSize={14} fontWeight="bold">
                Files
            </Grid>
            <Grid item xs={12}>
                <List>{ticketAssignedTicketFilesList}</List>
            </Grid>
        </Grid>
    );

    return (
        <div className={classes.blockRowSeat}>
            <div className={classes.assignedTickets}>
                <BlockRowSeatRange seatings={assignedTickets} />
                <AssignTicketButton
                    groupedTicketsBySplit={groupedTicketsBySplit}
                    shippingStatus={shippingStatus}
                    onClickAssign={onClickAssign}
                    orderline={orderline}
                />
            </div>

            <HtmlListTooltip
                sx={{
                    color: (theme) => `${theme.colors.white} !important`,
                    backgroundColor: (theme) =>
                        allTicketsAssigned(orderline)
                            ? theme.palette.success.main
                            : theme.colors.darkGrey,

                    ':hover': {
                        backgroundColor: (theme) =>
                            allTicketsAssigned(orderline)
                                ? theme.palette.success.main
                                : theme.colors.darkGrey,
                        opacity: 0.9,
                    },
                }}
                buttonTitle={`${assignedTickets?.length}/${orderline.quantity}`}
                title={tooltipTitleData}
            />
        </div>
    );
};
export function OrderlineSeatSection({
    orderline,
    shippingStatus,
    assignedTickets,
    status,
    blockRowSeat,
    onClickAssign,
}: OrderlineSeatSectionProps): JSX.Element {
    const classes = useStyles();

    if (!orderline) return <></>;

    if (orderline.status === ORDERLINE_STATUS.CANCELLED) {
        return (
            <span className={classes.cancelled}>
                <b>Cancelled</b>
            </span>
        );
    }

    if (orderline.type === ORDERLINE_TYPE.TICKET_ORDERLINE) {
        if (!blockRowSeat) return <></>;

        const { block, row, seat } = blockRowSeat;

        return <BlockRowSeat block={block} row={row} seat={seat} />;
    }

    return (
        <TicketPromiseSeatSection
            orderline={orderline}
            status={status}
            assignedTickets={assignedTickets}
            shippingStatus={shippingStatus}
            onClickAssign={onClickAssign}
        />
    );
}

export function Price({
    price,
}: {
    price: {
        currency: string;
        value?: number;
    };
}): JSX.Element {
    if (!price.currency || !price.value || isNaN(price.value)) {
        return <span>price format is invalid, please contact IT</span>;
    }

    return (
        <>
            <span>
                {formatPrice({
                    currency: price.currency,
                    amount: Number(price.value || 0),
                })}
            </span>
        </>
    );
}

export function findOrderlineTicketBlockRowSeat(orderline: Orderline): SeatDetails {
    const block = orderline.blockNumber;
    const row = orderline.rowNumber;
    const seat = orderline.seatNumber;

    return { block, row, seat };
}
