import { Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Button from 'src/view/components/button/Button';
import { ETBContainer } from 'src/view/components/container/container';

const useStyles = makeStyles((theme) => ({
    paper: {
        width: 500,
        textAlign: 'center',
    },
    button: {
        marginTop: theme.spacing(4),
    },
}));

export interface NoRouteMatchProps {
    isAuthorized?: boolean;
}

export const NoRouteMatch = ({ isAuthorized }: NoRouteMatchProps) => {
    const classes = useStyles();

    const text = !isAuthorized ? '404 - Not Found' : '401 - Not Authorized';

    return (
        <ETBContainer
            sx={(theme) => ({
                display: 'flex',
                justifyContent: 'center',
                height: '100%',
                paddingTop: theme.spacing(10),
                '& h1': {
                    textAlign: 'center',
                },
            })}
        >
            <Paper className={classes.paper}>
                <h1>{text}</h1>
                <Typography align="center">
                    We couldn't find this route. If you are missing a route, please contact the IT
                    department.
                </Typography>
                <Button href="/" className={classes.button}>
                    Go to Event Overview
                </Button>
            </Paper>
        </ETBContainer>
    );
};
