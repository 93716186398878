import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export interface FormFieldErrorProps {
    message?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    message: {
        color: theme.palette.error.main,
    },
}));

const FormFieldError = ({ message }: FormFieldErrorProps): JSX.Element => {
    const classes = useStyles();

    return <>{message && <span className={classes.message}>{message}</span>}</>;
};

export default FormFieldError;
