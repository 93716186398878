import { zodResolver } from '@hookform/resolvers/zod';
import Grid from '@mui/material/Grid';
import { Controller, useForm } from 'react-hook-form';
import { FormProps } from 'src/app/interfaces/form/formProps';
import ErrorDetail from 'src/data/api/responses/ErrorDetail';
import { useSpacingStyles } from 'src/shared/styles/spacingStyles';
import CheckboxWithLabel from 'src/view/components/checkbox-with-label/CheckboxWithLabel';
import ErrorsList from 'src/view/components/errors-list/ErrorsList';
import FormButtons from 'src/view/components/form/FormButtons';
import FormFieldError from 'src/view/components/form/FormFieldError';
import z from 'zod';

const forceRevokeTicketsValidationScheme = z.object({
    forceRevokeConfirmation: z.boolean().refine((val) => val === true, {
        message: 'Should be selected!',
    }),
    makeTicketsInvalidConfirmation: z.boolean().refine((val) => val === true, {
        message: 'Should be selected!',
    }),
});

export interface RevokeTicketsFormValues {
    forceRevokeConfirmation: boolean;
    makeTicketsInvalidConfirmation: boolean;
}

interface RevokeTicketsFormProps extends FormProps<RevokeTicketsFormValues> {
    forceMode?: boolean;
    errors: ErrorDetail[];
}

export const RevokeTicketsForm = ({
    onSubmit,
    loading,
    forceMode,
    errors = [],
}: RevokeTicketsFormProps) => {
    const spacingClasses = useSpacingStyles();
    const { handleSubmit, control } = useForm<RevokeTicketsFormValues>({
        resolver: forceMode ? zodResolver(forceRevokeTicketsValidationScheme) : undefined,
        defaultValues: {
            forceRevokeConfirmation: false,
            makeTicketsInvalidConfirmation: false,
        },
    });

    return (
        <>
            {forceMode && (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Controller
                            name="forceRevokeConfirmation"
                            control={control}
                            render={({
                                field: { name, onChange, value },
                                fieldState: { error },
                            }) => (
                                <>
                                    <CheckboxWithLabel
                                        name={name}
                                        label="Force revoke"
                                        checked={value}
                                        onChange={onChange}
                                    />
                                    <FormFieldError message={error?.message} />
                                </>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="makeTicketsInvalidConfirmation"
                            control={control}
                            render={({
                                field: { name, onChange, value },
                                fieldState: { error },
                            }) => (
                                <>
                                    <CheckboxWithLabel
                                        name={name}
                                        label="I understand the customer has already received the tickets. I understand I must invalidate the tickets at the supplier."
                                        checked={value}
                                        onChange={onChange}
                                    />
                                    <FormFieldError message={error?.message} />
                                </>
                            )}
                        />
                    </Grid>
                </Grid>
            )}

            <div className={spacingClasses.spacingVertical}>
                <ErrorsList errors={errors} />
            </div>

            <FormButtons
                buttons={[
                    {
                        children: forceMode ? 'Force revoke' : 'Revoke',
                        onClick: handleSubmit(onSubmit),
                        disabled: loading,
                    },
                ]}
            />
        </>
    );
};
