import { CSSProperties } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { ChildlessBaseComponent } from 'src/view/interfaces/BaseComponent';

const useStyles = makeStyles((theme: Theme) => ({
    brs: {
        display: 'flex',
        '& div': {
            minWidth: theme.spacing(2),
            textAlign: 'center',
        },
        textTransform: 'uppercase',
    },
    text: (props: Partial<BlockRowSeatProps>) => {
        let color = theme.palette.primary.main;

        if (props.color) {
            color = props.color;
        }

        return {
            color: color,
        };
    },
    grey: {
        color: theme.colors.grey,
    },
    divider: {
        width: 0,
        marginLeft: theme.spacing(1.25),
        marginRight: theme.spacing(1.25),
        border: '1px solid',
        borderColor: theme.colors.mediumGrey,
    },
}));

export interface BlockRowSeatProps extends ChildlessBaseComponent {
    block?: string;
    row?: string;
    seat?: string;
    showSeat?: boolean;
    color?: CSSProperties['color'];
}

export default function BlockRowSeat({
    block,
    row,
    seat,
    showSeat = true,
    color,
}: BlockRowSeatProps): JSX.Element {
    const classes = useStyles({ color });

    const EMPTY_CELL_VALUE = '--';

    const blockRenderer = block ? (
        <div className={classes.text}>{block}</div>
    ) : (
        <div className={classes.grey}>{EMPTY_CELL_VALUE}</div>
    );
    const rowRenderer = row ? (
        <div className={classes.text}>{row}</div>
    ) : (
        <div className={classes.grey}>{EMPTY_CELL_VALUE}</div>
    );
    const seatRenderer = seat ? (
        <div className={classes.text}>{seat}</div>
    ) : (
        <div className={classes.grey}>{EMPTY_CELL_VALUE}</div>
    );

    return (
        <div className={classes.brs}>
            {blockRenderer}
            <span className={classes.divider} />
            {rowRenderer}
            {showSeat && (
                <>
                    <span className={classes.divider} />
                    {seatRenderer}
                </>
            )}
        </div>
    );
}
