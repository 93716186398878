import { useEffect } from 'react';
import useApiFetch from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import PaginatedApiResponseBody from 'src/data/api/responses/PaginatedApiResponseBody';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import ParsedTicket from 'src/data/models/tickets/parsedTicket';
import ticketManagementService from 'src/data/services/ticketManagementService';
import { config } from 'src/app/constants/config/config';
import PaginationMeta from 'src/data/api/responses/PaginationMeta';

interface FetchEticketsDataWrapperChildrenParams
    extends DataWrapperChildrenParams<PaginatedApiResponseBody<ParsedTicket>> {
    fetchEtickets: () => Promise<PaginatedApiResponseBody<ParsedTicket, PaginationMeta>>;
}

type FetchEticketsDataWrapperProps = DataWrapperProps<
    FetchEticketsDataWrapperChildrenParams,
    PaginatedApiResponseBody<ParsedTicket>
> &
    BaseFetchOptions;

export const useFetchETicketsDataWrapper = ({
    onError,
    onSuccess,
    page,
    pageSize = config.ITEMS_PER_PAGE_STANDARD,
    q,
}: Omit<FetchEticketsDataWrapperProps, 'children'>) => {
    const [{ loading, data, errors, resetData }, handleFetchETickets] =
        useApiFetch<PaginatedApiResponseBody<ParsedTicket>>();

    const fetchETicketsCall = () => {
        return handleFetchETickets(
            ticketManagementService.getParsedTickets({
                page,
                pageSize,
                q,
            }),
            {
                useDefaultErrorHandler: true,
                onFail: onError,
                onSuccess,
            }
        );
    };

    useEffect(() => {
        fetchETicketsCall();
    }, [page, pageSize, q]);

    return {
        fetchEtickets: fetchETicketsCall,
        data,
        loading,
        errors,
        resetData,
    };
};

export const FetchETicketsDataWrapper = (props: FetchEticketsDataWrapperProps) => {
    const { children } = props;
    const hookData = useFetchETicketsDataWrapper(props);

    if (!children) return <></>;

    return <>{children(hookData)}</>;
};
