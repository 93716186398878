import { getInternalAxiosInstance } from 'src/data/api/api';
import type BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import { getQueryParams } from 'src/data/api/endpointUrlBuilder';
import type PaginatedApiResponseBody from 'src/data/api/responses/PaginatedApiResponseBody';
import { GET_ACTIVITY_LOGS_ENDPOINT } from '../constants/endpoints/activity-logs-endpoints';

export interface ActivityLog {
    affectedField: string;
    changeType: string;
    entityId: string;
    entityType: string;
    newStatus: string;
    oldStatus: string | null;
    updatedAt: string;
    createdAt: string;
    userFullName: string;
    userId: string;
    userMail: string;
}

export type ActivityLogsQuery = {
    groupingEntityId?: string;
    entityId?: string;
};

export async function getActivityLogs(
    activityLogParams: ActivityLogsQuery,
    options?: BaseFetchOptions
) {
    const baseFetchParams = options && getQueryParams(options);
    return await getInternalAxiosInstance().get<PaginatedApiResponseBody<ActivityLog>>(
        GET_ACTIVITY_LOGS_ENDPOINT,
        {
            params: {
                ...baseFetchParams,
                ...activityLogParams,
            },
        }
    );
}
