import makeStyles from '@mui/styles/makeStyles';

export const useFilterFormStyles = makeStyles((theme) => ({
    filterSpacing: {
        margin: theme.spacing(2, 0),
    },
    filterPlaceholderSpacing: {
        paddingRight: theme.spacing(3),
    },
    filterSearchInputPosition: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginLeft: 'auto',
    },
    filterSearchInput: {
        maxWidth: 200,
        width: 200,
    },
    filterFromCreditCardSearchInput: {
        maxWidth: 100,
        width: 100,
    },
}));
