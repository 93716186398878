export enum SHIPPING_STATUS {
    NOT_SENT = 'NotSent',
    SENT = 'Sent',
    UNKNOWN = 'Unknown',
}

export enum ORDERLINE_STATUS {
    ACTIVE = 'Active',
    CANCELLED = 'Cancelled',
    UNKNOWN = 'Unknown',
}

export enum ORDERLINE_TYPE {
    TICKET_PROMISE_ORDERLINE = 'TicketPromiseOrderLine',
    TICKET_ORDERLINE = 'TicketOrderLine',
}
