import { CircularProgress, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { SeriesGroupsAsyncAutoCompleteDataWrapper } from 'src/app/components/data-wrappers/series-groups/SeriesGroupsAsyncAutoCompleteDataWrapper';
import AutoComplete from 'src/view/components/auto-complete/AutoComplete';
import { AutoCompleteOption } from 'src/view/components/auto-complete/interfaces';
import FormButtons from 'src/view/components/form/FormButtons';
import { FormLabel } from 'src/view/components/form/FormLabel';
import Input from 'src/view/components/input/Input';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';

export interface SeriesDetailsFormValues {
    name: string;
    seriesGroup?: AutoCompleteOption;
    active: AutoCompleteOption;
    eventsCount: number;
}

export const seriesStatusOptions = [
    { label: 'Active', value: true },
    { label: 'inActive', value: false },
];

interface Props {
    seriesGroupId?: string;
    dataLoading?: boolean;
    actionLoading?: boolean;
    defaultValues?: SeriesDetailsFormValues;
    disableEdit?: boolean;
    onSubmit: (values: SeriesDetailsFormValues) => void;
    onDeleteSeries: () => void;
    disableDelete?: boolean;
}

export default function SerieDetailsForm({
    dataLoading,
    actionLoading,
    defaultValues,
    disableEdit = true,
    onSubmit,
    onDeleteSeries,
    seriesGroupId,
    disableDelete,
}: Props) {
    const theme = useTheme();

    const form = useForm<SeriesDetailsFormValues>({
        mode: 'onChange',
        defaultValues,
    });

    const {
        getValues,
        control,
        reset,
        formState: { isDirty },
    } = form;

    const isSubmitButtonDisabled = actionLoading || !isDirty;

    useEffect(() => {
        if (!defaultValues) return;

        reset(defaultValues);
    }, [defaultValues, reset]);

    const name = getValues().name,
        eventsCount = getValues().eventsCount;

    if (dataLoading) {
        return <LoadingOverlay />;
    }

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormLabel>Name</FormLabel>
                    <Controller
                        name="name"
                        control={control}
                        render={({ field: { name, onChange, value } }) => {
                            return (
                                <Input
                                    name={name}
                                    onChange={onChange}
                                    value={value}
                                    placeholder="Series name"
                                />
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormLabel>Series Group</FormLabel>

                    <Controller
                        name="seriesGroup"
                        control={control}
                        render={({ field: { name, onChange, value } }) => {
                            return (
                                <SeriesGroupsAsyncAutoCompleteDataWrapper
                                    initialValueId={seriesGroupId}
                                    name={name}
                                    onChange={onChange}
                                    value={value}
                                />
                            );
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormLabel>Events count</FormLabel>
                    <Input name={name} value={eventsCount} disabled />
                </Grid>
                <Grid item xs={12}>
                    <FormLabel>Active</FormLabel>
                    <Controller
                        name="active"
                        control={control}
                        render={({ field: { name, onChange, value: status } }) => {
                            return (
                                <AutoComplete
                                    name={name}
                                    value={status}
                                    options={seriesStatusOptions}
                                    onChange={onChange}
                                    isClearable={false}
                                />
                            );
                        }}
                    />
                </Grid>
            </Grid>
            {!disableEdit && (
                <FormButtons
                    justifyContent="space-between"
                    buttons={[
                        {
                            children: 'Delete Series',
                            onClick: onDeleteSeries,
                            variant: 'text',
                            color: 'error',
                            disabled: disableDelete,
                        },
                        {
                            children: 'Save Series',
                            onClick: form.handleSubmit(onSubmit),
                            disabled: isSubmitButtonDisabled,
                            startIcon: actionLoading && (
                                <CircularProgress size={theme.layout.loader.sizes.small} />
                            ),
                        },
                    ]}
                />
            )}
        </>
    );
}
