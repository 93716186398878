import { getInternalAxiosInstance } from 'src/data/api/api';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import QueryParam from 'src/data/api/common/QueryParam';
import { getQueryParams } from 'src/data/api/endpointUrlBuilder';
import ApiResponse from 'src/data/api/responses/ApiResponse';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import PaginatedApiResponseBody from 'src/data/api/responses/PaginatedApiResponseBody';
import { EVENTS_GET_ORDERLINES_ENDPOINT } from 'src/data/constants/endpoints/events-endpoints';
import {
    ORDERS_ASSIGN_TICKET_FOR_ORDERLINE_ENDPOINT,
    ORDERS_GET_AUTO_ASSIGN_OPTIONS,
    ORDERS_GET_ORDERLINE_BY_ID_ENDPOINT,
    ORDERS_ORDERLINE_UNASSIGN_TICKETS,
    ORDERS_POST_AUTO_ASSIGN,
    ORDERS_POST_AUTO_ASSIGN_RESULT,
    ORDERS_UPDATE_ORDERLINE_ASSIGNMENT_INSTRUCTIONS_ENDPOINT,
} from 'src/data/constants/endpoints/orders-endpoints';
import { EmptyBody } from 'src/data/models/common/emptyBody';
import { AssignedOrderline } from 'src/data/models/orderline/assigned-orderline';
import Orderline from 'src/data/models/orderline/orderline';

export interface PurchasesFetchOptions extends BaseFetchOptions {
    q?: string;
}

async function fetchOrderlines(
    eventId: string,
    options: PurchasesFetchOptions
): Promise<ApiResponse<PaginatedApiResponseBody<Orderline>>> {
    const extraQueryParams: QueryParam[] = [
        {
            key: 'include',
            value: 'orderSummary',
        },
    ];

    if (options.q) {
        extraQueryParams.push({
            key: 'q',
            value: options.q,
        });
    }

    return await getInternalAxiosInstance()
        .get<PaginatedApiResponseBody<Orderline>>(EVENTS_GET_ORDERLINES_ENDPOINT(eventId), {
            params: options && getQueryParams(options, extraQueryParams),
        })
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function fetchOrderlineById(orderId: string, orderLineId: string) {
    return await getInternalAxiosInstance()
        .get<ApiResponseBody<Orderline>>(ORDERS_GET_ORDERLINE_BY_ID_ENDPOINT(orderId, orderLineId))
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export interface UpdateOrderlineAssignmentInstructionsDto {
    assignmentInstructions?: string;
}

async function updateOrderlineAssignmentInstructions(
    orderId: string,
    orderLineId: string,
    dto: UpdateOrderlineAssignmentInstructionsDto
) {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<EmptyBody>>(
            ORDERS_UPDATE_ORDERLINE_ASSIGNMENT_INSTRUCTIONS_ENDPOINT(orderId, orderLineId),
            dto
        )
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export interface AssignTicketsDto {
    ticketIds: string[];
}

export interface OrderLineIdsDto {
    orderLineIds: string[];
}

async function assignTickets(orderId: string, orderlineId: string, dto: AssignTicketsDto) {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<EmptyBody>>(
            ORDERS_ASSIGN_TICKET_FOR_ORDERLINE_ENDPOINT(orderId, orderlineId),
            dto
        )
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function unassignTickets(OrderLineIds: OrderLineIdsDto) {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<EmptyBody>>(ORDERS_ORDERLINE_UNASSIGN_TICKETS, OrderLineIds)
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export const AUTO_ASSIGN_ALGORITHM_TYPES = {
    AUTOMATIC_ORDERLINE_ASSIGNER: 'AutomaticOrderLineAssigner',
    BIN_PACKING_ASSIGNER: 'BinPackingAssigner',
} as const;

export type AutoAssignAlgorithmTypesArray = [
    typeof AUTO_ASSIGN_ALGORITHM_TYPES.AUTOMATIC_ORDERLINE_ASSIGNER,
    typeof AUTO_ASSIGN_ALGORITHM_TYPES.BIN_PACKING_ASSIGNER
];

export interface AutoAssignOptionsResponse {
    algorithmOptions: AutoAssignAlgorithmTypesArray;
    seatingPlanCategoryResult: {
        id: string;
        name: string;
    }[];
}

export interface AutoAssignOptionsFetchOptions extends BaseFetchOptions {
    eventId: string;
}

async function getAutoAssignOptions(options: AutoAssignOptionsFetchOptions) {
    const extraQueryParams: QueryParam[] = [];

    if (options.eventId) {
        extraQueryParams.push({
            key: 'eventId',
            value: options.eventId,
        });
    }

    return await getInternalAxiosInstance()
        .get<ApiResponseBody<AutoAssignOptionsResponse>>(ORDERS_GET_AUTO_ASSIGN_OPTIONS, {
            params: options && getQueryParams({}, extraQueryParams),
        })
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export interface AutoAssignDto {
    seatingplanCategoryId: string;
    selectedAlgorithm: string;
    seatGuarantee: number;
}

async function autoAssign(options: AutoAssignOptionsFetchOptions, dto: AutoAssignDto) {
    const extraQueryParams: QueryParam[] = [];

    if (options.eventId) {
        extraQueryParams.push({
            key: 'eventId',
            value: options.eventId,
        });
    }

    return await getInternalAxiosInstance()
        .post<ApiResponseBody<EmptyBody>>(ORDERS_POST_AUTO_ASSIGN, dto, {
            params: options && getQueryParams({}, extraQueryParams),
        })
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export interface AutoAssignResultResponse {
    seatingPlanCategoryId: string;
    seatingPlanCategoryName: string;
    seatGuarantee: number;
    totalTickets: number;
    totalTicketsOrdered: number;
    totalTicketsAssigned: number;
    totalOrderLines: number;
    totalOrderLinesFullyAssigned: number;
    totalSplits: number;
    totalSplitsFullyUsed: number;
    algorithmUsed: string;
    assignedOrderLines: AssignedOrderline[];
    categoryContainsLockedTicket: boolean;
    totalLockedTicketsInCategory: number;
}

async function getAutoAssignResult(options: AutoAssignOptionsFetchOptions) {
    const extraQueryParams: QueryParam[] = [];

    if (options.eventId) {
        extraQueryParams.push({
            key: 'eventId',
            value: options.eventId,
        });
    }

    return await getInternalAxiosInstance()
        .get<ApiResponseBody<AutoAssignResultResponse>>(ORDERS_POST_AUTO_ASSIGN_RESULT, {
            params: options && getQueryParams({}, extraQueryParams),
        })
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export default {
    fetchOrderlines,
    fetchOrderlineById,
    updateOrderlineAssignmentInstructions,
    assignTickets,
    unassignTickets,
    getAutoAssignOptions,
    autoAssign,
    getAutoAssignResult,
};
