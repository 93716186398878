import { Box } from '@mui/material';
import { type UseQueryResult } from '@tanstack/react-query';
import { useMemo } from 'react';
import CreatePurchaseDetailsForm, {
    PurchaseDetailsFormValues,
    type CreatePurchaseDetailsFormProps,
} from 'src/app/components/forms/CreatePurchaseDetailsForm';
import { useCancelPurchase } from 'src/app/hooks/purchases/useCancelPurchase';
import { useCompletePurchase } from 'src/app/hooks/purchases/useCompletePurchase';
import { useUpdatePurchase } from 'src/app/hooks/purchases/useUpdatePurchase';
import { useCustomConfirm } from 'src/app/hooks/useCustomConfirm';
import { parseErrors } from 'src/app/utilities/helpers/errors';
import type ApiResponse from 'src/data/api/responses/ApiResponse';
import type ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import { PURCHASE_STATUS } from 'src/data/enums/purchase';
import type Purchase from 'src/data/models/purchase/Purchase';
import { UpdatePurchaseDto } from 'src/data/services/purchaseService';
import ErrorsList from 'src/view/components/errors-list/ErrorsList';
import LoadingSpinner from 'src/view/components/loading-overlay/LoadingSpinner';

export interface PurchaseDetailsFeatureProps {
    purchaseId: string;
    onActionSucceeded?: () => void;
    showActionButtons?: boolean;
    isContractTicket?: boolean;
    form: CreatePurchaseDetailsFormProps['form'];
    purchaseData: UseQueryResult<ApiResponse<ApiResponseBody<Purchase>>, unknown>;
}

export default function PurchaseDetailsFeature({
    purchaseId,
    onActionSucceeded,
    showActionButtons = true,
    isContractTicket = false,
    form,
    purchaseData,
}: PurchaseDetailsFeatureProps) {
    const confirm = useCustomConfirm();

    const purchaseDetailsFormOnSubmit = (values: PurchaseDetailsFormValues) => {
        const paymentMethodDto = values.paymentMethod?.value
            ? {
                  type: values.paymentMethod?.value,
                  creditCardInfo: values.creditcardNumber,
              }
            : undefined;

        const updatePurchaseDto: UpdatePurchaseDto = {
            paymentMethod: paymentMethodDto,
            notes: values.internalNotes,
            externalReferenceId: values.externalReference,
            finalized: !!values.finalized,
            purchaseType: values.purchaseType?.value,
        };

        updatePurchase({ purchaseId, updatePurchaseDto });
    };

    const {
        mutate: completePurchase,
        error: completePurchaseErrors,
        isLoading: isCompletingPurchase,
    } = useCompletePurchase(onActionSucceeded);

    const {
        mutate: cancelPurchase,
        error: cancelPurchaseErrors,
        isLoading: isCancellingPurchase,
    } = useCancelPurchase(onActionSucceeded);

    const {
        mutate: updatePurchase,
        error: updatePurchaseErrors,
        isLoading: isUpdatingPurchase,
        isError: isUpdatingPurchaseError,
    } = useUpdatePurchase(onActionSucceeded);

    const mapPurchaseInformationToDictionaryValues = useMemo(
        () => ({
            numTickets: purchaseData.data?.data.data.numTickets,
            numSplits: purchaseData.data?.data.data.numSplits,
            status: purchaseData.data?.data.data.status,
        }),
        [purchaseData]
    );

    if (purchaseData.isLoading) {
        return (
            <Box display="flex" p={4} alignItems="center" justifyContent="center">
                <LoadingSpinner />
            </Box>
        );
    }

    return (
        <>
            {purchaseData.isError && <ErrorsList errors={parseErrors(purchaseData.error)} />}
            {isUpdatingPurchaseError && <ErrorsList errors={parseErrors(updatePurchaseErrors)} />}

            <CreatePurchaseDetailsForm
                form={form}
                editMode
                isContractTicket={isContractTicket}
                isPurchaseStatusCompleted={
                    purchaseData?.data?.data.data.status === PURCHASE_STATUS.Completed
                }
                isPurchaseStatusCancelled={
                    purchaseData?.data?.data.data.status === PURCHASE_STATUS.Cancelled
                }
                informationDictionaryValues={mapPurchaseInformationToDictionaryValues}
                formSubmitText="Update Purchase"
                onSubmit={purchaseDetailsFormOnSubmit}
                onCancel={async () => {
                    const result = await confirm({
                        title: 'Are you sure you want to cancel this purchase?',
                        description: "It's possible to cancel contract and non-contract purchases",
                    });

                    if (result) cancelPurchase(purchaseId);
                }}
                onComplete={async () => {
                    const result = await confirm({
                        title: 'Are you sure you want to complete this purchase?',
                        description:
                            "The purchase will be completed and this can't be changed afterwards",
                    });

                    if (result) completePurchase(purchaseId);
                }}
                loading={isUpdatingPurchase}
                cancelIsLoading={isCancellingPurchase}
                completeIsLoading={isCompletingPurchase}
                showActionButtons={showActionButtons}
                completeErrors={parseErrors(completePurchaseErrors)}
                cancelErrors={parseErrors(cancelPurchaseErrors)}
                purchaseId={purchaseId}
            />
        </>
    );
}
