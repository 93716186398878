import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import makeStyles from '@mui/styles/makeStyles';
import { getStatusIndicators } from 'src/app/utilities/helpers/unprocessedTicketFileStatus';
import UnprocessedTicketFile, {
    TicketFileStatus,
} from 'src/data/models/tickets/unprocessedTicketFile';
import { SIZES } from 'src/shared/theme/enums';
import Button from 'src/view/components/button/Button';
import Chip from 'src/view/components/chip/Chip';
import StatusLabel from 'src/view/components/status-symbol/StatusSymbol';
import Table, { RowIdResolver } from 'src/view/components/table/table/Table';
import {
    NewTableColumn,
    TABLE_CELL_WIDTH,
    TableColumnSorting,
    TablePaginationData,
} from 'src/view/components/table/table/Types';
import IconComponent from 'src/view/icons/IconComponent';

const useStyles = makeStyles(() => ({
    fileRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}));

export interface UnprocessedTicketFilesTableProps {
    defaultSorting?: TableColumnSorting[];
    onChangeSorting?: (sortings: TableColumnSorting[]) => void;
    unprocessedTicketFiles?: UnprocessedTicketFile[];
    loading?: boolean;
    pagination?: TablePaginationData;
    enableCheckboxes?: boolean;
    higlightRow?: (unprocessedTicketFile: UnprocessedTicketFile) => boolean;
    onLinkTicketClick?: (file: UnprocessedTicketFile, index: number | undefined) => void;
    onDeleteClick: (
        data: RowIdResolver<{
            id: string;
            block: string | null;
            row: string | null;
            seat: string | null;
            confidence: number;
            status: TicketFileStatus;
            fileName: string;
        }>[]
    ) => void;
    onMassLinkClick: (
        data: RowIdResolver<{
            id: string;
            block: string | null;
            row: string | null;
            seat: string | null;
            confidence: number;
            status: TicketFileStatus;
            fileName: string;
        }>[]
    ) => void;
    initialSelectedRows: RowIdResolver<UnprocessedTicketFile>[];
    onChangeSelectedRows: (data: RowIdResolver<UnprocessedTicketFile>[]) => void;
}

export default function UnprocessedTicketFilesTable({
    loading,
    unprocessedTicketFiles = [],
    pagination,
    enableCheckboxes = true,
    onLinkTicketClick,
    onDeleteClick,
    onMassLinkClick,
    initialSelectedRows,
    onChangeSelectedRows,
}: UnprocessedTicketFilesTableProps): JSX.Element {
    const classes = useStyles();

    const cols: NewTableColumn<UnprocessedTicketFile>[] = [
        {
            title: 'Ticket File',
            width: TABLE_CELL_WIDTH.SMALL,
            cellRenderer: (rowData) => (
                <Chip
                    variant="outlined"
                    rounded
                    tinyChip
                    label={<div>{rowData.fileName}</div>}
                    icon={<AttachFileOutlinedIcon />}
                />
            ),
        },
        {
            key: 'confidence',
            title: 'Confidence',
            width: TABLE_CELL_WIDTH.SMALL,
            cellRenderer: (rowData) => {
                const variant = rowData.confidence > 50 ? 'success' : 'error';

                if (rowData.status !== 'Linked') {
                    return (
                        <div className={classes.fileRow}>
                            <StatusLabel label="" variant={variant} />
                            {rowData.confidence}%
                        </div>
                    );
                }

                return <></>;
            },
        },
        {
            key: 'status',
            title: 'Status',
            cellRenderer: (rowData) => {
                const iconProps = getStatusIndicators(rowData.status);

                return (
                    <div className={classes.fileRow}>
                        <IconComponent
                            Icon={iconProps.icon}
                            color={iconProps.color}
                            size={SIZES.LARGE}
                        />
                        {rowData.status}
                    </div>
                );
            },
        },
        {
            key: 'exceptionDetail',
            title: 'Exception Detail',
            cellRenderer: (rowData) => {
                return (
                    <div className={classes.fileRow}>
                        {rowData.exceptionDetail}
                    </div>
                );
            },
        },
        {
            key: 'fileName',
            title: 'Link',
            cellRenderer: (rowData, _, rowIndex) => {
                return onLinkTicketClick ? (
                    <Button
                        disabled={rowData.status == 'Linked'}
                        onClick={() => onLinkTicketClick(rowData, rowIndex)}
                    >
                        Link to Ticket
                    </Button>
                ) : (
                    <></>
                );
            },
        },
    ];

    return (
        <Table
            data={unprocessedTicketFiles}
            rowIdResolver={(rowData: UnprocessedTicketFile) => ({
                id: rowData.id,
                data: {
                    id: rowData.id,
                    block: rowData.block,
                    row: rowData.row,
                    seat: rowData.seat,
                    confidence: rowData.confidence,
                    status: rowData.status,
                    fileName: rowData.fileName,
                    url: rowData.url,
                    keyValues: rowData.keyValues,
                    exceptionDetail: rowData.exceptionDetail,
                },
            })}
            columns={cols}
            loading={loading}
            minWidth={900}
            enableCheckboxes={enableCheckboxes}
            pagination={pagination}
            disableCheckbox={(row) => row.status === 'Linked'}
            tableToolbarActions={(selectedRows) => {
                return [
                    {
                        label:
                            selectedRows.length === 1
                                ? 'Link file'
                                : 'Mass link files',
                        position: 'right',
                        callback: () => onMassLinkClick(selectedRows),
                        disabled: selectedRows.length === 0,
                    },
                    {
                        label:
                            selectedRows.length === 1
                                ? 'Delete ticket file'
                                : 'Delete ticket files',
                        position: 'right',
                        callback: () => onDeleteClick(selectedRows),
                        disabled: selectedRows.length === 0,
                        color: 'error'
                    },
                ];
            }}
            initialSelectedRows={initialSelectedRows}
            onChangeSelectedRows={onChangeSelectedRows}
        />
    );
}
