import { useCallback, useEffect, useState } from 'react';
import OrderlineDetailsForm, {
    mapInitialOrderlineDetailsFormValues,
    OrderlineDetailsFormValues,
} from 'src/app/components/forms/OrderlineDetailsForm';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import useApiFetch from 'src/app/hooks/useApiFetch';
import { EmptyBody } from 'src/data/models/common/emptyBody';
import Orderline from 'src/data/models/orderline/orderline';
import orderlineService, {
    UpdateOrderlineAssignmentInstructionsDto,
} from 'src/data/services/orderlineService';

interface Props {
    orderlineId: string;
    orderId: string;
    onClickAssign?: () => void;
    onActionSucceeded?: () => void;
    invalidatedAt?: number;
}

export default function OrderlineDetailsFeature({
    orderlineId,
    orderId,
    onClickAssign,
    onActionSucceeded,
    invalidatedAt,
}: Props) {
    const [editMode, setEditMode] = useState(false);

    const [{ loading: orderlineIsLoading, data: orderlineData }, handleFetchOrderlineById] =
        useApiFetch<ApiResponseBody<Orderline>>();

    const [
        { loading: updateAssignmentInstructionsIsLoading },
        handleUpdateOrderlineAssignmentInstructions,
    ] = useApiFetch<ApiResponseBody<EmptyBody>>();

    const orderline = orderlineData?.data;

    const [formDefaultValues, setFormDefaultValues] = useState<
        OrderlineDetailsFormValues | undefined
    >();

    useEffect(() => {
        handleFetchOrderlineById(orderlineService.fetchOrderlineById(orderId, orderlineId));
    }, [orderlineId, invalidatedAt, handleFetchOrderlineById, orderId]);

    useEffect(() => {
        if (!orderlineData) return;

        setFormDefaultValues(mapInitialOrderlineDetailsFormValues(orderlineData.data));
    }, [orderlineData]);

    const orderlineDetailsFormOnSubmit = useCallback(
        async (values: OrderlineDetailsFormValues) => {
            const { assignmentInstructions } = values;

            if (orderline && orderline.id) {
                const dto: UpdateOrderlineAssignmentInstructionsDto = {
                    assignmentInstructions,
                };

                setEditMode((prevState) => !prevState);

                handleUpdateOrderlineAssignmentInstructions(
                    orderlineService.updateOrderlineAssignmentInstructions(
                        orderline.orderId,
                        orderline.id,
                        dto
                    ),
                    {
                        useDefaultErrorHandler: true,
                        defaultSuccessMessage: 'Assignment instructions has been updated',
                        onSuccess: () => {
                            onActionSucceeded?.();
                        },
                    }
                );
            }
        },
        [handleUpdateOrderlineAssignmentInstructions, onActionSucceeded, orderline]
    );

    return (
        <OrderlineDetailsForm
            defaultValues={formDefaultValues}
            onFormSubmit={orderlineDetailsFormOnSubmit}
            onToggleEditMode={() => setEditMode((prevState) => !prevState)}
            loading={orderlineIsLoading || updateAssignmentInstructionsIsLoading}
            edit={editMode}
            onAssignButtonClick={onClickAssign}
            orderline={orderlineData?.data}
        />
    );
}
