import { useState } from 'react';
import { FetchEventsDataWrapper } from 'src/app/components/data-wrappers/event/FetchEventsDataWrapper';
import EventsPopoverTable from 'src/app/components/tables/EventsPopoverTable';
import Popover from 'src/view/components/popover/Popover';

interface EventsPopOverProps {
    purchaseId?: string;
    orderId?: string;
    text: string | number;
    linkText: string;
}

export const EventsPopOver = ({
    purchaseId,
    orderId,
    text,
    linkText,
}: EventsPopOverProps) => {
    const [page, setPage] = useState(1);

    return (
        <Popover text={text} trigger="click" boxPadding={0} maxWidth="unset">
            <FetchEventsDataWrapper purchaseId={purchaseId} orderId={orderId} page={page}>
                {({ loading, data }) => {
                    return (
                        <EventsPopoverTable
                            loading={loading}
                            events={data?.data || []}
                            linkText={linkText}
                            orderId={orderId}
                            pagination={{
                                currentPage: data?.meta.currentPage || 1,
                                onPaginate: (pageNumber) => setPage(pageNumber),
                                totalPages: data?.meta.totalPages || 1,
                            }}
                        />
                    );
                }}
            </FetchEventsDataWrapper>
        </Popover>
    );
};
