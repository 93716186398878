import urlService, { defaultCustomFields } from 'src/app/services/urlService';
import {
    filterOptionsToQueryParams,
    mapKeyStringObjectToFilterOptions,
} from 'src/app/utilities/helpers/filter';
import { ORDER_STATUS } from 'src/data/enums/order';

interface OrdersFilters {
    status?: ORDER_STATUS[];
}

const ordersFiltersDefault: OrdersFilters = {
    status: [ORDER_STATUS.Active],
};

const OrdersUrlService = {
    root(filters: OrdersFilters = ordersFiltersDefault) {
        return urlService.getOrders(
            `${filterOptionsToQueryParams({
                filterOptions: mapKeyStringObjectToFilterOptions(filters || ordersFiltersDefault),
                customFields: defaultCustomFields,
                suffix: 'orders',
            })}`
        );
    },
};

export default OrdersUrlService;
