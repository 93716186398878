import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';
import { useCreateTag } from 'src/app/hooks/useCreateTag';
import { GET_TAGS_QUERY_KEY, useGetTags } from 'src/app/hooks/useGetTags';
import { parseErrors } from 'src/app/utilities/helpers/errors';
import { TAG_TYPES } from 'src/data/enums/tagType';
import { type Tag } from 'src/data/models/tag/tag';
import AutoComplete, {
    type AutoCompleteProps,
} from 'src/view/components/auto-complete/AutoComplete';
import {
    type AutoCompleteOption,
    type AutoCompleteOptions,
} from 'src/view/components/auto-complete/interfaces';
import ErrorsList from 'src/view/components/errors-list/ErrorsList';

interface Props extends Omit<AutoCompleteProps, 'options' | 'loading' | 'onCreateOption'> {
    onError?: () => void;
    onTagCreated?: (option: AutoCompleteOption) => void;
    type: TAG_TYPES;
}

const mapTagsOptions = (data?: Tag[]): AutoCompleteOptions => {
    return (data ?? []).map((t) => ({
        label: t.name,
        value: t.id,
    }));
};

export const TagsAutocompleteFeature = ({
    type,
    onTagCreated,
    value,
    onChange,
    name,
    ...autocompleteProps
}: Props) => {
    const queryClient = useQueryClient();
    const [inputValue, setInputValue] = useState('');
    const {
        data: tagsResponse,
        isLoading: isTagsLoading,
        isError: isGetTagsError,
        error: getTagsError,
    } = useGetTags(type, { q: inputValue });
    const {
        mutateAsync: createTag,
        isLoading: isCreateTagLoading,
        isError: isCreateTagError,
        error: createTagError,
    } = useCreateTag({
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [GET_TAGS_QUERY_KEY, type],
            });
        },
    });

    const options = useMemo(() => {
        return mapTagsOptions(tagsResponse?.data.data);
    }, [tagsResponse]);

    const onCreateOption = useCallback(
        async (name: string) => {
            try {
                const result = await createTag({
                    type,
                    name,
                });
                const createdOption = { label: name, value: result.data.data.id };
                onTagCreated?.(createdOption);
            } catch (e) {
                /**
                 * The errors will be caught by the hooks and displayed below
                 * This try/catch block exists to prevent a unhandled runtime exception
                 */
            }
        },
        [createTag, onTagCreated]
    );

    const isLoading = isTagsLoading || isCreateTagLoading;

    return (
        <>
            {isGetTagsError && <ErrorsList errors={parseErrors(getTagsError)} />}
            {isCreateTagError && <ErrorsList errors={parseErrors(createTagError)} />}
            <AutoComplete
                {...autocompleteProps}
                loading={isLoading}
                name={name}
                options={options}
                onChange={onChange}
                onCreateOption={onCreateOption}
                value={value}
                onInputChange={(value, actionMeta) => {
                    setInputValue(value);
                    autocompleteProps.onInputChange?.(value, actionMeta);
                }}
            />
        </>
    );
};
