import { Alert } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useCallback, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import TicketDetailsFormFeature from 'src/app/components/data-wrappers/tickets/TicketDetailsFormFeature';
import PurchaseDetailsFeature from 'src/app/components/features/purchase/PurchaseDetailsFeature';
import { useFetchPurchaseById } from 'src/app/hooks/purchases/useFetchPurchaseById';
import currencyOptions from 'src/app/utilities/mappers/mapCurrenciesToOptions';
import mapPurchaseDataToViewModel from 'src/app/utilities/mappers/mapPurchaseDataToFormValues';
import Ticket from 'src/data/models/tickets/ticket';
import { Divider } from 'src/view/components/divider/Divider';
import { PurchaseDetailsFormValues } from '../../forms/CreatePurchaseDetailsForm';
import OrderDetailsFeature from './OrderDetailsFeature';

export interface TicketsTableDetailsFeatureProps {
    eventId: string;
    ticketId: string;
    showRelatedPurchase: boolean;
    showRelatedOrder: boolean;
    onDetailsUpdated?: () => void;
    form: UseFormReturn<PurchaseDetailsFormValues, unknown, undefined>;
}

const useStyles = makeStyles(() => ({
    noMarginTop: {
        marginTop: 0,
    },
}));

const OrderDetailsSection = ({
    orderId,
    ticketIsFetched,
}: {
    orderId: string | null;
    ticketIsFetched: boolean;
}) => {
    const classes = useStyles();

    return (
        <>
            <h2 className={classes.noMarginTop}>Order Details</h2>
            {orderId && <OrderDetailsFeature orderId={orderId} />}

            {!orderId && ticketIsFetched && (
                <Alert severity="info">Ticket is not assigned to an order</Alert>
            )}
        </>
    );
};

const initialValues: PurchaseDetailsFormValues = {
    purchaseDate: new Date(Date.now()),
    currency: currencyOptions[0],
};

export default function TicketsTableDetailsFeature({
    eventId,
    ticketId,
    showRelatedPurchase = false,
    showRelatedOrder = false,
    onDetailsUpdated,
    form,
}: TicketsTableDetailsFeatureProps) {
    const classes = useStyles();
    const [ticketIsFetched, setTicketIsFetched] = useState<boolean>(false);
    const [isContractTicket, setIsContractTicket] = useState<boolean>(false);
    const [purchaseId, setPurchaseId] = useState<string | null>(null);
    const [orderId, setOrderId] = useState<string | null>(null);

    const onFetchFinished = (purchaseId: string, orderId: string | null): void => {
        setPurchaseId(purchaseId);
        setOrderId(orderId);

        setTicketIsFetched(true);
    };

    const onTicketFetched = useCallback((ticket: Ticket) => {
        setIsContractTicket(!!ticket.isContractTicket);
    }, []);

    const purchaseData = useFetchPurchaseById(purchaseId ?? '', {
        enabled: !!purchaseId,
        onSuccess: (response) => {
            form.reset(
                { ...initialValues, ...mapPurchaseDataToViewModel(response.data?.data) },
                {
                    keepTouched: false,
                    keepDefaultValues: false,
                    keepDirty: false,
                    keepDirtyValues: false,
                    keepErrors: false,
                    keepIsSubmitSuccessful: false,
                    keepIsSubmitted: false,
                    keepIsValid: false,
                    keepSubmitCount: false,
                    keepValues: false,
                }
            );
        },
    });

    const renderTicketDetailsSection = (): JSX.Element => {
        return (
            <>
                <h2 className={classes.noMarginTop}>Ticket Details</h2>
                <TicketDetailsFormFeature
                    eventId={eventId}
                    ticketId={ticketId}
                    onFetchFinished={onFetchFinished}
                    onTicketUpdated={onDetailsUpdated}
                    onTicketFetched={onTicketFetched}
                />
            </>
        );
    };

    const renderPurchaseDetailsSection = (): JSX.Element => {
        if (!showRelatedPurchase) return <></>;

        return (
            <>
                <h2 className={classes.noMarginTop}>Purchase Details</h2>
                {purchaseId && (
                    <PurchaseDetailsFeature
                        form={form}
                        purchaseData={purchaseData}
                        purchaseId={purchaseId}
                        onActionSucceeded={onDetailsUpdated}
                        showActionButtons={false}
                        isContractTicket={isContractTicket}
                    />
                )}

                {!purchaseId && ticketIsFetched && (
                    <Alert severity="info">Ticket is not assigned to an purchase</Alert>
                )}
            </>
        );
    };

    return (
        <>
            {renderTicketDetailsSection()}
            <Divider />
            {renderPurchaseDetailsSection()}
            <Divider />
            {showRelatedOrder && (
                <OrderDetailsSection orderId={orderId} ticketIsFetched={ticketIsFetched} />
            )}
        </>
    );
}
