import { ENDPOINT_VERSIONS } from './_endpoint-versions';

export const TEAMS_GET_ENDPOINT = `/${ENDPOINT_VERSIONS.V2}/teams`;

export const TEAMS_CREATE_ENDPOINT = `/${ENDPOINT_VERSIONS.V2}/teams/create`;

export const TEAMS_DELETE_ENDPOINT = `/${ENDPOINT_VERSIONS.V2}/teams/delete`;

export const TEAMS_UPDATE_ENDPOINT = (teamId: string) =>
    `/${ENDPOINT_VERSIONS.V2}/teams/${teamId}/update`;
