const eventTabs = {
    DETAILS: 'details',
    ORDER_LINES: 'order-lines',
    AUTO_ASSIGN: 'auto-assign',
    PURCHASES: 'purchases',
    TICKETS: 'tickets',
    ORDERS: 'orders',
    SPLITS: 'splits',
    TTI: 'tti',
};

export default eventTabs;
