import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Alert, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useConfirm } from 'material-ui-confirm';
import { useState } from 'react';
import useDeleteSeatingplan from 'src/app/hooks/seating-plans/useDeleteSeatingplan';
import SeatingPlan from 'src/data/models/seating-plan/SeatingPlan';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';
import { Preview } from 'src/view/components/preview/Preview';
import PreviewItem from 'src/view/components/preview/PreviewItem';
import { PreviewLabel } from 'src/view/components/preview/PreviewLabel';
import { CreateEditSeatingPlanModal } from '../../features/venues/CreateEditSeatingPlanModal';

/** TODO: Remove this makeStyles */
const useStyles = makeStyles((theme) => ({
    previewItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    previewItemLabel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    actionsContainer: {
        display: 'flex',
        flex: 1,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    editButton: {
        marginLeft: theme.spacing(1),
    },
    viewAction: {
        color: theme.colors.linkBlue,
        textDecoration: 'underline',
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));

interface VenueSeatingplansPreviewProps {
    seatingPlans: SeatingPlan[];
    venueId: string;
    activeSeatingPlanId?: string;
    onClickItem: (id: string) => void;
}

export const SeatingPlansPreview = ({
    seatingPlans,
    venueId,
    onClickItem,
    activeSeatingPlanId,
}: VenueSeatingplansPreviewProps) => {
    const classes = useStyles();
    const confirm = useConfirm();

    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [currentSeatingPlan, setCurrentSeatingPlan] = useState<SeatingPlan | undefined>();

    const { mutate: deleteSeatingplan, isLoading } = useDeleteSeatingplan();

    if (seatingPlans.length === 0) {
        return <Alert severity="info">This venue does not have any seating plans yet</Alert>;
    }

    return (
        <>
            <Preview>
                {seatingPlans.map((s, index) => (
                    <PreviewItem
                        id={s.id}
                        key={`${s.id}-${index}`}
                        index={index}
                        className={classes.previewItem}
                        selected={activeSeatingPlanId === s.id}
                    >
                        {isLoading && <LoadingOverlay />}

                        <div className={classes.previewItemLabel}>
                            <PreviewLabel label={s.name} />
                            <div className={classes.actionsContainer}>
                                <div
                                    onClick={() => onClickItem(s.id)}
                                    className={classes.viewAction}
                                >
                                    view categories
                                </div>

                                <IconButton
                                    onClick={() => {
                                        setCurrentSeatingPlan({ id: s.id, name: s.name });
                                        setIsEditModalOpen(true);
                                    }}
                                    className={classes.editButton}
                                    color="primary"
                                >
                                    <EditIcon />
                                </IconButton>

                                <IconButton
                                    onClick={async () => {
                                        try {
                                            await confirm({
                                                title: `Are you sure you want to delete ${s.name}?`,
                                                description: 'This action cannot be undone.',
                                            });

                                            deleteSeatingplan(s.id);
                                        } catch (error) {
                                            /* User canceled */
                                        }
                                    }}
                                    className={classes.editButton}
                                    color="error"
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </div>
                        </div>
                    </PreviewItem>
                ))}
            </Preview>

            {isEditModalOpen && (
                <CreateEditSeatingPlanModal
                    venueId={venueId}
                    seatingPlan={currentSeatingPlan}
                    setIsModalOpenState={setIsEditModalOpen}
                />
            )}
        </>
    );
};
