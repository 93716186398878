import { ChildlessBaseComponent } from 'src/view/interfaces/BaseComponent';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
    label: {
        marginRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightBold,
        fontSize: theme.typography.pxToRem(15),
    },
}));

interface PreviewLabelProps extends ChildlessBaseComponent {
    label: string | JSX.Element;
}

export const PreviewLabel = ({ label, className }: PreviewLabelProps) => {
    const classes = useStyles();

    return <div className={classnames(className, classes.label)}>{label}</div>;
};
