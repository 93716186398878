import { Navigate } from 'react-router-dom';
import urlService from 'src/app/services/urlService';
import LoginPage from 'src/app//pages/authentication/Login';
import authService from 'src/data/services/authService';

export const LoginRoute = () => {
    if (authService.loggedIn()) return <Navigate to={urlService.root()} />;

    return <LoginPage />;
};
