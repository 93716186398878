interface TabPanelProps {
    children?: React.ReactNode;
    value: string;
    activeTab: string;
}

export function TabPanel(props: TabPanelProps): JSX.Element {
    const { children, value, activeTab, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={activeTab !== value}
            id={`simple-tabpanel-${value}`}
            aria-labelledby={`simple-tab-${value}`}
            {...other}
        >
            {children}
        </div>
    );
}
