import { Typography } from '@mui/material';
import { useMemo } from 'react';
import { TicketFormModel } from 'src/app/components/features/tickets/BatchEditTicketFeature';
import { BatchEditTicketFormFeature } from 'src/app/components/features/tickets/BatchEditTicketFormFeature';
import Seat from 'src/data/models/seat/seat';
import { AutoCompleteOption } from 'src/view/components/auto-complete/interfaces';
import Table, { RowIdResolver } from 'src/view/components/table/table/Table';
import {
    NewTableColumn,
    TABLE_CELL_HEIGHT,
    TABLE_CELL_WIDTH,
} from 'src/view/components/table/table/Types';

export interface BatchEditTicketsTableProps {
    seats: Seat[];
    tickets?: TicketFormModel[];
    loading?: boolean;
    onChangeSelectedRows?: (selectedRows: RowIdResolver[]) => void;
    initialSelectedRows?: RowIdResolver[];
    onChangeBlock: (ticketIndex: number, value: AutoCompleteOption | null) => void;
    onChangeRow: (ticketIndex: number, value: AutoCompleteOption | null) => void;
    onChangeSeat: (ticketIndex: number, value: string) => void;
    onActionSuccess?: () => void;
    enableCheckboxes?: boolean;
}

export default function BatchEditTicketsTable({
    seats,
    loading,
    tickets = [],
    onChangeSelectedRows,
    initialSelectedRows,
    onChangeBlock,
    onChangeRow,
    onChangeSeat,
    onActionSuccess,
    enableCheckboxes = true,
}: BatchEditTicketsTableProps): JSX.Element {
    const ticketsIndexMap = useMemo(() => {
        return new Map(tickets.map((ticket, index) => [ticket.id, index]));
    }, [tickets]);

    const columns: NewTableColumn<TicketFormModel>[] = useMemo(() => {
        return [
            {
                key: 'blockNumber',
                title: 'Block Row Seat',
                cellRenderer: (ticket) => {
                    const ticketIndex = tickets.findIndex((t) => t.id === ticket.id);

                    return (
                        <>
                            <Typography
                                marginRight={(theme) => theme.spacing(3)}
                                flexShrink={0}
                                color={(theme) => theme.colors.grey}
                            >
                                {ticketIndex + 1}
                            </Typography>
                            <BatchEditTicketFormFeature
                                variant="table"
                                seats={seats}
                                ticket={tickets[ticketIndex]}
                                onChangeBlockOption={(value) =>
                                    onChangeBlock(ticketIndex, value !== undefined ? value : null)
                                }
                                onChangeRowOption={(value) =>
                                    onChangeRow(ticketIndex, value !== undefined ? value : null)
                                }
                                onChangeSeatOption={(value) => onChangeSeat(ticketIndex, value)}
                                seatingplanCategoryId={tickets[ticketIndex].seatingplanCategoryId}
                                onBlockCreated={(data) => {
                                    onChangeBlock(ticketIndex, {
                                        label: data?.name,
                                        value: data?.id,
                                    });

                                    onActionSuccess?.();
                                }}
                                onRowCreated={(data) => {
                                    onChangeRow(ticketIndex, {
                                        label: data?.name,
                                        value: data?.id,
                                    });

                                    onActionSuccess?.();
                                }}
                            />
                        </>
                    );
                },
                width: TABLE_CELL_WIDTH.EXTRA_LARGE,
            },
            {
                key: 'seatingplanCategoryName',
                title: 'Seatingplancategory',
                cellRenderer: (ticket) => ticket.seatingplanCategoryName || '',
                width: TABLE_CELL_WIDTH.EXTRA_LARGE,
            },
        ];
    }, [tickets, seats, onChangeBlock, onChangeRow, onChangeSeat, onActionSuccess]);

    return (
        <Table<TicketFormModel>
            data={tickets}
            rowIdResolver={(rowData: TicketFormModel) => ({
                id: rowData.id,
            })}
            rowHeight={TABLE_CELL_HEIGHT.EXTRA_LARGE}
            columns={columns}
            loading={loading}
            minWidth={900}
            enableCheckboxes={enableCheckboxes}
            onChangeSelectedRows={(value) => {
                /** We are sorting the selection by the original index/position of the ticket.
                 * Example: 5 items are selected. 1,2,3,4 is de-selected.
                 * Upon selecting back the 1 and 2, the array looks like [5,1,2]
                 * The sorting below helps keeping the array like [1,2,5]
                 * Especially matters for the seatNumber, where we use index as an incrementor.
                 */
                const sortedSelectedRows = value
                    .map(({ id }) => ({
                        id,
                        index: ticketsIndexMap.get(id) || 0,
                    }))
                    .sort((a, b) => a.index - b.index);

                onChangeSelectedRows?.(sortedSelectedRows);
            }}
            initialSelectedRows={initialSelectedRows}
        />
    );
}
