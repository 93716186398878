import { zodResolver } from '@hookform/resolvers/zod';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { FormHelperText } from '@mui/material';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { TicketFormModel } from 'src/app/components/features/tickets/BatchEditTicketFeature';
import { zodOptionalDdropdownSchema } from 'src/app/utilities/zod/zodOptionalDdropdownSchema';
import { zodOptionalNumberInputSchema } from 'src/app/utilities/zod/zodOptionalNumberInputSchema';
import AutoComplete from 'src/view/components/auto-complete/AutoComplete';
import {
    AutoCompleteOptions,
    AutoCompleteValueOption,
} from 'src/view/components/auto-complete/interfaces';
import Button from 'src/view/components/button/Button';
import FormFieldError from 'src/view/components/form/FormFieldError';
import { FormLabel } from 'src/view/components/form/FormLabel';
import Input from 'src/view/components/input/Input';
import z from 'zod';

const useStyles = makeStyles((theme) => ({
    makeChangesButton: {
        marginRight: theme.spacing(2),
    },
    errorIcon: {
        height: 14,
        width: 14,
        marginRight: theme.spacing(0.5),
        fill: theme.colors.grey,
        marginTop: 2,
    },
    helperText: {
        color: theme.colors.grey,
    },
}));

const validationSchema = z.object({
    blockNumber: zodOptionalDdropdownSchema.nullish(),
    rowNumber: zodOptionalDdropdownSchema.nullish(),
    seatNumber: z.string().nullable().optional(),
    startSeat: zodOptionalNumberInputSchema,
    step: zodOptionalNumberInputSchema,
});

export type BatchEditTicketsFormValues = z.infer<typeof validationSchema>;

export interface BatchEditTicketsFormProps {
    ticket?: TicketFormModel;
    blockOptions: AutoCompleteOptions;
    onChangeBlockOption?: (value: AutoCompleteValueOption) => void;
    rowOptions: AutoCompleteOptions;
    onChangeRowOption?: (value: AutoCompleteValueOption) => void;
    onChangeSeatOption?: (value: string) => void;
    onFormSubmit?: (values: BatchEditTicketsFormValues) => void;
    onCreateBlock?: (input: string) => void;
    onCreateRow?: (input: string) => void;
    variant?: 'table' | 'standalone';
    block?: AutoCompleteValueOption;
    row?: AutoCompleteValueOption;
    seat?: string;
}

export const BatchEditTicketsForm = ({
    ticket,
    blockOptions,
    onChangeBlockOption,
    rowOptions,
    onChangeRowOption,
    onChangeSeatOption,
    onFormSubmit,
    onCreateBlock,
    onCreateRow,
    variant = 'standalone',
    block,
    row,
    seat,
}: BatchEditTicketsFormProps) => {
    const classes = useStyles();
    const form = useForm<BatchEditTicketsFormValues>({
        mode: 'onChange',
        resolver: zodResolver(validationSchema),
        defaultValues: {
            step: 1,
            seatNumber: seat,
        },
    });

    const {
        control,
        setValue,
        watch,
        handleSubmit,
        reset,
        clearErrors,
        formState: { isDirty },
    } = form;

    const isSubmitButtonDisabled = !isDirty;

    const blockValue = watch('blockNumber');
    const rowValue = watch('rowNumber');
    const startSeatValue = watch('startSeat');

    const isTableVariant = variant === 'table';

    const getGridItemWidth = () => {
        switch (variant) {
            case 'table':
                return 4;
            default:
                return 3;
        }
    };

    useEffect(() => {
        if (!ticket) return;

        reset(ticket);
    }, [reset, ticket]);

    useEffect(() => {
        if (!block) return;

        setValue('blockNumber', block);
        onChangeBlockOption?.(block);
    }, [block, onChangeBlockOption, setValue]);

    useEffect(() => {
        if (!row) return;

        setValue('rowNumber', row);
        onChangeRowOption?.(row);
    }, [onChangeRowOption, row, setValue]);

    useEffect(() => {
        setValue('seatNumber', seat);
        onChangeSeatOption?.(seat || '');
    }, [onChangeRowOption, row, setValue]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={getGridItemWidth()}>
                {!isTableVariant && <FormLabel>Block</FormLabel>}
                <Controller
                    name="blockNumber"
                    control={control}
                    render={({ field: { value, onChange, name } }) => (
                        <AutoComplete
                            name={name}
                            value={value}
                            onChange={(value) => {
                                onChange(value);
                                onChangeBlockOption?.(value);
                                setValue('rowNumber', null);
                                setValue('seatNumber', undefined);
                                setValue('startSeat', undefined);
                                clearErrors();
                            }}
                            placeholder="Select a block"
                            options={blockOptions}
                            onCreateOption={onCreateBlock}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={getGridItemWidth()}>
                {!isTableVariant && <FormLabel>Row</FormLabel>}
                <Controller
                    name="rowNumber"
                    control={control}
                    render={({ field: { value, onChange, name } }) => (
                        <AutoComplete
                            name={name}
                            value={value}
                            onChange={(value) => {
                                onChange(value);
                                onChangeRowOption?.(value);
                                if (!value) {
                                    setValue('startSeat', undefined);
                                    setValue('step', 1);
                                    clearErrors();
                                }
                            }}
                            placeholder="Select a row"
                            options={rowOptions}
                            disabled={!blockValue}
                            onCreateOption={onCreateRow}
                        />
                    )}
                />
            </Grid>

            {isTableVariant && (
                <>
                    <Grid item xs={getGridItemWidth()}>
                        <Controller
                            name="seatNumber"
                            control={control}
                            render={({ field: { value, onChange, name } }) => (
                                <>
                                    <Input
                                        name={name}
                                        value={value}
                                        onChange={(e) => {
                                            onChange(e);
                                            onChangeSeatOption?.(e.target.value);
                                        }}
                                        placeholder="Enter a seat"
                                    />
                                </>
                            )}
                        />
                    </Grid>
                </>
            )}

            {!isTableVariant && (
                <>
                    <Grid item xs={getGridItemWidth()}>
                        <FormLabel>Start seat</FormLabel>
                        <Controller
                            name="startSeat"
                            control={control}
                            render={({
                                field: { value, onChange, name },
                                fieldState: { error },
                            }) => (
                                <>
                                    <Input
                                        name={name}
                                        value={value}
                                        onChange={onChange}
                                        placeholder="Enter a start seat"
                                        disabled={!blockValue || !rowValue}
                                    />
                                    <FormFieldError message={error?.message} />
                                </>
                            )}
                        />
                    </Grid>
                    <Grid item xs={getGridItemWidth()}>
                        <FormLabel>Step</FormLabel>
                        <Controller
                            name="step"
                            control={control}
                            render={({
                                field: { value, onChange, name },
                                fieldState: { error },
                            }) => (
                                <>
                                    <Input
                                        name={name}
                                        value={value}
                                        onChange={onChange}
                                        placeholder="Enter a step (defaults to 1)"
                                        disabled={!blockValue || !rowValue || !startSeatValue}
                                    />
                                    <FormFieldError message={error?.message} />
                                </>
                            )}
                        />
                    </Grid>
                </>
            )}

            {onFormSubmit && (
                <Grid item xs={12} display="flex" alignItems="center">
                    <Button
                        onClick={() => handleSubmit(onFormSubmit)()}
                        className={classes.makeChangesButton}
                        disabled={isSubmitButtonDisabled}
                    >
                        Make changes
                    </Button>
                    <ErrorOutlineIcon className={classes.errorIcon} />
                    <FormHelperText className={classes.helperText}>
                        Changes can be seen in the overview above after clicking this button
                    </FormHelperText>
                </Grid>
            )}
        </Grid>
    );
};
