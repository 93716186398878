export enum SIZES {
    TINY = 'tiny',
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
}

export enum BUTTON_SIZES {
    TINY = 'tiny',
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
}

export enum LOADER_SIZES {
    TINY = '.6rem',
    SMALL = '1rem',
    MEDIUM = '1.3rem',
    LARGE = '1.5rem',
}

export enum THEME_TYPES {
    P1 = 'etbaas.p1hospitality.io',
    ETBAAS = 'etbaas.eventstravel.io',
}
