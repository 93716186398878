import { Navigate, Outlet } from 'react-router-dom';
import { BaseComponent } from 'src/view/interfaces/BaseComponent';
import urlService from 'src/app/services/urlService';
import authService from 'src/data/services/authService';

interface ProtectedRouteProps extends BaseComponent {
    isRoleAllowed?: boolean;
    redirectUrl?: string;
}

export const ProtectedRoute = ({
    isRoleAllowed = false,
    redirectUrl = '',
    children,
}: ProtectedRouteProps) => {
    if (!authService.loggedIn()) return <Navigate to={urlService.getLogin()} />;
    if (!isRoleAllowed) return <Navigate to={redirectUrl} />;

    return <div>{children || <Outlet />}</div>;
};
