import { Alert, Grid } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment/moment';
import { useState } from 'react';
import { useCompareOrdersDataWrapper } from 'src/app/components/data-wrappers/order/CompareOrdersDataWrapper';
import CompareOrdersForm, {
    CompareOrdersFormValues,
} from 'src/app/components/forms/order/CompareOrdersForm';
import { OrderComparisonResultTable } from 'src/app/components/tables/OrderComparisonResultTable';
import { CompareOrdersDto, REASON_TYPE } from 'src/data/services/orderService';
import { dateOnlyFormat } from 'src/shared/date';
import { useSpacingStyles } from 'src/shared/styles/spacingStyles';
import DictionaryGrid from 'src/view/components/dictionary-grid/DictionaryGrid';
import { Divider } from 'src/view/components/divider/Divider';
import ErrorsList from 'src/view/components/errors-list/ErrorsList';
import TableHeader from 'src/view/components/table/table-header/TableHeader';

const useStyles = makeStyles((theme: Theme) => ({
    page: {
        height: '100%',
        padding: theme.spacing(5),
    },
    header: {
        margin: 0,
    },
    form: {
        marginTop: theme.spacing(3),
    },
    results: {
        fontSize: '1rem',
    },
    problems: {
        color: theme.palette.error.main,
    },
}));

export const OrderComparisonToolFeature = (): JSX.Element => {
    const classes = useStyles();
    const spacingClasses = useSpacingStyles();

    const [formRangeData, setFormRangeData] = useState<Omit<CompareOrdersFormValues, 'file'>>();

    const { loading, data, errors, compareOrders } = useCompareOrdersDataWrapper({});

    const onSubmitCompareOrders = async (values: CompareOrdersFormValues) => {
        setFormRangeData({
            startDate: values.startDate,
            endDate: values.endDate,
        });

        const startDate = values.startDate ? moment(values.startDate).format(dateOnlyFormat) : '';
        const endDate = values.endDate ? moment(values.endDate).format(dateOnlyFormat) : '';

        const dto: CompareOrdersDto = {
            startDate,
            endDate,
            eventId: values.event?.value,
            seriesId: values.series?.value,
            file: values.file,
        };

        compareOrders(dto);
    };

    const renderEmptyGridItem = () => (
        <Grid
            item
            xs={0}
            md={6}
            sx={{
                display: {
                    xs: 'none',
                    md: 'flex',
                },
            }}
        />
    );

    const getDataWithReason = (reason: REASON_TYPE) => {
        if (!data) return [];

        return data.data.reasons
            .filter((order) => order.reason === reason)
            .map((order) => ({
                orderNumber: order.orderNumber,
                reason: order.reason,
            }));
    };

    return (
        <div>
            <h3>Upload CSV</h3>

            <CompareOrdersForm onSubmit={onSubmitCompareOrders} />

            {data && !loading && formRangeData && (
                <div>
                    <Divider />

                    <h3>Results</h3>

                    <Grid container className={classes.results}>
                        <Grid item xs={12} md={4}>
                            <DictionaryGrid
                                data={[
                                    {
                                        label: 'Date Start:',
                                        value: formRangeData?.startDate
                                            ? moment(formRangeData?.startDate).format(
                                                  dateOnlyFormat
                                              )
                                            : 'None',
                                    },
                                    {
                                        label: 'Date End:',
                                        value: formRangeData?.endDate
                                            ? moment(formRangeData.endDate).format(dateOnlyFormat)
                                            : 'None',
                                    },
                                    {
                                        label: 'Uploaded order numbers:',
                                        value: data.data.uploadedExternalRefIds,
                                    },
                                    {
                                        label: 'Missing order numbers:',
                                        value: data.data.missingOrderExternalRefIds,
                                    },
                                    {
                                        label: 'Extra order numbers:',
                                        value: data.data.extraOrderExternalRefIds,
                                    },
                                ]}
                            />
                        </Grid>
                    </Grid>

                    {data.data.reasons.length === 0 ? (
                        <Grid container spacing={2} className={spacingClasses.spacingTop}>
                            <Grid item xs={12} md={6}>
                                <Alert severity="success">No problems were found</Alert>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container spacing={2} className={spacingClasses.spacingTop}>
                            <Grid item xs={12} md={6}>
                                <Alert severity="error">Problems found</Alert>
                            </Grid>

                            {renderEmptyGridItem()}

                            <Grid item xs={12} md={6}>
                                <TableHeader title="Missing in ETBAAS" />
                                <OrderComparisonResultTable
                                    reasons={getDataWithReason(REASON_TYPE.MissingInEtbaas)}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TableHeader title="Missing in upload" />
                                <OrderComparisonResultTable
                                    reasons={getDataWithReason(REASON_TYPE.MissingInUpload)}
                                />
                            </Grid>
                        </Grid>
                    )}

                    <div className={spacingClasses.spacingTop}>
                        <ErrorsList errors={errors} />
                    </div>
                </div>
            )}
        </div>
    );
};
