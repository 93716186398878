import useApiFetch from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import { EmptyBody } from 'src/data/models/common/emptyBody';
import orderService from 'src/data/services/orderService';

interface RevokeTicketsForOrderDataWrapperChildrenParams
    extends DataWrapperChildrenParams<ApiResponseBody<EmptyBody>> {
    revokeTickets: (eventId: string, orderId: string) => Promise<ApiResponseBody<EmptyBody>>;
}

type RevokeTicketsForOrderDataWrapperProps = DataWrapperProps<
    RevokeTicketsForOrderDataWrapperChildrenParams,
    ApiResponseBody<EmptyBody>
> &
    BaseFetchOptions;

export const useRevokeTicketsForOrderDataWrapper = ({
    onError,
    onSuccess,
}: Omit<RevokeTicketsForOrderDataWrapperProps, 'children'>) => {
    const [{ data, loading, errors, resetData }, handleRevokeTickets] =
        useApiFetch<ApiResponseBody<EmptyBody>>();

    const revokeTickets = (eventId: string, orderId: string, force = false) => {
        return handleRevokeTickets(orderService.revokeTicketsForOrder(eventId, orderId, force), {
            useDefaultErrorHandler: true,
            onFail: onError,
            onSuccess,
        });
    };

    return {
        revokeTickets,
        data,
        loading,
        errors,
        resetData,
    };
};
