import useApiFetch, { ErrorReturn } from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import seatingPlanCategoryService from 'src/data/services/seatingPlanCategoryService';
import CreateDataResponse from 'src/data/api/common/CreateDataResponse';
import { IdResponse } from 'src/data/models/common/idResponse';

interface CreateRowDataWrapperChildrenParams
    extends DataWrapperChildrenParams<ApiResponseBody<CreateDataResponse>> {
    createRow: (
        name: string,
        seatingplanCategoryId: string,
        blockId: string
    ) => Promise<void | ErrorReturn>;
}

export type OnCreateRowSuccessType = IdResponse & { name: string };

type CreateRowDataWrapperProps = DataWrapperProps<
    CreateRowDataWrapperChildrenParams,
    OnCreateRowSuccessType
> &
    BaseFetchOptions;

export const useCreateRowDataWrapper = ({
    onError,
    onSuccess,
}: Omit<CreateRowDataWrapperProps, 'children'>) => {
    const [{ data, loading, errors, resetData }, handleCreateRow] =
        useApiFetch<ApiResponseBody<CreateDataResponse>>();

    const createRowCall = (name: string, seatingplanCategoryId: string, blockId: string) => {
        return handleCreateRow(
            seatingPlanCategoryService.createRow(name, seatingplanCategoryId, blockId),
            {
                useDefaultErrorHandler: true,
                onFail: onError,
                onSuccess: (data) => {
                    onSuccess?.({
                        id: data?.data.id || name,
                        name,
                    });
                },
            }
        );
    };

    return {
        createRow: createRowCall,
        data,
        loading,
        errors,
        resetData,
    };
};
