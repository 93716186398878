export default function loadCss(fileName: string) {
    if (!fileName) throw Error('The CSS file path is required');

    const documentHead = document.getElementsByTagName('HEAD')[0];
    const csslinkElement = document.createElement('link');

    csslinkElement.rel = 'stylesheet';
    csslinkElement.type = 'text/css';
    csslinkElement.href = `/${fileName}`;

    documentHead.appendChild(csslinkElement);
}
