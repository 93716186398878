import { Theme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import Chip from '@mui/material/Chip';

const BreadcrumbItem = withStyles((theme: Theme) => ({
    root: {
        backgroundColor: 'transparent',
        height: theme.spacing(3),
        color: theme.palette.primary.contrastText,
        fontSize: '.7rem',
        '&:hover, &:focus': {
            backgroundColor: 'transparent',
            cursor: 'pointer',
        },
    },
}))(Chip) as typeof Chip;

export default BreadcrumbItem;
