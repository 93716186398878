import * as Sentry from '@sentry/react';
import * as momentTimezone from 'moment-timezone';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { EnvironmentDisplayFeature } from 'src/app/components/features/environment-display-feature/EnvironmentDisplayFeature';
import TicketManagementTableFeature from 'src/app/components/features/ticket-management/TicketManagementTableFeature';
import TicketUrlService from 'src/app/components/features/tickets/TicketsUrlService';
import { Navbar } from 'src/app/components/navigation/Navbar';
import { config } from 'src/app/constants/config/config';
import 'src/app/global.module.scss';
import NotAuthorized from 'src/app/pages/NotAuthorized';
import OrderComparisonTool from 'src/app/pages/basic-administration/OrderComparisonTool';
import OrganizersOverview from 'src/app/pages/basic-administration/OrganizersOverview';
import SeriesGroupsOverview from 'src/app/pages/basic-administration/SeriesGroupsOverview';
import SeriesOverview from 'src/app/pages/basic-administration/SeriesOverview';
import SuppliersOverview from 'src/app/pages/basic-administration/SuppliersOverview';
import TeamsOverview from 'src/app/pages/basic-administration/TeamsOverview';
import VenuesOverview from 'src/app/pages/basic-administration/VenuesOverview';
import BasicAdministrationUrlService from 'src/app/pages/basic-administration/basicAdministrationUrlService';
import EventPage from 'src/app/pages/events/Event';
import EventsOverview from 'src/app/pages/events/EventsOverview';
import OrdersOverview from 'src/app/pages/orders/OrdersOverview';
import AddPurchase from 'src/app/pages/purchases/AddPurchase';
import PurchasesOverview from 'src/app/pages/purchases/PurchasesOverview';
import purchaseUrlService from 'src/app/pages/purchases/purchaseUrlService';
import ticketManagementUrlService from 'src/app/pages/ticket-management/ticketManagementUrlService';
import BatchUploadTickets from 'src/app/pages/tickets/BatchUploadTickets';
import { ETBAASConfirmProvider } from 'src/app/providers/ConfirmProvider';
import { ProtectedRoute } from 'src/app/routing/ProtectedRoute';
import { default as UrlService, default as urlService } from 'src/app/services/urlService';
import loadCss from 'src/app/utilities/helpers/loadCss';
import { THEME_TYPES } from 'src/shared/theme/enums';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';
import { NoRouteMatch } from 'src/view/components/no-route-match/NoRouteMatch';
import useAuthRouting from './hooks/auth/useAuthRouting';
import EventCategoriesOverview from './pages/basic-administration/EventCategoriesOverview';
import SeatingPlanCategoryPropertiesOverview from './pages/basic-administration/SeatingPlanCategoryPropertyDetailsDataWrapper';
import AdvancedEditPurchase from './pages/purchases/AdvancedEditPurchase';
import { LoginRoute } from './routing/LoginRoute';
import Toaster from './utilities/helpers/Toaster';

momentTimezone.tz.setDefault('Etc/UTC');

const App = () => {
    const { isAllowedTo, isConfiguringUser, isLoggedIn } = useAuthRouting();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        // Inits the toaster for further use throughout the app
        Toaster.init((message, options) => {
            enqueueSnackbar(message, {
                variant: options?.variant,
            });
        });
    }, [enqueueSnackbar]);

    useEffect(() => {
        // Load different variation of CSS depending on environment (prod vs. dev)
        if (config.THEME_TYPE === THEME_TYPES.P1) loadCss('p1-theme.css');
        else loadCss('default-theme.css');
    }, []);

    if (!isConfiguringUser) {
        return <LoadingOverlay />;
    }

    const basicAdministrationUrl = UrlService.getBasicAdministration();

    const shouldDisplayEnvironmentInformation = config.NODE_ENV === 'development' && isLoggedIn;

    return (
        <Sentry.ErrorBoundary fallback={<span>An error has occurred.</span>}>
            <ETBAASConfirmProvider>
                <div className="page">
                    {shouldDisplayEnvironmentInformation && <EnvironmentDisplayFeature />}
                    {isLoggedIn && <Navbar logo="ETBaaS" />}
                    <Routes>
                        <Route
                            path={urlService.getEventsOverview()}
                            element={
                                <ProtectedRoute
                                    isRoleAllowed={isAllowedTo?.etbaas}
                                    redirectUrl={isAllowedTo?.redirectUrl}
                                >
                                    <EventsOverview />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={urlService.getPurchases()}
                            element={
                                <ProtectedRoute
                                    isRoleAllowed={isAllowedTo?.etbaas}
                                    redirectUrl={isAllowedTo?.redirectUrl}
                                >
                                    <PurchasesOverview />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={purchaseUrlService.create()}
                            element={
                                <ProtectedRoute
                                    isRoleAllowed={isAllowedTo?.etbaas}
                                    redirectUrl={isAllowedTo?.redirectUrl}
                                >
                                    <AddPurchase />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={purchaseUrlService.advancedEdit()}
                            element={
                                <ProtectedRoute
                                    isRoleAllowed={isAllowedTo?.etbaas}
                                    redirectUrl={isAllowedTo?.redirectUrl}
                                >
                                    <AdvancedEditPurchase />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={urlService.getOrders()}
                            element={
                                <ProtectedRoute
                                    isRoleAllowed={isAllowedTo?.etbaas}
                                    redirectUrl={isAllowedTo?.redirectUrl}
                                >
                                    <OrdersOverview />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="event/:eventId/*"
                            element={
                                <ProtectedRoute
                                    isRoleAllowed={isAllowedTo?.etbaas}
                                    redirectUrl={isAllowedTo?.redirectUrl}
                                >
                                    <EventPage />
                                </ProtectedRoute>
                            }
                        />
                        <Route path={basicAdministrationUrl}>
                            <Route
                                path=""
                                element={
                                    <ProtectedRoute
                                        isRoleAllowed={isAllowedTo?.etbaas}
                                        redirectUrl={isAllowedTo?.redirectUrl}
                                    >
                                        <SeriesGroupsOverview />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path={BasicAdministrationUrlService.seriesGroups({
                                    rootOnly: true,
                                })}
                                element={
                                    <ProtectedRoute
                                        isRoleAllowed={isAllowedTo?.etbaas}
                                        redirectUrl={isAllowedTo?.redirectUrl}
                                    >
                                        <SeriesGroupsOverview />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path={BasicAdministrationUrlService.series({ rootOnly: true })}
                                element={
                                    <ProtectedRoute
                                        isRoleAllowed={isAllowedTo?.etbaas}
                                        redirectUrl={isAllowedTo?.redirectUrl}
                                    >
                                        <SeriesOverview />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path={BasicAdministrationUrlService.suppliers({ rootOnly: true })}
                                element={
                                    <ProtectedRoute
                                        isRoleAllowed={isAllowedTo?.etbaas}
                                        redirectUrl={isAllowedTo?.redirectUrl}
                                    >
                                        <SuppliersOverview />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path={BasicAdministrationUrlService.eventCategories({
                                    rootOnly: true,
                                })}
                                element={
                                    <ProtectedRoute
                                        isRoleAllowed={isAllowedTo?.etbaas}
                                        redirectUrl={isAllowedTo?.redirectUrl}
                                    >
                                        <EventCategoriesOverview />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path={BasicAdministrationUrlService.organizers({ rootOnly: true })}
                                element={
                                    <ProtectedRoute
                                        isRoleAllowed={isAllowedTo?.etbaas}
                                        redirectUrl={isAllowedTo?.redirectUrl}
                                    >
                                        <OrganizersOverview />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path={BasicAdministrationUrlService.teams({ rootOnly: true })}
                                element={
                                    <ProtectedRoute
                                        isRoleAllowed={isAllowedTo?.etbaas}
                                        redirectUrl={isAllowedTo?.redirectUrl}
                                    >
                                        <TeamsOverview />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path={BasicAdministrationUrlService.venues({ rootOnly: true })}
                                element={
                                    <ProtectedRoute
                                        isRoleAllowed={isAllowedTo?.etbaas}
                                        redirectUrl={isAllowedTo?.redirectUrl}
                                    >
                                        <VenuesOverview />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path={BasicAdministrationUrlService.seatingPlanCategoryProperties()}
                                element={
                                    <ProtectedRoute
                                        isRoleAllowed={isAllowedTo?.etbaas}
                                        redirectUrl={isAllowedTo?.redirectUrl}
                                    >
                                        <SeatingPlanCategoryPropertiesOverview />
                                    </ProtectedRoute>
                                }
                            />
                            <Route
                                path={BasicAdministrationUrlService.orderComparisonTool()}
                                element={
                                    <ProtectedRoute
                                        isRoleAllowed={isAllowedTo?.etbaas}
                                        redirectUrl={isAllowedTo?.redirectUrl}
                                    >
                                        <OrderComparisonTool />
                                    </ProtectedRoute>
                                }
                            />
                        </Route>
                        <Route
                            path={ticketManagementUrlService.root({ rootOnly: true })}
                            element={
                                <ProtectedRoute
                                    isRoleAllowed={isAllowedTo?.eTicketManagement}
                                    redirectUrl={isAllowedTo?.redirectUrl}
                                >
                                    <TicketManagementTableFeature />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path={TicketUrlService.batchUploadTickets(':eventId')}
                            element={
                                <ProtectedRoute
                                    isRoleAllowed={isAllowedTo?.etbaas}
                                    redirectUrl={isAllowedTo?.redirectUrl}
                                >
                                    <BatchUploadTickets />
                                </ProtectedRoute>
                            }
                        />
                        <Route path={urlService.getNotAuthorized()} element={<NotAuthorized />} />
                        <Route path={urlService.getLogin()} element={<LoginRoute />} />
                        <Route path="*" element={<NoRouteMatch />} />
                    </Routes>
                </div>
            </ETBAASConfirmProvider>
        </Sentry.ErrorBoundary>
    );
};

export default App;
