import { useMemo } from 'react';
import CreateSeatingplanCategoryForm, {
    CreateSeatingPlanCategoryValues,
} from 'src/app/components/forms/CreateSeatingplanCategoryForm';
import { useGenericStyles } from 'src/shared/styles/genericStyles';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';
import useCreateOrUpdateSeatingPlanCategory from 'src/app/hooks/useCreateOrUpdateSeatingPlanCategory';
import { useFetchSeatingPlanCategoryById } from 'src/app/hooks/useFetchSeatingPlanCategoryById';

interface Props {
    seatingplanId: string;
    seatingplanCategoryId?: string;
    onCreateOrUpdateSuccess?: () => void;
}

export default function CreateSeatingplanCategoryFormFeature({
    seatingplanId,
    seatingplanCategoryId,
    onCreateOrUpdateSuccess,
}: Props): JSX.Element {
    const genericClasses = useGenericStyles();

    const {
        mutate: createOrUpdateSeatingPlanCategory,
        isLoading: isCreatingOrUpdatingSeatingPlanCategory,
    } = useCreateOrUpdateSeatingPlanCategory(onCreateOrUpdateSuccess);

    const { data: seatingplanCategoryByIdData, isLoading: isGettingSeatingplanCategoryById } =
        useFetchSeatingPlanCategoryById(seatingplanCategoryId || '', {
            enabled: !!seatingplanCategoryId,
        });

    const submitForm = (values: CreateSeatingPlanCategoryValues) => {
        createOrUpdateSeatingPlanCategory({
            seatingplanId,
            seatingplanCategoryId,
            dto: getCreateOrUpdateSPCdto(values),
        });
    };

    const defaultValues = useMemo(() => {
        const seatingPlanCategoryData = seatingplanCategoryByIdData?.data?.data;

        return (
            seatingPlanCategoryData && {
                name: seatingPlanCategoryData.name,
                tags: seatingPlanCategoryData.tags?.map((t) => ({
                    label: t.name,
                    value: t.id,
                })),
                ticketTypes: seatingPlanCategoryData.ticketTypes?.map((t) => ({
                    label: t,
                    value: t,
                })),
                seatingPlanCategoryProperties: seatingPlanCategoryData.categoryProperties?.map(
                    (t) => ({
                        property: {
                            label: t.propertyKey,
                            value: t.propertyId,
                        },
                        additionalValue: t.value,
                    })
                ),
            }
        );
    }, [seatingplanCategoryByIdData]);

    if (seatingplanCategoryId && isGettingSeatingplanCategoryById) {
        return (
            <div className={genericClasses.center}>
                <LoadingOverlay />
            </div>
        );
    }

    return (
        <CreateSeatingplanCategoryForm
            onFormSubmit={submitForm}
            editMode={!!seatingplanCategoryId}
            loading={isCreatingOrUpdatingSeatingPlanCategory}
            formDefaultValues={defaultValues}
        />
    );
}

const getCreateOrUpdateSPCdto = (values: CreateSeatingPlanCategoryValues) => {
    const { name, tags, ticketTypes, seatingPlanCategoryProperties } = values;

    return {
        name,
        tagIds: tags?.map((t) => t.value),
        ticketTypes: ticketTypes?.map((t) => t.value),
        properties: seatingPlanCategoryProperties.map(({ property, additionalValue }) => {
            return {
                propertyId: property.value,
                value: additionalValue,
            };
        }),
    };
};
