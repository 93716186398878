import { useMutation } from '@tanstack/react-query';
import supplierService from 'src/data/services/supplierService';

type TData = Awaited<ReturnType<typeof supplierService.unarchiveSupplier>>;

export const useUnarchiveSupplier = (onSuccess?: (data: TData) => void) => {
    return useMutation({
        mutationFn: supplierService.unarchiveSupplier,
        onSuccess,
    });
};
