import { Theme } from '@mui/material';
import { CSSProperties } from '@mui/styles';
import { Styles } from 'react-select';

const getControlBorderRadius = (theme: Theme, hasRightButton: boolean): string | number => {
    const regularRadius = theme.layout.borderRadius.regular;

    if (hasRightButton) {
        return `${regularRadius}px 0 0 ${regularRadius}px`;
    }

    return regularRadius;
};

export const customStyles = (
    theme: Theme,
    hasRightButton: boolean,
    backgroundColor?: CSSProperties['color']
): Partial<Styles<CSSProperties, false>> => ({
    container: (provided) => {
        return {
            ...provided,
            flex: 1,
        };
    },
    valueContainer: (provided) => {
        return {
            ...provided,
            height: '45px',
            maxHeight: '45px',
            overflowY: 'scroll',
        };
    },
    input: (provided) => {
        return {
            ...provided,
            padding: theme.spacing(1),
            paddingLeft: 0,
            margin: 0,
            flex: 1,
        };
    },
    control: (provided, state) => {
        const getBackgroundColor = () => {
            if (backgroundColor) return backgroundColor;

            if (state.isDisabled) return theme.colors.mediumGrey;

            return theme.colors.white;
        };

        const getBorderColor = () => {
            if (state.isFocused) {
                return '#000';
            }

            if (state.isDisabled) {
                return theme.colors.mediumGrey;
            }

            return theme.colors.darkGrey;
        };

        return {
            ...provided,
            background: getBackgroundColor(),
            boxShadow: 'none',
            height: 45,
            borderWidth: 1,
            borderColor: getBorderColor(),
            borderRadius: getControlBorderRadius(theme, hasRightButton),
            overflow: 'hidden',
            '&:hover': {
                borderColor: '#000',
            },
        };
    },
    menu: (provided) => {
        return {
            ...provided,
            boxShadow: 'none',
        };
    },
    menuList: (provided) => {
        return {
            ...provided,
            border: `1px solid ${theme.colors.lightGrey}`,
            borderRadius: theme.layout.borderRadius.regular,
        };
    },
    option: (provided) => {
        return {
            ...provided,
            '&:hover': {
                cursor: 'pointer',
            },
        };
    },
    clearIndicator: (provided) => {
        return {
            ...provided,
            '&:hover': {
                cursor: 'pointer',
            },
        };
    },
    dropdownIndicator: (provided) => {
        return {
            ...provided,
            '&:hover': {
                cursor: 'pointer',
            },
        };
    },
});
