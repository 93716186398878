import { useQuery } from '@tanstack/react-query';
import { parseErrors } from 'src/app/utilities/helpers/errors';
import Toaster from 'src/app/utilities/helpers/Toaster';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import { fetchEventSuppliers } from 'src/data/services/eventService';

export const FETCH_EVENT_SUPPLIERS_QUERY_KEY = 'FETCH_EVENT_SUPPLIERS';

export const useFetchEventSuppliers = (id: string, options: BaseFetchOptions) => {
    return useQuery({
        queryKey: [FETCH_EVENT_SUPPLIERS_QUERY_KEY, id, options],
        queryFn: () => fetchEventSuppliers(id, options),
        onError: (e) => {
            Toaster.toastErrors(parseErrors(e));
        },
        enabled: !!id,
    });
};
