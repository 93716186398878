import { Grid, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';
import CreateEventFeature from 'src/app/components/features/CreateEventFeature';
import EventTableFeature from 'src/app/components/features/EventTableFeature';
import { useCreateEventForm } from 'src/app/components/forms/event/useCreateEventForm';
import { PopUpForm } from 'src/app/components/pop-up-form/pop-up-form';
import Button from 'src/view/components/button/Button';
import { ETBContainer } from 'src/view/components/container/container';
import Header from 'src/view/components/header/Header';

const useStyles = makeStyles((theme: Theme) => ({
    headerContent: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    spacingRight: {
        marginRight: theme.spacing(1),
    },
}));

export default function EventsOverview(): JSX.Element {
    const classes = useStyles();
    const [showCreateEventModal, setShowCreateEventModal] = useState(false);
    const [eventsInvalidatedAt, setEventsInvalidatedAt] = useState<number | undefined>();
    const form = useCreateEventForm();

    return (
        <>
            <Header GridProps={{ justifyContent: 'flex-end' }}>
                <Grid item>
                    <Button
                        className={classes.spacingRight}
                        color="primary"
                        onClick={() => setShowCreateEventModal(true)}
                        data-cy="create-event"
                    >
                        Create Event
                    </Button>
                </Grid>
            </Header>
            <ETBContainer>
                <EventTableFeature invalidatedAt={eventsInvalidatedAt} />
                <PopUpForm
                    formState={form.formState}
                    open={showCreateEventModal}
                    title="Create New event"
                    onClose={() => {
                        setShowCreateEventModal(false);
                        form.reset();
                    }}
                >
                    <CreateEventFeature
                        form={form}
                        onEventCreated={() => {
                            setEventsInvalidatedAt(Date.now());
                            setShowCreateEventModal(false);
                        }}
                    />
                </PopUpForm>
            </ETBContainer>
        </>
    );
}
