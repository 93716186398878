import authService from 'src/data/services/authService';
import { hasOneOfRoles } from 'src/app/utilities/helpers/userRole';
import { Roles } from 'src/data/enums/roles';

const roles = authService.loggedIn() ? authService.getProfile()['cognito:groups'] : [];

const isAdmin = hasOneOfRoles([Roles.ADMINISTRATOR], roles);

export const nameUpdateRoles = [Roles.ADMINISTRATOR];
const canUpdateEventName = hasOneOfRoles(nameUpdateRoles, roles);

export const organizerUpdateRoles = [Roles.PRODUCTLEAD, Roles.ADMINISTRATOR];
const canUpdateEventOrganizer = hasOneOfRoles(organizerUpdateRoles, roles);

export const seriesUpdateRoles = [Roles.PRODUCTLEAD, Roles.ADMINISTRATOR];
const canUpdateEventSeries = hasOneOfRoles(seriesUpdateRoles, roles);

export const eventCategoryUpdateRoles = [Roles.ADMINISTRATOR];
const canUpdateEventCategory = hasOneOfRoles(eventCategoryUpdateRoles, roles);

export const DeleteIteneraryFileRoles = [Roles.ADMINISTRATOR];
const canDeleteItieneraryFile = hasOneOfRoles(DeleteIteneraryFileRoles, roles);

export const ticketLockStatusModifyRoles = [
    Roles.OPERATIONS_LEAD,
    Roles.OPERATIONS_SENIOR,
    Roles.ADMINISTRATOR,
];
const canModifyTicketLockedStatus = hasOneOfRoles(ticketLockStatusModifyRoles, roles);

export const changePurchaseRoles = [Roles.ADMINISTRATOR, Roles.PRODUCTLEAD, Roles.PRODUCT_SENIOR];
const canChangePurchasePrices = hasOneOfRoles(changePurchaseRoles, roles);

export const FinanceRoles = [
    Roles.FINANCE,
    Roles.FINANCE_INTERN,
    Roles.FINANCE_JUNIOR,
    Roles.FINANCE_LEAD,
    Roles.FINANCE_SENIOR,
];

export const isFinanceRole = hasOneOfRoles(FinanceRoles, roles);
export const canLockEvent =
    isFinanceRole ||
    hasOneOfRoles(
        [Roles.OPERATIONS_LEAD, Roles.OPERATIONS_SENIOR, Roles.PRODUCTLEAD, Roles.PRODUCT_SENIOR],
        roles
    ) ||
    isAdmin;
export const canUnlockEvent = isFinanceRole || isAdmin;
export const canCloseEvent = isFinanceRole || isAdmin;

export default {
    canUpdateEventName,
    canUpdateEventOrganizer,
    canUpdateEventSeries,
    canModifyTicketLockedStatus,
    canChangePurchasePrices,
    canUpdateEventCategory,
    canDeleteItieneraryFile,
};
