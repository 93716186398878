import { zodResolver } from '@hookform/resolvers/zod';
import { Alert, List, ListItem, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useUpdateGeocoding } from 'src/app/components/features/venues/use-update-geocoding';
import { config } from 'src/app/constants/config/config';
import { useCustomConfirm } from 'src/app/hooks/useCustomConfirm';
import { zodRequiredDropdownSelectSchema } from 'src/app/utilities/zod/zodRequiredDropdownSelectSchema';
import { zodRequiredStringSchema } from 'src/app/utilities/zod/zodRequiredStringSchema';
import { countryList } from 'src/shared/countries';
import AutoComplete from 'src/view/components/auto-complete/AutoComplete';
import { AutoCompleteOption } from 'src/view/components/auto-complete/interfaces';
import FormButtons from 'src/view/components/form/FormButtons';
import FormFieldError from 'src/view/components/form/FormFieldError';
import Input from 'src/view/components/input/Input';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';
import Map from 'src/view/components/map/Map';
import ModalSubTitle from 'src/view/components/modal/ModalSubtitle';
import z from 'zod';

const validationSchema = z.object({
    venueName: zodRequiredStringSchema,
    address: zodRequiredStringSchema,
    addressLine2: z.string().optional().nullish(),
    postalCode: zodRequiredStringSchema,
    city: zodRequiredStringSchema,
    country: zodRequiredDropdownSelectSchema,
    latitude: z.number(),
    longitude: z.number(),
});

export interface CreateVenueFormValues {
    venueName: string;
    address: string;
    addressLine2: string;
    postalCode: string;
    city: string;
    country: AutoCompleteOption;
    latitude: number;
    longitude: number;
}

interface Props {
    loading: boolean;
    formDefaultValues: Partial<CreateVenueFormValues> | undefined;
    onFormSubmit: (values: CreateVenueFormValues) => void;
}

export function CreateVenueForm({ loading, formDefaultValues, onFormSubmit }: Props) {
    const form = useForm<CreateVenueFormValues>({
        mode: 'onChange',
        resolver: zodResolver(validationSchema),
    });

    const confirm = useCustomConfirm();

    const {
        control,
        watch,
        reset,
        handleSubmit,
        formState: { isValid },
    } = form;

    useEffect(() => {
        if (!formDefaultValues) return;
        reset(formDefaultValues);
    }, [formDefaultValues, reset]);

    const city = watch('city');
    const address = watch('address');
    const postalCode = watch('postalCode');
    const latitude = watch('latitude');
    const longitude = watch('longitude');
    const country = watch('country');

    const isLoadingExistingVenue =
        formDefaultValues &&
        formDefaultValues.city === city &&
        formDefaultValues.address === address &&
        formDefaultValues.postalCode === postalCode;

    // When loading an existing venue, geocoding verification is not needed.
    const geocodingPayload = isLoadingExistingVenue
        ? undefined
        : { city, address, postalCode, country: country?.label };

    const nonMatchingFields = useUpdateGeocoding(geocodingPayload, reset);

    const isSubmitButtonDisabled = loading || !isValid;

    return (
        <>
            {loading && <LoadingOverlay />}
            <Grid container>
                <Grid item xs={12}>
                    <ModalSubTitle>Venue Name</ModalSubTitle>
                    <Controller
                        name="venueName"
                        control={control}
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <>
                                <Input
                                    name={name}
                                    value={value}
                                    onChange={onChange}
                                    placeholder="Venue name"
                                    grey
                                />
                                <FormFieldError message={error?.message} />
                            </>
                        )}
                    />
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={6} sx={(theme) => ({ paddingRight: theme.spacing(1) })}>
                    <ModalSubTitle>Address*</ModalSubTitle>
                    <Controller
                        name="address"
                        control={control}
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <>
                                <Input
                                    name={name}
                                    value={value}
                                    onChange={onChange}
                                    placeholder="Address"
                                    grey
                                />
                                <FormFieldError message={error?.message} />
                            </>
                        )}
                    />
                </Grid>
                <Grid item xs={6} sx={(theme) => ({ paddingLeft: theme.spacing(1) })}>
                    <ModalSubTitle>Address line 2 </ModalSubTitle>
                    <Controller
                        name="addressLine2"
                        control={control}
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <>
                                <Input
                                    name={name}
                                    value={value}
                                    onChange={onChange}
                                    placeholder="Address optional information"
                                    grey
                                />
                                <FormFieldError message={error?.message} />
                            </>
                        )}
                    />
                </Grid>
                <Grid item xs={4} sx={(theme) => ({ paddingRight: theme.spacing(1) })}>
                    <ModalSubTitle>Postal Code*</ModalSubTitle>
                    <Controller
                        name="postalCode"
                        control={control}
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <>
                                <Input
                                    name={name}
                                    value={value}
                                    onChange={onChange}
                                    placeholder="Postal Code"
                                    grey
                                />
                                <FormFieldError message={error?.message} />
                            </>
                        )}
                    />
                </Grid>
                <Grid
                    item
                    xs={4}
                    sx={(theme) => ({
                        paddingLeft: theme.spacing(1),
                        paddingRight: theme.spacing(1),
                    })}
                >
                    <ModalSubTitle>City*</ModalSubTitle>
                    <Controller
                        name="city"
                        control={control}
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <>
                                <Input
                                    name={name}
                                    value={value}
                                    onChange={onChange}
                                    placeholder="City"
                                    grey
                                />
                                <FormFieldError message={error?.message} />
                            </>
                        )}
                    />
                </Grid>
                <Grid item xs={4} sx={(theme) => ({ paddingLeft: theme.spacing(1) })}>
                    <ModalSubTitle>Country*</ModalSubTitle>
                    <Controller
                        name="country"
                        control={control}
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <>
                                <AutoComplete
                                    name={name}
                                    value={value}
                                    options={countryList}
                                    onChange={onChange}
                                    placeholder="Search and select"
                                />
                                <FormFieldError message={error?.message} />
                            </>
                        )}
                    />
                </Grid>
                <Grid
                    item
                    xs={6}
                    sx={(theme) => ({
                        paddingRight: theme.spacing(1),
                        marginBottom: theme.spacing(2),
                    })}
                >
                    <ModalSubTitle>Latitude</ModalSubTitle>
                    <Box component="span" sx={(theme) => ({ color: theme.colors.grey })}>
                        {latitude || 'Enter full address'}
                    </Box>
                </Grid>
                <Grid item xs={6} sx={(theme) => ({ paddingLeft: theme.spacing(1) })}>
                    <ModalSubTitle>Longitude</ModalSubTitle>
                    <Box component="span" sx={(theme) => ({ color: theme.colors.grey })}>
                        {longitude || 'Enter full address'}
                    </Box>
                </Grid>
            </Grid>
            <Box
                sx={(theme) => ({
                    position: 'relative',
                    backgroundColor: theme.colors.lightestGrey,
                })}
            >
                {latitude && longitude ? (
                    <Map
                        apiKey={config.GOOGLE.API_KEY}
                        lat={latitude}
                        lng={longitude}
                        address={address || ''}
                    />
                ) : (
                    <Box
                        sx={(theme) => ({
                            height: '300px',
                            textAlign: 'center',
                            paddingTop: theme.spacing(2),
                        })}
                    >
                        Please provide address, city and postal code to be able to see the location
                    </Box>
                )}
            </Box>
            <FormButtons
                buttons={[
                    {
                        children: formDefaultValues ? 'Update Venue' : 'Create Venue',
                        onClick: async () => {
                            if (nonMatchingFields.length > 0) {
                                const hasUserConfirmed = await confirm({
                                    title: 'Proceed with caution!',
                                    content: (
                                        <AddressMaybeIncorrectPopup fields={nonMatchingFields} />
                                    ),
                                    confirmationText: 'I understand, continue',
                                    cancellationText: 'Cancel',
                                });

                                hasUserConfirmed && handleSubmit(onFormSubmit)();
                            } else {
                                handleSubmit(onFormSubmit)();
                            }
                        },
                        disabled: isSubmitButtonDisabled,
                    },
                ]}
            />
        </>
    );
}

const AddressMaybeIncorrectPopup = ({ fields }: { fields: string[] }) => {
    return (
        <Box>
            <Typography fontWeight="bold">
                Google could not find an exact match for this address. Consider taking a second look
                at the following field(s):
            </Typography>

            <List>{fields?.map((field) => <ListItem key={field}>{field}</ListItem>)}</List>

            <Alert severity="info">
                This is just a suggestion. If you are sure that the fields are correct, you may
                still continue.
            </Alert>
        </Box>
    );
};
