import { useState, useEffect } from 'react';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import FilterOption from 'src/data/api/common/FilterOption';
import formatValuesToParams from 'src/app/utilities/helpers/formatValuesToParams';
import {
    FilterAutoCompleteOption,
    FilterAutoCompleteOptions,
} from 'src/view/components/filters/AutoComplete/AutoComplete';
import Filters, { FiltersArray } from 'src/app/components/forms/filters/Filters';

export interface SeriesFilterFormValues {
    seriesGroup?: FilterAutoCompleteOption | null;
    searchTerm?: string;
}

interface Props {
    defaultValues: SeriesFilterFormValues;
    seriesGroupOptions: FilterAutoCompleteOptions;
    onChangeFilterOptions?: (options: FilterOption[]) => void;
    onChangeSearchTerm?: (q: string) => void;
}

export default function SeriesFilterForm({
    defaultValues,
    seriesGroupOptions,
    onChangeFilterOptions,
    onChangeSearchTerm,
}: Props): JSX.Element {
    const [urlInvalidatedAt, setUrlInvalidatedAt] = useState<number | undefined>();

    const form = useForm<SeriesFilterFormValues>({
        mode: 'onChange',
        defaultValues,
    });

    const { watch, setValue, control, reset } = form;

    const seriesGroup = watch('seriesGroup');

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues, reset]);

    useEffect(() => {
        if (!onChangeFilterOptions || !urlInvalidatedAt) return;

        const ticketsFilter = _.compact([
            formatValuesToParams.formatOptionToParam('seriesGroupId', seriesGroup),
        ]).filter((f) => f.value !== undefined && f.value !== '');

        onChangeFilterOptions(ticketsFilter);
    }, [urlInvalidatedAt, seriesGroupOptions, seriesGroup]);

    const onInputChange = (value: string) => {
        setValue('searchTerm', value, {
            shouldValidate: true,
        });

        onChangeSearchTerm?.(value);
    };

    const arrayOfFilters: FiltersArray = [
        {
            type: 'autocomplete',
            options: seriesGroupOptions,
            name: 'seriesGroup',
            filterPlaceholderProps: {
                placeholder: 'Select series group',
            },
            onChange: () => setUrlInvalidatedAt(Date.now()),
        },
        {
            type: 'search',
            name: 'searchTerm',
            onChange: onInputChange,
            searchPlaceholder: 'Search on event name',
            searchDefaultValue: defaultValues.searchTerm,
        },
    ];

    return <Filters control={control} filters={arrayOfFilters} />;
}
