import { FormLabel } from '@mui/material';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import { useState } from 'react';
import Price from 'src/data/models/common/price';
import { formatPrice } from 'src/shared/helpers/formatPrice';
import Modal, { ModalBody } from 'src/view/components/modal/Modal';
import TableHeader, { TableHeaderProps } from 'src/view/components/table/table-header/TableHeader';

const useStyles = makeStyles((theme) => ({
    formLabel: {
        marginBottom: 0,
        color: 'black',
        fontSize: '.875rem',
    },
    boldText: {
        fontSize: '1.125rem',
        color: theme.palette.primary.main,
    },
}));

interface TableHeaderFeatureProps extends Omit<TableHeaderProps, 'onClickShow'> {
    priceTotals?: Price[];
    rightSlot?: JSX.Element;
}
export const TableHeaderTotalFeature = (props: TableHeaderFeatureProps) => {
    const classes = useStyles();
    const [showModal, setShowModal] = useState(false);
    const { priceTotals, hideShowMoreButton = false } = props;

    return (
        <>
            {!hideShowMoreButton && (
                <Modal open={showModal} title="Statistics" onClose={() => setShowModal(false)}>
                    <ModalBody>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <FormLabel className={classes.formLabel}>
                                    Total amount of results
                                </FormLabel>
                                <div>
                                    <b className={classes.boldText}>{props.totalResults}</b>
                                </div>
                            </Grid>
                            {priceTotals && (
                                <>
                                    <Grid item xs={12}>
                                        <FormLabel className={classes.formLabel}>
                                            Total order selling price
                                        </FormLabel>
                                        {priceTotals?.map((p, index) => (
                                            <div key={`${p.value}.${index}`}>
                                                <b className={classes.boldText}>
                                                    {formatPrice({
                                                        currency: p.currency,
                                                        amount: p.value,
                                                    })}
                                                </b>
                                            </div>
                                        ))}
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </ModalBody>
                </Modal>
            )}

            <TableHeader
                {...props}
                totalResults={
                    props.totalResults !== undefined
                        ? `Total results: ${props.totalResults}`
                        : undefined
                }
                onClickShow={() => setShowModal(true)}
                hideShowMoreButton={!priceTotals}
                rightSlot={props.rightSlot}
            />
        </>
    );
};
