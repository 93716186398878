import moment from 'moment';
import { useMemo, useState } from 'react';
import { useFetchActivityLogs } from 'src/app/hooks/activity-logs/useFetchActivityLogs';
import { parseErrors } from 'src/app/utilities/helpers/errors';
import tableSortingToSortingOptionMapper from 'src/app/utilities/mappers/table/tableSortingToSortingOptionMapper';
import { ActivityLog, ActivityLogsQuery } from 'src/data/services/activityLogService';
import { localDateFormat } from 'src/shared/date';
import ErrorsList from 'src/view/components/errors-list/ErrorsList';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';
import Popover from 'src/view/components/popover/Popover';
import TableHeader from 'src/view/components/table/table-header/TableHeader';
import Table from 'src/view/components/table/table/Table';
import {
    NewTableColumn,
    TABLE_CELL_HEIGHT,
    TABLE_CELL_WIDTH,
    TableColumnSorting,
} from 'src/view/components/table/table/Types';

export const ActivityLogFeature = ({ groupingEntityId, entityId }: ActivityLogsQuery) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [sorting, setSorting] = useState<TableColumnSorting[]>([
        {
            column: 'updatedAt',
            direction: 'desc',
        },
    ]);

    const { isLoading, isError, data, error } = useFetchActivityLogs(
        {
            groupingEntityId,
            entityId,
        },
        {
            page: currentPage,
            pageSize: 10,
            sorting: sorting.map(tableSortingToSortingOptionMapper),
        }
    );

    const tableContent: NewTableColumn<ActivityLog>[] = useMemo(() => {
        return [
            {
                key: 'entityId',
                title: 'Entity Id',
                width: TABLE_CELL_WIDTH.EXTRA_LARGE,
                cellRenderer: (rowData) => {
                    return rowData.entityId;
                },
                isSortable: true,
            },
            {
                key: 'updatedAt',
                title: 'Date',
                width: TABLE_CELL_WIDTH.LARGE,
                isSortable: true,
                cellRenderer: (rowData) =>
                    moment(rowData.updatedAt).local().format(localDateFormat),
            },
            {
                title: 'Action',
                key: 'changeType',
                width: TABLE_CELL_WIDTH.SMALL,
                cellRenderer: (rowData) => rowData.changeType,
                isSortable: true,
            },
            {
                title: 'Old status',
                key: 'oldStatus',
                width: TABLE_CELL_WIDTH.MEDIUM,
                cellRenderer: (rowData) => {
                    const text = rowData.oldStatus || '';

                    return (
                        <Popover text={text} isLink={false}>
                            <span>{text}</span>
                        </Popover>
                    );
                },
                truncate: true,
            },
            {
                title: 'New status',
                key: 'newStatus',
                width: TABLE_CELL_WIDTH.MEDIUM,
                cellRenderer: (rowData) => {
                    const text = rowData.newStatus;

                    return (
                        <Popover text={text} isLink={false}>
                            <span>{text}</span>
                        </Popover>
                    );
                },
                truncate: true,
            },
            {
                key: 'userFullName',
                title: 'Name',
                width: TABLE_CELL_WIDTH.SMALL,
                cellRenderer: (rowData) => {
                    return rowData.userFullName;
                },
                isSortable: true,
            },
            {
                key: 'userMail',
                title: 'Email',
                width: TABLE_CELL_WIDTH.LARGE,
                cellRenderer: (rowData) => {
                    return rowData.userMail;
                },
                isSortable: true,
            },
            {
                key: 'entityType',
                title: 'Entity Type',
                width: TABLE_CELL_WIDTH.MEDIUM,
                cellRenderer: (rowData) => rowData.entityType,
                isSortable: true,
            },
        ];
    }, []);

    if (isError) {
        const parsedErrors = parseErrors(error);

        return <ErrorsList errors={parsedErrors} />;
    }

    return (
        <>
            {isLoading && <LoadingOverlay />}

            <TableHeader title="Activity Log" />

            <Table<ActivityLog>
                data={data?.data.data}
                rowIdResolver={(rowData: ActivityLog) => ({
                    id: rowData.entityId,
                    ...rowData,
                })}
                minWidth={1350}
                columns={tableContent}
                rowHeight={TABLE_CELL_HEIGHT.LARGE}
                onSortingChange={setSorting}
                defaultSorting={sorting}
                pagination={{
                    currentPage: data?.data.meta.currentPage || 1,
                    totalPages: data?.data.meta.totalPages || 1,
                    onPaginate: setCurrentPage,
                }}
            />
        </>
    );
};
