import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import DeleteIcon from '@mui/icons-material/Delete';
import { BaseComponent } from 'src/view/interfaces/BaseComponent';
import { type ReactNode } from 'react';

const useTicketOverviewStyles = makeStyles((theme: Theme) => ({
    container: {
        background: theme.colors.lightBlue,
        padding: theme.spacing(2),
        borderRadius: theme.layout.borderRadius.regular,
        overflowY: 'scroll',
        maxHeight: 442,
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.colors.mediumGrey,
        },
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexGrow: 1,
        margin: theme.spacing(0, 0, 1, 0),
    },
    rowNumber: {
        margin: theme.spacing(0, 2),
        lineHeight: theme.spacing(6.25),
        alignItems: 'center',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
    },
    infoContainer: {
        flex: 9,
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        background: theme.colors.white,
        marginRight: theme.spacing(2),
        borderRadius: theme.layout.borderRadius.regular,
        fontWeight: theme.typography.fontWeightBold,
        fontSize: theme.typography.pxToRem(15),
        boxShadow: theme.layout.boxShadowSmall,
    },
    infoContainerWithErrorText: {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    symbolContainer: {
        paddingRight: theme.spacing(2),
        fontWeight: theme.typography.fontWeightBold,
    },
    disabledInputContainer: {
        padding: theme.spacing(2, 2),
        marginRight: 0,
        background: theme.colors.white,
        borderRadius: theme.layout.borderRadius.regular,
        fontWeight: theme.typography.fontWeightBold,
    },
    deleteIcon: {
        marginLeft: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& svg': {
            '& path': {
                fill: theme.palette.error.main,
            },
        },
        '&:hover': {
            cursor: 'pointer',
        },
    },
    itemSelected: {
        borderLeft: `5px solid ${theme.palette.primary.main}`,
    },
    error: {
        color: theme.palette.error.light,
    },
    errorExplanation: {
        color: theme.palette.error.main,
        fontSize: 10,
    },
}));

export interface PreviewItemProps extends BaseComponent {
    id?: string;
    index: number;
    currency?: string | null;
    price?: string | null;
    onClick?: () => void;
    onDelete?: (id: string) => void;
    selected?: boolean;
    type?: 'normal' | 'error' | null;
    error?: ReactNode;
}

export default function PreviewItem({
    id,
    index,
    children,
    currency = '€',
    price,
    onClick,
    onDelete,
    className,
    selected,
    type = 'normal',
    error,
}: PreviewItemProps): JSX.Element {
    const classes = useTicketOverviewStyles();

    return (
        <div className={classes.row}>
            <div className={classes.rowNumber}>{index + 1}</div>
            <div
                className={classNames(
                    className,
                    classes.infoContainer,
                    selected && classes.itemSelected,
                    type === 'error' && classes.error,
                    error && classes.infoContainerWithErrorText
                )}
                onClick={onClick}
            >
                {children}
                {error && <p className={classes.errorExplanation}>{error}</p>}
            </div>

            {price && (
                <div>
                    {currency && <span className={classes.symbolContainer}>{currency}</span>}
                    <span className={classes.disabledInputContainer}>{price}</span>
                </div>
            )}
            {onDelete && id && (
                <div className={classes.deleteIcon}>
                    <DeleteIcon onClick={() => onDelete(id)} />
                </div>
            )}
        </div>
    );
}
