import { useState } from 'react';
import AddContractForm from 'src/app/components/forms/AddContractForm';
import { useCreateContractData } from 'src/app/hooks/useCreateContractData';
import { useCreateTemplateTickets } from 'src/app/hooks/useCreateTemplateTickets';
import { parseErrors } from 'src/app/utilities/helpers/errors';
import ErrorsList from 'src/view/components/errors-list/ErrorsList';

interface Props {
    eventId: string;
    onCreated?: () => void;
}

export default function AddContractFeature({ eventId, onCreated }: Props) {
    const [selectedContract, setSelectedContract] = useState<string>();
    const [selectedTemplate, setSelectedTemplate] = useState<string>();

    const {
        queriesData: { templateTicketsData, contractsData, templatesData },
        contractOptions,
        templatesOptions,
        notes,
    } = useCreateContractData({
        eventId,
        contractId: selectedContract,
        templateId: selectedTemplate,
    });

    const {
        mutate,
        isLoading: isLoadingCreateTemplateTicket,
        isError,
        error,
    } = useCreateTemplateTickets(onCreated);

    const isLoading =
        templateTicketsData.isFetching ||
        contractsData.isFetching ||
        templatesData.isFetching ||
        isLoadingCreateTemplateTicket;

    return (
        <>
            {isError && <ErrorsList errors={parseErrors(error)} />}

            <AddContractForm
                loading={isLoading}
                contractOptions={contractOptions}
                ticketTemplateOptions={templatesOptions}
                notes={notes}
                onFormSubmit={(values, templateItems) => {
                    if (!values.contract || !values.ticketTemplateSet || !templateItems) return;

                    mutate({
                        values,
                        eventId,
                        items: templateItems,
                    });
                }}
                ticketTemplateItems={templateTicketsData.data?.data.data}
                onChangeContract={(opt) => setSelectedContract(opt?.value)}
                onChangeTemplate={(opt) => setSelectedTemplate(opt?.value)}
                isFinishedFetchingContracts={!contractsData.isLoading}
                isFinishedFetchingTemplates={!templateTicketsData.isLoading}
            />
        </>
    );
}
