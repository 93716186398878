import { useState } from 'react';
import OrderLineTableFeature from 'src/app/components/features/orderline/OrderLineTableFeature';
import Orderline from 'src/data/models/orderline/orderline';
import Modal from 'src/view/components/modal/Modal';
import AssignTicketsFeature from 'src/app/components/features/orderline/AssignTicketsFeature';
import OrderlineDetailsFeature from 'src/app/components/features/orderline/OrderlineDetailsFeature';

export const OrderlinesOverviewFeature = () => {
    const [activeOrderLine, setActiveOrderLine] = useState<Orderline | undefined>();
    const [orderLinesInvalidatedAt, setOrderLinesInvalidatedAt] = useState<number | undefined>();
    const [detailsModalOpen, setDetailsModalOpen] = useState(false);
    const [assignModalOpen, setAssignModalOpen] = useState(false);

    const renderOrderLineDetailsModal = () => {
        if (!activeOrderLine?.id || !activeOrderLine?.orderId) return <></>;

        return (
            <Modal
                title="Orderline Details"
                open={detailsModalOpen}
                onClose={() => {
                    setDetailsModalOpen(false);
                }}
            >
                <OrderlineDetailsFeature
                    orderlineId={activeOrderLine.id}
                    orderId={activeOrderLine.orderId}
                    onClickAssign={() => setAssignModalOpen(true)}
                    onActionSucceeded={() => setOrderLinesInvalidatedAt(Date.now())}
                    invalidatedAt={orderLinesInvalidatedAt}
                />
            </Modal>
        );
    };

    return (
        <>
            <OrderLineTableFeature
                invalidatedAt={orderLinesInvalidatedAt}
                onClickAssign={(o: Orderline) => {
                    setActiveOrderLine(o);
                    setAssignModalOpen(true);
                }}
                onClickDetails={(o: Orderline) => {
                    setActiveOrderLine(o);
                    setDetailsModalOpen(true);
                }}
            />

            {renderOrderLineDetailsModal()}

            {activeOrderLine && (
                <Modal
                    title="Assign Tickets Modal"
                    width="fluid"
                    open={assignModalOpen}
                    onClose={() => setAssignModalOpen(false)}
                >
                    <AssignTicketsFeature
                        orderId={activeOrderLine.orderId}
                        orderLineId={activeOrderLine.id}
                        onOrderlineInvalidated={() => setOrderLinesInvalidatedAt(Date.now())}
                        onSuccessfullyAssignTickets={() => setAssignModalOpen(false)}
                    />
                </Modal>
            )}
        </>
    );
};
