import { getInternalAxiosInstance } from 'src/data/api/api';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import QueryParam from 'src/data/api/common/QueryParam';
import { getQueryParams } from 'src/data/api/endpointUrlBuilder';
import ApiResponse from 'src/data/api/responses/ApiResponse';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import PaginatedApiResponseBody from 'src/data/api/responses/PaginatedApiResponseBody';
import PaginationMeta from 'src/data/api/responses/PaginationMeta';
import {
    ORDERS_CHANGE_PAYMENT_STATUS_ENDPOINT,
    ORDERS_COMPARE_ENDPOINT,
    ORDERS_GET_BY_ID_GET_ENDPOINT,
    ORDERS_GET_ENDPOINT,
    ORDERS_ORDER_REVOKE_MANUALLY_SENT_TICKETS,
    ORDERS_UPDATE_INTERNAL_NOTES_ENDPOINT,
} from 'src/data/constants/endpoints/orders-endpoints';
import { FINANCE_PAYMENT_TYPES, PAYMENT_STATUS } from 'src/data/enums/order';
import { EmptyBody } from 'src/data/models/common/emptyBody';
import { IdResponse } from 'src/data/models/common/idResponse';
import Price from 'src/data/models/common/price';
import { CustomerAddress } from 'src/data/models/customer/customer';
import EventOrder from 'src/data/models/order/EventOrder';
import {
    EVENTS_CREATE_ORDER_ENDPOINT,
    EVENTS_MARK_TICKETS_AS_SENT_ENDPOINT,
    EVENTS_REVOKE_TICKETS_FOR_ORDER_ENDPOINT,
    EVENTS_SEND_TICKETS_FOR_ORDER_ENDPOINT,
} from '../constants/endpoints/events-endpoints';

async function fetchOrders(
    options: BaseFetchOptions = {
        includes: [],
    }
): Promise<ApiResponse<PaginatedApiResponseBody<EventOrder, PaginationMeta>>> {
    const extraQueryParams: QueryParam[] = [];

    if (options.q) {
        extraQueryParams.push({
            key: 'q',
            value: options.q,
        });
    }

    return await getInternalAxiosInstance()
        .get<PaginatedApiResponseBody<EventOrder>>(ORDERS_GET_ENDPOINT, {
            params: options && getQueryParams(options, extraQueryParams),
        })
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export async function fetchOrderById(id: string) {
    return await getInternalAxiosInstance().get<ApiResponseBody<EventOrder>>(
        ORDERS_GET_BY_ID_GET_ENDPOINT(id)
    );
}

async function sendTicketsForOrder(
    eventId: string,
    orderIds: string[]
): Promise<ApiResponse<ApiResponseBody<EmptyBody>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<EmptyBody>>(EVENTS_SEND_TICKETS_FOR_ORDER_ENDPOINT(eventId), {
            orderIds,
        })
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function revokeTicketsForOrder(
    eventId: string,
    orderId: string,
    force = false
): Promise<ApiResponse<ApiResponseBody<EmptyBody>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<EmptyBody>>(
            EVENTS_REVOKE_TICKETS_FOR_ORDER_ENDPOINT(eventId, orderId),
            {
                force,
            }
        )
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function markTicketsAsManuallySent(
    eventId: string,
    orderIds: string[]
): Promise<ApiResponse<ApiResponseBody<EmptyBody>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<EmptyBody>>(EVENTS_MARK_TICKETS_AS_SENT_ENDPOINT(eventId), {
            orderIds,
        })
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function revokeManuallySentTicketsByOrderId(
    orderId: string
): Promise<ApiResponse<ApiResponseBody<EmptyBody>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<EmptyBody>>(ORDERS_ORDER_REVOKE_MANUALLY_SENT_TICKETS(orderId))
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

interface changePaymentStatusDto {
    paymentStatus: PAYMENT_STATUS;
    reason: FINANCE_PAYMENT_TYPES;
}

async function changePaymentStatus(
    orderId: string,
    dto: changePaymentStatusDto
): Promise<ApiResponse<ApiResponseBody<EmptyBody>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<EmptyBody>>(ORDERS_CHANGE_PAYMENT_STATUS_ENDPOINT(orderId), dto)
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export interface CreateOrderDto {
    externalReferenceId?: string;
    orderNumber?: string;
    salesProfileId?: string;
    sourceSystem: string;
    orderedAt?: string;
    internalNotes?: string;
    assignmentInstructions?: string;
    invoiceNotes?: string;
    invoiceNumber?: string;
    invoiceContactId: string;
    invoiceContactAddress: CustomerAddress;
    deliveryContactId: string;
    deliveryContactAddress: CustomerAddress;
    orderLines: CreateOrderOrderlineDto[];
}

export interface CreateOrderOrderlineDto {
    quantity: number;
    eventId: string;
    seatingPlanCategoryId: string;
    blockId?: string;
    splitValue?: string;
    internalNotes?: string;
    assignmentInstructions?: string;
    price?: Price;
    assignedTicketsIds: string[];
}

async function createOrder(
    eventId: string,
    dto: CreateOrderDto
): Promise<ApiResponse<ApiResponseBody<IdResponse>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<IdResponse>>(EVENTS_CREATE_ORDER_ENDPOINT(eventId), dto)
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function updateOrderInternalNotes(
    orderId: string,
    internalNotes: string
): Promise<ApiResponse<ApiResponseBody<IdResponse>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<IdResponse>>(ORDERS_UPDATE_INTERNAL_NOTES_ENDPOINT(orderId), {
            internalNotes,
        })
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export interface CompareOrdersDto {
    startDate: string;
    endDate: string;
    eventId?: string;
    seriesId?: string;
    file: File;
}

export enum REASON_TYPE {
    MissingInEtbaas = 'MissingInEtbaas',
    MissingInUpload = 'MissingInUpload',
}

export interface OrdersWithReasons {
    orderNumber: string;
    reason: REASON_TYPE;
}

export interface CompareOrdersResponse {
    extraOrderExternalRefIds: number;
    missingOrderExternalRefIds: number;
    uploadedExternalRefIds: number;
    reasons: OrdersWithReasons[];
}

async function compareOrders(dto: CompareOrdersDto) {
    const formData = new FormData();
    formData.append('file', dto.file);

    const query = `${ORDERS_COMPARE_ENDPOINT}?startDate=${dto.startDate}&endDate=${
        dto.endDate
    }&eventId=${dto.eventId || ''}&seriesId=${dto.seriesId || ''}`;

    return await getInternalAxiosInstance()
        .post<ApiResponseBody<CompareOrdersResponse>>(query, formData)
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export default {
    // fetchOrderById,
    sendTicketsForOrder,
    revokeTicketsForOrder,
    markTicketsAsManuallySent,
    changePaymentStatus,
    createOrder,
    fetchOrders,
    updateOrderInternalNotes,
    compareOrders,
    revokeManuallySentTicketsByOrderId,
};
