import { useMemo } from 'react';
import FilterOption from 'src/data/api/common/FilterOption';
import tableFilterFormHelper from 'src/app/utilities/helpers/tableFilterFormHelper';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';
import ErrorsList from 'src/view/components/errors-list/ErrorsList';
import SeriesFilterForm, {
    SeriesFilterFormValues,
} from 'src/app/components/forms/SeriesFiltersForm';
import { FilterAutoCompleteOption } from 'src/view/components/filters/AutoComplete/AutoComplete';
import useFetchSeriesFilters from 'src/app/hooks/useFetchSeriesFilters';
import { parseErrors } from 'src/app/utilities/helpers/errors';

interface Props {
    filters: FilterOption[];
    initialSearch?: string;
    onChangeFilterOptions?: (options: FilterOption[]) => void;
    onChangeSearchTerm?: (q: string) => void;
}

export default function SeriesFilterFormDataWrapper({
    filters,
    initialSearch,
    onChangeFilterOptions,
    onChangeSearchTerm,
}: Props): JSX.Element {
    const { data: seriesData, error, isLoading, isError } = useFetchSeriesFilters(filters);

    const seriesGroupOptions = useMemo(() => {
        if (!seriesData) return [];

        return seriesData.data.data.map((s) => {
            return {
                label: s.name,
                value: s.id,
            };
        });
    }, [seriesData]);

    const findSeriesOptions = (): FilterAutoCompleteOption | undefined => {
        return tableFilterFormHelper.getInitialAutocompleteValues(
            'seriesGroupId',
            filters,
            seriesGroupOptions
        )?.[0];
    };

    const formDefaultValues: SeriesFilterFormValues = {
        seriesGroup: findSeriesOptions() ?? null,
        searchTerm: initialSearch,
    };

    if (isLoading && !seriesData) {
        return <LoadingOverlay />;
    }

    if (isError) {
        return <ErrorsList errors={parseErrors(error)} />;
    }

    return (
        <SeriesFilterForm
            defaultValues={formDefaultValues}
            seriesGroupOptions={seriesGroupOptions}
            onChangeFilterOptions={onChangeFilterOptions}
            onChangeSearchTerm={onChangeSearchTerm}
        />
    );
}
