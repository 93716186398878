import makeStyles from '@mui/styles/makeStyles';

const standardSpacing = 1;

export const useSpacingStyles = makeStyles((theme) => ({
    spacingVertical: {
        margin: theme.spacing(standardSpacing, 0),
    },
    spacingRight: {
        marginRight: theme.spacing(standardSpacing),
    },
    spacingLeft: {
        marginLeft: theme.spacing(standardSpacing),
    },
    spacingTop: {
        marginTop: theme.spacing(standardSpacing),
    },
    spacingBottom: {
        marginBottom: theme.spacing(standardSpacing),
    },
    noMarginTop: {
        marginTop: 0,
    },
    noMarginBottom: {
        marginBottom: 0,
    },
    noMarginLeft: {
        marginLeft: 0,
    },
    noMarginRight: {
        marginRight: 0,
    },
    noMarginVertical: {
        marginTop: 0,
        marginBottom: 0,
    },
}));
