import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';
import { useCreateSeatingPlanCategory } from 'src/app/hooks/useCreateSeatingPlanCategory';
import {
    useFetchSeatingPlanCategories,
    FETCH_SEATING_PLAN_CATEGORIES_QUERY_KEY,
} from 'src/app/hooks/useFetchSeatingPlanCategories';
import { useGetEventById } from 'src/app/hooks/useGetEventById';
import { parseErrors } from 'src/app/utilities/helpers/errors';
import AutoComplete, {
    type AutoCompleteProps,
} from 'src/view/components/auto-complete/AutoComplete';
import ErrorsList from 'src/view/components/errors-list/ErrorsList';

interface Props extends Omit<AutoCompleteProps, 'onCreateOption' | 'options' | 'loading'> {
    eventId: string;
    hideArchivedSpcs?: boolean;
    excludedSeatingPlanCategories?: {
        eventId: string;
        seatingPlanCategoryId: string;
    }[];
    enableCreation?: boolean;
}

export const SeatingPlanCategoriesAutocompleteFeature = ({
    eventId,
    hideArchivedSpcs,
    value,
    excludedSeatingPlanCategories,
    enableCreation,
    ...autocompleteProps
}: Props) => {
    const queryClient = useQueryClient();
    const [inputValue, setInputValue] = useState('');
    const {
        data: seatingPlanCategoriesResponse,
        isLoading: isFetchSeatingPlanCategoriesLoading,
        isError: isFetchSeatingPlanCategoriesError,
        error: fetchSeatingPlanCategoriesError,
    } = useFetchSeatingPlanCategories(eventId, { q: inputValue });

    const {
        isFetching: isGetEventByIdFetching,
        refetch: refetchEvent,
        isError: isGetEventByIdError,
        error: getEventByIdError,
    } = useGetEventById(eventId, undefined, {
        enabled: false,
    });

    const {
        mutateAsync: createSeatingPlan,
        isLoading: isCreateSeatingPlanCategoryLoading,
        isError: isCreateSeatingPlanCategoryError,
        error: createSeatingPlanCategoryError,
    } = useCreateSeatingPlanCategory({
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [FETCH_SEATING_PLAN_CATEGORIES_QUERY_KEY, eventId],
            });
        },
    });

    const excludedCategoriesPerEvent = useMemo(
        () =>
            (excludedSeatingPlanCategories ?? [])
                .filter((cat) => cat.eventId === eventId)
                .map((cat) => cat.seatingPlanCategoryId),
        [eventId, excludedSeatingPlanCategories]
    );

    const options = useMemo(() => {
        return (seatingPlanCategoriesResponse?.data.data.categories ?? [])
            .filter((item) => {
                if (excludedCategoriesPerEvent.includes(item.id)) return false;
                if (hideArchivedSpcs) return !item.isArchived;
                return true;
            })
            .map((item) => ({
                label: `${item.name}${item.isArchived ? ' (archived)' : ''}`,
                value: item.id,
            }));
    }, [seatingPlanCategoriesResponse, hideArchivedSpcs, excludedCategoriesPerEvent]);

    const onCreateSeatingPlanCategory = useCallback(
        async (name: string) => {
            try {
                const eventResponse = await refetchEvent();

                if (eventResponse?.data) {
                    const result = await createSeatingPlan({
                        seatingPlanId: eventResponse.data.data.data.seatingPlanId,
                        dto: {
                            name,
                        },
                    });

                    const createdOption = { label: name, value: result.data.data.id };

                    autocompleteProps.onChange(createdOption);
                }
            } catch (e) {
                /**
                 * The errors will be caught by the hooks and displayed below
                 * This try/catch block exists to prevent a unhandled runtime exception
                 */
            }
        },
        [refetchEvent, createSeatingPlan, autocompleteProps]
    );

    const isLoading =
        isFetchSeatingPlanCategoriesLoading ||
        isGetEventByIdFetching ||
        isCreateSeatingPlanCategoryLoading;

    return (
        <>
            <AutoComplete
                {...autocompleteProps}
                loading={isLoading}
                value={value}
                onCreateOption={enableCreation ? onCreateSeatingPlanCategory : undefined}
                options={options}
                onInputChange={(value, actionMeta) => {
                    setInputValue(value);
                    autocompleteProps.onInputChange?.(value, actionMeta);
                }}
                data-cy="seating-plan-category-autocomplete"
            />
            {isFetchSeatingPlanCategoriesError && (
                <ErrorsList errors={parseErrors(fetchSeatingPlanCategoriesError)} />
            )}
            {isCreateSeatingPlanCategoryError && (
                <ErrorsList errors={parseErrors(createSeatingPlanCategoryError)} />
            )}
            {isGetEventByIdError && <ErrorsList errors={parseErrors(getEventByIdError)} />}
        </>
    );
};
