import { useEffect } from 'react';
import useApiFetch from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import PaginatedApiResponseBody from 'src/data/api/responses/PaginatedApiResponseBody';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import EventOrder from 'src/data/models/order/EventOrder';
import orderService from 'src/data/services/orderService';
import eventService from 'src/data/services/eventService';
import PaginationMeta from 'src/data/api/responses/PaginationMeta';

interface FetchOrdersDataWrapperChildrenParams
    extends DataWrapperChildrenParams<PaginatedApiResponseBody<EventOrder>> {
    fetchOrders: () => Promise<PaginatedApiResponseBody<EventOrder, PaginationMeta>>;
}

interface FetchOrdersDataWrapperProps
    extends DataWrapperProps<FetchOrdersDataWrapperChildrenParams>,
        BaseFetchOptions {
    eventId?: string;
}

export const useFetchOrdersDataWrapper = ({
    eventId,
    includes = [],
    onError,
    onSuccess,
    invalidatedAt,
    page,
    pageSize,
    filter,
    sorting,
    q,
}: Omit<FetchOrdersDataWrapperProps, 'children'>) => {
    const [{ loading, data, errors, resetData }, handleFetchOrders] =
        useApiFetch<PaginatedApiResponseBody<EventOrder>>();

    const fetchOrdersCall = () => {
        if (eventId) {
            return handleFetchOrders(
                eventService.fetchEventOrdersOverview(eventId, {
                    includes,
                    page,
                    pageSize,
                    filter,
                    sorting,
                    q,
                }),
                {
                    useDefaultErrorHandler: true,
                    onFail: onError,
                    onSuccess,
                }
            );
        }

        return handleFetchOrders(
            orderService.fetchOrders({
                includes,
                page,
                pageSize,
                filter,
                sorting,
                q,
            }),
            {
                useDefaultErrorHandler: true,
                onFail: onError,
                onSuccess,
            }
        );
    };

    useEffect(() => {
        fetchOrdersCall();
    }, [page, pageSize, filter, sorting, q, invalidatedAt, eventId]);

    return {
        fetchOrders: fetchOrdersCall,
        data,
        loading,
        errors,
        resetData,
    };
};

export const FetchOrdersDataWrapper = (props: FetchOrdersDataWrapperProps) => {
    const { children } = props;
    const hookData = useFetchOrdersDataWrapper(props);

    if (!children) return <></>;

    return <>{children(hookData)}</>;
};
