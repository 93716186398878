import React, { useEffect } from 'react';
import Table from 'src/view/components/table/table/Table';
import {
    NewTableColumn,
    TablePaginationData,
    TABLE_CELL_WIDTH,
} from 'src/view/components/table/table/Types';
import { dateOnlyFormat } from 'src/shared/date';
import moment from 'moment';
import { Event } from 'src/data/models/events/event';
import eventUrlService from 'src/app/pages/events/eventUrlService';

export interface EventsPopoverTableProps {
    events: Event[];
    loading?: boolean;
    pagination: TablePaginationData;
    linkText: string;
    orderId?: string;
}
export default function EventsPopoverTable({
    loading,
    events,
    pagination,
    linkText,
    orderId,
}: EventsPopoverTableProps): JSX.Element {
    const [columns, setColumns] = React.useState<NewTableColumn<Event>[]>([]);

    useEffect(() => {
        let cols: NewTableColumn<Event>[] = [];

        cols = [
            {
                key: 'dateTimeStart',
                title: 'Date start',
                width: TABLE_CELL_WIDTH.SMALL,
                cellRenderer: (rowData) => {
                    return moment(rowData.dateTimeStart).format(dateOnlyFormat);
                },
            },
            {
                key: 'name',
                title: 'event name',
                width: TABLE_CELL_WIDTH.LARGE,
                cellRenderer: (rowData) => rowData.name,
            },
            {
                width: 55,
                cellRenderer: (rowData) => (
                    <a href={eventUrlService.orders(rowData.id, { orderId })}>{linkText}</a>
                ),
            },
        ];

        setColumns(cols);
    }, []);

    return (
        <Table<Event>
            data={events}
            rowIdResolver={(rowData: Event) => ({ id: rowData.id })}
            columns={columns}
            loading={loading}
            pagination={pagination.totalPages > 1 ? pagination : undefined}
            maxWidth={650}
        />
    );
}
