import { IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';
import { useSpacingStyles } from 'src/shared/styles/spacingStyles';
import { ChildlessBaseComponent } from 'src/view/interfaces/BaseComponent';

const useStyles = makeStyles((theme) => ({
    container: {
        background: theme.colors.lightBlue,
        padding: theme.spacing(2),
        borderRadius: theme.layout.borderRadius.regular,
        overflowY: 'scroll',
    },
    listItem: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: theme.spacing(1, 2),
        background: 'white',
        borderRadius: theme.layout.borderRadius.regular,
    },
    listItemLabel: {
        marginRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightBold,
        fontSize: theme.typography.pxToRem(13),
    },
    actionsContainer: {
        display: 'flex',
        flexShrink: 0,
        flexGrow: 0,
    },
    clickableItem: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
}));

interface OptionsListProps {
    items: OptionsListItem[];
}

interface OptionsListItem {
    label: string;
    onClick?: () => void;
    actions?: OptionsListItemAction[];
    hidden?: boolean;
}

interface OptionsListItemAction {
    icon: JSX.Element;
    onClick?: () => void;
    url?: string;
}

export const OptionsList = ({ items, className }: OptionsListProps & ChildlessBaseComponent) => {
    const classes = useStyles();
    const spacingClasses = useSpacingStyles();

    return (
        <div className={classnames(classes.container, className)}>
            {items.map((item, index) => {
                if (item.hidden) return <></>;

                return (
                    <div
                        className={classnames(
                            classes.listItem,
                            index !== items.length - 1 && spacingClasses.spacingBottom,
                            item.onClick && classes.clickableItem
                        )}
                        onClick={item.onClick}
                        key={item.label}
                    >
                        <div className={classes.listItemLabel}>{item.label}</div>
                        {item.actions && item.actions.length > 0 && (
                            <div className={classes.actionsContainer}>
                                {item.actions.map((action) => (
                                    <IconButton
                                        href={action.url || ''}
                                        className={spacingClasses.spacingLeft}
                                        size="small"
                                    >
                                        {action.icon}
                                    </IconButton>
                                ))}
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
};
