import { zodResolver } from '@hookform/resolvers/zod';
import { Grid } from '@mui/material';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import PurchaseDetailsFeature from 'src/app/components/features/purchase/PurchaseDetailsFeature';
import { TableHeaderTotalFeature } from 'src/app/components/features/tables/TableHeaderTotalFeature';
import { PurchaseDetailsFormValues } from 'src/app/components/forms/CreatePurchaseDetailsForm';
import PurchasesFilterForm from 'src/app/components/forms/PurchasesFilterForm';
import { PopUpForm } from 'src/app/components/pop-up-form/pop-up-form';
import PurchasesTable from 'src/app/components/tables/PurchasesTable';
import { config } from 'src/app/constants/config/config';
import { useFetchPurchaseById } from 'src/app/hooks/purchases/useFetchPurchaseById';
import { useFetchPurchases } from 'src/app/hooks/purchases/useFetchPurchases';
import { usePurchaseFilters } from 'src/app/hooks/purchases/usePurchaseFilters';
import PurchaseUrlService from 'src/app/pages/purchases/purchaseUrlService';
import currencyOptions from 'src/app/utilities/mappers/mapCurrenciesToOptions';
import mapPurchaseDataToViewModel from 'src/app/utilities/mappers/mapPurchaseDataToFormValues';
import tableSortingToSortingOptionMapper from 'src/app/utilities/mappers/table/tableSortingToSortingOptionMapper';
import { zodCreateOrEditPurchaseValidationSchema } from 'src/app/utilities/zod/purchase/zodCreateOrEditPurchaseValidationSchema';
import SortingOption from 'src/data/api/common/SortingOption';
import Purchase from 'src/data/models/purchase/Purchase';
import { useSpacingStyles } from 'src/shared/styles/spacingStyles';
import Button from 'src/view/components/button/Button';
import { ETBContainer } from 'src/view/components/container/container';
import Header from 'src/view/components/header/Header';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';
import Modal from 'src/view/components/modal/Modal';
import { TableColumnSorting } from 'src/view/components/table/table/Types';

interface PurchasePageProps {
    eventId?: string;
    eventName?: string;
}

const initialValues: PurchaseDetailsFormValues = {
    purchaseDate: new Date(Date.now()),
    currency: currencyOptions[0],
};

export default function PurchasesOverview({ eventId, eventName }: PurchasePageProps) {
    const spacingClasses = useSpacingStyles();
    const navigate = useNavigate();

    const [showEditModal, setShowEditModal] = useState(false);
    const [notesModalContent, setNotesModalContent] = useState<string | null>(null);
    const [purchase, setPurchase] = useState<Purchase | undefined>();
    const [sorting, setSorting] = useState<SortingOption[]>([]);

    const purchaseData = useFetchPurchaseById(purchase?.id ?? '', {
        enabled: !!purchase?.id,
        onSuccess: (response) => {
            form.reset(
                { ...initialValues, ...mapPurchaseDataToViewModel(response.data?.data) },
                {
                    keepTouched: false,
                    keepDefaultValues: false,
                    keepDirty: false,
                    keepDirtyValues: false,
                    keepErrors: false,
                    keepIsSubmitSuccessful: false,
                    keepIsSubmitted: false,
                    keepIsValid: false,
                    keepSubmitCount: false,
                    keepValues: false,
                }
            );
        },
    });

    const form = useForm<PurchaseDetailsFormValues>({
        mode: 'all',
        resolver: zodResolver(zodCreateOrEditPurchaseValidationSchema),
    });

    const onPurchaseEdit = (purchase: Purchase) => {
        setPurchase(purchase);
        setShowEditModal(true);
    };

    const renderPurchaseNotesModal = (): JSX.Element => {
        return (
            <Modal
                title="Purchase notes"
                open={!!notesModalContent}
                onClose={() => setNotesModalContent(null)}
                width="small"
            >
                <>{notesModalContent}</>
            </Modal>
        );
    };

    const handleSortingChange = (tableSorting: TableColumnSorting[]) => {
        if (sorting.length === 0 && tableSorting.length === 0) return;

        setSorting(tableSorting.map(tableSortingToSortingOptionMapper));
    };
    const {
        defaultValues,
        filterOptions,
        customFields,
        eventsFiltersOptions,
        setFilterOptions,
        setCustomFields,
    } = usePurchaseFilters();

    const { data, isLoading } = useFetchPurchases(
        {
            filter: filterOptions,
            q: customFields.q || '',
            sorting: sorting,
            page: Number(customFields['page'] || 1),
            pageSize: config.ITEMS_PER_PAGE_STANDARD,
        },
        eventId
    );

    return (
        <>
            <Header GridProps={{ justifyContent: 'space-between' }}>
                <Grid item>
                    <h1>{eventName}</h1>
                </Grid>
                <Grid item>
                    <Button
                        className={spacingClasses.spacingRight}
                        color="primary"
                        onClick={() =>
                            navigate(
                                eventId
                                    ? PurchaseUrlService.create({ eventId, eventName })
                                    : PurchaseUrlService.create()
                            )
                        }
                    >
                        Create Purchase
                    </Button>
                </Grid>
            </Header>
            <ETBContainer>
                <>
                    <PurchasesFilterForm
                        defaultValues={defaultValues}
                        setFilterOptions={setFilterOptions}
                        setCustomFields={setCustomFields}
                        eventsFiltersOptions={eventsFiltersOptions}
                        customFields={customFields}
                    />
                    {isLoading && <LoadingOverlay />}
                    <TableHeaderTotalFeature
                        title="Purchases"
                        totalResults={data?.data.meta.total}
                        priceTotals={data?.data.meta.totalPriceSumPerCurrency}
                    />
                    <PurchasesTable
                        eventId={eventId}
                        purchases={data?.data.data}
                        loading={isLoading}
                        pagination={{
                            currentPage: data?.data.meta.currentPage || 1,
                            totalPages: data?.data.meta.totalPages || 1,
                            onPaginate: (page: number) =>
                                setCustomFields({
                                    ...customFields,
                                    page: page.toString(),
                                }),
                        }}
                        onSortingChange={handleSortingChange}
                        onPurchaseEdit={onPurchaseEdit}
                    />
                    {!!purchase?.id && (
                        <PopUpForm
                            formState={form.formState}
                            open={showEditModal}
                            onClose={() => {
                                setShowEditModal(false);
                            }}
                            confirmOptions={{
                                title: 'Are you sure you want to exit editing purchase details?',
                                description: 'Any unsaved changes will be lost',
                            }}
                        >
                            <div>
                                <PurchaseDetailsFeature
                                    purchaseData={purchaseData}
                                    form={form}
                                    purchaseId={purchase.id}
                                />
                            </div>
                        </PopUpForm>
                    )}
                    {renderPurchaseNotesModal()}
                </>
            </ETBContainer>
        </>
    );
}
