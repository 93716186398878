import { useUpdateSeriesGroupDataWrapper } from 'src/app/components/data-wrappers/series-groups/UpdateSeriesGroupDataWrapper';
import CreateSeriesGroupForm from 'src/app/components/forms/CreateSeriesGroupForm';
import { useFetchSeriesGroupByIdDataWrapper } from 'src/app/components/data-wrappers/series-groups/FetchSeriesGroupByIdDataWrapper';

interface UpdateSeriesGroupFeature {
    seriesGroupId: string;
    onSuccess: () => void;
    onError?: () => void;
}

export const UpdateSeriesGroupFormFeature = ({
    seriesGroupId,
    onSuccess,
    onError,
}: UpdateSeriesGroupFeature) => {
    const { loading: updateSeriesIsLoading, updateSeriesGroup } = useUpdateSeriesGroupDataWrapper({
        seriesGroupId,
        onSuccess,
        onError,
    });

    const { loading: fetchByIdIsLoading, data } = useFetchSeriesGroupByIdDataWrapper({
        seriesGroupId,
    });

    return (
        <CreateSeriesGroupForm
            formDefaultValues={{
                name: data?.data.name,
            }}
            dataLoading={fetchByIdIsLoading}
            actionLoading={updateSeriesIsLoading}
            onFormSubmit={(values) => {
                if (!values.name) return;

                updateSeriesGroup({
                    name: values.name,
                });
            }}
            editMode={true}
        />
    );
};
