import useApiFetch, { ErrorReturn } from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import { IdResponse } from 'src/data/models/common/idResponse';
import seriesGroupService, { CreateSeriesGroupDto } from 'src/data/services/seriesGroupService';

interface UpdateSeriesGroupDataWrapperChildrenParams
    extends DataWrapperChildrenParams<ApiResponseBody<IdResponse>> {
    updateSeriesGroup: (dto: CreateSeriesGroupDto) => Promise<void | ErrorReturn>;
}

interface UpdateSeriesGroupDataWrapperProps
    extends DataWrapperProps<
            UpdateSeriesGroupDataWrapperChildrenParams,
            ApiResponseBody<IdResponse>
        >,
        BaseFetchOptions {
    seriesGroupId: string;
}

export const useUpdateSeriesGroupDataWrapper = ({
    seriesGroupId,
    onError,
    onSuccess,
}: Omit<UpdateSeriesGroupDataWrapperProps, 'children'>) => {
    const [{ data, loading, errors, resetData }, handleCreateSeriesGroup] =
        useApiFetch<ApiResponseBody<IdResponse>>();

    const updateSeriesGroupCall = (dto: CreateSeriesGroupDto) => {
        return handleCreateSeriesGroup(seriesGroupService.updateSeriesGroup(seriesGroupId, dto), {
            useDefaultErrorHandler: true,
            defaultSuccessMessage: 'Series Group has been update',
            onFail: onError,
            onSuccess,
        });
    };

    return {
        updateSeriesGroup: updateSeriesGroupCall,
        data,
        loading,
        errors,
        resetData,
    };
};
