const ticketFormReadableFormat: { [key: string]: string } = {
    MemberCard: 'MEMBER CARD',
    MemberCardSeatingPlanCategoryTickets: 'MEMBER CARD',
    MemberCardBlockTickets: 'MEMBER CARD',
    MemberCardRowTickets: 'MEMBER CARD',
    MemberCardSeatTickets: 'MEMBER CARD',
    MobileSeatingPlanCategoryTickets: 'MOBILE',
    MobileBlockTickets: 'MOBILE',
    MobileRowTickets: 'MOBILE',
    MobileSeatTickets: 'MOBILE',
    ElectronicSeatingPlanCategoryTickets: 'ELECTRONIC',
    ElectronicBlockTickets: 'ELECTRONIC',
    ElectronicRowTickets: 'ELECTRONIC',
    ElectronicSeatTickets: 'ELECTRONIC',
    PaperSeatingPlanCategoryTickets: 'PAPER',
    PaperBlockTickets: 'PAPER',
    PaperRowTickets: 'PAPER',
    PaperSeatTickets: 'PAPER',
};

export default function formatTicketType(value: string): string {
    return ticketFormReadableFormat[value] || value;
}
