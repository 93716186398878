import {
    SeriesDetailsFormValues,
    seriesStatusOptions,
} from 'src/app/components/forms/SerieDetailsForm';
import Series from 'src/data/models/series/series';

export default function mapSerieDataToViewModel(purchase: Series): SeriesDetailsFormValues {
    const { name, active, eventsCount, seriesGroupName, seriesGroupId } = purchase;

    return {
        name,
        active: active ? seriesStatusOptions[0] : seriesStatusOptions[1],
        eventsCount,
        seriesGroup: {
            label: seriesGroupName,
            value: seriesGroupId,
        },
    };
}
