import { getInternalAxiosInstance } from 'src/data/api/api';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import QueryParam from 'src/data/api/common/QueryParam';
import { getQueryParams } from 'src/data/api/endpointUrlBuilder';
import ApiResponse from 'src/data/api/responses/ApiResponse';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import PaginatedApiResponseBody from 'src/data/api/responses/PaginatedApiResponseBody';
import {
    SERIES_CREATE_ENDPOINT,
    SERIES_DELETE_ENDPOINT,
    SERIES_GET_BY_ID_ENDPOINT,
    SERIES_GET_ENDPOINT,
    SERIES_UPDATE_ENDPOINT,
} from 'src/data/constants/endpoints/series-endpoints';
import UpdateSeriesDto from 'src/data/dtos/UpdateSeriesDto';
import { EmptyBody } from 'src/data/models/common/emptyBody';
import { IdResponse } from 'src/data/models/common/idResponse';
import Series from 'src/data/models/series/series';

export interface SeriesFetchOptions extends BaseFetchOptions {
    q?: string;
}

async function fetchSeries(
    options: SeriesFetchOptions
): Promise<ApiResponse<PaginatedApiResponseBody<Series>>> {
    const extraQueryParams: QueryParam[] = [];

    if (options.q) {
        extraQueryParams.push({
            key: 'q',
            value: options.q,
        });
    }

    return await getInternalAxiosInstance()
        .get<PaginatedApiResponseBody<Series>>(SERIES_GET_ENDPOINT, {
            params: options && getQueryParams(options, extraQueryParams),
        })
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function fetchSeriesById(seriesId: string): Promise<ApiResponse<ApiResponseBody<Series>>> {
    return await getInternalAxiosInstance()
        .get<ApiResponseBody<Series>>(SERIES_GET_BY_ID_ENDPOINT(seriesId))
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function updateSeriesById(
    seriesId: string,
    dto: UpdateSeriesDto
): Promise<ApiResponse<ApiResponseBody<Series>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<Series>>(SERIES_UPDATE_ENDPOINT(seriesId), dto)
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export interface CreateSeriesResponse {
    id: string;
}

async function createSeries(
    name: string,
    active: boolean,
    seriesGroupId?: string
): Promise<ApiResponse<ApiResponseBody<IdResponse>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<IdResponse>>(SERIES_CREATE_ENDPOINT, {
            name,
            active,
            seriesGroupId,
        })
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function deleteSeries(seriesId: string): Promise<ApiResponse<ApiResponseBody<EmptyBody>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<EmptyBody>>(SERIES_DELETE_ENDPOINT(seriesId))
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export default {
    fetchSeries,
    fetchSeriesById,
    updateSeriesById,
    createSeries,
    deleteSeries,
};
