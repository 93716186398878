import React, { useEffect } from 'react';
import Table from 'src/view/components/table/table/Table';
import { NewTableColumn, TABLE_CELL_WIDTH } from 'src/view/components/table/table/Types';
import TicketTemplateItem from 'src/data/models/contracts/ticketTemplateItem';
import { CategoryPrice } from 'src/app/components/forms/AddContractForm';

export interface TicketTemplatesTableProps {
    loading?: boolean;
    ticketTemplateItems?: TicketTemplateItem[];
    categoryPrices?: CategoryPrice[];
}

export default function TicketTemplatesTable({
    loading,
    ticketTemplateItems = [],
    categoryPrices = [],
}: TicketTemplatesTableProps): JSX.Element {
    const [columns, setColumns] = React.useState<NewTableColumn<TicketTemplateItem>[]>([]);

    useEffect(() => {
        let cols: NewTableColumn<TicketTemplateItem>[] = [];

        cols = [
            {
                key: 'split',
                title: 'Split',
                cellRenderer: (ticketTemplateItem) => <span>{ticketTemplateItem.split}</span>,
                width: TABLE_CELL_WIDTH.TINY,
            },
            {
                key: 'seatingPlanCategoryName',
                title: 'Category',
                cellRenderer: (ticketTemplateItem) => (
                    <span>{ticketTemplateItem.seatingPlanCategoryName}</span>
                ),
                width: TABLE_CELL_WIDTH.MEDIUM,
            },
            {
                key: 'blockName',
                title: 'Block',
                cellRenderer: (ticketTemplateItem) => <span>{ticketTemplateItem.blockName}</span>,
                width: TABLE_CELL_WIDTH.SMALL,
            },
            {
                key: 'rowNumber',
                title: 'Row',
                cellRenderer: (ticketTemplateItem) => <span>{ticketTemplateItem.rowNumber}</span>,
                width: TABLE_CELL_WIDTH.SMALL,
            },
            {
                key: 'seatNumber',
                title: 'Seat',
                cellRenderer: (ticketTemplateItem) => <span>{ticketTemplateItem.seatNumber}</span>,
                width: TABLE_CELL_WIDTH.SMALL,
            },
            {
                key: 'quantity',
                title: 'Quantity',
                cellRenderer: (ticketTemplateItem) => <span>{ticketTemplateItem.quantity}</span>,
                width: TABLE_CELL_WIDTH.SMALL,
            },
            {
                align: 'right',
                title: 'Purchase Price',
                cellRenderer: (ticketTemplateItem) => {
                    const category = categoryPrices.find(
                        (c) => c.categoryName === ticketTemplateItem.seatingPlanCategoryName
                    );

                    if (!category || !category.price) return '';

                    return `${category.currency?.value} ${category.price}`;
                },
                width: TABLE_CELL_WIDTH.EXTRA_LARGE,
            },
        ];

        setColumns(cols);
    }, [JSON.stringify(categoryPrices)]);

    return (
        <Table<TicketTemplateItem>
            rowIdResolver={(rowData) => ({ id: rowData.id })}
            data={ticketTemplateItems}
            columns={columns}
            loading={loading}
            minWidth={400}
        />
    );
}
