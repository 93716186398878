import React from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useSnackbar } from 'notistack';
import classNames from 'classnames';
import { ChildlessBaseComponent } from 'src/view/interfaces/BaseComponent';

const getSvgSize = (svgSize: TextCopierProps['svgSize']) => (svgSize === 'small' ? 10 : 15);

const useStyles = makeStyles((theme: Theme) => ({
    eventIdContainer: {
        fontSize: theme.typography.subtitle2.fontSize,
        display: 'flex',
        alignItems: 'center',
        padding: `${theme.spacing(0.5)}`,
        borderRadius: theme.layout.borderRadius.regular,
        '&:hover': {
            cursor: 'pointer',
            background: 'rgb(0, 0, 0, .05)',
        },
    },
    copySvg: ({ color, hideLabel, svgSize }: Partial<TextCopierProps>) => ({
        height: getSvgSize(svgSize),
        width: getSvgSize(svgSize),
        fill: color ? color : theme.colors.darkGrey,
        marginRight: hideLabel ? 0 : theme.spacing(0.5),
    }),
    text: ({ color }: Partial<TextCopierProps>) => ({
        color: color ? color : theme.colors.darkGrey,
    }),
}));

interface TextCopierProps {
    value: string;
    label?: string;
    color?: React.CSSProperties['color'];
    hideLabel?: boolean;
    svgSize?: 'small' | 'normal';
}

export const TextCopier = ({
    value,
    label,
    color,
    hideLabel,
    style,
    className,
    svgSize,
}: TextCopierProps & ChildlessBaseComponent) => {
    const classes = useStyles({ color, hideLabel, svgSize });
    const { enqueueSnackbar } = useSnackbar();

    const copyText = async () => {
        await navigator.clipboard.writeText(value);
        enqueueSnackbar(`Copied ${value} to clipboard`, {
            autoHideDuration: 4000,
        });
    };

    return (
        <div
            className={classNames(classes.eventIdContainer, className)}
            style={style}
            onClick={() => copyText()}
        >
            <ContentCopyIcon className={classNames(classes.copySvg)} />
            {!hideLabel && <span className={classes.text}>{label ? label : value}</span>}
        </div>
    );
};
