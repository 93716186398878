import React, { useEffect } from 'react';
import Table from 'src/view/components/table/table/Table';
import { NewTableColumn, TABLE_CELL_WIDTH } from 'src/view/components/table/table/Types';
import { PurchaseStatisticSeatingplanCategory } from 'src/data/models/purchase/PurchaseStatistic';
import { PricesPopOver } from 'src/app/components/pop-overs/common/PricesPopover';

export interface PurchaseStatisticsPopoverTableProps {
    seatingplanCategories: PurchaseStatisticSeatingplanCategory[];
    loading?: boolean;
}
export default function PurchaseStatisticsTable({
    loading,
    seatingplanCategories,
}: PurchaseStatisticsPopoverTableProps): JSX.Element {
    const [columns, setColumns] = React.useState<
        NewTableColumn<PurchaseStatisticSeatingplanCategory>[]
    >([]);

    useEffect(() => {
        let cols: NewTableColumn<PurchaseStatisticSeatingplanCategory>[] = [];

        cols = [
            {
                key: 'numTickets',
                title: '# of tickets',
                width: TABLE_CELL_WIDTH.SMALL,
                cellRenderer: (rowData) => {
                    return rowData.numTickets;
                },
            },
            {
                key: 'name',
                title: 'Category',
                width: TABLE_CELL_WIDTH.MEDIUM,
                cellRenderer: (rowData) => rowData.name,
            },
            {
                key: 'prices',
                title: 'Total price',
                width: TABLE_CELL_WIDTH.SMALL,
                cellRenderer: (rowData) => {
                    const priceOfFirstItem = rowData.originalPrices[0];

                    return (
                        <PricesPopOver
                            text={`${priceOfFirstItem?.currency} ${priceOfFirstItem?.value}`}
                            secondaryPrice={rowData.euroPrice}
                            prices={rowData.originalPrices || 0}
                        />
                    );
                },
            },
        ];

        setColumns(cols);
    }, []);

    return (
        <Table<PurchaseStatisticSeatingplanCategory>
            data={seatingplanCategories}
            rowIdResolver={(rowData) => ({ id: rowData.id })}
            columns={columns}
            loading={loading}
            maxWidth={550}
        />
    );
}
