import LaunchIcon from '@mui/icons-material/Launch';
import moment from 'moment';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useCreatePurchaseDataWrapper } from 'src/app/components/data-wrappers/purchase/CreatePurchaseDataWrapper';
import {
    CreatePurchaseForm,
    CreatePurchaseFormValues,
    PurchaseEvent,
} from 'src/app/components/forms/purchase/CreatePurchaseForm';
import EventUrlService from 'src/app/pages/events/eventUrlService';
import purchaseUrlService from 'src/app/pages/purchases/purchaseUrlService';
import CreatePurchaseDto, { PurchaseLine } from 'src/data/dtos/CreatePurchaseDto';
import { useGenericStyles } from 'src/shared/styles/genericStyles';
import { useSpacingStyles } from 'src/shared/styles/spacingStyles';
import ErrorsList from 'src/view/components/errors-list/ErrorsList';
import Modal, { ModalBody } from 'src/view/components/modal/Modal';
import { OptionsList } from 'src/view/components/options-list/OptionsList';

export const CreatePurchaseFormFeature = () => {
    const spacingClasses = useSpacingStyles();
    const genericClasses = useGenericStyles();

    const [searchParams] = useSearchParams();
    const eventId = searchParams.get('eventId');
    const eventName = searchParams.get('eventName');
    const [showRedirectModal, setShowRedirectModal] = useState(false);
    const [formEvents, setFormEvents] = useState<PurchaseEvent[]>([]);
    const [submittedValues, setSubmittedValues] = useState<Partial<CreatePurchaseFormValues>>();

    const {
        loading,
        errors,
        createPurchase,
        data: purchaseData,
    } = useCreatePurchaseDataWrapper({
        onSuccess: () => setShowRedirectModal(true),
    });

    const mapFormValuesToCreatePurchaseDto = (
        values: CreatePurchaseFormValues
    ): CreatePurchaseDto => {
        const mapPurchaseLines = () => {
            const purchaseLines: PurchaseLine[] = [];

            values.events.forEach((event) => {
                event.ticketInfo?.forEach((ti) => {
                    purchaseLines.push({
                        type: 'Ticket',
                        quantity: ti.quantity || 1,
                        pricePerUnit: {
                            currency: values.currency.value,
                            value: Number(ti.pricePerTicket),
                        },
                        eventId: event.event.value,
                        ticketsPerSplit: Number(ti.ticketsPerSplit),
                        seatingPlanCategoryId: ti?.seatingplanCategory?.value,
                        bookingOptionId: ti?.bookingOption?.value,
                    });
                });
            });

            return purchaseLines;
        };

        const paymentMethod = values.paymentMethod
            ? {
                  type: values.paymentMethod?.value,
                  creditCardInfo: values.creditcardNumber,
              }
            : undefined;

        return {
            supplierId: values.supplier?.value,
            paymentMethod,
            purchasedAt: moment.utc(moment(values.purchaseDate).valueOf()).format(),
            supplierAccountUsername: values.supplierAccountUsername,
            purchasePrice: {
                currency: values.currency.value,
                value: Number(values.totalPurchasePrice),
            },
            externalReferenceId: values.externalReference,
            notes: values.internalNotes,
            foreignPriceExchangeRate: values.foreignPriceExchangeRate,
            purchaseLines: mapPurchaseLines(),
            finalized: !!values.finalized,
            purchaseType: values.purchaseType?.value,
        };
    };

    const defaultValues: Partial<CreatePurchaseFormValues> = {
        events:
            eventId && eventName
                ? [
                      {
                          event: {
                              label: eventName,
                              value: eventId,
                          },
                      },
                  ]
                : [],
    };

    const onClickCreateSimilairPurchase = () => {
        setSubmittedValues((submittedValues) => ({
            ...submittedValues,
            events: submittedValues?.events?.map((event) => ({
                event: event.event,
                ticketInfo: event.ticketInfo,
            })),
            totalPurchasePrice: '',
            paymentMethod: undefined,
            creditcardNumber: '',
            internalNotes: '',
        }));

        setShowRedirectModal(false);
    };

    const renderRedirectOptionsModal = () => {
        if (!purchaseData) return;

        return (
            <Modal
                open={showRedirectModal}
                title="Purchase created successfully"
                subTitle="Where do you want to redirect to?"
                enableCloseIcon={false}
            >
                <ModalBody>
                    <OptionsList
                        items={[
                            {
                                label: 'Purchases overview',
                                actions: [
                                    {
                                        icon: <LaunchIcon fontSize="small" />,
                                        url: purchaseUrlService.overview(),
                                    },
                                ],
                            },
                        ]}
                    />

                    <h3>Go to event tickets</h3>
                    <OptionsList
                        items={formEvents.map((event) => ({
                            label: event.event.label,
                            actions: [
                                {
                                    icon: <LaunchIcon fontSize="small" />,
                                    url: EventUrlService.tickets(event.event.value, {
                                        purchaseId: purchaseData?.data.id,
                                    }),
                                },
                            ],
                        }))}
                    />

                    <div className={spacingClasses.spacingTop}>
                        <div>
                            <a href={purchaseUrlService.create()}>Create another purchase</a>
                        </div>
                        <div className={spacingClasses.spacingTop}>
                            <span
                                className={genericClasses.link}
                                onClick={onClickCreateSimilairPurchase}
                            >
                                Create similar purchase
                            </span>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        );
    };

    return (
        <>
            <CreatePurchaseForm
                defaultValues={defaultValues}
                loading={loading}
                values={submittedValues}
                onSubmit={(values) => {
                    setFormEvents(values.events);

                    createPurchase(mapFormValuesToCreatePurchaseDto(values)).then(() =>
                        setShowRedirectModal(true)
                    );

                    setSubmittedValues(values);
                }}
            />
            {errors.length > 0 && <ErrorsList errors={errors} />}
            {renderRedirectOptionsModal()}
        </>
    );
};
