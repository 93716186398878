import { useQuery } from '@tanstack/react-query';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import { ActivityLogsQuery, getActivityLogs } from 'src/data/services/activityLogService';

export const ACTIVITY_LOGS_QUERY_KEY = 'ACTIVITY_LOGS_QUERY_KEY';
export const useFetchActivityLogs = (
    activityLogsParams: ActivityLogsQuery,
    fetchOptions: BaseFetchOptions
) => {
    return useQuery({
        queryKey: [ACTIVITY_LOGS_QUERY_KEY, activityLogsParams, fetchOptions],
        queryFn: () => getActivityLogs(activityLogsParams, fetchOptions),
    });
};
