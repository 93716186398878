import { useQuery } from '@tanstack/react-query';
import supplierService from 'src/data/services/supplierService';

export const SUPPLIER_QUERY = 'SUPPLIER_QUERY';

type QueryOptions = {
    enabled: boolean;
};

export const useGetSupplierById = (id: string, queryOptions: QueryOptions) => {
    return useQuery({
        queryKey: [SUPPLIER_QUERY, id],
        queryFn: () => {
            return supplierService.getSupplierById(id);
        },
        ...queryOptions,
    });
};
