import { getInternalAxiosInstance } from 'src/data/api/api';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import { getQueryParams } from 'src/data/api/endpointUrlBuilder';
import ApiResponse from 'src/data/api/responses/ApiResponse';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import {
    GET_FILES_GET_AVAILABLE_TICKETS_COUNT,
    SET_FILES_TO_TICKETS_ENDPOINT,
    UPLOAD_FILE_ENDPOINT,
} from 'src/data/constants/endpoints/files-endpoints';
import { EmptyBody } from 'src/data/models/common/emptyBody';

async function uploadFile(file: File): Promise<ApiResponse<ApiResponseBody<string>>> {
    const formData = new FormData();
    formData.append('file', file);

    return await getInternalAxiosInstance()
        .post<ApiResponseBody<string>>(UPLOAD_FILE_ENDPOINT, formData)
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export interface SetFilesToTicketsDto {
    eventId: string;
    seatingplanCategoryId: string;
    blockNumber: string;
    rowNumber: string;
    fileUploadIds: string[];
    supplierId?: string;
}

async function setFilesToTickets(
    dto: SetFilesToTicketsDto
): Promise<ApiResponse<ApiResponseBody<EmptyBody>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<EmptyBody>>(SET_FILES_TO_TICKETS_ENDPOINT, dto)
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export interface GetAvailableTicketsCountResponse {
    availableTicketCount: string;
}

async function getAvailableTicketsCount(
    options: BaseFetchOptions
): Promise<ApiResponse<ApiResponseBody<GetAvailableTicketsCountResponse>>> {
    return await getInternalAxiosInstance()
        .get<ApiResponseBody<GetAvailableTicketsCountResponse>>(
            GET_FILES_GET_AVAILABLE_TICKETS_COUNT,
            {
                params: getQueryParams(options),
            }
        )
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export default {
    uploadFile,
    setFilesToTickets,
    getAvailableTicketsCount,
};
