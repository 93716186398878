import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';
import FormButtons from 'src/view/components/form/FormButtons';
import useApiFetch from 'src/app/hooks/useApiFetch';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import { EmptyBody } from 'src/data/models/common/emptyBody';
import ticketSalesCapService from 'src/data/services/ticketSalesCapService';

interface Props {
    eventId: string;
    id: string;
    onSucceed?: () => void;
}

export default function DeleteTicketsSalesCapDataWrapper({
    eventId,
    id,
    onSucceed,
}: Props): JSX.Element {
    const [{ loading }, handleDelete] = useApiFetch<ApiResponseBody<EmptyBody>>();

    const onDelete = () => {
        handleDelete(ticketSalesCapService.deleteTicketsSalesCap(eventId, id), {
            useDefaultErrorHandler: true,
            onSuccess: () => onSucceed?.(),
        });
    };

    return (
        <>
            {loading && <LoadingOverlay />}
            Are yous sure you want to delete tickets sales cap?
            <FormButtons
                buttons={[
                    {
                        children: 'Delete',
                        onClick: onDelete,
                    },
                ]}
            />
        </>
    );
}
