import { useMutation, useQueryClient } from '@tanstack/react-query';
import { unarchiveEventCategory } from 'src/data/services/eventCategoriesService';
import { EVENT_CATEGORIES_QUERY_KEY } from 'src/app/hooks/event-categories/useEventCategories';
import Toaster from 'src/app/utilities/helpers/Toaster';
import { parseErrors } from 'src/app/utilities/helpers/errors';

const useUnarchiveEventCategory = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: unarchiveEventCategory,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [EVENT_CATEGORIES_QUERY_KEY],
            });
        },
        onError: (errors) => Toaster.toastErrors(parseErrors(errors)),
    });
};

export default useUnarchiveEventCategory;
