import { intersection } from 'ramda';
import { Roles } from 'src/data/enums/roles';
import urlService from 'src/app/services/urlService';
import eventsOverviewUrlService from 'src/app/pages/events/eventsOverviewUrlService';

export interface UserRoleConfig {
    inventory: boolean;
    etbaas: boolean;
    eTicketManagement: boolean;
    administrator: boolean;
    redirectUrl: string;
}

const adminRole = [Roles.DEVELOPERS, Roles.VENDING_MACHINE, Roles.ADMINISTRATOR];
const etbaas = [...adminRole, Roles.EMPLOYEES];
const inventoryRole = [...adminRole, Roles.INVENTORY];
const eticketManagementRole = [...adminRole, Roles.TICKET_MANAGEMENT];
const administratorRole = [Roles.ADMINISTRATOR];

const getIsAllowedToEtbaas = (userRoles: Roles[]) => !!intersection(etbaas, userRoles).length;

const getIsAllowedToInventory = (userRoles: Roles[]) =>
    !!intersection(inventoryRole, userRoles).length;

const getIsAllowedToTicketManagement = (userRoles: Roles[]) =>
    !!intersection(eticketManagementRole, userRoles).length;

const getIsAdministrator = (userRoles: Roles[]) =>
    !!intersection(administratorRole, userRoles).length;

export const allowTo = (userRoles: Roles[]): UserRoleConfig => {
    const etbaas = getIsAllowedToEtbaas(userRoles);
    const inventory = getIsAllowedToInventory(userRoles);
    const eTicketManagement = getIsAllowedToTicketManagement(userRoles);
    const administrator = getIsAdministrator(userRoles);

    const getRedirectUrl = (): string => {
        if (etbaas || inventory) return eventsOverviewUrlService.root();
        if (eTicketManagement) return urlService.getTicketManagement();
        return urlService.getNotAuthorized();
    };

    return {
        etbaas,
        inventory,
        eTicketManagement,
        administrator,
        redirectUrl: getRedirectUrl(),
    };
};

export const hasPermission = (rolesRequested: Roles[], userRoles: Roles[]): boolean => {
    const isAllowedTo = allowTo(userRoles);

    if (rolesRequested.includes(Roles.INVENTORY))
        return isAllowedTo.inventory && isAllowedTo.etbaas;

    if (rolesRequested.includes(Roles.TICKET_MANAGEMENT))
        return isAllowedTo.eTicketManagement && isAllowedTo.etbaas;

    if (rolesRequested.length === 1 && rolesRequested.includes(Roles.EMPLOYEES))
        return isAllowedTo.etbaas;

    return false;
};

export const hasOneOfRoles = (rolesToFind: Roles[], userRoles: Roles[]) => {
    let hasRoles = false;

    rolesToFind.forEach((roleTofind) => {
        if (hasRoles) return;

        hasRoles = !!userRoles.find((r) => r === roleTofind);
    });

    return hasRoles;
};
