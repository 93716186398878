import { zodResolver } from '@hookform/resolvers/zod';
import { CircularProgress, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import FormButtons from 'src/view/components/form/FormButtons';
import FormFieldError from 'src/view/components/form/FormFieldError';
import { FormLabel } from 'src/view/components/form/FormLabel';
import Input from 'src/view/components/input/Input';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';
import z from 'zod';

const validationSchema = z.object({
    name: z.string().min(1, 'Name is required!'),
});

export interface CreateSeriesGroupFormValues {
    name?: string;
}

interface Props {
    dataLoading?: boolean;
    actionLoading?: boolean;
    editMode?: boolean;
    formDefaultValues?: CreateSeriesGroupFormValues;
    onFormSubmit: (values: CreateSeriesGroupFormValues) => void;
}

export default function CreateSeriesGroupForm({
    dataLoading,
    actionLoading,
    editMode = false,
    formDefaultValues,
    onFormSubmit,
}: Props) {
    const form = useForm<CreateSeriesGroupFormValues>({
        mode: 'onChange',
        resolver: zodResolver(validationSchema),
    });
    const {
        control,
        reset,
        handleSubmit,
        formState: { isDirty },
    } = form;
    const theme = useTheme();

    const isSubmitButtonDisabled = actionLoading || !isDirty;

    useEffect(() => {
        reset({ name: formDefaultValues?.name });
    }, [formDefaultValues?.name, reset]);

    if (dataLoading) return <LoadingOverlay />;

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormLabel>Name</FormLabel>
                    <Controller
                        name="name"
                        control={control}
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <>
                                <Input
                                    name={name}
                                    value={value}
                                    onChange={onChange}
                                    placeholder="Name"
                                    grey
                                />
                                <FormFieldError message={error?.message} />
                            </>
                        )}
                    />
                </Grid>
            </Grid>
            <FormButtons
                buttons={[
                    {
                        children: editMode ? 'Update Series Group' : 'Create Series Group',
                        onClick: () => handleSubmit(onFormSubmit)(),
                        disabled: isSubmitButtonDisabled,
                        startIcon: actionLoading && (
                            <CircularProgress size={theme.layout.loader.sizes.small} />
                        ),
                    },
                ]}
            />
        </>
    );
}
