import { List, ListItem } from '@mui/material';
import HtmlListTooltip from 'src/view/components/tooltip/HtmlListTooltip';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
    number: {
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        flexShrink: 0,
    },
    separation: {
        marginLeft: theme.spacing(1.25),
        marginRight: theme.spacing(1.25),
        width: 0,
        border: `1px solid ${theme.colors.mediumGrey}`,
    },
    dashSeparation: {
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
    },
    unknownDashes: {
        color: theme.colors.grey,
    },
    blockRowSeat: {
        display: 'flex',
        textTransform: 'uppercase',
    },
    listItem: {
        width: 200,
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
    },
    ticketIndex: {
        marginRight: theme.spacing(2),
        fontWeight: 'bold',
    },
}));

export interface Seating {
    blockNumber?: string | null;
    rowNumber?: string | null;
    seatNumber?: string | null;
}

interface BlockRowSeatRangeProps {
    seatings?: Seating[];
}

export const BlockRowSeatRange = ({ seatings = [] }: BlockRowSeatRangeProps): JSX.Element => {
    const classes = useStyles();
    if (seatings.length === 0) return <></>;

    const firstTicketBlockNumber = seatings[0].blockNumber;
    const firstTicketRowNumber = seatings[0].rowNumber;

    const ticketsWithSameBlockAndRow = seatings.filter(
        (t) => t.blockNumber === firstTicketBlockNumber && t.rowNumber === firstTicketRowNumber
    );

    const renderSeperator = <span className={classes.separation}></span>;
    const renderDashSeperator = <span className={classes.dashSeparation}>-</span>;

    const renderItem = (number?: string | null) => (
        <>
            {number ? (
                <div className={classes.number}>{number}</div>
            ) : (
                <span className={classes.unknownDashes}>--</span>
            )}
        </>
    );

    const shouldShowSeatRange = () => {
        const hasUndefinedSeats = seatings.some((s) => !s.seatNumber);

        if (ticketsWithSameBlockAndRow.length === seatings?.length && !hasUndefinedSeats)
            return true;
        if (hasUndefinedSeats && seatings.length === 1) return true;
        if (hasUndefinedSeats && seatings?.length > 1) return false;

        return false;
    };

    if (shouldShowSeatRange()) {
        const sortedSeatings = seatings.sort((a, b) => {
            if (!a.seatNumber || !b.seatNumber) return 0;

            return a.seatNumber?.localeCompare(b.seatNumber, undefined, {
                numeric: true,
                sensitivity: 'base',
            });
        });

        const minSeatNumberTicket = sortedSeatings[0];
        const maxSeatNumberTicket = sortedSeatings[sortedSeatings.length - 1];

        return (
            <div className={classes.blockRowSeat}>
                {renderItem(firstTicketBlockNumber)}
                {renderSeperator}
                {renderItem(firstTicketRowNumber)}
                {renderSeperator}
                {minSeatNumberTicket !== maxSeatNumberTicket ? (
                    <>
                        {renderItem(minSeatNumberTicket?.seatNumber)}
                        {renderDashSeperator}
                        {renderItem(maxSeatNumberTicket?.seatNumber)}
                    </>
                ) : (
                    renderItem(minSeatNumberTicket?.seatNumber)
                )}
            </div>
        );
    }

    const tooltipData = seatings.map((t, index) => (
        <ListItem
            divider
            className={classNames(classes.listItem, classes.blockRowSeat)}
            key={`t.blockNumber-${index}`}
        >
            <span className={classes.ticketIndex}>{index + 1}.</span>
            {renderItem(t.blockNumber)}
            {renderSeperator}
            {renderItem(t.rowNumber)}
            {renderSeperator}
            {renderItem(t.seatNumber)}
        </ListItem>
    ));

    const tooltipTitleData = <List>{tooltipData}</List>;

    return <HtmlListTooltip buttonTitle={`${seatings.length} tickets`} title={tooltipTitleData} />;
};
