const ticketFilesAcceptedMimeTypes = {
    'application/pdf': ['.pdf'],
    'application/vnd.apple.pkpass': ['.pkpass'],
    'application/zip': ['.zip'],
    '': ['.pkpass'],
};

export default {
    ticketFilesAcceptedMimeTypes,
    ticketFilesAcceptedMimeTypesString: Object.keys(ticketFilesAcceptedMimeTypes).join(','),
};
