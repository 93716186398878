import { Dropzone, DropzoneComponentProps } from 'src/view/components/dropzone-input/Dropzone';
import { useUploadFileDataWrapper } from 'src/app/components/data-wrappers/files/UploadFileDataWrapper';
import { DropzoneProps } from 'react-dropzone';
import file from 'src/app/constants/constants/file-types/file';
import { ChildlessBaseComponent } from 'src/view/interfaces/BaseComponent';

export interface FileData {
    fileId: string | null;
    name: string;
}

interface FileUploadFeatureProps extends ChildlessBaseComponent {
    onFilesUploaded: (ids: FileData[]) => void;
    onLoadingStateChanged?: (state: boolean) => void;
    onFileUploadFailed?: (file: Omit<FileData, 'fileId'>) => void;
    dropzoneComponentProps?: DropzoneComponentProps;
    dropzoneProps?: DropzoneProps;
}

export const FileUploadFeature = ({
    onFilesUploaded,
    onLoadingStateChanged,
    onFileUploadFailed,
    className,
    dropzoneComponentProps,
    dropzoneProps,
}: FileUploadFeatureProps) => {
    const { loading: uploadFilesIsLoading, uploadFile } = useUploadFileDataWrapper({});

    return (
        <Dropzone
            className={className}
            loading={dropzoneComponentProps?.loading || uploadFilesIsLoading}
            onDrop={async (acceptedFiles: File[], fileRejections, event) => {
                if (dropzoneProps?.onDrop) {
                    dropzoneProps.onDrop(acceptedFiles, fileRejections, event);

                    return;
                }

                onLoadingStateChanged?.(true);

                const uploadedFilesResults = await Promise.all(
                    acceptedFiles.map(async (file) => {
                        try {
                            return await uploadFile(file);
                        } catch {
                            onFileUploadFailed?.({ name: file.name });

                            return {
                                data: null,
                                name: file.name,
                            };
                        }
                    })
                );

                onLoadingStateChanged?.(false);

                const fileData = uploadedFilesResults.map((result, index) => ({
                    fileId: result?.data || null,
                    name: acceptedFiles[index].name,
                }));

                onFilesUploaded(fileData);
            }}
            accept={dropzoneProps?.accept || file.ticketFilesAcceptedMimeTypes}
            {...dropzoneProps}
            {...dropzoneComponentProps}
        />
    );
};
