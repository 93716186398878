import useApiFetch from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import { IdResponse } from 'src/data/models/common/idResponse';
import { SeatingPlanCategoryProperty } from 'src/data/models/seating-plan-category-property/seatingPlanCategoryProperty';
import seatingPlanCategoryPropertyService from 'src/data/services/seatingPlanCategoryPropertyService';

interface FetchSeatingPlanCategoryPropertyDataWrapperChildrenParams
    extends DataWrapperChildrenParams<ApiResponseBody<IdResponse>> {
    updateSeatingplanCategoryProperty: (
        propertyId: string,
        categoryProperty: SeatingPlanCategoryProperty
    ) => Promise<ApiResponseBody<IdResponse>>;
}

type UpdateSeatingPlanCategoryPropertyDataWrapperProps = DataWrapperProps<
    FetchSeatingPlanCategoryPropertyDataWrapperChildrenParams,
    ApiResponseBody<IdResponse>
>;

export const useUpdateSeatingPlanCategoryPropertyDataWrapper = ({
    onError,
    onSuccess,
}: Omit<UpdateSeatingPlanCategoryPropertyDataWrapperProps, 'children'> & BaseFetchOptions) => {
    const [{ data, loading, errors, resetData }, handleUpdateSeatingplanCategoryProperties] =
        useApiFetch<ApiResponseBody<IdResponse>>();

    const updateSeatingplanCategoryProperty = (
        propertyId: string,
        categoryProperty: SeatingPlanCategoryProperty
    ) => {
        return handleUpdateSeatingplanCategoryProperties(
            seatingPlanCategoryPropertyService.updateProperty(propertyId, categoryProperty),
            {
                useDefaultErrorHandler: true,
                onFail: onError,
                onSuccess,
            }
        );
    };

    return {
        updateSeatingplanCategoryProperty,
        data,
        loading,
        errors,
        resetData,
    };
};
