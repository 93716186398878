import { useMutation, useQueryClient } from '@tanstack/react-query';
import Toaster from 'src/app/utilities/helpers/Toaster';
import { parseErrors } from 'src/app/utilities/helpers/errors';
import { deleteSeatingplan } from 'src/data/services/seatingPlanService';
import { FETCH_SEATINGPLAN_BY_VENUE_ID_QUERY } from './useFetchSeatingPlansByVenueId';

type TData = Awaited<ReturnType<typeof deleteSeatingplan>>;

export default function useDeleteSeatingplan(onSuccess?: (data: TData) => unknown) {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: deleteSeatingplan,
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: [FETCH_SEATINGPLAN_BY_VENUE_ID_QUERY] });

            onSuccess?.(data);
        },
        onError: (errors) => Toaster.toastErrors(parseErrors(errors)),
    });
}
