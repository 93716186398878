import React, { useEffect, useState } from 'react';
import Table from 'src/view/components/table/table/Table';
import Chip from 'src/view/components/chip/Chip';
import {
    NewTableColumn,
    TABLE_CELL_HEIGHT,
    TABLE_CELL_WIDTH,
    TablePaginationData,
} from 'src/view/components/table/table/Types';
import { RowIdResolver } from 'src/view/components/table/table/Table';
import { BlockRowSeatRange } from 'src/view/components/block-row-seat/BlockRowSeatRange';
import Button from 'src/view/components/button/Button';
import { TableAction } from 'src/view/components/table-toolbar/TableToolbar';
import { useSpacingStyles } from 'src/shared/styles/spacingStyles';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import { useGenericStyles } from 'src/shared/styles/genericStyles';
import classnames from 'classnames';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { BUTTON_SIZES } from 'src/shared/theme/enums';
import { UndoAutoAssignResultOrderlineDataWrapper } from 'src/app/components/data-wrappers/orderline/UndoAutoAssignResultOrderlineDataWrapper';
import { AcceptAutoAssignResultOrderlineDataWrapper } from 'src/app/components/data-wrappers/orderline/AcceptAutoAssignResultOrderlineDataWrapper';
import { CircularProgress } from '@mui/material';
import { useTheme } from '@mui/styles';
import _ from 'lodash';
import { AssignedOrderline, SplitAssignment } from 'src/data/models/orderline/assigned-orderline';

const gridRowHeight = 23;
const useStyles = makeStyles(() => ({
    splitPosition: {
        display: 'flex',
        alignItems: 'center',
        height: gridRowHeight,
        '& svg': {
            height: 14,
        },
    },
    blockRowSeatContainer: {
        display: 'flex',
        alignItems: 'center',
        height: gridRowHeight,
    },
    undoButtonContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    acceptButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: '100%',
    },
}));

interface AcceptedOrderlineHocProps {
    isAccepted: boolean;
    children: (value: boolean, setAccepted: (value: boolean) => void) => JSX.Element;
}

const AcceptedOrderlineHoc = ({ isAccepted, children }: AcceptedOrderlineHocProps) => {
    const [rowIsAccepted, setRowIsAccepted] = useState(isAccepted);

    return children(rowIsAccepted, setRowIsAccepted);
};

export interface OrderlinesSuccessfullAssignmentsTableProps {
    successfullAssignedItems: AssignedOrderline[];
    loading?: boolean;
    pagination?: TablePaginationData;
    initialSelectedRows?: RowIdResolver<AssignedOrderline>[];
    tableToolbarActions?: (selectedRows: RowIdResolver<AssignedOrderline>[]) => TableAction[];
    onChangeSelectedRows: () => void;
}

export default function OrderlinesSuccessfullAssignmentsTable({
    loading,
    successfullAssignedItems,
    pagination,
    initialSelectedRows,
    tableToolbarActions,
    onChangeSelectedRows,
}: OrderlinesSuccessfullAssignmentsTableProps): JSX.Element {
    const theme = useTheme();
    const classes = useStyles();
    const spacingClasses = useSpacingStyles();
    const genericClasses = useGenericStyles();

    const [columns, setColumns] = React.useState<NewTableColumn<AssignedOrderline>[]>([]);

    const shouldHaveSpacingBottom = (length: number, currentIndex: number) =>
        length > 1 && length - 1 !== currentIndex;

    const getTicketIds = (orderline: AssignedOrderline) => {
        let ticketIds: string[] = [];

        orderline.splitAssignments.forEach((split) => {
            const mappedTicketIds = split.ticketListResult.map((result) => result.ticketId);

            ticketIds = [...mappedTicketIds, ...ticketIds];
        });

        return ticketIds;
    };

    const sumSplitAssignmentQuantities = (splitAssignments: SplitAssignment[]) =>
        _.sumBy(splitAssignments, (splitAssignment) => splitAssignment.quantity);

    useEffect(() => {
        let cols: NewTableColumn<AssignedOrderline>[] = [];

        cols = [
            {
                key: 'customerEmail',
                title: 'Customer',
                width: TABLE_CELL_WIDTH.LARGE,
                cellRenderer: (rowData) => rowData.customerEmail,
            },
            {
                key: 'quantity',
                title: 'Quantity',
                width: TABLE_CELL_WIDTH.SMALL,
                cellRenderer: (rowData) => sumSplitAssignmentQuantities(rowData.splitAssignments),
            },
            {
                key: 'splitAssignments',
                title: 'Split position',
                width: TABLE_CELL_WIDTH.LARGE,
                cellRenderer: (rowData) => {
                    const sortedSplitAssignments = _.sortBy(
                        rowData.splitAssignments,
                        (splitAssignment) => splitAssignment.startPosition
                    );

                    return (
                        <div className={genericClasses.fullWidth}>
                            {sortedSplitAssignments.map((split, i) => (
                                <Grid
                                    key={split.splitId}
                                    container
                                    className={classnames(
                                        classes.splitPosition,
                                        shouldHaveSpacingBottom(
                                            rowData.splitAssignments.length,
                                            i
                                        ) && spacingClasses.spacingBottom
                                    )}
                                >
                                    <Grid item xs={2} display="flex" alignItems="center">
                                        <div>{split.splitName}</div>
                                    </Grid>
                                    <Grid item xs={10} display="flex" alignItems="center">
                                        <Chip
                                            rounded
                                            tinyChip
                                            label={`${split.startPosition + 1}/${
                                                split.totalSplitQuantity
                                            }`}
                                        />
                                        {split.startPosition !== split.endPosition && (
                                            <>
                                                <KeyboardArrowRightIcon color="disabled" />
                                                <Chip
                                                    rounded
                                                    tinyChip
                                                    label={`${split.endPosition + 1}/${
                                                        split.totalSplitQuantity
                                                    }`}
                                                />
                                            </>
                                        )}
                                    </Grid>
                                </Grid>
                            ))}
                        </div>
                    );
                },
            },
            {
                title: 'Block | Row | Seat',
                width: TABLE_CELL_WIDTH.LARGE,
                cellRenderer: (rowData) => (
                    <div>
                        {rowData.splitAssignments.map((splitAssignment, i) => (
                            <div
                                key={splitAssignment.splitId}
                                className={classnames(
                                    shouldHaveSpacingBottom(rowData.splitAssignments.length, i) &&
                                        spacingClasses.spacingBottom,
                                    classes.blockRowSeatContainer
                                )}
                            >
                                <BlockRowSeatRange
                                    seatings={splitAssignment.ticketListResult.map((result) => ({
                                        blockNumber: result.block,
                                        rowNumber: result.row,
                                        seatNumber: result.seat,
                                    }))}
                                />
                            </div>
                        ))}
                    </div>
                ),
            },
            {
                title: '',
                width: TABLE_CELL_WIDTH.SMALL,
                cellRenderer: (rowData) => (
                    <AcceptedOrderlineHoc isAccepted={rowData.isAccepted}>
                        {(isAccepted, setIsAccepted) => (
                            <div className={classes.acceptButtonContainer}>
                                {isAccepted ? (
                                    <UndoAutoAssignResultOrderlineDataWrapper>
                                        {({ loading, undoAutoAssignResultOrderline }) => (
                                            <>
                                                {isAccepted && (
                                                    <CheckCircleIcon
                                                        color="success"
                                                        className={spacingClasses.spacingRight}
                                                        fontSize="small"
                                                    />
                                                )}
                                                <Button
                                                    size={BUTTON_SIZES.TINY}
                                                    variant="outlined"
                                                    onClick={() =>
                                                        undoAutoAssignResultOrderline(
                                                            [rowData.orderLineId],
                                                            {
                                                                onSuccess: () =>
                                                                    setIsAccepted(false),
                                                            }
                                                        )
                                                    }
                                                    disabled={loading}
                                                    startIcon={
                                                        loading && (
                                                            <CircularProgress
                                                                size={
                                                                    theme.layout.loader.sizes.small
                                                                }
                                                            />
                                                        )
                                                    }
                                                >
                                                    Undo
                                                </Button>
                                            </>
                                        )}
                                    </UndoAutoAssignResultOrderlineDataWrapper>
                                ) : (
                                    <>
                                        {sumSplitAssignmentQuantities(rowData.splitAssignments) ===
                                        0 ? (
                                            <></>
                                        ) : (
                                            <AcceptAutoAssignResultOrderlineDataWrapper>
                                                {({ acceptAutoAssignResultOrderline, loading }) => {
                                                    return (
                                                        <Button
                                                            size={BUTTON_SIZES.TINY}
                                                            onClick={() => {
                                                                acceptAutoAssignResultOrderline(
                                                                    rowData.orderId,
                                                                    rowData.orderLineId,
                                                                    {
                                                                        ticketIds:
                                                                            getTicketIds(rowData),
                                                                    },
                                                                    {
                                                                        onSuccess: () =>
                                                                            setIsAccepted(true),
                                                                    }
                                                                );
                                                            }}
                                                            disabled={loading}
                                                            startIcon={
                                                                loading && (
                                                                    <CircularProgress
                                                                        size={
                                                                            theme.layout.loader
                                                                                .sizes.small
                                                                        }
                                                                    />
                                                                )
                                                            }
                                                        >
                                                            Accept
                                                        </Button>
                                                    );
                                                }}
                                            </AcceptAutoAssignResultOrderlineDataWrapper>
                                        )}
                                    </>
                                )}
                            </div>
                        )}
                    </AcceptedOrderlineHoc>
                ),
            },
        ];

        setColumns(cols);
    }, []);

    return (
        <Table<AssignedOrderline, AssignedOrderline>
            data={successfullAssignedItems.map((orderline) => {
                return {
                    ...orderline,
                    // is needed as unique identifier inside Table for 'shift button' multi-select
                    id: orderline.orderLineId,
                };
            })}
            rowIdResolver={(orderline: AssignedOrderline) => ({
                id: orderline.orderLineId,
                data: orderline,
            })}
            columns={columns}
            loading={loading}
            pagination={pagination}
            minWidth={650}
            enableCheckboxes={true}
            initialSelectedRows={initialSelectedRows}
            onChangeSelectedRows={onChangeSelectedRows}
            rowHeight={TABLE_CELL_HEIGHT.NONE}
            tableToolbarActions={tableToolbarActions}
        />
    );
}
