import FilterOption from 'src/data/api/common/FilterOption';
import {
    TableColumnSorting,
    TableColumnSortingDirection,
} from 'src/view/components/table/table/Types';
import {
    CUSTOM_FIELD_PARAM_KEY,
    CustomFields,
    FILTER_OPTION_PARAM_KEY,
    SORTING_OPTION_PARAM_KEY,
} from 'src/app/constants/constants/filters/filters';

export const filterOptionsToQueryParams = (props: CreateFilterOptionsQueryProps): string => {
    const currentSuffixQuery = createFilterOptionsQuery({
        filterKey: FILTER_OPTION_PARAM_KEY,
        sortingKey: SORTING_OPTION_PARAM_KEY,
        customFieldsKey: CUSTOM_FIELD_PARAM_KEY,
        ...props,
    });

    const searchPrefix = currentSuffixQuery.length > 0 ? '?' : '';

    return `${searchPrefix}${currentSuffixQuery}`;
};

export interface CreateFilterOptionsQueryProps {
    filterOptions?: FilterOption[];
    filterKey?: string;
    customFields?: CustomFields;
    customFieldsKey?: string;
    sortingOptions?: TableColumnSorting[];
    sortingKey?: string;
    suffix?: string;
}

export function createFilterOptionsQuery({
    filterOptions,
    filterKey,
    customFields,
    customFieldsKey,
    sortingOptions,
    sortingKey,
    suffix,
}: CreateFilterOptionsQueryProps): string {
    let isFirstQueryParam = true;
    const currentSuffix = suffix ? `_${suffix}` : '';

    const filterOptionQueryParams: string[] =
        filterOptions?.map((option: FilterOption) => {
            const operation = option.operation ? `[${option.operation}]` : '';
            const queryString = `${isFirstQueryParam ? '' : '&'}${filterKey}${currentSuffix}[${
                option.property
            }]${operation}=${option.value}`;

            isFirstQueryParam = false;

            return queryString;
        }) || [];

    const sortingOptionQueryParams: string[] =
        sortingOptions?.map((option: TableColumnSorting) => {
            const queryString = `${isFirstQueryParam ? '' : '&'}${sortingKey}${currentSuffix}[${
                option.column
            }]=${option.direction}`;

            isFirstQueryParam = false;

            return queryString;
        }) || [];

    const customFieldQueryParams: string[] = [];

    for (const key in customFields) {
        customFieldQueryParams.push(
            `${isFirstQueryParam ? '' : '&'}${customFieldsKey}${currentSuffix}[${key}]=${
                customFields[key]
            }`
        );

        isFirstQueryParam = false;
    }

    return [...filterOptionQueryParams, ...sortingOptionQueryParams, ...customFieldQueryParams]
        .join('')
        .replace(/,&/g, '&');
}

export const mapKeyStringObjectToFilterOptions = <T>(obj: T) => {
    const filterOptions: FilterOption[] = [];

    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            if (obj[key] === undefined || obj[key] === null) return;

            if (Object.prototype.hasOwnProperty.call(obj[key], 'operation')) {
                const filterOption = obj[key] as FilterOption;
                filterOptions.push({
                    property: filterOption.property,
                    value: filterOption.value as string,
                    operation: filterOption.operation,
                });
            } else {
                filterOptions.push({ property: key, value: obj[key] as string });
            }
        }
    }

    return filterOptions;
};

export const mapKeyStringObjectToSortingOptions = <T>(obj: T) => {
    const sortingOptions: TableColumnSorting[] = [];

    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            if (!obj[key]) return;

            sortingOptions.push({
                column: key,
                direction: obj[key] as TableColumnSortingDirection,
            });
        }
    }

    return sortingOptions;
};
