import decode from 'jwt-decode';
import { User } from 'src/data/models/user/user';

interface DecodedToken {
    exp: number;
}

function getToken(): string {
    return localStorage.getItem('id_token') || '';
}

function decodeToken(): DecodedToken {
    const token = getToken();

    return decode(token);
}

function isTokenExpired(token: string): boolean {
    if (!token) return true;

    const decoded: DecodedToken = decodeToken();

    return decoded.exp < Date.now() / 1000;
}

function loggedIn(): boolean {
    const token = getToken();

    if (!token || isTokenExpired(token)) return false;

    return true;
}

function getExpirationNotificationTime(): number | null {
    const token = getToken();

    if (!token || isTokenExpired(token)) return null;

    const profile = decodeToken();

    return profile.exp - Date.now() / 1000;
}

function getProfile(): User {
    if (process.env.REACT_APP_DUMMY_DATA === 'true')
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return {
            email: 'dummy@email.com',
            given_name: 'Dummy',
            family_name: 'User',
        };

    return decode(getToken());
}

function logout(): void {
    localStorage.removeItem('id_token');
}

export default {
    loggedIn,
    getProfile,
    isTokenExpired,
    getToken,
    logout,
    decodeToken,
    getExpirationNotificationTime,
};
