import { useEffect } from 'react';
import useApiFetch from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import PaginatedApiResponseBody from 'src/data/api/responses/PaginatedApiResponseBody';
import PaginationMeta from 'src/data/api/responses/PaginationMeta';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import supplierService from 'src/data/services/supplierService';
import { Supplier } from 'src/data/models/supplier/supplier';

interface FetchSuppliersDataWrapperChildrenParams
    extends DataWrapperChildrenParams<PaginatedApiResponseBody<Supplier>> {
    fetchSuppliers: () => Promise<PaginatedApiResponseBody<Supplier, PaginationMeta>>;
}

interface FetchSuppliersDataWrapperProps
    extends DataWrapperProps<
            FetchSuppliersDataWrapperChildrenParams,
            PaginatedApiResponseBody<Supplier>
        >,
        BaseFetchOptions {
    eventId?: string;
}

export const useFetchSuppliersDataWrapper = ({
    includes = [],
    onError,
    onSuccess,
    invalidatedAt,
    eventId,
    page,
    pageSize,
    filter,
    sorting,
    q,
}: Omit<FetchSuppliersDataWrapperProps, 'children'>) => {
    const [{ loading, data, errors, resetData }, handleFetchSuppliers] =
        useApiFetch<PaginatedApiResponseBody<Supplier, PaginationMeta>>();

    const fetchSuppliersCall = () => {
        return handleFetchSuppliers(
            supplierService.getSuppliers({
                includes,
                page,
                pageSize,
                filter,
                sorting,
                q,
            }),
            {
                useDefaultErrorHandler: true,
                onFail: onError,
                onSuccess,
            }
        );
    };

    useEffect(() => {
        fetchSuppliersCall();
    }, [page, pageSize, filter, sorting, q, invalidatedAt, eventId]);

    return {
        fetchSuppliers: fetchSuppliersCall,
        data,
        loading,
        errors,
        resetData,
    };
};

export const FetchSuppliersDataWrapper = (props: FetchSuppliersDataWrapperProps) => {
    const { children } = props;
    const hookData = useFetchSuppliersDataWrapper(props);

    if (!children) return <></>;

    return <>{children(hookData)}</>;
};
