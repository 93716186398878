import { zodResolver } from '@hookform/resolvers/zod';
import Grid from '@mui/material/Grid';
import { Controller, useForm } from 'react-hook-form';
import { zodRequiredStringSchema } from 'src/app/utilities/zod/zodRequiredStringSchema';
import { SeatingPlanCategoryProperty } from 'src/data/models/seating-plan-category-property/seatingPlanCategoryProperty';
import { useSpacingStyles } from 'src/shared/styles/spacingStyles';
import CheckboxWithLabel from 'src/view/components/checkbox-with-label/CheckboxWithLabel';
import FormButtons from 'src/view/components/form/FormButtons';
import { FormLabel } from 'src/view/components/form/FormLabel';
import Input from 'src/view/components/input/Input';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';
import z from 'zod';

interface Props {
    onSucceed?: () => void;
    propertyId?: string;
    disabled?: boolean;
    defaultValues?: Partial<SeatingPlanCategoryProperty> | null;
    loading: boolean;
    onFormSubmit: (propertyDto: SeatingPlanCategoryProperty) => void;
}
export const PropertyValidationSchema = z.object({
    key: zodRequiredStringSchema.refine(
        (value) => {
            // Checks for special characters, commas, or dots
            return !/[^a-zA-Z0-9 _]/g.test(value);
        },
        {
            message: 'Value cannot contain special characters, commas, or dots',
        }
    ),
    internalDescription: z.string().optional(),
    supportsValue: z.boolean(),
    id: z.string().optional(),
});

export default function SeatingPlanCategoryPropertyForm({
    propertyId,
    disabled,
    defaultValues,
    loading,
    onFormSubmit,
}: Props) {
    const fallbackDefaultValues = {
        id: '',
        key: '',
        internalDescription: '',
        supportsValue: false,
    };
    const spacingClasses = useSpacingStyles();
    const form = useForm<SeatingPlanCategoryProperty>({
        mode: 'onChange',
        defaultValues: defaultValues ? defaultValues : fallbackDefaultValues,
        resolver: zodResolver(PropertyValidationSchema),
    });

    const {
        control,
        handleSubmit,
        formState: { isDirty },
    } = form;

    const isSubmitButtonDisabled = disabled || loading || !isDirty;

    return (
        <>
            {loading && <LoadingOverlay />}
            <Grid container>
                <Grid item xs={12} className={spacingClasses.spacingBottom}>
                    <FormLabel>Property</FormLabel>
                    <Controller
                        name="key"
                        control={control}
                        render={({
                            field: { name, value, onChange, ref, onBlur },
                            fieldState: { error },
                        }) => (
                            <Input
                                disabled={!!propertyId}
                                name={name}
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                inputRef={ref}
                                placeholder="Property"
                                inputHelperText={error?.message}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} className={spacingClasses.spacingBottom}>
                    <FormLabel>Internal Notes</FormLabel>
                    <Controller
                        name="internalDescription"
                        control={control}
                        render={({ field: { name, value, onChange, ref, onBlur } }) => (
                            <Input
                                name={name}
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                inputRef={ref}
                                placeholder="Write your internal notes here"
                                multiline
                                rows={4}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="supportsValue"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                            <CheckboxWithLabel
                                label="Supports additional value"
                                checked={value}
                                value={!!value}
                                onChange={onChange}
                            />
                        )}
                    />
                </Grid>
                <FormButtons
                    buttons={[
                        {
                            disabled: isSubmitButtonDisabled,
                            children: propertyId ? 'Update' : 'Create',
                            onClick: () => handleSubmit(onFormSubmit)(),
                        },
                    ]}
                />
            </Grid>
        </>
    );
}
