import { zodResolver } from '@hookform/resolvers/zod';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { FetchSeatingplanCategoryPropertiesDataWrapper } from 'src/app/components/data-wrappers/seating-plan-category-properties/FetchSeatingplanCategoryPropertiesDataWrapper';
import { TagsAutocompleteFeature } from 'src/app/components/features/tags/TagsAutocompleteFeature';
import { ticketTypeOptions } from 'src/app/constants/constants/form/ticketTypeOptions';
import { zodRequiredDropdownSelectSchema } from 'src/app/utilities/zod/zodRequiredDropdownSelectSchema';
import { TAG_TYPES } from 'src/data/enums/tagType';
import AutoComplete from 'src/view/components/auto-complete/AutoComplete';
import type {
    AutoCompleteOption,
    AutoCompleteOptions,
} from 'src/view/components/auto-complete/interfaces';
import FormButtons from 'src/view/components/form/FormButtons';
import FormFieldError from 'src/view/components/form/FormFieldError';
import Input from 'src/view/components/input/Input';
import { LinkActionButton } from 'src/view/components/link-action-button/LinkActionButton';
import ItemList from 'src/view/components/lists/ItemList';
import LoadingOverlay from 'src/view/components/loading-overlay/LoadingOverlay';
import ModalSubTitle from 'src/view/components/modal/ModalSubtitle';

import z from 'zod';

const useStyles = makeStyles((theme) => ({
    linkPropertyFormGrid: {
        padding: 8,
        background: theme.colors.lightBlue,
        margin: 0,
        width: '100%',
        borderRadius: 4,
        paddingTop: 0,
    },
    linkPropertyFormGridItem: {
        paddingLeft: 0,
    },
}));
const validationSchema = z.object({
    name: z.string().min(1, 'Name is required'),
    tags: z.array(zodRequiredDropdownSelectSchema).optional(),
    ticketTypes: z.array(zodRequiredDropdownSelectSchema).optional(),
    seatingPlanCategoryProperties: z.array(
        z.object({
            property: zodRequiredDropdownSelectSchema,
            additionalValue: z.string().nullish(),
        })
    ),
});

const propertiesValidationSchema = z.object({
    property: zodRequiredDropdownSelectSchema,
    additionalValue: z.string().nullish(),
});

export interface CreateSeatingPlanCategoryValues {
    name: string;
    tags?: AutoCompleteOptions;
    ticketTypes?: AutoCompleteOptions;
    seatingPlanCategoryProperties: LinkSeatingPlanCategoryPropertyValues[];
}

export interface LinkSeatingPlanCategoryPropertyValues {
    property: AutoCompleteOption;
    additionalValue?: string;
}

interface Props {
    loading?: boolean;
    editMode?: boolean;
    formDefaultValues?: Partial<CreateSeatingPlanCategoryValues>;
    onFormSubmit: (values: CreateSeatingPlanCategoryValues) => void;
}

export default function CreateSeatingplanCategoryForm({
    loading,
    editMode = false,
    formDefaultValues,
    onFormSubmit,
}: Props) {
    const classes = useStyles();

    const [showLinkPropertyForm, setShowLinkPropertyForm] = useState(false);

    const form = useForm<CreateSeatingPlanCategoryValues>({
        mode: 'onChange',
        resolver: zodResolver(validationSchema),
        defaultValues: formDefaultValues,
    });

    const {
        control,
        formState: { isDirty },
        handleSubmit,
        setValue,
        watch,
    } = form;

    const isSubmitButtonDisabled = loading || !isDirty;

    const { fields, append, remove } = useFieldArray({
        name: 'seatingPlanCategoryProperties',
        control,
    });

    const properties = watch('seatingPlanCategoryProperties');

    const seatingplanPropertiesForm = useForm<LinkSeatingPlanCategoryPropertyValues>({
        resolver: zodResolver(propertiesValidationSchema),
    });

    const createPropertyValue = seatingplanPropertiesForm.watch('property');

    function onSubmitLinkSeatingplanCategoryProperty(
        values: LinkSeatingPlanCategoryPropertyValues
    ) {
        if (properties.find((property) => property.property.value === values.property?.value))
            return;

        append(values);
        seatingplanPropertiesForm.reset();
        setShowLinkPropertyForm(false);
    }

    return (
        <>
            {loading && <LoadingOverlay />}
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ModalSubTitle>Name</ModalSubTitle>
                    <Controller
                        name="name"
                        control={control}
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <>
                                <Input
                                    name={name}
                                    value={value}
                                    onChange={onChange}
                                    placeholder="Seatingplan category name"
                                    grey
                                />
                                <FormFieldError message={error?.message} />
                            </>
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ModalSubTitle>Tags</ModalSubTitle>
                    <Controller
                        name="tags"
                        control={control}
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <>
                                <TagsAutocompleteFeature
                                    type={TAG_TYPES.SeatingPlanCategoryTag}
                                    onChange={onChange}
                                    isMulti
                                    name={name}
                                    value={value}
                                    placeholder="Select or create tag"
                                    onTagCreated={(option) => {
                                        if (value) {
                                            setValue('tags', [...value, option]);

                                            return;
                                        }

                                        setValue('tags', [option]);
                                    }}
                                />
                                <FormFieldError message={error?.message} />
                            </>
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ModalSubTitle>Ticket types</ModalSubTitle>
                    <Controller
                        name="ticketTypes"
                        control={control}
                        render={({ field: { name, onChange, value }, fieldState: { error } }) => (
                            <>
                                <AutoComplete
                                    name={name}
                                    onChange={onChange}
                                    value={value}
                                    disabled={loading}
                                    isMulti={true}
                                    isClearable={false}
                                    options={ticketTypeOptions}
                                    placeholder="select ticket types"
                                />
                                <FormFieldError message={error?.message} />
                            </>
                        )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="seatingPlanCategoryProperties"
                        control={control}
                        render={({ fieldState: { error } }) => (
                            <>
                                <ItemList
                                    header="Properties"
                                    listItems={
                                        fields.map(({ property, additionalValue }) => {
                                            return {
                                                text: property?.label,
                                                value: property?.value,
                                                chipInfo: additionalValue,
                                            };
                                        }) || []
                                    }
                                    onItemRemove={(_, index) => {
                                        remove(index);
                                    }}
                                    removableItems
                                />

                                <FormFieldError message={error?.message} />
                            </>
                        )}
                    />
                    <LinkActionButton onClick={() => setShowLinkPropertyForm(true)}>
                        + Link property
                    </LinkActionButton>
                </Grid>
                {showLinkPropertyForm && (
                    <Grid item xs={12}>
                        <FetchSeatingplanCategoryPropertiesDataWrapper>
                            {({ data: fetchedCategoryProperties }) => {
                                const linkedProperties =
                                    properties?.map(({ property }) => property.value) || [];

                                const availableProperties =
                                    fetchedCategoryProperties?.data.filter(
                                        ({ id }) => !linkedProperties.includes(id)
                                    ) || [];

                                return (
                                    <Grid container className={classes.linkPropertyFormGrid}>
                                        <Grid
                                            item
                                            xs={12}
                                            className={classes.linkPropertyFormGridItem}
                                        >
                                            <ModalSubTitle>Property*</ModalSubTitle>
                                            <Controller
                                                name="property"
                                                control={seatingplanPropertiesForm.control}
                                                render={({
                                                    field: { name, value, onChange },
                                                    fieldState: { error },
                                                }) => (
                                                    <>
                                                        <AutoComplete
                                                            options={
                                                                availableProperties.map(
                                                                    ({
                                                                        id,
                                                                        key,
                                                                        supportsValue,
                                                                    }) => ({
                                                                        value: id,
                                                                        label: key,
                                                                        supportsValue,
                                                                    })
                                                                ) || []
                                                            }
                                                            value={value}
                                                            placeholder="Select a property"
                                                            onChange={onChange}
                                                            name={name}
                                                        />
                                                        <FormFieldError message={error?.message} />
                                                    </>
                                                )}
                                            />
                                        </Grid>
                                        {!!createPropertyValue && (
                                            <Grid
                                                item
                                                xs={12}
                                                className={classes.linkPropertyFormGridItem}
                                            >
                                                <ModalSubTitle>Additional value</ModalSubTitle>
                                                <Controller
                                                    name="additionalValue"
                                                    control={seatingplanPropertiesForm.control}
                                                    render={({
                                                        field: { name, value, onChange },
                                                    }) => (
                                                        <Input
                                                            name={name}
                                                            value={value}
                                                            onChange={onChange}
                                                            placeholder={
                                                                !createPropertyValue.supportsValue
                                                                    ? 'This property does not support any additional value'
                                                                    : 'Additional property value'
                                                            }
                                                            disabled={
                                                                !createPropertyValue.supportsValue
                                                            }
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        )}

                                        <Grid item xs={12}>
                                            <FormButtons
                                                buttons={[
                                                    {
                                                        variant: 'text',
                                                        children: 'Cancel',
                                                        color: 'error',
                                                        onClick: () => {
                                                            seatingplanPropertiesForm.reset();
                                                            setShowLinkPropertyForm(false);
                                                        },
                                                    },
                                                    {
                                                        children: 'Confirm',

                                                        onClick:
                                                            seatingplanPropertiesForm.handleSubmit(
                                                                onSubmitLinkSeatingplanCategoryProperty
                                                            ),
                                                    },
                                                ]}
                                            />
                                        </Grid>
                                    </Grid>
                                );
                            }}
                        </FetchSeatingplanCategoryPropertiesDataWrapper>
                    </Grid>
                )}
            </Grid>

            <FormButtons
                buttons={[
                    {
                        children: editMode
                            ? 'Update Seatingplan category'
                            : 'Create Seatingplan category',
                        onClick: () => handleSubmit(onFormSubmit)(),
                        disabled: isSubmitButtonDisabled,
                    },
                ]}
            />
        </>
    );
}
