import { useEffect } from 'react';
import useApiFetch, { ErrorReturn } from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import seriesGroupService from 'src/data/services/seriesGroupService';
import SeriesGroup from 'src/data/models/series-group/SeriesGroup';

interface FetchSeriesGroupByIdDataWrapperChildrenParams
    extends DataWrapperChildrenParams<ApiResponseBody<SeriesGroup>> {
    fetchSeriesGroupById: (id: string) => Promise<void | ErrorReturn>;
}

interface FetchSeriesGroupByIdDataWrapperProps
    extends DataWrapperProps<
            FetchSeriesGroupByIdDataWrapperChildrenParams,
            ApiResponseBody<SeriesGroup>
        >,
        BaseFetchOptions {
    seriesGroupId: string;
}

export const useFetchSeriesGroupByIdDataWrapper = ({
    seriesGroupId,
    onError,
    onSuccess,
    invalidatedAt,
}: Omit<FetchSeriesGroupByIdDataWrapperProps, 'children'>) => {
    const [{ data, loading, errors, resetData }, handleFetchSeriesGroupById] =
        useApiFetch<ApiResponseBody<SeriesGroup>>();

    const fetchOrderByIdCall = () => {
        return handleFetchSeriesGroupById(seriesGroupService.fetchSeriesGroupById(seriesGroupId), {
            useDefaultErrorHandler: true,
            onFail: onError,
            onSuccess,
        });
    };

    useEffect(() => {
        fetchOrderByIdCall();
    }, [invalidatedAt]);

    return {
        fetchSeriesGroupById: () => fetchOrderByIdCall(),
        data,
        loading,
        errors,
        resetData,
    };
};
