import { Alert, Grid, Link } from '@mui/material';
import EventUrlService from 'src/app/pages/events/eventUrlService';
import EventTicketFile from 'src/data/models/tickets/eventTicketFile';

interface TicketAlreadyLinkedErrorProps {
    ticketError: EventTicketFile;
}

const TicketAlreadyLinkedError = ({ ticketError }: TicketAlreadyLinkedErrorProps) => {
    const isSameFileName = ticketError.inputFileName === ticketError.ticketFileName;
    const warningMessage = isSameFileName ? (
        'This file is already linked to another ticket.'
    ) : (
        <>
            This file is already linked to another ticket under different file name:
            <strong> {ticketError.ticketFileName}</strong>
        </>
    );

    return (
        <Grid
            item
            xs={12}
            sx={{
                margin: '4px 0',
            }}
        >
            <Alert severity="error">
                {warningMessage}
                <Link
                    sx={{
                        marginLeft: '5px',
                    }}
                    target="_blank"
                    href={EventUrlService.tickets(ticketError.eventId, {
                        ticketId: ticketError.ticketId,
                    })}
                >
                    See ticket
                </Link>
            </Alert>
        </Grid>
    );
};
export default TicketAlreadyLinkedError;
