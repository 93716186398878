export const P1Logo = () => (
    <svg
        version="1.1"
        id="Laag_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 364 445"
        xmlSpace="preserve"
        height="80"
    >
        <style type="text/css"></style>
        <linearGradient
            id="SVGID_1_"
            gradientUnits="userSpaceOnUse"
            x1="308.5845"
            y1="449.9353"
            x2="308.5845"
            y2="89.1153"
            gradientTransform="matrix(1 0 0 -1 0 447.8706)"
        >
            <stop offset="1.000000e-02" style={{ stopColor: '#F7A429' }} />
            <stop offset="1" style={{ stopColor: '#ED7028' }} />
        </linearGradient>
        <path
            style={{ fill: 'url(#SVGID_1_)' }}
            d="M353.3,0.7c-1.2-0.3-2.3-0.4-3.5-0.4l-21.3,2.9L307.2,6L289,8.5l-21,2.8c-3.1,0.2-6.1,1.2-8.6,3
	c-2.8,2.1-4.6,5.1-5.3,8.5c-0.3,1.4-0.5,2.9-0.5,4.4v42.2c0,2.4,0.5,4.8,1.5,7c2.3,4.9,7.3,8,12.7,7.9c0.9,0,1.8-0.1,2.7-0.2
	l19.6-2.5v270.4c-0.1,3.6,1.1,7.1,3.5,9.8c2.7,2.7,6.5,4.2,10.3,4h45.8c8.1,0,13.8-5.7,13.8-13.8V14.1
	C363.5,7.2,359.5,2.1,353.3,0.7z M350,95.7v256.3c0,0.1,0,0.2,0,0.3h-46.3v-286l-35.1,4.5c-0.4,0.1-0.9,0.1-1.3,0
	c-0.1-0.4-0.2-0.8-0.2-1.3V27.3c-0.1-0.7,0-1.4,0.3-2.1c0.6-0.3,1.2-0.4,1.8-0.4l4-0.5l18.2-2.5l21.3-2.8l37.3-5.1
	c0,0.1,0,0.2,0,0.2L350,95.7L350,95.7z"
        />
        <linearGradient
            id="SVGID_00000106852485774893702040000007181928694337582224_"
            gradientUnits="userSpaceOnUse"
            x1="104.9"
            y1="449.9353"
            x2="104.9"
            y2="89.1153"
            gradientTransform="matrix(1 0 0 -1 0 447.8706)"
        >
            <stop offset="1.000000e-02" style={{ stopColor: '#F7A429' }} />
            <stop offset="1" style={{ stopColor: '#ED7028' }} />
        </linearGradient>
        <path
            style={{ fill: 'url(#SVGID_00000106852485774893702040000007181928694337582224_)' }}
            d="M207,48c-3-12.9-8.8-23.3-17.3-31.1
	c-3.6-3.3-7.7-6.2-12.1-8.4c-6.5-3.3-13.5-5.6-20.8-6.8c-5.8-1-11.7-1.5-17.6-1.4h-125C6.1,0.3,0.5,5.9,0.5,14.1v127
	c0,7.8,5.2,13.3,12.8,13.8c0.3,0,0.7,0,1,0H60c8.4,0,13.8-5.4,13.8-13.8V71.9h51.3c3.7,0,6.1,0.7,7.7,1.9c1.8,1.4,2.6,3.4,3,5.9
	c0.1,1,0.2,2.1,0.2,3.1v82.7c0,4.4-1.1,7.4-3.4,9l-0.4,0.2l-0.8,0.4l-0.9,0.4l-0.3,0.1c-0.3,0.1-0.6,0.1-0.8,0.2s-0.6,0.1-0.9,0.2
	l-0.3,0.1c-1,0.2-2.1,0.2-3.2,0.2H14.3c-0.6,0-1.2,0-1.8,0.1c-7.1,0.8-12,6.2-12,13.7v161.9c-0.3,7.3,5.3,13.5,12.6,13.8
	c0.4,0,0.8,0,1.2,0H60c8.4,0,13.8-5.4,13.8-13.8V247.8h65.4c2.8,0,5.5-0.1,8-0.3c6.8-0.4,13.6-1.7,20.1-3.7c2.8-0.9,5.6-2,8.2-3.3
	c14.8-7.1,25-19.2,30.1-35.8c2.4-8.1,3.6-16.5,3.6-24.9c0-0.7,0-1.4,0-2.1V70.4c0-1.4,0-2.9-0.1-4.2C209.1,60,208.3,54,207,48z
	 M195.9,129.5v48.2c0,5.8-0.5,11.5-1.6,17.2c-3.9,19.2-14.9,31.4-32.8,36.6c-4.9,1.4-10,2.3-15.1,2.6c-2.2,0.2-4.6,0.2-7,0.2h-79
	v117.6c0,0.1,0,0.2,0,0.3H14.1c0-0.1,0-0.2,0-0.3V190c0-0.1,0-0.2,0-0.3h111.1c1.8,0,3.5-0.1,5.3-0.4c0.6-0.1,1.3-0.3,1.9-0.4
	l0.9-0.2c0.7-0.1,1.3-0.3,2-0.5c0.6-0.2,1.2-0.5,1.8-0.8l0.6-0.3c0.7-0.3,1.4-0.6,2-1c0.3-0.2,0.6-0.4,0.9-0.7c4.2-3,9-8.9,9-20
	V82.7c0-1.7-0.1-3.3-0.3-5c-0.6-5.2-3-10-6.8-13.6c-3.8-3.3-8.5-5.2-13.5-5.6c-1.3-0.1-2.5-0.2-3.8-0.2H60.3v82.8c0,0.1,0,0.2,0,0.3
	H14.1c0-0.1,0-0.2,0-0.3v-127c0-0.1,0-0.2,0-0.3h125.2c1.8,0,3.6,0.1,5.3,0.2c9.2,0.5,17.1,2.2,23.8,5.2c4.3,1.8,8.2,4.3,11.7,7.4
	c8.5,7.6,13.6,18.7,15.2,33.2c0.4,3.5,0.6,7.1,0.6,10.7V129.5z"
        />
        <path d="M23.6,444.7h-10v-39H0.7v-8.4h35.7v8.4H23.6V444.7z" />
        <path
            d="M75.8,426.6v18.2h-10v-47.3h13.8c6.4,0,11.2,1.2,14.3,3.5c3.1,2.3,4.6,5.9,4.6,10.7c0,2.7-0.8,5.3-2.3,7.4
	c-1.7,2.3-3.9,4-6.5,5.1c7.1,10.7,11.8,17.5,13.9,20.6H92.4l-11.3-18.2L75.8,426.6z M75.8,418.4H79c3.2,0,5.5-0.5,7-1.6
	c1.6-1.2,2.4-3.1,2.3-5c0.2-1.9-0.7-3.7-2.3-4.8c-1.6-0.9-3.9-1.4-7.2-1.4h-3L75.8,418.4z"
        />
        <path
            d="M162.8,444.7l-3.4-11.3h-17.3l-3.5,11.3h-10.8l16.7-47.5h12.3l16.8,47.5H162.8z M156.9,425.1c-3.2-10.2-5-16-5.4-17.3
	s-0.7-2.4-0.9-3.2c-0.7,2.8-2.7,9.6-6.1,20.5H156.9z"
        />
        <path
            d="M228.2,397.4h10.1l-16.1,47.3h-11l-16.1-47.3h10.1l8.9,28.2c0.5,1.7,1,3.6,1.5,5.8s0.9,3.8,1,4.6c0.6-3.5,1.4-7,2.4-10.4
	L228.2,397.4z"
        />
        <path d="M296.5,444.7h-27.3v-47.3h27.3v8.2h-17.2V416h16v8.2h-16v12.2h17.2V444.7z" />
        <path d="M334.3,444.7v-47.3h10v39h19.2v8.3L334.3,444.7z" />
    </svg>
);
