import { useQuery } from '@tanstack/react-query';
import type BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import { SEATING_PLAN_CATEGORY_FILTER } from 'src/data/enums/seatingPlanCategoryFilter';
import { fetchSeatingPlanCategories } from 'src/data/services/eventService';

type FetchOptions = BaseFetchOptions & {
    filter?: SEATING_PLAN_CATEGORY_FILTER;
};

export const FETCH_SEATING_PLAN_CATEGORIES_QUERY_KEY = 'FETCH_SEATING_PLAN_CATEGORIES';

export const useFetchSeatingPlanCategories = (eventId: string, options?: FetchOptions) => {
    return useQuery({
        queryKey: [FETCH_SEATING_PLAN_CATEGORIES_QUERY_KEY, eventId, options?.filter, options],
        queryFn: ({ signal }) =>
            fetchSeatingPlanCategories(eventId, options?.filter, options, signal),
        enabled: !!eventId,
    });
};
