import { RowIdResolver } from 'src/view/components/table/table/Table';
import {
    SPLIT_CONNECTION_LINE_TYPES,
    TicketsTableDataResolver,
} from 'src/app/components/tables/TicketsTable';
import Ticket from 'src/data/models/tickets/ticket';
import {
    getSplitConnectionMetadata,
    TicketSplitConnectionMetadata,
} from 'src/app/components/tables/helpers/ticketsTableHelpers';

type SelectedTicketRowsType = RowIdResolver<TicketsTableDataResolver>[];

const getSortedTickets = (selectedRows: SelectedTicketRowsType) => {
    return selectedRows
        .map((ticket) => {
            return {
                ...ticket.data,
                sortOrder: ticket.data?.sortOrder ? ticket.data.sortOrder : 0,
            };
        })
        .sort((a, b) => a.sortOrder - b.sortOrder);
};

export const getSplitFromSelectedTickts = (selectedRows: SelectedTicketRowsType) => {
    for (const row of selectedRows) {
        if (row.data?.splitName) return row.data.splitName;
    }

    return null;
};

export const everyTicketHasSplit = (selectedRows: SelectedTicketRowsType) => {
    if (!selectedRows) return false;

    return selectedRows.every((r) => r.data?.splitName);
};

export const ticketsHaveSplit = (selectedRows: SelectedTicketRowsType) => {
    if (!selectedRows) return false;

    return selectedRows.some((r) => !!r.data?.splitName);
};

export const ticketsHaveSameSplit = (selectedRows: SelectedTicketRowsType) => {
    const split = getSplitFromSelectedTickts(selectedRows);

    if (!split) return false;

    return selectedRows.every((r) => r.data?.splitName === split);
};

export const selectionDoesNotBreakTheSplit = (selectedRows: SelectedTicketRowsType) => {
    if (!ticketsHaveSameSplit(selectedRows)) return false;

    const tickets = getSortedTickets(selectedRows);

    const firstTicket = tickets[0];
    const lastTicket = tickets[tickets.length - 1];
    const totalSplitTicketsCount = firstTicket?.splitTotalTicketsCount;

    return firstTicket?.sortOrder === 1 || lastTicket?.sortOrder === totalSplitTicketsCount;
};

export const selectionLeavesOnlyOneSplitTicket = (selectedRows: SelectedTicketRowsType) => {
    if (!ticketsHaveSameSplit(selectedRows)) return false;

    const tickets = getSortedTickets(selectedRows);

    const totalSplitTicketsCount = tickets[0].splitTotalTicketsCount || 0;

    return totalSplitTicketsCount - tickets.length === 1;
};

export const selectedSplitTicketsAreIncrementingByOne = (selectedRows: SelectedTicketRowsType) => {
    if (!ticketsHaveSameSplit(selectedRows)) return false;

    const tickets = getSortedTickets(selectedRows);
    let lastSortOrder = tickets[0]?.sortOrder;

    for (let i = 0; i < tickets.length; i++) {
        if (lastSortOrder !== tickets[i].sortOrder) {
            return false;
        }

        lastSortOrder++;
    }
    return true;
};

export const selectedSplitTicketsCanBeDeleted = (selectedRows: SelectedTicketRowsType) => {
    if (!ticketsHaveSplit(selectedRows)) return false;

    /* 
       Sort tickets by ascending sortOrder
    */
    const tickets = getSortedTickets(selectedRows);

    if (!tickets.length) return false;

    /* 
       Is the first item in the beginning of the split
       Is the last item in the end of the split
    */

    if (!selectionDoesNotBreakTheSplit(selectedRows)) return false;

    /* 
       Whether the sortOrder incrementing by one, starting from first item in the array
    */

    if (!selectedSplitTicketsAreIncrementingByOne(selectedRows)) return false;

    /*
        If the split remains only one ticket upon deletion
    */
    if (selectionLeavesOnlyOneSplitTicket(selectedRows)) return false;

    return true;
};

export const canCreateSplitFromSelection = (selectedRows: SelectedTicketRowsType) => {
    return (
        selectedRows.length < 2 ||
        ticketsHaveDifferentCategories(selectedRows) ||
        ticketsHaveSplit(selectedRows)
    );
};

/**
 * This functions tells us if the tickets have the same splits in combination with empty splits
 * If you see a ! in front of this function, it means that the selected tickets have different splits basically
 */
export const ticketsHaveNoOrSameSplit = (selectedRows: SelectedTicketRowsType) => {
    const split = getSplitFromSelectedTickts(selectedRows);

    return selectedRows.every((r) => r.data?.splitName === split || !r.data?.splitName);
};

export const ticketsHaveDifferentCategories = (selectedRows: SelectedTicketRowsType) => {
    const category = selectedRows[0].data?.seatingplanCategoryId;

    return !selectedRows.every((row) => row.data?.seatingplanCategoryId === category);
};

export const getTicketsConnectionLines = (splitTickets: Ticket[]) => {
    if (!splitTickets.length) {
        return [
            {
                topLine: SPLIT_CONNECTION_LINE_TYPES.NONE,
                bottomLine: SPLIT_CONNECTION_LINE_TYPES.NONE,
            },
        ];
    }

    const splitConnectionMetaData = getSplitConnectionMetadata(splitTickets);

    const getConnectionLineTop = ({
        isFirstOfSplit,
        previousTicketSplitConnects,
        hasPreviousButDoesNotConnect,
    }: TicketSplitConnectionMetadata) => {
        if (hasPreviousButDoesNotConnect) return SPLIT_CONNECTION_LINE_TYPES.BROKEN;

        if (isFirstOfSplit) return SPLIT_CONNECTION_LINE_TYPES.NONE;

        if (previousTicketSplitConnects) return SPLIT_CONNECTION_LINE_TYPES.CONNECTED;

        return SPLIT_CONNECTION_LINE_TYPES.NONE;
    };

    const getConnectionLineBottom = ({
        isLastOfSplit,
        nextTicketSplitConnects,
        hasNextButDoesNotConnect,
    }: TicketSplitConnectionMetadata) => {
        if (hasNextButDoesNotConnect) return SPLIT_CONNECTION_LINE_TYPES.BROKEN;

        if (isLastOfSplit) return SPLIT_CONNECTION_LINE_TYPES.NONE;

        if (nextTicketSplitConnects) return SPLIT_CONNECTION_LINE_TYPES.CONNECTED;

        return SPLIT_CONNECTION_LINE_TYPES.NONE;
    };

    return splitTickets.map((_, index) => {
        return {
            topLine: getConnectionLineTop(splitConnectionMetaData[index]),
            bottomLine: getConnectionLineBottom(splitConnectionMetaData[index]),
        };
    });
};

const SPLIT_DELETION_DELETE_BUTTON_INFO = {
    BREAKS_SPLIT:
        'The selection breaks the split. Selection must include the first or last ticket of the split.',
    NOT_CONTINOUS: 'The selection must be continous (e.g. 1,2,3 / 8,9,10 and not 1,3,4 / 6,9,10).',
    NOT_SAME_SPLIT: 'The selection is not from the same split.',
    NO_SPLIT: 'The selection includes ticket(s) with no split.',
    REMAINS_ONLY_1_SPLIT_TICKET: 'The selection leaves the split with 1 ticket',
};

const CREATE_NEW_SPLIT_BUTTON_INFO = {
    ALREADY_PART_OF_SPLIT: 'Selected ticket(s) include a ticket with an existing split.',
    NOT_ENOUGH_TICKETS: 'At least 2 tickets must be selected.',
    DIFFERENT_SEATNG_CATEGORY: 'Selected tickets have different seating plan categories.',
};

export const getDeleteSplitDisabledButtonReasons = (
    selectedRows: RowIdResolver<TicketsTableDataResolver>[]
) => {
    const disabledReasons = [];
    if (!selectedRows.length) return [];

    const selectionBreaksTheSplit = !selectionDoesNotBreakTheSplit(selectedRows);
    const selectionDoesNotIncrementsCorrectly =
        !selectedSplitTicketsAreIncrementingByOne(selectedRows);

    if (!everyTicketHasSplit(selectedRows)) {
        disabledReasons.push(SPLIT_DELETION_DELETE_BUTTON_INFO.NO_SPLIT);

        return disabledReasons;
    }

    if (!ticketsHaveNoOrSameSplit(selectedRows)) {
        disabledReasons.push(SPLIT_DELETION_DELETE_BUTTON_INFO.NOT_SAME_SPLIT);

        return disabledReasons;
    }

    if (selectionBreaksTheSplit) {
        disabledReasons.push(SPLIT_DELETION_DELETE_BUTTON_INFO.BREAKS_SPLIT);
    }

    if (selectionDoesNotIncrementsCorrectly) {
        disabledReasons.push(SPLIT_DELETION_DELETE_BUTTON_INFO.NOT_CONTINOUS);
    }

    if (selectionLeavesOnlyOneSplitTicket(selectedRows)) {
        if (selectionDoesNotIncrementsCorrectly || selectionBreaksTheSplit) {
            return disabledReasons;
        }

        disabledReasons.push(SPLIT_DELETION_DELETE_BUTTON_INFO.REMAINS_ONLY_1_SPLIT_TICKET);
    }

    return disabledReasons;
};

export const getCreateSplitDisabledButtonReasons = (
    selectedRows: RowIdResolver<TicketsTableDataResolver>[]
) => {
    const disabledReasons: string[] = [];
    if (!selectedRows.length) return [];

    if (ticketsHaveSplit(selectedRows)) {
        disabledReasons.push(CREATE_NEW_SPLIT_BUTTON_INFO.ALREADY_PART_OF_SPLIT);

        return disabledReasons;
    }

    if (selectedRows.length < 2) {
        disabledReasons.push(CREATE_NEW_SPLIT_BUTTON_INFO.NOT_ENOUGH_TICKETS);

        return disabledReasons;
    }

    if (ticketsHaveDifferentCategories(selectedRows)) {
        disabledReasons.push(CREATE_NEW_SPLIT_BUTTON_INFO.DIFFERENT_SEATNG_CATEGORY);
    }

    return disabledReasons;
};