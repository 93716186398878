import { useEffect } from 'react';
import useApiFetch from 'src/app/hooks/useApiFetch';
import DataWrapperChildrenParams from 'src/app/interfaces/dataWrapperChildrenParams';
import DataWrapperProps from 'src/app/interfaces/dataWrapperProps';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import PaginatedApiResponseBody from 'src/data/api/responses/PaginatedApiResponseBody';
import PaginationMeta from 'src/data/api/responses/PaginationMeta';
import Ticket from 'src/data/models/tickets/ticket';
import ticketsService, { EventTicketsFetchOptions } from 'src/data/services/ticketsService';
import useDeepCompareEffect from 'use-deep-compare-effect';

interface FetchTicketsDataWrapperChildrenParams
    extends DataWrapperChildrenParams<PaginatedApiResponseBody<Ticket>> {
    fetchTickets: (
        options?: EventTicketsFetchOptions
    ) => Promise<PaginatedApiResponseBody<Ticket, PaginationMeta>>;
}

interface FetchTicketsDataWrapperProps
    extends DataWrapperProps<
            FetchTicketsDataWrapperChildrenParams,
            PaginatedApiResponseBody<Ticket>
        >,
        BaseFetchOptions {
    eventId: string;
}

export const useFetchTicketsDataWrapper = ({
    includes = [],
    onError,
    onSuccess,
    invalidatedAt,
    eventId,
    page,
    pageSize,
    filter,
    sorting,
    q,
    sortBy,
    skipInitialCall,
}: Omit<FetchTicketsDataWrapperProps, 'children'>) => {
    const [{ loading, data, errors, resetData }, handleFetchTickets] =
        useApiFetch<PaginatedApiResponseBody<Ticket>>();

    const fetchTicketsCall = (options?: EventTicketsFetchOptions) => {
        const fetchOptions = {
            sorting,
            filter,
            page,
            pageSize,
            includes,
            q,
            sortBy,
            ...options,
        };

        return handleFetchTickets(ticketsService.fetchEventTickets(eventId, fetchOptions), {
            useDefaultErrorHandler: true,
            onFail: onError,
            onSuccess,
        });
    };

    useDeepCompareEffect(() => {
        if (skipInitialCall) return;

        fetchTicketsCall();
    }, [page, pageSize, filter, sorting, q, sortBy, invalidatedAt]);

    useEffect(() => {
        if (!invalidatedAt) return;

        fetchTicketsCall();
    }, [invalidatedAt]);

    return {
        fetchTickets: fetchTicketsCall,
        data,
        loading,
        errors,
        resetData,
    };
};

export const FetchTicketsDataWrapper = (props: FetchTicketsDataWrapperProps) => {
    const { children } = props;
    const hookData = useFetchTicketsDataWrapper(props);

    if (!children) return <></>;

    return <>{children(hookData)}</>;
};
