import { Grid, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import React from 'react';
import { type ChildlessBaseComponent } from 'src/view/interfaces/BaseComponent';

export type DictionaryItemProps = ValuePresentSchema | CustomValuePresentSchema;

export interface DictionaryProps extends ChildlessBaseComponent {
    data?: DictionaryItemProps[];
    children?: React.ReactNode;
    boldStyling?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
    gridContainer: (props: DictionaryProps) => ({
        fontSize: props.boldStyling ? theme.typography.pxToRem(16) : theme.typography.pxToRem(14),

        '& .MuiGrid-item:nth-child(4n), .MuiGrid-item:nth-child(4n-1)': {
            backgroundColor: theme.colors.lightestGrey,
        },
        '& .MuiGrid-item:nth-child(2n), .MuiGrid-item:nth-child(2n-1)': {
            color: props.boldStyling ? `${theme.palette.primary.main}` : 'inherit',
        },
        '& .MuiGrid-item': {
            fontWeight: props.boldStyling ? theme.typography.fontWeightBold : 'inherit',
            padding: theme.spacing(1.5, 1),
            display: 'flex',
            flexWrap: 'wrap',
            '&:nth-of-type(odd)': {
                color: theme.colors.darkGrey,
            },
        },
        '& .MuiFormControl-root': {
            flex: 1,
            background: theme.palette.primary.contrastText,
        },
        '& .MuiInputBase-input': {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
            fontSize: '.8rem',
        },
    }),
}));

export default function DictionaryGrid({
    data,
    children,
    className,
    boldStyling,
}: DictionaryProps): JSX.Element {
    const classes = useStyles({ boldStyling });

    return (
        <Grid container className={classNames(classes.gridContainer, className)}>
            {data?.map((item: DictionaryItemProps) => {
                return <DictionaryGridItem key={item.label} {...item} />;
            }) || children}
        </Grid>
    );
}

function DictionaryGridItem({
    value,
    label,
    renderCustomValue,
    hyperlink,
}: DictionaryItemProps): JSX.Element {
    const Component = hyperlink ? 'a' : 'div';

    return (
        <React.Fragment>
            <Grid item xs={4}>
                {label}
            </Grid>
            <Grid item xs={8}>
                <Grid component={Component} href={hyperlink}>
                    {renderCustomValue?.() || value}
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

interface BaseDictionaryItem {
    label: string;
    hyperlink?: string;
}

/** When "value" is present, you may not provide additional "renderCustomValue" property */
interface ValuePresentSchema extends BaseDictionaryItem {
    value: string | number;
    renderCustomValue?: never;
}

/** When renderCustomValue is present, you may not provide additional value property. */
interface CustomValuePresentSchema extends BaseDictionaryItem {
    renderCustomValue?: () => React.ReactNode;
    value?: never;
}
