import ListItem, { ListItemProps } from './ListItem';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
    header: {
        fontSize: '0.9rem',
        margin: '8px 0px',
        padding: 0,
    },
    list: {
        margin: 0,
        padding: 0,
        paddingLeft: 16,
    },
}));

interface ItemListProps {
    listItems: Partial<ListItemProps>[];
    ordered?: boolean;
    header: string;
    removableItems: boolean;
    onItemRemove?: (item: Partial<ListItemProps>, index: number) => void;
    editable?: boolean;
    onClickEdit?: (item: Partial<ListItemProps>, index: number) => void;
}

const ItemList = ({
    listItems,
    header,
    ordered = false,
    removableItems,
    editable,
    onItemRemove,
    onClickEdit,
}: ItemListProps): JSX.Element => {
    const classes = useStyles();
    const ListElement = (ordered ? 'ol' : 'ul') as keyof JSX.IntrinsicElements;

    return (
        <>
            <h2 className={classes.header}>{header}</h2>
            <ListElement className={classes.list}>
                {listItems.length > 0 ? (
                    listItems.map(({ text, value, chipInfo }, index) => {
                        return (
                            <ListItem
                                value={value || ''}
                                text={text || ''}
                                key={text}
                                onRemove={() => onItemRemove?.(listItems[index], index)}
                                removable={removableItems}
                                chipInfo={chipInfo}
                                editable={editable}
                                onClickEdit={() => onClickEdit?.(listItems[index], index)}
                            />
                        );
                    })
                ) : (
                    <ListItem
                        text="The list is empty."
                        key="empty-list"
                        value={null}
                        removable={false}
                    />
                )}
            </ListElement>
        </>
    );
};

export default ItemList;
