import { Alert, Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import { ETBContainer } from 'src/view/components/container/container';
import Header from 'src/view/components/header/Header';
import SplitTableFeature from 'src/app/components/features/splits/SplitTableFeature';

interface SplitsOverviewProps {
    eventName: string;
}

const SplitsOverview = ({ eventName }: SplitsOverviewProps) => {
    const { eventId } = useParams<{ eventId: string }>();

    if (!eventId) {
        return <Alert severity="error">No eventId was found</Alert>;
    }

    return (
        <>
            <Header>
                <Grid item>
                    <h1>{eventName}</h1>
                </Grid>
            </Header>
            <ETBContainer>
                <SplitTableFeature eventId={eventId} />
            </ETBContainer>
        </>
    );
};

export default SplitsOverview;
