import makeStyles from '@mui/styles/makeStyles';

export const useGenericStyles = makeStyles((theme) => ({
    hide: {
        display: 'none',
    },
    center: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
    },
    clickable: {
        cursor: 'pointer',
    },
    link: {
        color: theme.colors.linkBlue,
        textDecoration: 'underline',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    fullWidth: {
        width: '100%',
    },
}));
