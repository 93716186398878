import { AutoCompleteOptions } from 'src/view/components/auto-complete/interfaces';
import { TicketType } from 'src/app/constants/enums/ticketType';

export const ticketTypeOptions: AutoCompleteOptions = [
    {
        label: 'Ticket',
        value: TicketType.Ticket,
    },
    {
        label: 'Mobile',
        value: TicketType.Mobile,
    },
    {
        label: 'Electronic',
        value: TicketType.Electronic,
    },
    {
        label: 'Pickup at venue',
        value: TicketType.PickupAtVenue,
    },
    {
        label: 'Paper',
        value: TicketType.Paper,
    },
    {
        label: 'Lanyard',
        value: TicketType.Lanyard,
    }
];
