import GoogleMapReact from 'google-map-react';
import Marker from 'src/view/components/map/Marker';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
    mapContainer: {
        position: 'relative',
        height: '300px',
    },
    map: {
        height: '100%',
        position: 'relative',
    },
}));

interface MapProps {
    apiKey: string;
    lat: number;
    lng: number;
    zoom?: number;
    address: string;
}

export default function Map({ apiKey, lat, lng, address, zoom = 16 }: MapProps) {
    const classes = useStyles();

    return (
        <div className={classes.mapContainer}>
            <div className={classes.map}>
                <GoogleMapReact
                    yesIWantToUseGoogleMapApiInternals
                    bootstrapURLKeys={{
                        key: apiKey,
                        libraries: 'places',
                    }}
                    defaultZoom={zoom}
                    center={{ lat: lat, lng: lng }}
                    zoom={zoom}
                >
                    <Marker
                        lat={lat}
                        lng={lng}
                        imgUrl="/images/venuemarker.png"
                        address={address}
                    />
                </GoogleMapReact>
            </div>
        </div>
    );
}
