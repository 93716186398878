import Ticket from 'src/data/models/tickets/ticket';

export interface TicketSplitConnectionMetadata {
    ticketId: string;
    isFirstOfSplit: boolean;
    isLastOfSplit: boolean;
    previousTicketSplitConnects: boolean;
    nextTicketSplitConnects: boolean;
    hasPreviousButDoesNotConnect: boolean;
    hasNextButDoesNotConnect: boolean;
}

const getFallback = (ticketId: string): TicketSplitConnectionMetadata => ({
    ticketId,
    isFirstOfSplit: false,
    isLastOfSplit: false,
    previousTicketSplitConnects: false,
    nextTicketSplitConnects: false,
    hasPreviousButDoesNotConnect: false,
    hasNextButDoesNotConnect: false,
});

export const getSplitConnectionMetadata = (tickets: Ticket[]): TicketSplitConnectionMetadata[] => {
    return tickets.map((ticket, index): TicketSplitConnectionMetadata => {
        if (!ticket.sortOrder) {
            return getFallback(ticket.id);
        }

        const previous = index > 0 ? tickets[index - 1] : null;
        const next = index < tickets.length ? tickets[index + 1] : null;

        const isFirstOfSplit = ticket.sortOrder === 1;
        const isLastOfSplit = ticket.sortOrder === ticket.splitTotalTicketsCount;

        const previousHasSameSplit = previous && ticket.splitId === previous.splitId;
        const nextHasSameSplit = next && ticket.splitId === next.splitId;

        const previousTicketSplitConnects =
            previousHasSameSplit && previous?.sortOrder === ticket.sortOrder - 1;
        const nextTicketSplitConnects =
            nextHasSameSplit && next?.sortOrder === ticket.sortOrder + 1;

        return {
            ticketId: ticket.id,
            isFirstOfSplit,
            isLastOfSplit,
            previousTicketSplitConnects: !!previousTicketSplitConnects,
            nextTicketSplitConnects: !!nextTicketSplitConnects,
            hasPreviousButDoesNotConnect: !isFirstOfSplit && !previousTicketSplitConnects,
            hasNextButDoesNotConnect: !isLastOfSplit && !nextTicketSplitConnects,
        };
    });
};
