import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Box, List, ListItem, SvgIconOwnProps, SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { useMemo } from 'react';
import { EventDetails } from 'src/data/models/events/event';
import { countryList } from 'src/shared/countries';

export const Restrictions = ({ restrictions }: { restrictions: EventDetails['restrictions'] }) => {
    const {
        allowAwayFans,
        requireFullCustomerAddress,
        blacklistedCountryCodes = [],
        blacklistedNationalityCodes = [],
    } = restrictions;

    const { blacklistedCountryNames, blacklistedNationalityNames } = useMemo(
        () =>
            getRestrictedCountryAndNationalities(
                blacklistedCountryCodes,
                blacklistedNationalityCodes
            ),
        [blacklistedCountryCodes, blacklistedNationalityCodes]
    );

    return (
        <div>
            {!allowAwayFans && (
                <RestrictionItem icon={CloseIcon} color="error" text="No away fans allowed" />
            )}
            {requireFullCustomerAddress && (
                <RestrictionItem
                    icon={CheckIcon}
                    color="success"
                    text="Requires full customer address"
                />
            )}
            {blacklistedCountryCodes.length > 0 && (
                <RestrictionItem
                    icon={CloseIcon}
                    color="error"
                    text={`${blacklistedCountryCodes.length} Blacklisted ${
                        blacklistedCountryCodes.length === 1 ? 'country' : 'countries'
                    }`}
                    list={blacklistedCountryNames}
                />
            )}
            {blacklistedNationalityCodes.length > 0 && (
                <RestrictionItem
                    icon={CloseIcon}
                    color="error"
                    text={`${blacklistedNationalityCodes.length} Blacklisted ${
                        blacklistedNationalityCodes.length === 1 ? 'nationality' : 'nationalities'
                    }`}
                    list={blacklistedNationalityNames}
                />
            )}
        </div>
    );
};

const RestrictionItem = ({
    icon: Icon,
    color,
    text,
    list,
}: {
    icon: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>>;
    color: SvgIconOwnProps['color'];
    text?: string;
    list?: CountryRestrctionListType[];
}) => {
    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: (theme) => theme.spacing(1),
                }}
            >
                <Icon color={color} />
                {text}
            </Box>
            {list && <CountryRestrictionsList list={list} />}
        </div>
    );
};

type CountryRestrctionListType = (typeof countryList)[number];

const CountryRestrictionsList = ({ list }: { list: CountryRestrctionListType[] }) => {
    return (
        <div>
            <List
                sx={{
                    paddingTop: 0,
                    marginTop: 0,
                    marginBottom: (theme) => theme.spacing(1),
                    fontSize: '.875rem',
                    marginLeft: 1,
                }}
            >
                {list.map((country) => (
                    <ListItem
                        sx={{
                            color: (theme) => theme.colors.darkGrey,
                        }}
                        key={country.label}
                    >
                        {country.label}
                    </ListItem>
                ))}
            </List>
        </div>
    );
};

const getRestrictedCountryAndNationalities = (
    blacklistedCountryCodes: string[],
    blacklistedNationalityCodes: string[]
) => {
    return {
        blacklistedCountryNames: countryList.filter((c) =>
            new Set(blacklistedCountryCodes).has(c.value)
        ),
        blacklistedNationalityNames: countryList.filter((c) =>
            new Set(blacklistedNationalityCodes).has(c.value)
        ),
    };
};
