import debounce from 'lodash/debounce';
import React, { CSSProperties, useMemo, useState } from 'react';
import Input from 'src/view/components/input/Input';

export interface SearchInputProps {
    onChange: (value: string) => void;
    debounceTime?: number;
    placeholder?: string;
    containerClassName?: string;
    defaultValue?: string;
    limit?: number;
    maxWidth?: CSSProperties['maxWidth'];
}

export default function SearchInput({
    onChange,
    debounceTime = 600,
    placeholder = 'search',
    containerClassName,
    defaultValue = '',
    limit = 0,
}: SearchInputProps): JSX.Element {
    const [searchValue, setSearchValue] = useState<string>(defaultValue);

    const debouncedOnChange = useMemo(() => {
        return debounce((value: string) => {
            onChange(value);
        }, debounceTime);
    }, [debounceTime, onChange]);

    const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const value = e.currentTarget.value;

        setSearchValue(value);

        if (value.length < Math.max(limit, 0)) return;

        debouncedOnChange(value);
    };

    return (
        <Input
            containerClassName={containerClassName}
            placeholder={placeholder}
            onChange={handleOnChange}
            value={searchValue}
        />
    );
}
