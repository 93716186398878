export enum PURCHASE_STATUS {
    Confirmed = 'Confirmed',
    Cancelled = 'Cancelled',
    Completed = 'Completed',
}

export const PURCHASE_TYPES = ['OnSale', 'Trade', 'ExtraByPartners'] as const;
export type PurchaseType = (typeof PURCHASE_TYPES)[number];

export const PURCHASE_TYPE_ITEMS: Record<PurchaseType, string> = {
    ExtraByPartners: 'Extra by Partners',
    OnSale: 'On Sale',
    Trade: 'Trade',
};
