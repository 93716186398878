import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Grid from '@mui/material/Grid';
import { useMemo, useState } from 'react';
import { SeatingPlanCategoriesPreview } from 'src/app/components/previews/venues/seating-plan-categories-preview';
import { useFetchSPCsBySeatingplanId } from 'src/app/hooks/seating-plan-categories/useFetchSPCsBySeatingplanId';
import Button from 'src/view/components/button/Button';
import ModalSubTitle from 'src/view/components/modal/ModalSubtitle';
import { CreateEditSeatingPlanCategoryModal } from './CreateSeatingPlanCategoryModal';

export function SeatingPlanCategoriesList({ seatingPlanId }: { seatingPlanId: string }) {
    const [showArchivedSPCs, setShowArchivedSPCs] = useState(false);

    const [addSeatingplanCategoryOpen, setAddSeatingplanCategoryOpen] = useState(false);

    const { data, isFetching: isFetchingSPCs } = useFetchSPCsBySeatingplanId(seatingPlanId);

    const [archivedSeatingplanCategories, nonArchivedSeatingplanCategories] = useMemo(() => {
        /** TODO: Ideally, use single operation to do the both */
        const archived = data?.data.data.filter(({ isArchived }) => isArchived) || [];
        const nonArchived = data?.data.data.filter(({ isArchived }) => !isArchived) || [];

        return [archived, nonArchived];
    }, [data]);

    const seatingPlanCategoriesToRender = useMemo(() => {
        return showArchivedSPCs ? data?.data.data || [] : nonArchivedSeatingplanCategories;
    }, [data, showArchivedSPCs]);

    return (
        <>
            <Grid
                container
                justifyContent="space-between"
                sx={(theme) => ({
                    marginBottom: theme.spacing(1),
                    marginTop: theme.spacing(1),
                })}
            >
                <Grid item display="flex" alignItems="center">
                    <ModalSubTitle>Seating plan categories</ModalSubTitle>
                </Grid>
                <Grid item display="flex" alignItems="center" justifyContent="flex-end">
                    <Button
                        variant="text"
                        sx={{
                            color: (theme) => theme.colors.blue,
                        }}
                        onClick={() => setShowArchivedSPCs((prev) => !prev)}
                        disabled={archivedSeatingplanCategories.length === 0}
                    >
                        {showArchivedSPCs ? 'Hide' : 'Show'} archived (
                        {archivedSeatingplanCategories.length})
                    </Button>

                    <Button
                        variant="text"
                        startIcon={<AddCircleOutlineIcon />}
                        sx={(theme) => ({
                            color: theme.palette.tertiary.main,
                        })}
                        onClick={() => setAddSeatingplanCategoryOpen(true)}
                    >
                        Add seating plan category
                    </Button>
                </Grid>
            </Grid>

            <SeatingPlanCategoriesPreview
                seatingPlanCategories={seatingPlanCategoriesToRender}
                seatingPlanId={seatingPlanId}
                loading={isFetchingSPCs}
            />

            {addSeatingplanCategoryOpen && (
                <CreateEditSeatingPlanCategoryModal
                    seatingplanId={seatingPlanId}
                    setModalOpenState={setAddSeatingplanCategoryOpen}
                />
            )}
        </>
    );
}
