import urlService, { defaultCustomFields, PathConfig } from 'src/app/services/urlService';
import { filterOptionsToQueryParams } from 'src/app/utilities/helpers/filter';

const TicketManagementUrlService = {
    root(props?: PathConfig) {
        const { rootOnly } = props || {};

        if (rootOnly) return urlService.getTicketManagement();

        return urlService.getTicketManagement(
            `${filterOptionsToQueryParams({
                customFields: defaultCustomFields,
                suffix: 't-management',
            })}`
        );
    },
};

export default TicketManagementUrlService;
