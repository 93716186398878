import { getInternalAxiosInstance } from 'src/data/api/api';
import BaseFetchOptions from 'src/data/api/common/BaseFetchOptions';
import QueryParam from 'src/data/api/common/QueryParam';
import { getQueryParams } from 'src/data/api/endpointUrlBuilder';
import ApiResponse from 'src/data/api/responses/ApiResponse';
import ApiResponseBody from 'src/data/api/responses/ApiResponseBody';
import PaginatedApiResponseBody from 'src/data/api/responses/PaginatedApiResponseBody';
import {
    SERIES_GROUPS_CREATE_ENDPOINT,
    SERIES_GROUPS_DELETE_ENDPOINT,
    SERIES_GROUPS_GET_BY_ID_ENDPOINT,
    SERIES_GROUPS_GET_ENDPOINT,
    SERIES_GROUPS_UPDATE_ENDPOINT,
} from 'src/data/constants/endpoints/series-groups-endpoints';
import { IdResponse } from 'src/data/models/common/idResponse';
import SeriesGroup from 'src/data/models/series-group/SeriesGroup';

async function fetchSeriesGroups(
    options: BaseFetchOptions = {
        includes: [],
    }
): Promise<ApiResponse<PaginatedApiResponseBody<SeriesGroup>>> {
    const extraQueryParams: QueryParam[] = [];

    if (options.q) {
        extraQueryParams.push({
            key: 'q',
            value: options.q,
        });
    }

    return await getInternalAxiosInstance()
        .get<PaginatedApiResponseBody<SeriesGroup>>(SERIES_GROUPS_GET_ENDPOINT, {
            params: options && getQueryParams(options, extraQueryParams),
        })
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function fetchSeriesGroupById(
    seriesGroupId: string
): Promise<ApiResponse<ApiResponseBody<SeriesGroup>>> {
    return await getInternalAxiosInstance()
        .get<ApiResponseBody<SeriesGroup>>(SERIES_GROUPS_GET_BY_ID_ENDPOINT(seriesGroupId))
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export interface CreateSeriesGroupDto {
    name: string;
}

async function createSeriesGroup(
    dto: CreateSeriesGroupDto
): Promise<ApiResponse<ApiResponseBody<IdResponse>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<SeriesGroup>>(SERIES_GROUPS_CREATE_ENDPOINT, dto)
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function updateSeriesGroup(
    seriesGroupId: string,
    dto: CreateSeriesGroupDto
): Promise<ApiResponse<ApiResponseBody<IdResponse>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<SeriesGroup>>(SERIES_GROUPS_UPDATE_ENDPOINT(seriesGroupId), dto)
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

async function deleteSeriesGroup(
    seriesGroupId: string
): Promise<ApiResponse<ApiResponseBody<IdResponse>>> {
    return await getInternalAxiosInstance()
        .post<ApiResponseBody<SeriesGroup>>(SERIES_GROUPS_DELETE_ENDPOINT(seriesGroupId))
        .then((response) => ({
            data: response.data,
            headers: response.headers,
            statusCode: response.status,
        }));
}

export default {
    fetchSeriesGroups,
    fetchSeriesGroupById,
    createSeriesGroup,
    updateSeriesGroup,
    deleteSeriesGroup,
};
